import {
  CloseFullscreen,
  Edit,
  OpenInFull,
  StarBorderRounded,
  StarRounded,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Eye, XLg } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import { getPositionsOfOrganization } from "../../../../../services/AdminService";
import TaskService from "../../../../../services/TaskService";
import {
  getLoginUserDetails,
  getRatingList,
  renderRatingMessageIcon,
} from "../../../../../utils/Utils";
import {checkOtp} from "../../../../../utils/OtpServiceUtils" ;

export default function PmsFinalReviewForm({ data, handleClose, refreshData }) {
  const loginUser = getLoginUserDetails();
  const [isExpanded, setIsExpanded] = useState(true);

  const [positionsList, setPositionsList] = useState([]);
  const [employeePmsList, setEmployeePmsList] = useState([]);
  const [selectedEmployeeData, setSelectedEmployeeData] = useState(null);
  const [employeeEvaluationList, setEmployeeEvaluationList] = useState(null);

  const [refreshCount, setRefreshCount] = useState(0);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    const _fetchData = async () => {
      const res = await getPositionsOfOrganization(loginUser.orgId);
      if (res && res.data) setPositionsList(res.data);
    };
    if (data) {
      _fetchData();
      fetchData();
    }
  }, [data]);

  // const fetchData = async () => {
  //   const res = await TaskService.getPmsEmployeesForFinalReview(
  //     data.formDataTransId
  //   );
  //   if (res && res.data) setEmployeePmsList(res.data);
  // };

  const fetchData = async () => {
    try {
      const { otp, requestId } = await checkOtp("/pms/getPmsEmployeesForFinalReview");
      console.log('getPmsEmployeesForFinalReviewOTP ------->>>>', otp, requestId);
  
      const reqDto = {
        requestId: requestId,
        otp: otp,
        orgId: loginUser.orgId,
        pmsId: data.formDataTransId
      };
  
      const res = await TaskService.getPmsEmployeesForFinalReview(reqDto);
      if (res && res.data) {
        setEmployeePmsList(res.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    const _fetchData = async () => {
      const res = await TaskService.getEmployeeEvaluation(
        selectedEmployeeData.pmsEmpId
      );
      if (res && res.data) setEmployeeEvaluationList(res.data);
    };

    if (selectedEmployeeData) _fetchData();
  }, [selectedEmployeeData]);

  const handleSaveUserDetails = async () => {
    const reqDto = {
      pmsEmpId: selectedEmployeeData.pmsEmpId,
      hikeAmount: selectedEmployeeData.hikeAmount,
      hikePc: selectedEmployeeData.hikePc,
      newPositionId:
        selectedEmployeeData.reviewerRecommendPromotion === "Y"
          ? parseInt(selectedEmployeeData.reviewerRecommendPtositionId)
          : 0,
    };
    const res = await TaskService.saveFinalReview(loginUser.userId, reqDto);
    if (res && res.data && res.data > 0) {
      toast.success("Data saved successfully");
      await fetchData();
      setSelectedEmployeeData(null);
      setEmployeeEvaluationList(null);
    } else {
      toast.error("Something want wrong. Please try again.");
    }
  };

  const handleSubmit = async () => {
    let isValid = true;
    for (let i = 0; i < employeePmsList.length; i++) {
      const item = employeePmsList[i];
      if (item.pmsFlag !== "F") {
        toast.error(`Please submit the details of ${item.employeeName}`);
        isValid = false;
      }
    }
    if (!isValid) return;

    const reqDto = {
      taskDetailId: data.taskId,
      effectiveDate: data.taskCompletionTime,
      pmsId: data.formDataTransId,
    };
    setSubmitting(true);
    const res = await TaskService.submitFinalReview(
      loginUser.orgId,
      loginUser.userId,
      reqDto
    );
    setSubmitting(false);
    if (res && res.data && res.data > 0) {
      toast.success("Data submitted successfully");
      refreshData();
      handleClose();
    } else {
      toast.error("Something want wrong. Please try again.");
    }
  };

  return (
    <>
      <div
        className="RightFlotingFormContainerArea"
        style={{
          width: isExpanded ? "100%" : "",
        }}
      >
        <div className="flottingContainer">
          {/* header start */}
          <div className="flotHeaderPanel">
            <div class="flotHedCon">
              {data && data.taskName ? data.taskName : ""}
            </div>
            <div class="flotActionBtnGrp">
              <div class="flotActBtn closeBtnD10">
                <Tooltip
                  arrow
                  title={isExpanded ? "Exit Full Screen" : "Enter Full Screen"}
                >
                  <IconButton onClick={(e) => setIsExpanded((r) => !r)}>
                    {isExpanded ? <CloseFullscreen /> : <OpenInFull />}
                  </IconButton>
                </Tooltip>
              </div>
              <div class="flotActBtn closeBtnD10">
                <IconButton onClick={handleClose}>
                  <XLg />
                </IconButton>
              </div>
            </div>
          </div>
          {/* header end */}

          {/* body start */}
          <div className="flotContain">
            <div className="elementFormContainer previewSpace ">
              <div className="indentFormtable">
                <div className="indtableResponsive">
                  <table>
                    <thead>
                      <tr>
                        <th>Employee Name</th>
                        <th>Positions</th>
                        <th>Unit name</th>
                        <th>Current CTC</th>
                        <th>View Details</th>
                      </tr>
                    </thead>
                    <tbody>
                      {employeePmsList &&
                        employeePmsList.map((item) => {
                          return (
                            <tr key={item.pmsEmpId}>
                              <td>
                                <div className="textOnly">
                                  {item.employeeName}
                                </div>
                              </td>
                              <td>
                                <div className="textOnly">
                                  {item.positions &&
                                    item.positions.map((pos) => (
                                      <div className="textOnly">
                                        {pos.positionName}({pos.effectiveDate})
                                      </div>
                                    ))}
                                </div>
                              </td>
                              <td>
                                <div className="textOnly">{item.unitName}</div>
                              </td>
                              <td>
                                <div className="textOnly">
                                  {item.currentCtc}
                                  {item.currency}
                                </div>
                              </td>
                              <td>
                                <IconButton
                                  onClick={() => setSelectedEmployeeData(item)}
                                >
                                  {item.pmsFlag === "F" ? <Eye /> : <Edit />}
                                </IconButton>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {/* body end */}

          {/* footer start */}
          <div className="flotFooterPanel">
            <div className="formBtnElement">
              <Button
                className="dfultPrimaryBtn"
                onClick={() => {
                  if (!submitting) handleSubmit();
                }}
              >
                {!submitting ? "Submit" : "Submitting..."}
              </Button>
              <Button className="dfultDarkSecondaryBtn" onClick={handleClose}>
                Close
              </Button>
            </div>
          </div>
          {/* footer end */}
        </div>
      </div>

      {selectedEmployeeData && employeeEvaluationList && (
        <div className="bgOfset">
          <div className="bgOfsetOverlay"></div>
          <Box
            Box
            className="ModelBox pmsModelBox"
            sx={{ boxShadow: 24, p: 4 }}
          >
            <div className="addMoreAgendaModel">
              <div className="employeeDtlHeader">
                <ul className="empdtlUL">
                  <li className="empDtlList">
                    <span className="empDtlInfoHeAD">Employee Name:</span>
                    <span className="empDtlInfo">
                      {selectedEmployeeData.employeeName}
                    </span>
                  </li>
                  <li className="empDtlList">
                    <span className="empDtlInfoHeAD"> Positions:</span>
                    <span className="empDtlInfo">
                      {selectedEmployeeData.positions &&
                        selectedEmployeeData.positions.map((pos) => {
                          const str = `${pos.positionName}(${pos.effectiveDate}), `;
                          return str.substring(0, str.length - 2);
                        })}
                    </span>
                  </li>
                  <li className="empDtlList">
                    <span className="empDtlInfoHeAD">Unit name:</span>
                    <span className="empDtlInfo">
                      {selectedEmployeeData.unitName}
                    </span>
                  </li>
                  <li className="empDtlList">
                    <span className="empDtlInfoHeAD">Current CTC:</span>
                    <span className="empDtlInfo">
                      {selectedEmployeeData.currentCtc}
                      {selectedEmployeeData.currency}
                    </span>
                  </li>
                  <li className="empDtlList">
                    <span className="empDtlInfoHeAD">Last Increment Date:</span>
                    <span className="empDtlInfo">
                      {selectedEmployeeData.lastIncrementDate}
                    </span>
                  </li>
                  <li className="empDtlList">
                    <span className="empDtlInfoHeAD">Reviewer Name:</span>
                    <span className="empDtlInfo">
                      {selectedEmployeeData.reviewerName}
                    </span>
                  </li>
                  <li className="empDtlList">
                    <span className="empDtlInfoHeAD">Absent Percentage:</span>
                    <span className="empDtlInfo">
                      {selectedEmployeeData.absentPercentage}
                    </span>
                  </li>
                </ul>
              </div>

              <div className="elementFormContainer">
                <div className="indentFormtable">
                  <div className="modResptable mxH-55vh">
                    <table>
                      <thead>
                        <tr>
                          <th>Metrics</th>
                          <th>Self Rating</th>
                          <th>Reviewer Rating</th>
                          <th>Self Evaluation</th>
                          <th>Reviewer Evaluation</th>
                        </tr>
                      </thead>
                      <tbody>
                        {employeeEvaluationList.map((item) => {
                          return (
                            <tr key={item.pmsEmpMetricsId}>
                              <td>
                                <div className="textOnly">{item.metrics}</div>
                              </td>
                              <td>
                                <div className="textOnly">
                                  {item.selfRating}
                                </div>
                              </td>
                              <td>
                                <div className="textOnly">
                                  {item.reviewerRating}
                                </div>
                              </td>
                              <td>
                                <div className="textOnly">
                                  {item.selfEvaluation}
                                </div>
                              </td>
                              <td>
                                <div className="textOnly">
                                  {item.reviewerEvaluation}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                      {refreshCount >= 0 && (
                        <tfoot>
                          <tr>
                            <td colSpan={5}>
                              <div className="formElementGroup">
                                <div className="formElement width250">
                                  <FormControl className="formControl">
                                    <FormGroup className="formRadioGroup">
                                      <FormControlLabel
                                        className="formRadioField"
                                        control={
                                          <Checkbox
                                            defaultChecked={
                                              selectedEmployeeData.reviewerRecommendPromotion ===
                                              "Y"
                                            }
                                            value={
                                              selectedEmployeeData.reviewerRecommendPromotion ===
                                              "Y"
                                            }
                                            onChange={(e) => {
                                              selectedEmployeeData.reviewerRecommendPromotion =
                                                selectedEmployeeData.reviewerRecommendPromotion ===
                                                "Y"
                                                  ? "N"
                                                  : "Y";
                                              setRefreshCount((r) => r + 1);
                                            }}
                                          />
                                        }
                                        label="Recommend for promotion"
                                      />
                                    </FormGroup>
                                  </FormControl>
                                </div>
                                {selectedEmployeeData.reviewerRecommendPromotion ===
                                  "Y" && (
                                  <div className="formElement width250">
                                    <FormControl className="formControl">
                                      <InputLabel id="compTime">
                                        Select position
                                      </InputLabel>
                                      <Select
                                        className="modelSelectFild"
                                        variant="outlined"
                                        labelId="compTime"
                                        id="compTime-select"
                                        value={
                                          selectedEmployeeData.reviewerRecommendPtositionId
                                        }
                                        label="Select position"
                                        onChange={(e) => {
                                          selectedEmployeeData.reviewerRecommendPtositionId =
                                            e.target.value;
                                          setRefreshCount((r) => r + 1);
                                        }}
                                      >
                                        {positionsList &&
                                          positionsList.map((pos) => (
                                            <MenuItem
                                              value={pos.positionId}
                                              disabled={
                                                selectedEmployeeData.reviewerRecommendPromotion !==
                                                "Y"
                                              }
                                            >
                                              {pos.position}
                                            </MenuItem>
                                          ))}
                                      </Select>
                                    </FormControl>
                                  </div>
                                )}
                                <div className="formElement width250">
                                  <div className="ratingWithTxt">
                                    <div className="star-rating">
                                      {getRatingList(
                                        employeeEvaluationList[0]?.maxRating
                                      ).map((star) => (
                                        <span
                                          key={star}
                                          className={
                                            star <=
                                            selectedEmployeeData.reviewerOverallRating
                                              ? "star filled"
                                              : "star"
                                          }
                                          onClick={() => {
                                            selectedEmployeeData.reviewerOverallRating =
                                              star;
                                            setRefreshCount((r) => r + 1);
                                          }}
                                        >
                                          {star <=
                                          selectedEmployeeData.reviewerOverallRating ? (
                                            <StarRounded />
                                          ) : (
                                            <StarBorderRounded />
                                          )}
                                        </span>
                                      ))}
                                    </div>
                                    {renderRatingMessageIcon(
                                      selectedEmployeeData.reviewerOverallRating
                                    )}
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td colSpan={5}>
                              <div className="formElementGroup">
                                <div className="textOnly">
                                  Current CTC :{" "}
                                  {selectedEmployeeData.currentCtc}
                                </div>

                                <div className="formElement width250">
                                  <FormControl className="formControl">
                                    <TextField
                                      label="Hike Percent"
                                      variant="outlined"
                                      className="modelTextFild"
                                      multiline
                                      value={selectedEmployeeData.hikePc}
                                      onChange={(e) => {
                                        const val = e.target.value;
                                        if (val >= 0) {
                                          selectedEmployeeData.hikePc = val;
                                          selectedEmployeeData.hikeAmount =
                                            (selectedEmployeeData.currentCtc *
                                              val) /
                                            100;
                                          setRefreshCount((r) => r + 1);
                                        }
                                      }}
                                    />
                                  </FormControl>
                                </div>

                                <div className="formElement width250">
                                  <FormControl className="formControl">
                                    <TextField
                                      label="Hike Amount"
                                      variant="outlined"
                                      className="modelTextFild"
                                      multiline
                                      value={selectedEmployeeData.hikeAmount}
                                      onChange={(e) => {
                                        const val = e.target.value;
                                        if (val >= 0) {
                                          selectedEmployeeData.hikeAmount = val;
                                          const pc =
                                            (val /
                                              selectedEmployeeData.currentCtc) *
                                            100;
                                          selectedEmployeeData.hikePc =
                                            pc.toFixed(2);
                                          setRefreshCount((r) => r + 1);
                                        }
                                      }}
                                    />
                                  </FormControl>
                                </div>

                                <div className="textOnly">
                                  CTC after hike:{" "}
                                  {selectedEmployeeData.currentCtc +
                                    selectedEmployeeData.hikeAmount}
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tfoot>
                      )}
                    </table>
                  </div>
                </div>
              </div>

              <div className="modActionBtnGrp">
                <Button
                  className="dfultPrimaryBtn"
                  onClick={handleSaveUserDetails}
                >
                  Save
                </Button>
                <Button
                  className="dfultDarkSecondaryBtn"
                  onClick={() => {
                    setSelectedEmployeeData(null);
                    setEmployeeEvaluationList(null);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Box>
        </div>
      )}
    </>
  );
}
