import {
  AccessibleRounded,
  Add,
  ArrowBack,
  ArrowBackIosNewRounded,
  ArrowForwardIosRounded,
  MaleRounded,
  MonetizationOnOutlined,
  Search,
  StarRateRounded,
  TranslateRounded,
} from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import EXP from "../../../../dashboard/image/EXP";
import { Eye } from "react-bootstrap-icons";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DashboardServices from "../../../../../services/DashboardServices";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";

export default function ViewBookAppointmentDrList({
  handelCloseViewBookAppointmentDrList,
  closeAll,
  openViewFullSlot,
  handelOpenAppointmentBooking,
  refreshbookAppointMent,
  bookAppointClass,
  registeredPatient,
}) {
  const userDetails = jwtDecode(localStorage.getItem("token"));
  const { t } = useTranslation();
  const [monthSelect, setMonthSelect] = useState(new Date());
  const [selectDoctor, setSelectDoctor] = useState("");
  const [loading, setLoading] = useState(true);

  const handleChangselectDoctor = (event) => {
    setSelectDoctor(event.target.value);
  };
  const handleMonthSelect = (newDate) => {
    setMonthSelect(newDate);
  };
  const renderMonthContent = (month, shortMonth, longMonth, day) => {
    const fullYear = new Date(day).getFullYear();
    const tooltipText = `Tooltip for month: ${longMonth} ${fullYear}`;

    return <span title={tooltipText}>{shortMonth}</span>;
  };

  const [doctorAvailData, setdoctorAvailData] = useState([]);
  const [FilterDoctorAvailData, setFilterDoctorAvailData] = useState([]);
  const [searchDoctorQuery, setSearchDotorQuery] = useState("");
  const [searchSpecialityQuery, setSearchSpecialityQuery] = useState("");
  const [selectedSlotId, setSelectedSlotId] = useState(null);

  useEffect(() => {
    DashboardServices.getDoctorListWithAvailableSlot(
      userDetails.signleUnitId
    ).then((response) => {
      console.log("response", response.data);
      const sortedData = response.data.sort((a, b) => {
        return a.doctorName.localeCompare(b.doctorName);
      });
      console.log("sortedData", sortedData);
      setdoctorAvailData(sortedData);
      setFilterDoctorAvailData(sortedData);

      //  setdoctorAvailData(response.data);
      // setFilterDoctorAvailData(response.data);
      setLoading(false);
    });
  }, [refreshbookAppointMent]);
  useEffect(() => {
    ;
    console.log("registered patient");
  }, [registeredPatient]);

  const handleDotorSearch = (e) => {
    const query = e.target.value;
    setSearchDotorQuery(query);

    const filteredData = doctorAvailData.filter(
      (doctor) =>
        doctor.doctorName &&
        doctor.doctorName.toLowerCase().includes(query.toLowerCase())
    );

    setFilterDoctorAvailData(filteredData);
  };

  const handleSpecialitySearch = (e) => {
    const query = e.target.value;
    setSearchSpecialityQuery(query);

    const filteredData = doctorAvailData.filter(
      (doctor) =>
        doctor.specialization &&
        doctor.specialization.toLowerCase().includes(query.toLowerCase())
    );

    setFilterDoctorAvailData(filteredData);
  };

  const handleSlotClick = (doctorId, slot) => {
    setSelectedSlotId(slot.slotId);
    handleSubmitData(doctorId, slot);
  };

  const handleSubmitData = (doctorId, slot) => {
    console.log("doctorId", doctorId);

    console.log("slot", slot);

    const delDoctor = doctorAvailData.find((val) => val.doctorId === doctorId);

    const obj = {
      doctorName: delDoctor.doctorName,
      specialization: delDoctor.specialization,
      experience: delDoctor.experience,
      language: delDoctor.language,
      fees: delDoctor.fees,
      feesCurrency: delDoctor.feesCurrency,
      rating: delDoctor.rating,
      numberOfUserRated: delDoctor.numberOfUserRated,
      imageLink: delDoctor.imageLink,
      timezone: delDoctor.timezone,
      doctorId: delDoctor.doctorId,
      slot: {
        slotId: slot.slotId,
        fromTime: slot.fromTime,
        toTime: slot.toTime,
        patientName: "",
        patientUnitId: 0,
        patientAge: null,
        patientGender: "",
        patientId: 0,
      },
      date: new Date(slot.slotDate),
      patientData: registeredPatient || null,
    };

    console.log("obj", obj);

    handelOpenAppointmentBooking(obj);
  };

  return (
    <>
      <div className="AnalyticsDashboardContainer">
        <div className="AnalyticsDashboardSubHeader">
          <IconButton
            onClick={() => {
              handelCloseViewBookAppointmentDrList();
              closeAll();
            }}
          >
            <ArrowBack />
          </IconButton>
          {t("book_doctor_appointment")}
        </div>
        <div className="anDashCardContainArea BADoctorListContain">
          <div className="fullContainArea">
            <div className="cuHeaderGrp">
              <div className="formSearchGrp">
                <div className="searchTasklist">
                  <input
                    type="text"
                    class="serchTaskInput"
                    placeholder={t("search_by_dr")}
                    value={searchDoctorQuery}
                    onChange={handleDotorSearch}
                  />
                  <IconButton className="seacrhIcon">
                    <Search />
                  </IconButton>
                </div>
                <div className="searchTasklist">
                  <input
                    type="text"
                    class="serchTaskInput"
                    placeholder={t("search_by_dr_speciality")}
                    value={searchSpecialityQuery}
                    onChange={handleSpecialitySearch}
                  />
                  <IconButton className="seacrhIcon">
                    <Search />
                  </IconButton>
                </div>
              </div>
            </div>

            <div className="cuContainArea">
              {loading ? (
                <div className="center-progress" style={{ height: "65vh" }}>
                  <CircularProgress sx={{ marginTop: "180px" }} />
                </div>
              ) : doctorAvailData.length === 0 ? (
                <div class="noDataCard ">
                  <span>{t("oops")}</span>
                  <br />
                  <span className="sallMsg">{t("no_booked_doctor_slots_found")}</span>
                </div>
              ) : (
                <div className="appoinmentBookingSlot">
                  <div class="slots-container docList">
                    {FilterDoctorAvailData.map((doctor) => (
                      <div className="doctorListSlot">
                        <div className="doctorDetails">
                          <div className="doctorInfoWthImg">
                            <div className="docImage">
                              <img
                                // src="https://plus.unsplash.com/premium_photo-1661551577028-80cfb8e4d236?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NDV8fGRvY3RvcnxlbnwwfHwwfHx8MA%3D%3D"
                                src={doctor.imageLink}
                                alt="zoyel_doctor"
                              />
                            </div>
                            <div className="doctorFullInfo">
                              <div className="doctorNmSpc">
                                <div className="docName">
                                  {doctor.doctorName}
                                </div>
                                <div className="docSpclty">
                                  ({doctor.specialization})
                                </div>
                              </div>
                              <div className="docExpFeeLan">
                                <div className="docEFL">
                                  <div className="eflicon fillnone">
                                    <EXP />
                                  </div>
                                  <div className="eflText">
                                    {doctor.experience}
                                  </div>
                                </div>
                                <div className="docEFL">
                                  <div className="eflicon">
                                    <MonetizationOnOutlined />
                                  </div>
                                  <div className="eflText">
                                    {doctor.fees}
                                    <span> {doctor.feesCurrency}</span>
                                  </div>
                                </div>
                                <div className="docEFL">
                                  <div className="eflicon">
                                    <TranslateRounded />
                                  </div>
                                  {/* <div className="eflText">
                                    {doctor.language}
                                  </div> */}
                                  <div className="eflText">
                                    {doctor.language
                                      .split(",")
                                      .sort((a, b) =>
                                        a.trim().localeCompare(b.trim())
                                      )
                                      .join(", ")}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="doctorRating">
                            <div className="docRatvalue">
                              <StarRateRounded />
                              <span className="ratingvalue">
                                {doctor.rating || "N/A"}
                              </span>
                            </div>
                            <div className="docRatCount">
                              {doctor.numberOfUserRated} user
                            </div>
                          </div>
                        </div>
                        <div className="doctorDetailSlots">
                          {doctor.availableSlot.map((slot) => (
                            <div
                              // class="slot-box"
                              key={slot.slotId}
                              className={
                                bookAppointClass &&
                                selectedSlotId === slot.slotId
                                  ? "slot-box active"
                                  : "slot-box"
                              }
                              onClick={() => {
                                console.log("doctor data", doctor);
                                // handelOpenAppointmentBooking();
                                // handleSubmitData(doctor.doctorId ,slot)
                                handleSlotClick(doctor.doctorId, slot);
                              }}
                            >
                              <div className="slotInfo">
                                <div class="icon">
                                  <AccessibleRounded />
                                </div>
                                <div className="slotTime">
                                  <span className="date">
                                    {new Date(slot.slotDate).toLocaleDateString(
                                      "en-GB",
                                      {
                                        day: "2-digit",
                                        month: "short",
                                        year: "numeric",
                                      }
                                    )}
                                  </span>
                                  <span className="time">
                                    {slot.fromTime} - {slot.toTime}
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))}
                          {/* <div class="slot-box">
                        <div className="slotInfo">
                          <div class="icon">
                            <AccessibleRounded />
                          </div>
                          <div className="slotTime">
                            <span className="date">29 Aug 2024</span>
                            <span className="time"> 10:45 - 11:00</span>
                          </div>
                        </div>
                      </div>
                      <div class="slot-box">
                        <div className="slotInfo">
                          <div class="icon">
                            <AccessibleRounded />
                          </div>
                          <div className="slotTime">
                            <span className="date">29 Aug 2024</span>
                            <span className="time"> 11:00 - 11:15</span>
                          </div>
                        </div>
                      </div>
                      <div class="slot-box">
                        <div className="slotInfo">
                          <div class="icon">
                            <AccessibleRounded />
                          </div>
                          <div className="slotTime">
                            <span className="date">29 Aug 2024</span>
                            <span className="time"> 11:15 - 11:30</span>
                          </div>
                        </div>
                      </div>
                      <div class="slot-box">
                        <div className="slotInfo">
                          <div class="icon">
                            <AccessibleRounded />
                          </div>
                          <div className="slotTime">
                            <span className="date">29 Aug 2024</span>
                            <span className="time"> 11:30 - 11:45</span>
                          </div>
                        </div>
                      </div>
                      <div class="slot-box">
                        <div className="slotInfo">
                          <div class="icon">
                            <AccessibleRounded />
                          </div>
                          <div className="slotTime">
                            <span className="date">29 Aug 2024</span>
                            <span className="time"> 11:45 - 12:00</span>
                          </div>
                        </div>
                      </div>
                      <div class="slot-box">
                        <div className="slotInfo">
                          <div class="icon">
                            <AccessibleRounded />
                          </div>
                          <div className="slotTime">
                            <span className="date">29 Aug 2024</span>
                            <span className="time"> 10:30 - 10:45</span>
                          </div>
                        </div>
                      </div> */}

                          <Button
                            startIcon={<Eye />}
                            className="dfultPrimaryBtn "
                            // onClick={openViewFullSlot}
                            onClick={() => openViewFullSlot(doctor.doctorId)}
                          >
                            {t("more_slots")}
                          </Button>
                        </div>
                      </div>
                    ))}

                    {/* <div className="doctorListSlot">
                    <div className="doctorDetails">
                      <div className="doctorInfoWthImg">
                        <div className="docImage">
                          <img
                            src="https://plus.unsplash.com/premium_photo-1661551577028-80cfb8e4d236?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NDV8fGRvY3RvcnxlbnwwfHwwfHx8MA%3D%3D"
                            alt="zoyel_doctor"
                          />
                        </div>
                        <div className="doctorFullInfo">
                          <div className="doctorNmSpc">
                            <div className="docName">Dr. Sanket Santra</div>
                            <div className="docSpclty">
                              (Emergency Medicine)
                            </div>
                          </div>
                          <div className="docExpFeeLan">
                            <div className="docEFL">
                              <div className="eflicon fillnone">
                                <EXP />
                              </div>
                              <div className="eflText">6+ Years</div>
                            </div>
                            <div className="docEFL">
                              <div className="eflicon">
                                <TranslateRounded />
                              </div>
                              <div className="eflText">English, Spanish</div>
                            </div>
                            <div className="docEFL">
                              <div className="eflicon">
                                <MonetizationOnOutlined />
                              </div>
                              <div className="eflText">
                                750<span>usd</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="doctorRating">
                        <div className="docRatvalue">
                          <StarRateRounded />
                          <span className="ratingvalue">4.8</span>
                        </div>
                        <div className="docRatCount">1.7K user</div>
                      </div>
                    </div>
                    <div className="doctorDetailSlots">
                      <div
                        class="slot-box active"
                        onClick={() => {
                          handelOpenAppointmentBooking();
                        }}
                      >
                        <div className="slotInfo">
                          <div class="icon">
                            <AccessibleRounded />
                          </div>
                          <div className="slotTime">
                            <span className="date">29 Aug 2024</span>
                            <span className="time">10:30 - 10:45</span>
                          </div>
                        </div>
                      </div>
                      <div class="slot-box">
                        <div className="slotInfo">
                          <div class="icon">
                            <AccessibleRounded />
                          </div>
                          <div className="slotTime">
                            <span className="date">29 Aug 2024</span>
                            <span className="time"> 10:45 - 11:00</span>
                          </div>
                        </div>
                      </div>
                      <div class="slot-box">
                        <div className="slotInfo">
                          <div class="icon">
                            <AccessibleRounded />
                          </div>
                          <div className="slotTime">
                            <span className="date">29 Aug 2024</span>
                            <span className="time"> 11:00 - 11:15</span>
                          </div>
                        </div>
                      </div>
                      <div class="slot-box">
                        <div className="slotInfo">
                          <div class="icon">
                            <AccessibleRounded />
                          </div>
                          <div className="slotTime">
                            <span className="date">29 Aug 2024</span>
                            <span className="time"> 11:15 - 11:30</span>
                          </div>
                        </div>
                      </div>
                      <div class="slot-box">
                        <div className="slotInfo">
                          <div class="icon">
                            <AccessibleRounded />
                          </div>
                          <div className="slotTime">
                            <span className="date">29 Aug 2024</span>
                            <span className="time"> 11:30 - 11:45</span>
                          </div>
                        </div>
                      </div>
                      <div class="slot-box">
                        <div className="slotInfo">
                          <div class="icon">
                            <AccessibleRounded />
                          </div>
                          <div className="slotTime">
                            <span className="date">29 Aug 2024</span>
                            <span className="time"> 11:45 - 12:00</span>
                          </div>
                        </div>
                      </div>
                      <div class="slot-box">
                        <div className="slotInfo">
                          <div class="icon">
                            <AccessibleRounded />
                          </div>
                          <div className="slotTime">
                            <span className="date">29 Aug 2024</span>
                            <span className="time"> 10:30 - 10:45</span>
                          </div>
                        </div>
                      </div>
                      <Button
                        startIcon={<Eye />}
                        className="dfultPrimaryBtn "
                        onClick={openViewFullSlot}
                      >
                        More Slots
                      </Button>
                    </div>
                  </div> */}

                    {/* <div className="doctorListSlot">
                    <div className="doctorDetails">
                      <div className="doctorInfoWthImg">
                        <div className="docImage">
                          <img
                            src="https://images.unsplash.com/photo-1612276529418-52e6ad86ee1d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NjB8fGRvY3RvcnxlbnwwfHwwfHx8MA%3D%3D"
                            alt="zoyel_doctor"
                          />
                        </div>
                        <div className="doctorFullInfo">
                          <div className="doctorNmSpc">
                            <div className="docName">Dr. Prashant Saha</div>
                            <div className="docSpclty">
                              (Emergency Medicine)
                            </div>
                          </div>
                          <div className="docExpFeeLan">
                            <div className="docEFL">
                              <div className="eflicon fillnone">
                                <EXP />
                              </div>
                              <div className="eflText">6+ Years</div>
                            </div>
                            <div className="docEFL">
                              <div className="eflicon">
                                <TranslateRounded />
                              </div>
                              <div className="eflText">English, Spanish</div>
                            </div>
                            <div className="docEFL">
                              <div className="eflicon">
                                <MonetizationOnOutlined />
                              </div>
                              <div className="eflText">
                                750<span>usd</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="doctorRating">
                        <div className="docRatvalue">
                          <StarRateRounded />
                          <span className="ratingvalue">4.8</span>
                        </div>
                        <div className="docRatCount">1.7K user</div>
                      </div>
                    </div>
                    <div className="doctorDetailSlots">
                      <div class="slot-box">
                        <div className="slotInfo">
                          <div class="icon">
                            <AccessibleRounded />
                          </div>
                          <div className="slotTime">
                            <span className="date">29 Aug 2024</span>
                            <span className="time">10:30 - 10:45</span>
                          </div>
                        </div>
                      </div>
                      <div class="slot-box">
                        <div className="slotInfo">
                          <div class="icon">
                            <AccessibleRounded />
                          </div>
                          <div className="slotTime">
                            <span className="date">29 Aug 2024</span>
                            <span className="time"> 10:45 - 11:00</span>
                          </div>
                        </div>
                      </div>
                      <div class="slot-box">
                        <div className="slotInfo">
                          <div class="icon">
                            <AccessibleRounded />
                          </div>
                          <div className="slotTime">
                            <span className="date">29 Aug 2024</span>
                            <span className="time"> 11:00 - 11:15</span>
                          </div>
                        </div>
                      </div>
                      <div class="slot-box">
                        <div className="slotInfo">
                          <div class="icon">
                            <AccessibleRounded />
                          </div>
                          <div className="slotTime">
                            <span className="date">29 Aug 2024</span>
                            <span className="time"> 11:15 - 11:30</span>
                          </div>
                        </div>
                      </div>
                      <div class="slot-box">
                        <div className="slotInfo">
                          <div class="icon">
                            <AccessibleRounded />
                          </div>
                          <div className="slotTime">
                            <span className="date">29 Aug 2024</span>
                            <span className="time"> 11:30 - 11:45</span>
                          </div>
                        </div>
                      </div>
                      <div class="slot-box">
                        <div className="slotInfo">
                          <div class="icon">
                            <AccessibleRounded />
                          </div>
                          <div className="slotTime">
                            <span className="date">29 Aug 2024</span>
                            <span className="time"> 11:45 - 12:00</span>
                          </div>
                        </div>
                      </div>
                      <div class="slot-box">
                        <div className="slotInfo">
                          <div class="icon">
                            <AccessibleRounded />
                          </div>
                          <div className="slotTime">
                            <span className="date">29 Aug 2024</span>
                            <span className="time"> 10:30 - 10:45</span>
                          </div>
                        </div>
                      </div>
                      <Button
                        startIcon={<Eye />}
                        className="dfultPrimaryBtn "
                        onClick={openViewFullSlot}
                      >
                        More Slots
                      </Button>
                    </div>
                  </div>

                  <div className="doctorListSlot">
                    <div className="doctorDetails">
                      <div className="doctorInfoWthImg">
                        <div className="docImage">
                          <img
                            src="https://images.unsplash.com/photo-1582750433449-648ed127bb54?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mjd8fGRvY3RvcnxlbnwwfHwwfHx8MA%3D%3D"
                            alt="zoyel_doctor"
                          />
                        </div>
                        <div className="doctorFullInfo">
                          <div className="doctorNmSpc">
                            <div className="docName">Dr. Sk Aftabuddin</div>
                            <div className="docSpclty">
                              (Emergency Medicine)
                            </div>
                          </div>
                          <div className="docExpFeeLan">
                            <div className="docEFL">
                              <div className="eflicon fillnone">
                                <EXP />
                              </div>
                              <div className="eflText">6+ Years</div>
                            </div>
                            <div className="docEFL">
                              <div className="eflicon">
                                <TranslateRounded />
                              </div>
                              <div className="eflText">English, Spanish</div>
                            </div>
                            <div className="docEFL">
                              <div className="eflicon">
                                <MonetizationOnOutlined />
                              </div>
                              <div className="eflText">
                                750<span>usd</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="doctorRating">
                        <div className="docRatvalue">
                          <StarRateRounded />
                          <span className="ratingvalue">4.8</span>
                        </div>
                        <div className="docRatCount">1.7K user</div>
                      </div>
                    </div>
                    <div className="doctorDetailSlots">
                      <div class="slot-box">
                        <div className="slotInfo">
                          <div class="icon">
                            <AccessibleRounded />
                          </div>
                          <div className="slotTime">
                            <span className="date">29 Aug 2024</span>
                            <span className="time">10:30 - 10:45</span>
                          </div>
                        </div>
                      </div>
                      <div class="slot-box">
                        <div className="slotInfo">
                          <div class="icon">
                            <AccessibleRounded />
                          </div>
                          <div className="slotTime">
                            <span className="date">29 Aug 2024</span>
                            <span className="time"> 10:45 - 11:00</span>
                          </div>
                        </div>
                      </div>
                      <div class="slot-box">
                        <div className="slotInfo">
                          <div class="icon">
                            <AccessibleRounded />
                          </div>
                          <div className="slotTime">
                            <span className="date">29 Aug 2024</span>
                            <span className="time"> 11:00 - 11:15</span>
                          </div>
                        </div>
                      </div>
                      <div class="slot-box">
                        <div className="slotInfo">
                          <div class="icon">
                            <AccessibleRounded />
                          </div>
                          <div className="slotTime">
                            <span className="date">29 Aug 2024</span>
                            <span className="time"> 11:15 - 11:30</span>
                          </div>
                        </div>
                      </div>
                      <div class="slot-box">
                        <div className="slotInfo">
                          <div class="icon">
                            <AccessibleRounded />
                          </div>
                          <div className="slotTime">
                            <span className="date">29 Aug 2024</span>
                            <span className="time"> 11:30 - 11:45</span>
                          </div>
                        </div>
                      </div>
                      <div class="slot-box">
                        <div className="slotInfo">
                          <div class="icon">
                            <AccessibleRounded />
                          </div>
                          <div className="slotTime">
                            <span className="date">29 Aug 2024</span>
                            <span className="time"> 11:45 - 12:00</span>
                          </div>
                        </div>
                      </div>
                      <div class="slot-box">
                        <div className="slotInfo">
                          <div class="icon">
                            <AccessibleRounded />
                          </div>
                          <div className="slotTime">
                            <span className="date">29 Aug 2024</span>
                            <span className="time"> 10:30 - 10:45</span>
                          </div>
                        </div>
                      </div>
                      <Button
                        startIcon={<Eye />}
                        className="dfultPrimaryBtn "
                        onClick={openViewFullSlot}
                      >
                        More Slots
                      </Button>
                    </div>
                  </div>

                  <div className="doctorListSlot">
                    <div className="doctorDetails">
                      <div className="doctorInfoWthImg">
                        <div className="docImage">
                          <img
                            src="https://plus.unsplash.com/premium_photo-1661551577028-80cfb8e4d236?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NDV8fGRvY3RvcnxlbnwwfHwwfHx8MA%3D%3D"
                            alt="zoyel_doctor"
                          />
                        </div>
                        <div className="doctorFullInfo">
                          <div className="doctorNmSpc">
                            <div className="docName">Dr. Sanket Santra</div>
                            <div className="docSpclty">
                              (Emergency Medicine)
                            </div>
                          </div>
                          <div className="docExpFeeLan">
                            <div className="docEFL">
                              <div className="eflicon fillnone">
                                <EXP />
                              </div>
                              <div className="eflText">6+ Years</div>
                            </div>
                            <div className="docEFL">
                              <div className="eflicon">
                                <TranslateRounded />
                              </div>
                              <div className="eflText">English, Spanish</div>
                            </div>
                            <div className="docEFL">
                              <div className="eflicon">
                                <MonetizationOnOutlined />
                              </div>
                              <div className="eflText">
                                750<span>usd</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="doctorRating">
                        <div className="docRatvalue">
                          <StarRateRounded />
                          <span className="ratingvalue">4.8</span>
                        </div>
                        <div className="docRatCount">1.7K user</div>
                      </div>
                    </div>
                    <div className="doctorDetailSlots">
                      <div class="slot-box">
                        <div className="slotInfo">
                          <div class="icon">
                            <AccessibleRounded />
                          </div>
                          <div className="slotTime">
                            <span className="date">29 Aug 2024</span>
                            <span className="time">10:30 - 10:45</span>
                          </div>
                        </div>
                      </div>
                      <div class="slot-box">
                        <div className="slotInfo">
                          <div class="icon">
                            <AccessibleRounded />
                          </div>
                          <div className="slotTime">
                            <span className="date">29 Aug 2024</span>
                            <span className="time"> 10:45 - 11:00</span>
                          </div>
                        </div>
                      </div>
                      <div class="slot-box">
                        <div className="slotInfo">
                          <div class="icon">
                            <AccessibleRounded />
                          </div>
                          <div className="slotTime">
                            <span className="date">29 Aug 2024</span>
                            <span className="time"> 11:00 - 11:15</span>
                          </div>
                        </div>
                      </div>
                      <div class="slot-box">
                        <div className="slotInfo">
                          <div class="icon">
                            <AccessibleRounded />
                          </div>
                          <div className="slotTime">
                            <span className="date">29 Aug 2024</span>
                            <span className="time"> 11:15 - 11:30</span>
                          </div>
                        </div>
                      </div>
                      <div class="slot-box">
                        <div className="slotInfo">
                          <div class="icon">
                            <AccessibleRounded />
                          </div>
                          <div className="slotTime">
                            <span className="date">29 Aug 2024</span>
                            <span className="time"> 11:30 - 11:45</span>
                          </div>
                        </div>
                      </div>
                      <div class="slot-box">
                        <div className="slotInfo">
                          <div class="icon">
                            <AccessibleRounded />
                          </div>
                          <div className="slotTime">
                            <span className="date">29 Aug 2024</span>
                            <span className="time"> 11:45 - 12:00</span>
                          </div>
                        </div>
                      </div>
                      <div class="slot-box">
                        <div className="slotInfo">
                          <div class="icon">
                            <AccessibleRounded />
                          </div>
                          <div className="slotTime">
                            <span className="date">29 Aug 2024</span>
                            <span className="time"> 10:30 - 10:45</span>
                          </div>
                        </div>
                      </div>
                      <Button
                        startIcon={<Eye />}
                        className="dfultPrimaryBtn "
                        onClick={openViewFullSlot}
                      >
                        More Slots
                      </Button>
                    </div>
                  </div>

                  <div className="doctorListSlot">
                    <div className="doctorDetails">
                      <div className="doctorInfoWthImg">
                        <div className="docImage">
                          <img
                            src="https://images.unsplash.com/photo-1612276529418-52e6ad86ee1d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NjB8fGRvY3RvcnxlbnwwfHwwfHx8MA%3D%3D"
                            alt="zoyel_doctor"
                          />
                        </div>
                        <div className="doctorFullInfo">
                          <div className="doctorNmSpc">
                            <div className="docName">Dr. Prashant Saha</div>
                            <div className="docSpclty">
                              (Emergency Medicine)
                            </div>
                          </div>
                          <div className="docExpFeeLan">
                            <div className="docEFL">
                              <div className="eflicon fillnone">
                                <EXP />
                              </div>
                              <div className="eflText">6+ Years</div>
                            </div>
                            <div className="docEFL">
                              <div className="eflicon">
                                <TranslateRounded />
                              </div>
                              <div className="eflText">English, Spanish</div>
                            </div>
                            <div className="docEFL">
                              <div className="eflicon">
                                <MonetizationOnOutlined />
                              </div>
                              <div className="eflText">
                                750<span>usd</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="doctorRating">
                        <div className="docRatvalue">
                          <StarRateRounded />
                          <span className="ratingvalue">4.8</span>
                        </div>
                        <div className="docRatCount">1.7K user</div>
                      </div>
                    </div>
                    <div className="doctorDetailSlots">
                      <div class="slot-box">
                        <div className="slotInfo">
                          <div class="icon">
                            <AccessibleRounded />
                          </div>
                          <div className="slotTime">
                            <span className="date">29 Aug 2024</span>
                            <span className="time">10:30 - 10:45</span>
                          </div>
                        </div>
                      </div>
                      <div class="slot-box">
                        <div className="slotInfo">
                          <div class="icon">
                            <AccessibleRounded />
                          </div>
                          <div className="slotTime">
                            <span className="date">29 Aug 2024</span>
                            <span className="time"> 10:45 - 11:00</span>
                          </div>
                        </div>
                      </div>
                      <div class="slot-box">
                        <div className="slotInfo">
                          <div class="icon">
                            <AccessibleRounded />
                          </div>
                          <div className="slotTime">
                            <span className="date">29 Aug 2024</span>
                            <span className="time"> 11:00 - 11:15</span>
                          </div>
                        </div>
                      </div>
                      <div class="slot-box">
                        <div className="slotInfo">
                          <div class="icon">
                            <AccessibleRounded />
                          </div>
                          <div className="slotTime">
                            <span className="date">29 Aug 2024</span>
                            <span className="time"> 11:15 - 11:30</span>
                          </div>
                        </div>
                      </div>
                      <div class="slot-box">
                        <div className="slotInfo">
                          <div class="icon">
                            <AccessibleRounded />
                          </div>
                          <div className="slotTime">
                            <span className="date">29 Aug 2024</span>
                            <span className="time"> 11:30 - 11:45</span>
                          </div>
                        </div>
                      </div>
                      <div class="slot-box">
                        <div className="slotInfo">
                          <div class="icon">
                            <AccessibleRounded />
                          </div>
                          <div className="slotTime">
                            <span className="date">29 Aug 2024</span>
                            <span className="time"> 11:45 - 12:00</span>
                          </div>
                        </div>
                      </div>
                      <div class="slot-box">
                        <div className="slotInfo">
                          <div class="icon">
                            <AccessibleRounded />
                          </div>
                          <div className="slotTime">
                            <span className="date">29 Aug 2024</span>
                            <span className="time"> 10:30 - 10:45</span>
                          </div>
                        </div>
                      </div>
                      <Button
                        startIcon={<Eye />}
                        className="dfultPrimaryBtn "
                        onClick={openViewFullSlot}
                      >
                        More Slots
                      </Button>
                    </div>
                  </div>

                  <div className="doctorListSlot">
                    <div className="doctorDetails">
                      <div className="doctorInfoWthImg">
                        <div className="docImage">
                          <img
                            src="https://images.unsplash.com/photo-1582750433449-648ed127bb54?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mjd8fGRvY3RvcnxlbnwwfHwwfHx8MA%3D%3D"
                            alt="zoyel_doctor"
                          />
                        </div>
                        <div className="doctorFullInfo">
                          <div className="doctorNmSpc">
                            <div className="docName">Dr. Sk Aftabuddin</div>
                            <div className="docSpclty">
                              (Emergency Medicine)
                            </div>
                          </div>
                          <div className="docExpFeeLan">
                            <div className="docEFL">
                              <div className="eflicon fillnone">
                                <EXP />
                              </div>
                              <div className="eflText">6+ Years</div>
                            </div>
                            <div className="docEFL">
                              <div className="eflicon">
                                <TranslateRounded />
                              </div>
                              <div className="eflText">English, Spanish</div>
                            </div>
                            <div className="docEFL">
                              <div className="eflicon">
                                <MonetizationOnOutlined />
                              </div>
                              <div className="eflText">
                                750<span>usd</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="doctorRating">
                        <div className="docRatvalue">
                          <StarRateRounded />
                          <span className="ratingvalue">4.8</span>
                        </div>
                        <div className="docRatCount">1.7K user</div>
                      </div>
                    </div>
                    <div className="doctorDetailSlots">
                      <div class="slot-box">
                        <div className="slotInfo">
                          <div class="icon">
                            <AccessibleRounded />
                          </div>
                          <div className="slotTime">
                            <span className="date">29 Aug 2024</span>
                            <span className="time">10:30 - 10:45</span>
                          </div>
                        </div>
                      </div>
                      <div class="slot-box">
                        <div className="slotInfo">
                          <div class="icon">
                            <AccessibleRounded />
                          </div>
                          <div className="slotTime">
                            <span className="date">29 Aug 2024</span>
                            <span className="time"> 10:45 - 11:00</span>
                          </div>
                        </div>
                      </div>
                      <div class="slot-box">
                        <div className="slotInfo">
                          <div class="icon">
                            <AccessibleRounded />
                          </div>
                          <div className="slotTime">
                            <span className="date">29 Aug 2024</span>
                            <span className="time"> 11:00 - 11:15</span>
                          </div>
                        </div>
                      </div>
                      <div class="slot-box">
                        <div className="slotInfo">
                          <div class="icon">
                            <AccessibleRounded />
                          </div>
                          <div className="slotTime">
                            <span className="date">29 Aug 2024</span>
                            <span className="time"> 11:15 - 11:30</span>
                          </div>
                        </div>
                      </div>
                      <div class="slot-box">
                        <div className="slotInfo">
                          <div class="icon">
                            <AccessibleRounded />
                          </div>
                          <div className="slotTime">
                            <span className="date">29 Aug 2024</span>
                            <span className="time"> 11:30 - 11:45</span>
                          </div>
                        </div>
                      </div>
                      <div class="slot-box">
                        <div className="slotInfo">
                          <div class="icon">
                            <AccessibleRounded />
                          </div>
                          <div className="slotTime">
                            <span className="date">29 Aug 2024</span>
                            <span className="time"> 11:45 - 12:00</span>
                          </div>
                        </div>
                      </div>
                      <div class="slot-box">
                        <div className="slotInfo">
                          <div class="icon">
                            <AccessibleRounded />
                          </div>
                          <div className="slotTime">
                            <span className="date">29 Aug 2024</span>
                            <span className="time"> 10:30 - 10:45</span>
                          </div>
                        </div>
                      </div>
                      <Button
                        startIcon={<Eye />}
                        className="dfultPrimaryBtn "
                        onClick={openViewFullSlot}
                      >
                        More Slots
                      </Button>
                    </div>
                  </div> */}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
