import { AttachFile } from "@mui/icons-material";
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  TextField,
  Select,
  MenuItem,
  Checkbox,
  OutlinedInput,
  ListItemText,
  FormGroup,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Eye, XLg } from "react-bootstrap-icons";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  getOrganizationWorkTiming,
  updateWorkingTiming,
} from "../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";
import DateUtils from "../../../../utils/DateUtils";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 2;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 100,
    },
  },
};

export default function AddWorkingDays({ handelCloseAddWorkingday }) {
  const userDetails = jwtDecode(localStorage.getItem("token"));
  const { t } = useTranslation();

  const [organizationWorkTimingData, setOrganizationWorkTimingData] = useState(
    []
  );

  const [loading, setLoading] = useState(true);

  const [loaders, setLoaders] = useState(false);

  useEffect(() => {
    fetchOrganisationWorkTiming();
  }, [userDetails.orgId, userDetails.userId]);

  const fetchOrganisationWorkTiming = () => {
    getOrganizationWorkTiming(userDetails.orgId, userDetails.userId).then(
      (response) => {
        console.log("getOrganizationWorkTiming", response.data);
        setOrganizationWorkTimingData(response.data);
        setLoading(false);
      }
    );
  };

  const weekNo = ["1st Week", "2nd Week", "3rd Week", "4th Week", "5th Week"];

  // const [selectweekNo, setSelectweekNo] = React.useState([]);

  // const handleChangeWeekNo = (event) => {
  //   console.log('handleChangeWeekNo', event.target.value);
  //   const {
  //     target: { value },
  //   } = event;
  //   setSelectweekNo(
  //     // On autofill we get a stringified value.
  //     typeof value === "string" ? value.split(",") : value
  //   );
  //   console.log('selectweekNo',value)
  // };

  // const [selectedStartTime, setSelectedStartTime] = useState(null);

  // const changeStartTime = (date) => {
  //   console.log('changeStartTime', date);
  //   setSelectedStartTime(date);
  // };

  const [startTimes, setStartTimes] = useState({});

  useEffect(() => {
    const initialEndTimes = {};
    organizationWorkTimingData.forEach((dayData) => {
      initialEndTimes[dayData.dayId] = new Date(
        `2000-01-01T${dayData.fromTime}`
      );
    });
    setStartTimes(initialEndTimes);
  }, [organizationWorkTimingData]);

  const changeStartTime = (newDate, dayId) => {
    setStartTimes((prevStartTimes) => ({
      ...prevStartTimes,
      [dayId]: newDate,
    }));
  };

  console.log("startTimes", startTimes);

  const [endTimes, setEndTimes] = useState({});

  useEffect(() => {
    const initialEndTimes = {};
    organizationWorkTimingData.forEach((dayData) => {
      initialEndTimes[dayData.dayId] = new Date(`2000-01-01T${dayData.toTime}`);
    });
    setEndTimes(initialEndTimes);
  }, [organizationWorkTimingData]);

  const changeEndTime = (newDate, dayId) => {
    setEndTimes((prevEndTimes) => ({
      ...prevEndTimes,
      [dayId]: newDate,
    }));
  };

  console.log("endTimes", endTimes);

  // const [selectedEndTime, setSelectedEndTime] = useState(null);

  // const changeEndTime = (newDate) => {
  //   console.log('changeEndTime', newDate);
  //   setSelectedEndTime(newDate);
  // };

  const [selectedWeeks, setSelectedWeeks] = useState({});

  // Populate selectedWeeks state with default values when the component mounts
  // useEffect(() => {
  //   const initialSelectedWeeks = {};
  //   organizationWorkTimingData.forEach((dayData) => {
  //     // initialSelectedWeeks[dayData.dayId] = dayData.workingWeeks.split(",").map(Number);
  //     initialSelectedWeeks[dayData.dayId] = dayData.workingWeeks
  //       ? dayData.workingWeeks.split(",").map(Number)
  //       : [];
  //   });
  //   setSelectedWeeks(initialSelectedWeeks);
  // }, [organizationWorkTimingData]);

  useEffect(() => {
    const initialSelectedWeeks = {};
    organizationWorkTimingData.forEach((dayData) => {
      initialSelectedWeeks[dayData.dayId] = dayData.workingWeeks
        ? dayData.workingWeeks.split(",").map(Number)
        : [];
    });
    setSelectedWeeks(initialSelectedWeeks);
  }, [organizationWorkTimingData]);

  const handleChangeWeekNo = (event, dayId) => {
    const { value } = event.target;
    setSelectedWeeks((prevSelectedWeeks) => ({
      ...prevSelectedWeeks,
      [dayId]: value,
    }));
  };

  console.log("selectedWeeks", selectedWeeks);

  const formatTimeTo12Hour = (timeString) => {
    const [hours, minutes] = timeString.split(":");
    const hour = parseInt(hours, 10);
    const suffix = hour >= 12 ? "p.m." : "a.m.";
    const formattedHour = hour % 12 || 12; // Convert 0 to 12
    return `${formattedHour}:${minutes} ${suffix}`;
  };

  const [editMode, setEditMode] = useState(false);

  const handleEditModeChange = (event) => {
    console.log("handleEditModeChange", event.target.checked);
    setEditMode(event.target.checked);
  };

  // const updateFinalSubmit = () =>{
  //   console.log('updateFinalSubmit');

  //   const reqDtoArray = organizationWorkTimingData.map(dayData => {
  //     const reqDto = {
  //         id: dayData.id,
  //         dayId: dayData.dayId,
  //         dayName: dayData.dayName,
  //         workingWeeks: (selectedWeeks[dayData.dayId].length > 0 ? selectedWeeks[dayData.dayId].join(',') : '0'),
  //         businessHours:`${DateUtils.printAMPM(
  //           startTimes[dayData.dayId]
  //         )} to ${DateUtils.printAMPM(endTimes[dayData.dayId])}`,
  //         fromTime: DateUtils.getTimeInHHMM(startTimes[dayData.dayId]),
  //         toTime: DateUtils.getTimeInHHMM(endTimes[dayData.dayId])
  //     };
  //     return reqDto;
  // });

  // console.log('reqDtoArray', reqDtoArray);

  // updateWorkingTiming(reqDtoArray)
  // .then((response)=>{
  //   if (response.data) {
  //     if (response.data.returnValue === "1") {
  //       toast.success(response.data.message, {
  //         position: toast.POSITION.TOP_RIGHT,
  //       });
  //       // this.setState({ showEditModal: false });
  //       // this.fetchData();
  //       fetchOrganisationWorkTiming();
  //       setEditMode(false);
  //     } else {
  //       toast.error(response.data.message, {
  //         position: toast.POSITION.TOP_RIGHT,
  //       });
  //     }
  //   }
  // })

  // }

  const updateFinalSubmit = async () => {
    setLoaders(true);
    console.log("updateFinalSubmit");

    let success = true;

    for (let i = 0; i < organizationWorkTimingData.length; i++) {
      const dayData = organizationWorkTimingData[i];
      // const reqDto = {
      //   id: dayData.id,
      //   dayId: dayData.dayId,
      //   dayName: dayData.dayName,
      //   workingWeeks: selectedWeeks[dayData.dayId].join(","),
      //   businessHours: `${DateUtils.printAMPM(
      //     startTimes[dayData.dayId]
      //   )} to ${DateUtils.printAMPM(endTimes[dayData.dayId])}`,
      //   fromTime: DateUtils.getTimeInHHMM(startTimes[dayData.dayId]),
      //   toTime: DateUtils.getTimeInHHMM(endTimes[dayData.dayId]),
      // };

      const reqDto = {
        id: dayData.id,
        dayId: dayData.dayId,
        dayName: dayData.dayName,
        workingWeeks: selectedWeeks[dayData.dayId].join(","),
        businessHours: "",
        fromTime:"",
        toTime:"",
      };

      console.log("reqDto", reqDto);

      const response = await updateWorkingTiming(reqDto);

      if (response.data && response.data.returnValue !== "1") {
        success = false;
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoaders(false);
        break;
      }
    }

    if (success) {
      toast.success(t("all_updates_have_been_successfully_applied"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoaders(false);
      fetchOrganisationWorkTiming();
      setEditMode(false);
    }
  };

  return (
    <>
      <div className="rightFlotingPanel">
        <div className="rightFlotingContainer">
          <div className="rfContHeader">
            <div className="rfcHeadText">{t("working_days")}</div>
            <div className="rfcHActionBtnGrp">
              <div className="actionBtn closeBtnD10">
                <IconButton onClick={() => handelCloseAddWorkingday()}>
                  <XLg />
                </IconButton>
              </div>
            </div>
          </div>
          <div className="rfContContain">
            <div className="rfContContainDetails">
              {loading ? (
                <div className="center-progress" style={{ height: "65vh" }}>
                  <CircularProgress
                    sx={{ marginTop: "180px", marginLeft: "50px" }}
                  />
                </div>
              ) : (
                <div className="elementFormContainer WorkingDaysHours">
                  <div className="formElement">
                    <FormControl className="formControl">
                      <FormGroup
                        aria-label="position"
                        row
                        className="feformGroup"
                      >
                        <FormControlLabel
                          className="formCheckBox"
                          control={<Checkbox  checked={editMode} onChange={handleEditModeChange} />}
                          label={t("do_you_want_to_edit_this")}
                          labelPlacement="End"
                        />
                      </FormGroup>
                    </FormControl>
                  </div>

              {console.log('editMode', editMode)}

                  {editMode && organizationWorkTimingData.map((dayData) => (
                    
                    <div className="formElementGrp" key={dayData.id}>
                      <div className="daysName">{dayData.dayName}</div>

                      {/* <div className="formElement mxW100-2">
                        <FormControl className="formControl">
                          <InputLabel
                            id={`workStartTime_${dayData.dayName}`}
                            className="setCompletionDate"
                          >
                            Start Time*
                          </InputLabel>
                          <ReactDatePicker
                            labelId={`workStartTime_${dayData.dayName}`}
                            className="formDatePicker onlyTime"
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={30}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                            selected={
                              startTimes[dayData.dayId] ||
                              new Date(`2000-01-01T${dayData.fromTime}`)
                            }
                            onChange={(newDate) =>
                              changeStartTime(newDate, dayData.dayId)
                            }
                            disabled={!editMode}
                          />
                        </FormControl>
                      </div> */}
                      {/* <div className="formElement mxW100-2">
                        <FormControl className="formControl">
                          <InputLabel
                            id={`workEndTime_${dayData.dayName}`}
                            className="setCompletionDate"
                          >
                            End Time*
                          </InputLabel>
                          <ReactDatePicker
                            labelId={`workEndTime_${dayData.dayName}`}
                            className="formDatePicker onlyTime"
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={30}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                            selected={
                              endTimes[dayData.dayId] ||
                              new Date(`2000-01-01T${dayData.toTime}`)
                            }
                            onChange={(newDate) =>
                              changeEndTime(newDate, dayData.dayId)
                            }
                            disabled={!editMode}
                          />
                        </FormControl>
                      </div> */}

                      <div className="formElement">
                        <FormControl className="formControl">
                          <InputLabel id={`Employee_Gender_${dayData.dayName}`}>
                          {t("select_week")}
                          </InputLabel>
                          <Select
                            className="formInputField"
                            variant="outlined"
                            labelId={`Employee_Gender_${dayData.dayName}`}
                            id={`EmployeeGender_${dayData.dayName}`}
                            multiple
                            value={selectedWeeks[dayData.dayId] || []}
                            onChange={(event) =>
                              handleChangeWeekNo(event, dayData.dayId)
                            }
                            input={<OutlinedInput label={t("select_week")} />}
                            renderValue={(selected) =>
                              selected
                                .map((index) => weekNo[index - 1])
                                .join(", ")
                            }
                            MenuProps={MenuProps}
                            disabled={!editMode}
                          >
                            {weekNo.map((weeks, index) => (
                              <MenuItem key={weeks} value={index + 1}>
                                <Checkbox
                                  // checked={selectweekNo.indexOf(weeks) > -1}
                                  // checked={dayData.workingWeeks.includes(String(index + 1))}
                                  checked={(
                                    selectedWeeks[dayData.dayId] || []
                                  ).includes(index + 1)}
                                />
                                <ListItemText primary={weeks} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  ))}

                  {!editMode && organizationWorkTimingData.map((dayData) => {
                    // Check if we should display this day
                    const showDay = selectedWeeks[dayData.dayId] && selectedWeeks[dayData.dayId].length > 0 && dayData.workingWeeks !== '0';

                    return showDay ? (
                      <div className="formElementGrp" key={dayData.id}>
                        <div className="daysName">{dayData.dayName}</div>

                        {/* <div className="formElement mxW100-2">
                          <FormControl className="formControl">
                            <InputLabel
                              id={`workStartTime_${dayData.dayName}`}
                              className="setCompletionDate"
                            >
                              Start Time*
                            </InputLabel>
                            <ReactDatePicker
                              labelId={`workStartTime_${dayData.dayName}`}
                              className="formDatePicker onlyTime"
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={30}
                              timeCaption="Time"
                              dateFormat="h:mm aa"
                              selected={
                                startTimes[dayData.dayId] ||
                                new Date(`2000-01-01T${dayData.fromTime}`)
                              }
                              onChange={(newDate) =>
                                changeStartTime(newDate, dayData.dayId)
                              }
                              disabled={!editMode}
                            />
                          </FormControl>
                        </div> */}
                        {/* <div className="formElement mxW100-2">
                          <FormControl className="formControl">
                            <InputLabel
                              id={`workEndTime_${dayData.dayName}`}
                              className="setCompletionDate"
                            >
                              End Time*
                            </InputLabel>
                            <ReactDatePicker
                              labelId={`workEndTime_${dayData.dayName}`}
                              className="formDatePicker onlyTime"
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={30}
                              timeCaption="Time"
                              dateFormat="h:mm aa"
                              selected={
                                endTimes[dayData.dayId] ||
                                new Date(`2000-01-01T${dayData.toTime}`)
                              }
                              onChange={(newDate) =>
                                changeEndTime(newDate, dayData.dayId)
                              }
                              disabled={!editMode}
                            />
                          </FormControl>
                        </div> */}

                        <div className="formElement">
                          <FormControl className="formControl">
                            <InputLabel id={`Employee_Gender_${dayData.dayName}`}>
                              {t("select_week")}
                            </InputLabel>
                            <Select
                              className="formInputField"
                              variant="outlined"
                              labelId={`Employee_Gender_${dayData.dayName}`}
                              id={`EmployeeGender_${dayData.dayName}`}
                              multiple
                              value={selectedWeeks[dayData.dayId] || []}
                              onChange={(event) =>
                                handleChangeWeekNo(event, dayData.dayId)
                              }
                              input={<OutlinedInput label="Select Week" />}
                              renderValue={(selected) =>
                                selected
                                  .map((index) => weekNo[index - 1])
                                  .join(", ")
                              }
                              MenuProps={MenuProps}
                              disabled={!editMode}
                            >
                              {weekNo.map((weeks, index) => (
                                <MenuItem key={weeks} value={index + 1}>
                                  <Checkbox
                                    // checked={selectweekNo.indexOf(weeks) > -1}
                                    // checked={dayData.workingWeeks.includes(String(index + 1))}
                                    checked={(
                                      selectedWeeks[dayData.dayId] || []
                                    ).includes(index + 1)}
                                  />
                                  <ListItemText primary={weeks} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    ) : null;
                  })}


                  {/* <div className="formElementGrp">
                  <div className="daysName">Sunday</div>

                  <div className="formElement width120">
                    <FormControl className="formControl">
                      <InputLabel
                        id="workStartTime"
                        className="setCompletionDate"
                      >
                        Start Time*
                      </InputLabel>
                      <ReactDatePicker
                        labelId="workStartTime"
                        className="formDatePicker onlyTime"
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        selected={selectedStartTime}
                        onChange={(date) => changeStartTime(date)}
                      />
                    </FormControl>
                  </div>
                  <div className="formElement width120">
                    <FormControl className="formControl">
                      <InputLabel
                        id="workEndTime"
                        className="setCompletionDate"
                      >
                        End Time*
                      </InputLabel>
                      <ReactDatePicker
                        labelId="workEndTime"
                        className="formDatePicker onlyTime"
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        selected={selectedEndTime}
                        onChange={(date) => changeEndTime(date)}
                      />
                    </FormControl>
                  </div>

                  <div className="formElement">
                    <FormControl className="formControl">
                      <InputLabel id="Employee_Gender">Select Week</InputLabel>
                      <Select
                        className="formInputField"
                        variant="outlined"
                        labelId="Employee_Gender"
                        id="EmployeeGender"
                        multiple
                        value={selectweekNo}
                        onChange={handleChangeWeekNo}
                        input={<OutlinedInput label="Select Week" />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                      >
                        {weekNo.map((weeks) => (
                          <MenuItem key={weeks} value={weeks}>
                            <Checkbox
                              checked={selectweekNo.indexOf(weeks) > -1}
                            />
                            <ListItemText primary={weeks} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="formElementGrp">
                  <div className="daysName">Monday</div>

                  <div className="formElement width120">
                    <FormControl className="formControl">
                      <InputLabel
                        id="workStartTime"
                        className="setCompletionDate"
                      >
                        Start Time*
                      </InputLabel>
                      <ReactDatePicker
                        labelId="workStartTime"
                        className="formDatePicker onlyTime"
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        selected={selectedStartTime}
                        onChange={(date) => changeStartTime(date)}
                      />
                    </FormControl>
                  </div>
                  <div className="formElement width120">
                    <FormControl className="formControl">
                      <InputLabel
                        id="workEndTime"
                        className="setCompletionDate"
                      >
                        End Time*
                      </InputLabel>
                      <ReactDatePicker
                        labelId="workEndTime"
                        className="formDatePicker onlyTime"
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        selected={selectedEndTime}
                        onChange={(date) => changeEndTime(date)}
                      />
                    </FormControl>
                  </div>

                  <div className="formElement">
                    <FormControl className="formControl">
                      <InputLabel id="Employee_Gender">Select Week</InputLabel>
                      <Select
                        className="formInputField"
                        variant="outlined"
                        labelId="Employee_Gender"
                        id="EmployeeGender"
                        multiple
                        value={selectweekNo}
                        onChange={handleChangeWeekNo}
                        input={<OutlinedInput label="Select Week" />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                      >
                        {weekNo.map((weeks) => (
                          <MenuItem key={weeks} value={weeks}>
                            <Checkbox
                              checked={selectweekNo.indexOf(weeks) > -1}
                            />
                            <ListItemText primary={weeks} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="formElementGrp">
                  <div className="daysName">Tuesday</div>

                  <div className="formElement width120">
                    <FormControl className="formControl">
                      <InputLabel
                        id="workStartTime"
                        className="setCompletionDate"
                      >
                        Start Time*
                      </InputLabel>
                      <ReactDatePicker
                        labelId="workStartTime"
                        className="formDatePicker onlyTime"
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        selected={selectedStartTime}
                        onChange={(date) => changeStartTime(date)}
                      />
                    </FormControl>
                  </div>
                  <div className="formElement width120">
                    <FormControl className="formControl">
                      <InputLabel
                        id="workEndTime"
                        className="setCompletionDate"
                      >
                        End Time*
                      </InputLabel>
                      <ReactDatePicker
                        labelId="workEndTime"
                        className="formDatePicker onlyTime"
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        selected={selectedEndTime}
                        onChange={(date) => changeEndTime(date)}
                      />
                    </FormControl>
                  </div>

                  <div className="formElement">
                    <FormControl className="formControl">
                      <InputLabel id="Employee_Gender">Select Week</InputLabel>
                      <Select
                        className="formInputField"
                        variant="outlined"
                        labelId="Employee_Gender"
                        id="EmployeeGender"
                        multiple
                        value={selectweekNo}
                        onChange={handleChangeWeekNo}
                        input={<OutlinedInput label="Select Week" />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                      >
                        {weekNo.map((weeks) => (
                          <MenuItem key={weeks} value={weeks}>
                            <Checkbox
                              checked={selectweekNo.indexOf(weeks) > -1}
                            />
                            <ListItemText primary={weeks} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="formElementGrp">
                  <div className="daysName">Wednesday</div>

                  <div className="formElement width120">
                    <FormControl className="formControl">
                      <InputLabel
                        id="workStartTime"
                        className="setCompletionDate"
                      >
                        Start Time*
                      </InputLabel>
                      <ReactDatePicker
                        labelId="workStartTime"
                        className="formDatePicker onlyTime"
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        selected={selectedStartTime}
                        onChange={(date) => changeStartTime(date)}
                      />
                    </FormControl>
                  </div>
                  <div className="formElement width120">
                    <FormControl className="formControl">
                      <InputLabel
                        id="workEndTime"
                        className="setCompletionDate"
                      >
                        End Time*
                      </InputLabel>
                      <ReactDatePicker
                        labelId="workEndTime"
                        className="formDatePicker onlyTime"
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        selected={selectedEndTime}
                        onChange={(date) => changeEndTime(date)}
                      />
                    </FormControl>
                  </div>

                  <div className="formElement">
                    <FormControl className="formControl">
                      <InputLabel id="Employee_Gender">Select Week</InputLabel>
                      <Select
                        className="formInputField"
                        variant="outlined"
                        labelId="Employee_Gender"
                        id="EmployeeGender"
                        multiple
                        value={selectweekNo}
                        onChange={handleChangeWeekNo}
                        input={<OutlinedInput label="Select Week" />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                      >
                        {weekNo.map((weeks) => (
                          <MenuItem key={weeks} value={weeks}>
                            <Checkbox
                              checked={selectweekNo.indexOf(weeks) > -1}
                            />
                            <ListItemText primary={weeks} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="formElementGrp">
                  <div className="daysName">Thursday</div>

                  <div className="formElement width120">
                    <FormControl className="formControl">
                      <InputLabel
                        id="workStartTime"
                        className="setCompletionDate"
                      >
                        Start Time*
                      </InputLabel>
                      <ReactDatePicker
                        labelId="workStartTime"
                        className="formDatePicker onlyTime"
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        selected={selectedStartTime}
                        onChange={(date) => changeStartTime(date)}
                      />
                    </FormControl>
                  </div>
                  <div className="formElement width120">
                    <FormControl className="formControl">
                      <InputLabel
                        id="workEndTime"
                        className="setCompletionDate"
                      >
                        End Time*
                      </InputLabel>
                      <ReactDatePicker
                        labelId="workEndTime"
                        className="formDatePicker onlyTime"
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        selected={selectedEndTime}
                        onChange={(date) => changeEndTime(date)}
                      />
                    </FormControl>
                  </div>

                  <div className="formElement">
                    <FormControl className="formControl">
                      <InputLabel id="Employee_Gender">Select Week</InputLabel>
                      <Select
                        className="formInputField"
                        variant="outlined"
                        labelId="Employee_Gender"
                        id="EmployeeGender"
                        multiple
                        value={selectweekNo}
                        onChange={handleChangeWeekNo}
                        input={<OutlinedInput label="Select Week" />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                      >
                        {weekNo.map((weeks) => (
                          <MenuItem key={weeks} value={weeks}>
                            <Checkbox
                              checked={selectweekNo.indexOf(weeks) > -1}
                            />
                            <ListItemText primary={weeks} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="formElementGrp">
                  <div className="daysName">Friday</div>

                  <div className="formElement width120">
                    <FormControl className="formControl">
                      <InputLabel
                        id="workStartTime"
                        className="setCompletionDate"
                      >
                        Start Time*
                      </InputLabel>
                      <ReactDatePicker
                        labelId="workStartTime"
                        className="formDatePicker onlyTime"
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        selected={selectedStartTime}
                        onChange={(date) => changeStartTime(date)}
                      />
                    </FormControl>
                  </div>
                  <div className="formElement width120">
                    <FormControl className="formControl">
                      <InputLabel
                        id="workEndTime"
                        className="setCompletionDate"
                      >
                        End Time*
                      </InputLabel>
                      <ReactDatePicker
                        labelId="workEndTime"
                        className="formDatePicker onlyTime"
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        selected={selectedEndTime}
                        onChange={(date) => changeEndTime(date)}
                      />
                    </FormControl>
                  </div>

                  <div className="formElement">
                    <FormControl className="formControl">
                      <InputLabel id="Employee_Gender">Select Week</InputLabel>
                      <Select
                        className="formInputField"
                        variant="outlined"
                        labelId="Employee_Gender"
                        id="EmployeeGender"
                        multiple
                        value={selectweekNo}
                        onChange={handleChangeWeekNo}
                        input={<OutlinedInput label="Select Week" />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                      >
                        {weekNo.map((weeks) => (
                          <MenuItem key={weeks} value={weeks}>
                            <Checkbox
                              checked={selectweekNo.indexOf(weeks) > -1}
                            />
                            <ListItemText primary={weeks} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="formElementGrp">
                  <div className="daysName">Saturday</div>

                  <div className="formElement width120">
                    <FormControl className="formControl">
                      <InputLabel
                        id="workStartTime"
                        className="setCompletionDate"
                      >
                        Start Time*
                      </InputLabel>
                      <ReactDatePicker
                        labelId="workStartTime"
                        className="formDatePicker onlyTime"
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        selected={selectedStartTime}
                        onChange={(date) => changeStartTime(date)}
                      />
                    </FormControl>
                  </div>
                  <div className="formElement width120">
                    <FormControl className="formControl">
                      <InputLabel
                        id="workEndTime"
                        className="setCompletionDate"
                      >
                        End Time*
                      </InputLabel>
                      <ReactDatePicker
                        labelId="workEndTime"
                        className="formDatePicker onlyTime"
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        selected={selectedEndTime}
                        onChange={(date) => changeEndTime(date)}
                      />
                    </FormControl>
                  </div>

                  <div className="formElement">
                    <FormControl className="formControl">
                      <InputLabel id="Employee_Gender">Select Week</InputLabel>
                      <Select
                        className="formInputField"
                        variant="outlined"
                        labelId="Employee_Gender"
                        id="EmployeeGender"
                        multiple
                        value={selectweekNo}
                        onChange={handleChangeWeekNo}
                        input={<OutlinedInput label="Select Week" />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                      >
                        {weekNo.map((weeks) => (
                          <MenuItem key={weeks} value={weeks}>
                            <Checkbox
                              checked={selectweekNo.indexOf(weeks) > -1}
                            />
                            <ListItemText primary={weeks} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div> */}
                </div>
              )}
            </div>
          </div>
          <div className="rfContFooter">
            <div className="formBtnElement">
              <Button
                className="dfultPrimaryBtn"
                onClick={() => updateFinalSubmit()}
                disabled={!editMode}
              >
                {loaders ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  <>{t("update")}</>
                )}
              </Button>
              {!loaders && (
                <Button
                  className="dfultDarkSecondaryBtn"
                  // disabled={!editMode}
                  onClick={() => handelCloseAddWorkingday()}
                >
                  {t("cancel")}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
