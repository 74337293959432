import { Add, ArrowBack, Search } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { FileEarmarkArrowDownFill, Prescription } from "react-bootstrap-icons";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DashboardServices from "../../../../../services/DashboardServices";
import DateUtils from "../../../../../utils/DateUtils";
import { apiEndPoint } from "../../../../../constants/url_provider";
import { toast } from "react-toastify";
// import zhPatientService from "../../../../../services/ZhPatientService";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";

export default function ViewPatientDocument({
  handelCloseViewPatientDocument,
  closeAll,
}) {
  const userDetails = jwtDecode(localStorage.getItem("token"));
  const { t } = useTranslation();
  const [patientDocumentData, setPatientDocumentData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterpatientDocumentData, setFilterPatientDocumentData] = useState(
    []
  );
  const [loading, setLoading] = useState(true);
  const [primaryPosdate, setPrimaryPosdate] = useState(new Date());
  const handleJoiningprimaryPosdate = (newDate) => {
    setPrimaryPosdate(newDate);
  };

  const [primaryToDate, setPrimaryToDate] = useState(new Date());

  const handleToDateChange = (newDate) => {
    setPrimaryToDate(newDate);
  };

  const [selectPatient, setSelectPatient] = useState();
  const handleChangselectPatient = () => {
    setSelectPatient();
  };
  const [documentType, setDocumentType] = useState("PRESCRIPTION");
  const handleChangDocumentType = (event) => {
    setDocumentType(event.target.value);
  };

  useEffect(() => {
    fetchPatientdocumentData();
  }, []);

  const getMaxDate = (date1) => {
    
    const date2 = new Date();
    if (!date1) {
      return date2;
    }
    const tempDate = date1 < date2 ? date1 : date2;
    return tempDate;
  };

  const fetchPatientdocumentData = () => {
    console.log("fetch data");

    const reqDto = {
      formDate: DateUtils.getDateInDDMMYYYY(primaryPosdate),
      toDate: DateUtils.getDateInDDMMYYYY(primaryToDate),
      patientId: 0,
      documentType: documentType,
      unitId: userDetails.signleUnitId,
    };

    console.log("reqDto", reqDto);

    // return;

    DashboardServices.getPatientDocumentsOfUnit(reqDto).then((response) => {
      console.log("response of get patient", response.data);
      setPatientDocumentData(response.data);
      setFilterPatientDocumentData(response.data);
      setLoading(false);
    });
  };

  console.log("patientDocumentData", patientDocumentData);

  const handleDownLoadFile = (patient) => {
    console.log("patient", patient);
    if (patient.prescriptionFileName !== null) {
      toast.info(t("please_wait_file_is_getting_get_downloaded"), {
        position: toast.POSITION.TOP_RIGHT,
      });

      const newLink = patient.prescriptionFileName.replace(/\//g, "~");
      console.log("newLink", newLink);

      let newFolderLink = patient.folderName
        .replace(/\//g, "~")
        .replace(/\\/g, "~");
      console.log("newLink", newFolderLink);

      // let reqDto = {
      //   field1: newLink ,
      //   field2: patient.folderName+"/Prescription" ,
      //   field3: 300
      // }
      // zhPatientService.presigned(reqDto)
      // .then((response)=>{
      //   console.log('response', response.data);
      //    window.open(
      //     response.data ,
      //   "_blank",
      //   "noopener,noreferrer"
      // );
      // })

      window.open(
        apiEndPoint + `/appTest/downloadFileTest/${newLink}/${newFolderLink}`,
        "_blank",
        "noopener,noreferrer"
      );
    } else {
      toast.error(t("the_file_cannot_be_downloaded"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    const filteredData = patientDocumentData.filter(
      (patientDocument) =>
        patientDocument.patientName &&
        patientDocument.patientName.toLowerCase().includes(query.toLowerCase())
    );

    setFilterPatientDocumentData(filteredData);
  };

  return (
    <>
      <div className="AnalyticsDashboardContainer">
        <div className="AnalyticsDashboardSubHeader">
          <IconButton
            onClick={() => {
              handelCloseViewPatientDocument();
              closeAll();
            }}
          >
            <ArrowBack />
          </IconButton>
          {t("patient_documents")}
        </div>
        <div className="anDashCardContainArea">
          <div className="fullContainArea">
            <div className="cuHeaderGrp">
              {/* <div className="cuhederTxt">Departmment List</div> */}
              {/* <div className="searchTasklist">
                <input
                  type="text"
                  class="serchTaskInput"
                  placeholder="Search Patient"
                />
                <IconButton className="seacrhIcon">
                  <Search />
                </IconButton>
              </div> */}
              <div className="formSearchGrp">
                <div className="formElement width180">
                  <FormControl className="formControl">
                    <InputLabel id="primPosition" className="setCompletionDate">
                    {t("from_date")}
                    </InputLabel>
                    <ReactDatePicker
                      toggleCalendarOnIconClick
                      showYearDropdown
                      yearDropdownItemNumber={100}
                      scrollableYearDropdown
                      showMonthDropdown
                      showIcon
                      labelId="primPosition"
                      className="formDatePicker"
                      selected={primaryPosdate}
                      // minDate={new Date()}
                      maxDate={new Date()}
                      dateFormat="dd/MM/yyyy"
                      onChange={(date) => {
                        handleJoiningprimaryPosdate(date);
                      }}
                    />
                  </FormControl>
                </div>
                <div className="formElement width180">
                  <FormControl className="formControl">
                    <InputLabel id="primPosition" className="setCompletionDate">
                    {t("to_date")}
                    </InputLabel>
                    <ReactDatePicker
                      toggleCalendarOnIconClick
                      showYearDropdown
                      yearDropdownItemNumber={100}
                      scrollableYearDropdown
                      showMonthDropdown
                      showIcon
                      labelId="primPosition"
                      className="formDatePicker"
                      selected={primaryToDate}
                      // minDate={new Date()}
                      // maxDate={new Date()}
                      minDate={primaryPosdate}
                      maxDate={getMaxDate(
                        primaryPosdate
                          ? new Date(
                              primaryPosdate.getFullYear(),
                              primaryPosdate.getMonth() + 3,
                              primaryPosdate.getDate()
                            )
                          : null
                      )}
                      dateFormat="dd/MM/yyyy"
                      onChange={(date) => {
                        handleToDateChange(date);
                      }}
                    />
                  </FormControl>
                </div>
                <Button
                  className="dfultPrimaryBtn"
                  onClick={fetchPatientdocumentData}
                  startIcon={<Search />}
                >
                  {t("search")}
                </Button>

                {/* <div className="formElement width250">
                  <FormControl className="formControl">
                    <InputLabel id="selectPatient">Select Patient</InputLabel>
                    <Select
                      required
                      labelId="selectPatient"
                      id="select_Patient"
                      value={selectPatient}
                      label="Select Patient"
                      onChange={handleChangselectPatient}
                      className="formInputField"
                      variant="outlined"
                    >
                      <MenuItem value="sanket">Sanket Santra</MenuItem>
                    </Select>
                  </FormControl>
                </div> */}
              </div>

              {/* <div className="addUnitsBtn">
                <Button
                  startIcon={<Add />}
                  className="dfultPrimaryBtn"
                  onClick={() => handelOpenAddBookConsultation()}
                >
                  Book Consultation
                </Button>
              </div> */}

              <div className="formElement width250">
                <FormControl className="formControl">
                  <InputLabel id="documentType">{t("document_type")}</InputLabel>
                  <Select
                    required
                    labelId="documentType"
                    id="select_documentType"
                    value={documentType}
                    label={t("document_type")}
                    onChange={handleChangDocumentType}
                    className="formInputField"
                    variant="outlined"
                  >
                    <MenuItem value="PRESCRIPTION">{t("prescription")}</MenuItem>
                    {/* <MenuItem value="investigation">Investigation</MenuItem> */}
                  </Select>
                </FormControl>
              </div>
            </div>

            <div className="cuContainArea">
              {loading ? (
                <div className="center-progress" style={{ height: "65vh" }}>
                  <CircularProgress sx={{ marginTop: "180px" }} />
                </div>
              ) : patientDocumentData.length === 0 ? (
                <div class="noDataCard ">
                  <span>{t("oops")}</span>
                  <br />
                  <span className="sallMsg">
                  {t("no_patient_document_data_is_present")}
                  </span>
                </div>
              ) : (
                <div className="TableContainer">
                  <table className="taskListTable">
                    <thead className="taskListtableHeader">
                      <tr>
                        <th>
                          <div className="textWsearch">
                            <span>{t("patient_name")}</span>

                            <div className="searchTasklist">
                              <input
                                type="text"
                                class="serchTaskInput"
                                placeholder={t("search_patient")}
                                value={searchQuery}
                                onChange={handleSearch}
                              />
                            </div>
                          </div>
                        </th>
                        {/* <th>Email id </th> */}
                        {/* <th>Center Name </th> */}
                        <th>{t("consulting_doctor")}</th>
                        <th>{t("consulting_start_time")}</th>
                        <th>{t("consulting_end_time")}</th>
                        {/* <th className="width180">Consultations Status</th> */}
                        <th className="width100">{t("document")}</th>
                      </tr>
                    </thead>
                    <tbody className="scrolableTableBody">
                      {filterpatientDocumentData.map((patient, index) => (
                        <tr key={index}>
                          <td>
                            <div className="tskLstTaskNM tdFdC">
                              <span className="tdHtext">
                                {patient.patientName}
                              </span>
                              <span className="tdStext">
                                ({patient.gender}, {patient.age})
                              </span>
                            </div>
                          </td>
                          {/* <td>
          <div className="tskLstTaskNM">
            example@example.com
          </div>
        </td> */}
                          {/* <td>
          <div className="tskLstTaskNM tdFdC">
            <span className="tdHtext">Example Center Name</span>
            <span className="tdStext">(+91 1234567890)</span>
          </div>
        </td> */}
                          <td>
                            <div className="tskLstTaskNM tdFdC">
                              <span className="tdHtext">
                                {patient?.doctorName?.split(".").join(". ")}
                              </span>
                              <span className="tdStext">
                                ({patient?.doctorSpecilization})
                              </span>
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {patient.consultStartTime}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {patient.consultEndTime}
                            </div>
                          </td>
                          {/* <td>
          <div className="tskLstTaskNM">
            <span className="constStatus statSuccessfull">
              Completed
            </span>
          </div>
         </td> */}
                          <td>
                            <div className="tblActionBtnGrp jcc">
                              <Tooltip title={t("download_prescription")} arrow>
                                <IconButton
                                  className="dnPresc"
                                  onClick={() => handleDownLoadFile(patient)}
                                >
                                  <FileEarmarkArrowDownFill />
                                </IconButton>
                              </Tooltip>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>

                    {/* <tbody className="scrolableTableBody">
                    <tr>
                      <td>
                        <div className="tskLstTaskNM tdFdC">
                          <span className="tdHtext">Sk Aftabuddin</span>
                          <span className="tdStext">(Male, 15/Mar/1996)</span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          skaftabuddin@gmail.com
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM tdFdC">
                          <span className="tdHtext">New Town, Kolkata</span>
                          <span className="tdStext">(+91 7384979442)</span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM tdFdC">
                          <span className="tdHtext">Dr. Sanket Santra</span>
                          <span className="tdStext">(Orthopedic Surgeon)</span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          <span className="constStatus statSuccessfull">
                            Completed
                          </span>
                        </div>
                      </td>

                      <td>
                        <div className="tblActionBtnGrp jcc">
                          <IconButton className="dnPresc">
                            <FileEarmarkArrowDownFill />
                          </IconButton>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="tskLstTaskNM tdFdC">
                          <span className="tdHtext">Vikash Kumar</span>
                          <span className="tdStext">(Male, 25/Aug/1993)</span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          vikashkumar@gmail.com
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM tdFdC">
                          <span className="tdHtext">New Town, Kolkata</span>
                          <span className="tdStext">(+91 7384979442)</span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM tdFdC">
                          <span className="tdHtext">Dr. Prashnat Shah</span>
                          <span className="tdStext">(Cardiologists)</span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          <span className="constStatus statCancel">Cancel</span>
                        </div>
                      </td>

                      <td>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="tskLstTaskNM tdFdC">
                          <span className="tdHtext">Sk Aftabuddin</span>
                          <span className="tdStext">(Male, 15/Mar/1996)</span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          skaftabuddin@gmail.com
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM tdFdC">
                          <span className="tdHtext">New Town, Kolkata</span>
                          <span className="tdStext">(+91 7384979442)</span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM tdFdC">
                          <span className="tdHtext">Dr. Sanket Santra</span>
                          <span className="tdStext">(Orthopedic Surgeon)</span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          <span className="constStatus statSuccessfull">
                            Completed
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className="tblActionBtnGrp jcc">
                          <IconButton className="dnPresc">
                            <FileEarmarkArrowDownFill />
                          </IconButton>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <div className="tskLstTaskNM tdFdC">
                          <span className="tdHtext">Rahul Singh</span>
                          <span className="tdStext">(Male, 22/Dec/1988)</span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          rahul.singh88@gmail.com
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM tdFdC">
                          <span className="tdHtext">Sector 5, Noida</span>
                          <span className="tdStext">(+91 9823456789)</span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM tdFdC">
                          <span className="tdHtext">Dr. Anjali Mehra</span>
                          <span className="tdStext">(Cardiologist)</span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          <span className="constStatus statSuccessfull">
                            Completed
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className="tblActionBtnGrp jcc">
                          <IconButton className="dnPresc">
                            <FileEarmarkArrowDownFill />
                          </IconButton>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <div className="tskLstTaskNM tdFdC">
                          <span className="tdHtext">Priya Sharma</span>
                          <span className="tdStext">(Female, 11/Jan/1993)</span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          priya.sharma93@gmail.com
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM tdFdC">
                          <span className="tdHtext">Andheri, Mumbai</span>
                          <span className="tdStext">(+91 9004561230)</span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM tdFdC">
                          <span className="tdHtext">Dr. Suresh Nair</span>
                          <span className="tdStext">(Dermatologist)</span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          <span className="constStatus statSuccessfull">
                            Completed
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className="tblActionBtnGrp jcc">
                          <IconButton className="dnPresc">
                            <FileEarmarkArrowDownFill />
                          </IconButton>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <div className="tskLstTaskNM tdFdC">
                          <span className="tdHtext">Amitabh Chatterjee</span>
                          <span className="tdStext">(Male, 07/Feb/1978)</span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">amitabh78@yahoo.com</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM tdFdC">
                          <span className="tdHtext">Salt Lake, Kolkata</span>
                          <span className="tdStext">(+91 9876543210)</span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM tdFdC">
                          <span className="tdHtext">Dr. Rina Banerjee</span>
                          <span className="tdStext">(Gynecologist)</span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          <span className="constStatus statSuccessfull">
                            Completed
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className="tblActionBtnGrp jcc">
                          <IconButton className="dnPresc">
                            <FileEarmarkArrowDownFill />
                          </IconButton>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <div className="tskLstTaskNM tdFdC">
                          <span className="tdHtext">Rohit Gupta</span>
                          <span className="tdStext">(Male, 10/Jul/1985)</span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          rohit.gupta85@gmail.com
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM tdFdC">
                          <span className="tdHtext">Alwarpet, Chennai</span>
                          <span className="tdStext">(+91 9012345678)</span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM tdFdC">
                          <span className="tdHtext">Dr. Ramesh Kumar</span>
                          <span className="tdStext">(Neurosurgeon)</span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          <span className="constStatus statSuccessfull">
                            Completed
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className="tblActionBtnGrp jcc">
                          <IconButton className="dnPresc">
                            <FileEarmarkArrowDownFill />
                          </IconButton>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <div className="tskLstTaskNM tdFdC">
                          <span className="tdHtext">Sunita Verma</span>
                          <span className="tdStext">(Female, 30/Aug/1990)</span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          sunita.verma90@hotmail.com
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM tdFdC">
                          <span className="tdHtext">Bandra, Mumbai</span>
                          <span className="tdStext">(+91 9123456789)</span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM tdFdC">
                          <span className="tdHtext">Dr. Manish Kapoor</span>
                          <span className="tdStext">(Pulmonologist)</span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          <span className="constStatus statSuccessfull">
                            Completed
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className="tblActionBtnGrp jcc">
                          <IconButton className="dnPresc">
                            <FileEarmarkArrowDownFill />
                          </IconButton>
                        </div>
                      </td>
                    </tr>
                  </tbody> */}
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
