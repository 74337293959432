import { React, useState, useEffect } from "react";

export default function CardAnalyticsAdmin() {
  // timer start
  const calculateTimeLeft = () => {
    const launchDate = new Date("2024-10-07T12:30:30"); // Set your launch date here
    const currentTime = new Date();
    const difference = launchDate - currentTime;

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft]);

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span key={interval} className={`countdown-${interval}`}>
        {timeLeft[interval]} {interval}{" "}
      </span>
    );
  });
  // timer end

  return (
    <div className="anDashIndCard">
      <div className="anDashCardArea">
        <div className="andHeader">
          <div className="andCrdNM">Analytics - Admin</div>
          <div className="andCrdUnitCount">
            <span title="Attrition">
              {/* This Month: <span className="fbold"> 215421</span> */}
            </span>

            <span title="Tenure">
              {/* This week: <span className="fbold">12154</span> */}
            </span>
          </div>
        </div>

        <div className="andCrdGraf">
          {/* <div className="anDLftGrf">
            <div className="centTxtShow">
              <Pie
                className="hw100"
                type="doughnut"
                data={data}
                options={options}
              />
              <div className="highlighted-text">
                
                <span className="highDataCount">80</span>
              </div>
            </div>
          </div>
          <div className="anDRightGrf">
            <Line
              className="hw100"
              type="line"
              data={barLineData}
              options={barLineOoptions}
            />
            
          </div> */}
          <div className="comingSoon">
            <span className="csText">Coming Soon...</span>
            <span className="timerCountdown">
              {timerComponents.length ? (
                timerComponents
              ) : (
                <span>Launched!</span>
              )}
            </span>
          </div>
        </div>

        <div className="andCrdFooter">
          {/* <Button
            className="dfultPrimaryBtn"
            startIcon={<Eye />}
            onClick={() => handleOpenViewReferredPatient()}
          >
            Refferred Patient
          </Button>
          <Button
            className="dfultPrimaryBtn"
            startIcon={<Eye />}
            onClick={() => handelOpenViewReviewPatient()}
          >
            Review Patient
          </Button> */}
        </div>
      </div>
    </div>
  );
}
