import { ExpandMoreRounded, MicOutlined } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useRef } from "react";
import { useState } from "react";
import RecordAudio from "../../../../../../../common/RecordAudio";
import { convertBlobToBase64 } from "../../../../../../../utils/Utils";
import { useTranslation } from "react-i18next";

export default function PatientComplaints({ data, updateData, extraData }) {
  const { t } = useTranslation();
  const [accordionExpanded, setAccordionExpanded] = useState(true);
  const [complaint, setComplaint] = useState("");
  const [showRecAudio, setShowRecAudio] = React.useState(false);
  const tempAudioChunk = useRef([]);
  const [recordingLength, setRecordingLength] = useState(0);
  const [recordingLink, setRecordingLink] = useState();

  const getSingleBlob = () => {
    const promises = [];

    for (const blob of tempAudioChunk.current) {
      const promise = blob.arrayBuffer();
      promises.push(promise);
    }

    Promise.all(promises)
      .then((arrayBuffers) => {
        const concatenatedArrayBuffer = arrayBuffers.reduce((acc, buffer) => {
          const totalLength = acc.byteLength + buffer.byteLength;
          const combined = new Uint8Array(totalLength);
          combined.set(new Uint8Array(acc), 0);
          combined.set(new Uint8Array(buffer), acc.byteLength);
          return combined.buffer;
        }, new ArrayBuffer(0));

        const combinedBlob = new Blob([concatenatedArrayBuffer], {
          type: "audio/wav",
        });
        tempAudioChunk.current = [combinedBlob];
        console.log(tempAudioChunk.current);
      })
      .catch((error) => {
        console.error("Error occurred while combining Blobs:", error);
      });
  };

  const handleShowRecAudioClose = () => {
    console.log(tempAudioChunk.current);
    if (tempAudioChunk.current && tempAudioChunk.current.length > 0) {
      getSingleBlob();
    }
    setShowRecAudio(false);
  };

  const handleAudio = async (combinedBlob) => {
    return new Promise((resolve, reject) => {
      let audioFileDataBase64;
      if (tempAudioChunk.current.length > 0) {
        // const combinedTempBlob = getSingleBlob()
        const audioBlob = new Blob(tempAudioChunk.current, {
          type: "audio/wav",
        });
        if (audioBlob) {
          convertBlobToBase64(audioBlob)
            .then((base64Data) => {
              audioFileDataBase64 = base64Data;
              resolve(audioFileDataBase64);
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          resolve(audioFileDataBase64);
        }
      } else {
        resolve(audioFileDataBase64);
      }
    });
  };

  return (
    <>
      <Accordion
        className="docInputElmntAcod"
        expanded={accordionExpanded}
        onChange={() => {
          setAccordionExpanded((prev) => !prev);
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreRounded />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="acodHeader"
        >
          <div className="tskElemHeding">
            {t("patient_complaints")}
            {!accordionExpanded && <span></span>}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="formElement">
            <FormControl className="formControl pRelative">
              <TextField
                label={t("complaints")}
                required
                variant="outlined"
                className="descptionTextFild"
                multiline
                rows={4}
                maxRows={5}
                value={complaint}
                onChange={(e) => {
                  const value=e.target.value
                  setComplaint(value);
                  updateData({ patientComplaint: value });
                }}
              ></TextField>
              <Tooltip
                className="audioNoteTooltip"
                title={t("record_patient_complaints")}
                arrow
                placement="bottom"
              >
                <IconButton
                  onClick={() => setShowRecAudio(true)}
                  className={
                    tempAudioChunk.current.length > 0
                      ? `audioNote activeStatus`
                      : "audioNote"
                  }
                >
                  {/* when audio avalable add class "activeStatus" */}
                  <MicOutlined />
                </IconButton>
              </Tooltip>
            </FormControl>
          </div>
        </AccordionDetails>
      </Accordion>
      {showRecAudio && (
        <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
          <div className="addMoreAgendaModel">
            <div className="modelTitle">{t("audio_message")}</div>
            {/* <div className="modActionBtnGrp"></div> */}
            <RecordAudio
              closeDialog={async () => {
                const audioFileDataBase64 = await handleAudio();
                updateData({ audioData: audioFileDataBase64 });
                handleShowRecAudioClose();
              }}
              tempAudioChunk={tempAudioChunk}
              recordingLength={recordingLength}
              setRecordingLength={setRecordingLength}
              recordingLink={recordingLink}
              setRecordingLink={setRecordingLink}
            />
          </div>
        </Box>
      )}
    </>
  );
}
