import {
  Add,
  Edit,
  ExpandMoreRounded,
  HealthAndSafety,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useMemo, useRef } from "react";
import { useState } from "react";
import { Trash } from "react-bootstrap-icons";
import { getHealthDto, MAX_SUGGESTED_RESULT } from "./consultationUtil";
import DashboardServices from "../../../../../../../services/DashboardServices";
import { toast } from "react-toastify";
import { CustomModal } from "../../../../../../task/view/component/Modals/CustomModal";
import { MedicationBody } from "./MedicationBody";
import { useTranslation } from "react-i18next";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const timings = [
  "Stat",
  "Before Breakfast",
  "After Breakfast",
  "Before Lunch",
  "After Lunch",
  "Before Evening",
  "After Evening",
  "Before Dinner",
  "After Dinner",
  "After Food",
  "Empty Stomach",
];

export default function Medication({
  updateData,
  data,
  setShowDrugSafety,
  extraData,
}) {
  const { t } = useTranslation();
  const [accordionExpanded, setAccordionExpanded] = useState(true);
  const [accordionString, setAccordionString] = useState("");

  const [dosage, setDosage] = useState("");
  const [mForm, setMForm] = useState("");
  const [frequency, setFrequency] = useState("");
  const [regimen, setRegimen] = useState("");

  const [mTiming, setMTiming] = useState([]);
  const [doctorGenericList, setDoctorGenericList] = useState([]);
  const [genericList, setGenericList] = useState([]);
  const [brandList, setBrandList] = useState([]);

  const [genericSearchInput, setGenericSearchInput] = useState("");
  const [selectedGeneric, setSelectedGeneric] = useState(null);
  const [medicationNote, setMedicationNote] = useState("");

  const [medicationList, setMedicationList] = useState([]);
  const [durationList, setDurationList] = useState([]);
  const [frequencyList, setFrequencyList] = useState([]);

  const [dosageList, setDosageList] = useState([]);
  const [suggestedDosage, setSuggestedDosage] = useState([]);

  const [routeList, setRoutelist] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [showUpdateModule, setShowUpdateModule] = useState(false);
  const editIndex = useRef(null);
  const editData = useRef();

  //Brand item

  const [suggestedBrand, setSuggestedBrand] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [brandInputString, setBrandInputString] = useState("");

  const getFilteredList = (data, str) => {
    const searchString = "" + str;
    if (searchString === "" || searchString === null) {
      const temp = data?.slice(0, MAX_SUGGESTED_RESULT);
      return { startsWithList: temp, containsList: [] };
    }
    const startsWithList = [];
    const containsList = [];

    data?.forEach((element) => {
      if (
        element.generic.toLowerCase().startsWith(searchString.toLowerCase())
      ) {
        startsWithList.push(element);
      } else if (
        element.generic.toLowerCase().includes(searchString.toLowerCase())
      ) {
        containsList.push(element);
      }
    });
    return { startsWithList, containsList };
  };

  const getFilteredBrand = (data, str) => {
    const searchString = "" + str;
    if (searchString === "" || searchString === null) {
      return data.slice(0, MAX_SUGGESTED_RESULT);
    }
    const startsWithList = [];
    const containsList = [];

    data.forEach((element) => {
      if (
        element.brandName.toLowerCase().startsWith(searchString.toLowerCase())
      ) {
        startsWithList.push(element);
      } else if (
        element.brandName.toLowerCase().includes(searchString.toLowerCase())
      ) {
        containsList.push(element);
      }
    });
    return [...startsWithList, ...containsList];
  };

  const setUniqueDoses = () => {
    const uniqueDosage = {};
    dosageList.forEach((item) => {
      if (!uniqueDosage[item.dosage]) {
        uniqueDosage[item.dosage] = item;
      }
    });
    const uniqueItems = Object.values(uniqueDosage);
    setSuggestedDosage(uniqueItems);
    return uniqueItems;
  };

  const suggestedMedicineList = useMemo(() => {
    const userAllergicIdList = medicationList?.map(
      (item) => item.genericName.id
    );

    let filteredGeneric = genericList?.filter((procedure) => {
      const alreadyTaken = userAllergicIdList.some(
        (item) => item === procedure.id
      );
      return !alreadyTaken;
    });

    let filteredDoctor = doctorGenericList?.filter((procedure) => {
      const alreadyTaken = userAllergicIdList.some(
        (item) => item === procedure.id
      );
      return !alreadyTaken;
    });

    const genericListFilteredData = getFilteredList(
      filteredGeneric,
      genericSearchInput,
      "generic"
    );
    const doctorFilteredData = getFilteredList(
      filteredDoctor,
      genericSearchInput
    );
    const tempValue = [
      ...(doctorFilteredData.startsWithList || []),
      ...(genericListFilteredData.startsWithList || []),
      ...(doctorFilteredData.containsList || []),
      ...(genericListFilteredData.containsList || []),
    ];
    if (tempValue.length === 0) {
      setSuggestedBrand([]);
      setBrandInputString("");
      setSelectedBrand(null);
      setUniqueDoses();
    }
    return tempValue.slice(0, 10);
  }, [genericSearchInput, doctorGenericList, genericList]);

  // const suggestedBrand = useMemo(() => {
  //   const tempBrandList = getFilteredBrand(brandList, brandInputString);
  //   return tempBrandList.slice(0, MAX_SUGGESTED_RESULT);
  // }, [brandList, brandInputString]);

  const updateSuggestedBrand = (data, str) => {
    const tempBrandList = getFilteredBrand(data, str);
    setSuggestedBrand(tempBrandList?.slice(0, MAX_SUGGESTED_RESULT));
  };

  useEffect(() => {
    const userAllergicIdList = medicationList?.map(
      (item) => item.genericName.id
    );

    let filteredBrand = brandList?.filter((brnd) => {
      const alreadyTaken = userAllergicIdList.some(
        (item) => item === brnd.genericId
      );
      return !alreadyTaken;
    });
    updateSuggestedBrand(filteredBrand, brandInputString);
  }, [brandList, brandInputString, medicationList]);

  useEffect(() => {
    let doctorGenList = JSON.parse(localStorage.getItem("doctor_generic"));
    setDoctorGenericList(doctorGenList);
    let genList = JSON.parse(localStorage.getItem("generic"));
    setGenericList(genList);
    getBrands();
    getFrequencies();
    getDurations();
    getFormDosages();
    getRoutes();
  }, []);

  useEffect(() => {
    if (data.medication) {
      setMedicationList(data.medication);
    }
  }, [data.medication]);

  const getFrequencies = () => {
    const diseasetring = localStorage.getItem("frequencyList");
    if (diseasetring) {
      setFrequencyList(JSON.parse(diseasetring));
      return;
    }

    const reqDto = getHealthDto();
    DashboardServices.getFrequencies(reqDto).then((response) => {
      if (response.data) {
        setFrequencyList(response.data);
        localStorage.setItem("frequencyList", JSON.stringify(response.data));
      }
    });
  };

  const getDurations = () => {
    const diseasetring = localStorage.getItem("durationList");
    if (diseasetring) {
      setDurationList(JSON.parse(diseasetring));
      return;
    }

    const reqDto = getHealthDto();
    DashboardServices.getDurations(reqDto).then((response) => {
      if (response.data) {
        setDurationList(response.data);
        localStorage.setItem("durationList", JSON.stringify(response.data));
      }
    });
  };

  const getFormDosages = () => {
    const diseasetring = localStorage.getItem("dosageList");
    if (diseasetring) {
      setDosageList(JSON.parse(diseasetring));
      return;
    }

    const reqDto = getHealthDto();
    DashboardServices.getFormDosages(reqDto).then((response) => {
      if (response.data) {
        setDosageList(response.data);
        localStorage.setItem("dosageList", JSON.stringify(response.data));
      }
    });
  };

  const getBrands = () => {
    const brandString = localStorage.getItem("brandList");
    if (brandString) {
      setBrandList(JSON.parse(brandString));
      return;
    }

    const reqDto = getHealthDto();
    DashboardServices.getBrands(reqDto).then((response) => {
      if (response.data) {
        setBrandList(response.data);
        localStorage.setItem("brandList", JSON.stringify(response.data));
      }
    });
  };

  const getRoutes = () => {
    const brandString = localStorage.getItem("routeList");
    if (brandString) {
      setRoutelist(JSON.parse(brandString));
      return;
    }

    const reqDto = getHealthDto();
    DashboardServices.getRoutes(reqDto).then((response) => {
      if (response.data) {
        setRoutelist(response.data);
        localStorage.setItem("routeList", JSON.stringify(response.data));
      }
    });
  };

  const handleChangemTiming = (event) => {
    const {
      target: { value },
    } = event;
    setMTiming(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleChangeDosage = (event) => {
    setDosage(event.target.value);
  };

  const handleChangemForm = (event) => {
    setMForm(event.target.value);
  };

  const handleChangeFrequency = (event) => {
    setFrequency(event.target.value);
  };

  const handleChangeRegimen = (event) => {
    setRegimen(event.target.value);
  };

  const initializeForm = () => {
    setDosage("");
    setMForm("");
    setFrequency("");
    setRegimen("");
    setGenericSearchInput("");
    setSelectedGeneric(null);
    setMedicationNote("");
    setMTiming([]);
    setSelectedBrand(null);
    setBrandInputString("");
    setSuggestedDosage([]);
  };

  const setDosageAccordingToFormId = (formId) => {
    const dosageId = dosageList?.filter((item) => item.formId === formId);
    setSuggestedDosage(dosageId);
    // return dosageId;
  };

  const handleAddMedication = () => {
    if (
      !selectedGeneric &&
      (!genericSearchInput || genericSearchInput === "")
    ) {
      toast.error(t("please_select_medicine_name"));
      return;
    }
    if (!dosage || dosage === "") {
      toast.error(t("please_select_dosage"));
      return;
    }

    if (!frequency || frequency === "") {
      toast.error(t("please_select_frequency"));
      return;
    }

    if (!regimen || regimen === "") {
      toast.error(t("please_select_duration"));
      return;
    }

    const tempFreq = frequencyList.find((item) => item.id === frequency);
    const tempDuration = durationList.find((item) => item.id === regimen);
    let genericName = selectedGeneric;
    if (!genericName) {
      genericName = {
        id: 0,
        generic: genericSearchInput,
        formId: -1,
        locallyAdded: true,
      };
    }

    const dto = {
      frequency: tempFreq,
      dosage,
      root: mForm,
      note: medicationNote,
      duration: tempDuration,
      timing: mTiming,
      genericName,
      brandName: selectedBrand,
    };
    setMedicationList((prev) => {
      const temp = [...prev, dto];
      updateData({ medication: temp });
      return temp;
    });
    initializeForm();
  };

  useEffect(() => {
    const str = medicationList
      ?.map(
        (item) =>
          `${item.genericName.generic} (${item.dosage.dosage}- ${item.frequency.frequency} - ${item.duration.durationName})`
      )
      .join(", ");
    setAccordionString(str);
  }, [medicationList]);

  return (
    <>
      <Accordion
        className="docInputElmntAcod"
        expanded={accordionExpanded}
        onChange={() => {
          setAccordionExpanded((prev) => !prev);
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreRounded />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="acodHeader"
        >
          <div className="tskElemHeding">
            {t("medication")}:
            {/* <span>symptoms gose here when acordion not expend</span> */}
            {!accordionExpanded && <span>{accordionString}</span>}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {extraData?.isDoctor && (
            <>
              <div className="addNewformGrp">
                <div className="formElement">
                  <FormControl className="formControl">
                    <Autocomplete
                      freeSolo
                      className="formAutocompleteField"
                      variant="outlined"
                      value={selectedGeneric}
                      options={suggestedMedicineList}
                      inputValue={genericSearchInput}
                      onChange={(e, newValue) => {
                        setSelectedGeneric(newValue);
                        if (newValue) {
                          const { id, formId, routeId } = newValue;
                          const route = routeList?.find(
                            (item) => item.id == routeId
                          );
                          setDosageAccordingToFormId(formId);
                          if (route) {
                            setMForm(route);
                          }
                          const tempBrand = brandList.filter(
                            (item) => item.genericId === id
                          );
                          if (tempBrand && tempBrand.length > 0) {
                            setSuggestedBrand(
                              tempBrand.slice(0, MAX_SUGGESTED_RESULT)
                            );
                          }
                        } else {
                          setBrandInputString("");
                          setSelectedBrand(null);
                        }
                      }}
                      onInputChange={(e, value, reason) => {
                        setGenericSearchInput(value);
                        if (value === "" || !value) {
                          // setBrandInputString("");
                          updateSuggestedBrand(brandList, "");
                        }
                        if (reason === "clear") {
                          setSuggestedDosage([]);
                          setMForm("");
                        }
                      }}
                      getOptionLabel={(option) => option.generic}
                      renderOption={(props, item) => {
                        return (
                          <li {...props} key={item.id}>
                            {item.generic}
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="formAutoComInputField autocompFildPlaceholder"
                          placeholder={t("generic_name")}
                          InputProps={{
                            ...params.InputProps,
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </div>
                <div className="formElement">
                  <FormControl className="formControl">
                    <Autocomplete
                      className="formAutocompleteField"
                      variant="outlined"
                      value={selectedBrand}
                      options={suggestedBrand}
                      inputValue={brandInputString}
                      onChange={(e, newValue) => {
                        setSelectedBrand(newValue);
                        if (newValue) {
                          const { genericId, formId } = newValue;
                          setDosageAccordingToFormId(formId);
                          let tempDoctorGeneric = doctorGenericList?.find(
                            (item) => item.id === genericId
                          );
                          if (tempDoctorGeneric) {
                            setSelectedGeneric(tempDoctorGeneric);
                            const { routeId } = tempDoctorGeneric;
                            const route = routeList?.find(
                              (item) => item.id == routeId
                            );
                            if (route) {
                              setMForm(route);
                            }
                          } else {
                            tempDoctorGeneric = genericList?.find(
                              (item) => item.id === genericId
                            );
                            if (tempDoctorGeneric) {
                              setSelectedGeneric(tempDoctorGeneric);
                            } else {
                              toast.error(
                                t(
                                  "this_brand_generic_name_is_not_present_in_the_table"
                                )
                              );
                            }
                          }
                        }
                      }}
                      onInputChange={(e, value) => {
                        setBrandInputString(value);
                      }}
                      getOptionLabel={(option) => option.brandName}
                      renderOption={(props, item) => {
                        return (
                          <li {...props} key={item.id}>
                            {item.brandName}
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="formAutoComInputField autocompFildPlaceholder"
                          placeholder={t("search_by_brand")}
                          InputProps={{
                            ...params.InputProps,
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </div>
              </div>
              <div className="formElementGroup ">
                <div className="formElement ">
                  <FormControl className="formControl">
                    <InputLabel id="severity-select-label">
                      {t("dosage")}*
                    </InputLabel>
                    <Select
                      labelId="severity-select-label"
                      id="severity-select"
                      required
                      value={dosage}
                      label={t("dosage")}
                      onChange={handleChangeDosage}
                      className="formInputField"
                      variant="outlined"
                    >
                      {suggestedDosage &&
                        suggestedDosage.map((item) => {
                          return (
                            <MenuItem value={item}>{item.dosage} </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </div>
                <div className="formElement ">
                  <FormControl className="formControl">
                    <InputLabel id="Form-select-label">{t("rout")}</InputLabel>
                    <Select
                      labelId="Form-select-label"
                      id="Form-select"
                      value={mForm}
                      label={t("form")}
                      onChange={handleChangemForm}
                      className="formInputField"
                      variant="outlined"
                    >
                      <MenuItem key={"selectRoute"} value={""}>
                        --Select Route--
                      </MenuItem>
                      {routeList &&
                        routeList.map((item) => {
                          return (
                            <MenuItem key={item.id} value={item}>
                              {item.route}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </div>
                <div className="formElement ">
                  <FormControl className="formControl">
                    <InputLabel id="frequency-select-label">
                      {t("frequency")}*
                    </InputLabel>
                    <Select
                      labelId="frequency-select-label"
                      id="frequency-select"
                      value={frequency}
                      label={t("frequency")}
                      onChange={handleChangeFrequency}
                      className="formInputField"
                      variant="outlined"
                    >
                      <MenuItem value={""}>--Select Frequency--</MenuItem>
                      {frequencyList &&
                        frequencyList.map((item) => {
                          return (
                            <MenuItem value={item.id}>
                              {item.frequency}{" "}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </div>
                <div className="formElement ">
                  <FormControl className="formControl">
                    <InputLabel id="regimen-select-label">
                      {t("duration")}*
                    </InputLabel>
                    <Select
                      labelId="regimen-select-label"
                      id="regimen-select"
                      value={regimen}
                      label={t("duration")}
                      onChange={handleChangeRegimen}
                      className="formInputField"
                      variant="outlined"
                    >
                      <MenuItem value={""}>--Select Duration--</MenuItem>
                      {durationList &&
                        durationList.map((item) => {
                          return (
                            <MenuItem value={item.id}>
                              {item.durationName}{" "}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </div>

                <div className="formElement ">
                  <FormControl className="formControl">
                    <InputLabel id="demo-multiple-checkbox-label">
                      {t("timing")}
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={mTiming}
                      onChange={handleChangemTiming}
                      input={<OutlinedInput label="Timing" />}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                      className="formInputField"
                      variant="outlined"
                    >
                      {timings?.map((time) => (
                        <MenuItem key={time} value={time}>
                          <Checkbox checked={mTiming.indexOf(time) > -1} />
                          <ListItemText primary={time} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="addNewformGrp">
                <div className="formElement">
                  <FormControl className="formControl">
                    <TextField
                      // hiddenLabel
                      label={t("notes")}
                      autoComplete="off"
                      variant="outlined"
                      value={medicationNote}
                      onChange={(e) => {
                        setMedicationNote(e.target.value);
                      }}
                      className="formTextFieldArea"
                    />
                  </FormControl>
                </div>

                <Button
                  className="dfultPrimaryBtn "
                  startIcon={<Add />}
                  onClick={handleAddMedication}
                >
                  {t("add")}
                </Button>
              </div>
              <div className="bb"></div>
            </>
          )}

          <div className="complaintList mt10">
            <ul>
              {medicationList?.map((item, index) => {
                return (
                  <>
                    <li>
                      <div className="complstDtlGrp ">
                        <div className="complstDtl">
                          <div className="compntName ">
                            {item.genericName.generic}
                          </div>
                          <div className="compntName BrandMed">
                            {item.brandName && item.brandName.brandName}
                          </div>
                          <div className="compinfoGRP">
                            <div className="compStime">
                              <span>{t("dosage")}:</span>
                              <span className="data">{item.dosage.dosage}</span>
                            </div>
                            <div className="compStime">
                              <span>{t("frequency")}:</span>
                              <span className="data">
                                {item.frequency.frequency}
                              </span>
                            </div>
                            <div className="compStime">
                              <span>{t("duration")}:</span>
                              <span className="data">
                                {item.duration.durationName}
                              </span>
                            </div>

                            {item.timing && item.timing.length !== 0 && (
                              <div className="compStime">
                                <span>{t("time")}:</span>
                                <span className="data">
                                  {item.timing.join(", ")}
                                </span>
                              </div>
                            )}
                            {item.note && item.note !== "" && (
                              <div className="compStime">
                                <span>{t("notes")}:</span>
                                <span className="data">{item.note}</span>
                              </div>
                            )}
                          </div>
                        </div>

                        {extraData?.isDoctor && (
                          <IconButton
                            className="removeItemBtn"
                            onClick={() => {
                              const temp = [...medicationList];
                              temp.splice(index, 1);
                              updateData({ medication: temp });
                              setMedicationList(temp);
                            }}
                          >
                            <Trash />
                          </IconButton>
                        )}
                      </div>
                    </li>
                  </>
                );
              })}
            </ul>
          </div>
          <div className="bb"></div>

          {extraData?.isDoctor && (
            <>
              <div className="ddBtnGroup flxSPBTW">
                <Tooltip arrow title={t("drug_safety_check")}>
                  <Button
                    className="differnDiagnosBtn"
                    startIcon={<HealthAndSafety />}
                    onClick={() => setShowDrugSafety(true)}
                  >
                    {t("drug_safety_check")}
                  </Button>
                </Tooltip>
              </div>
            </>
          )}
        </AccordionDetails>
      </Accordion>
      {showUpdateModule && (
        <CustomModal
          modalTitle={t("edit_medication")}
          closeModal={() => {
            setShowUpdateModule(false);
          }}
          // className={"width90"}
        >
          <MedicationBody
            handleEditData={(data) => {
              const temp = [...medicationList];
              temp[editIndex.current] = { ...data };
              setMedicationList(temp);
              editData.current = null;
              editIndex.current = null;
              setShowUpdateModule(false);
            }}
            data={editData.current}
          />
        </CustomModal>
      )}
    </>
  );
}
