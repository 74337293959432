import { ExpandMoreRounded, Add, Save, AttachFile } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { setHours, setMinutes } from "date-fns";
import { Trash } from "react-bootstrap-icons";
import zhPatientService from "../../../../../../../services/ZhPatientService";
import { getLoginUserDetails } from "../../../../../../../utils/Utils";
import dayjs from "dayjs";
import { getUnits } from "../../../../../../../services/AdminService";
import {
  MAX_FILE_SIZE_TASK,
  VALID_FILE_EXTENSION_TASK,
} from "../../../../../../../constants/const_values";
import { toast } from "react-toastify";
import { convertFileToBase64_Split } from "../../../../../../../utils/FileUtils";

export default function TestResultInput({ data, extraData }) {
  const loginUserDetails = getLoginUserDetails();
  const { t } = useTranslation();
  const [accordionExpanded, setAccordionExpanded] = useState(true);
  const [addParameter, setAddParameter] = useState(false);
  const [sampleCollected, setSampleCollected] = useState();
  const [sampleType, setSampleType] = useState();
  const [testReagent, setTestReagent] = useState();
  const [testMethod, setTestMethod] = useState();
  const [testItemMap, setTestItemMap] = useState({});
  const [testList, setTestList] = useState([]);
  const [timezone, setTimezone] = useState();
  const [testParameterName, setTestParameterName] = useState();
  const [tempFilteredTest, setTempFilteredTest] = useState([]);
  const [testValue, setTestValue] = useState("");
  const [testUnit, setTestUnit] = useState("");
  const [testReferenceRange, setTestReferenceRange] = useState("");
  const fileRef = useRef();

  const toggleaddParameter = () => {
    setAddParameter(!addParameter);
  };
  const handleUpdateSampleTypes = (event) => {
    setSampleType(event.target.value);
  };
  const handleUpdateSampleCollectedBy = (event) => {
    setSampleCollected(event.target.value);
  };
  const [completion, setCompletion] = useState(
    setHours(setMinutes(new Date(), 0), 9)
  );

  //   const filterPassedTime = (time) => {
  //     const currentDate = new Date();
  //     const selectedDate = new Date(time);

  //     return currentDate.getTime() < selectedDate.getTime();
  //   };

  // const filteredTest = useMemo(() => {
  //   const tempData = data?.investigation?.filter((item) => {
  //     return item.goFortest === true;
  //   });
  //   return tempData;
  // }, [data]);

  const initializeAddParameter = () => {
    setTestParameterName("");
    setTestUnit("");
    setTestValue("");
    setTestMethod("");
    setTestReferenceRange("");
  };

  const handelFileUpload = async (event) => {
    const files = event.target.files;
    let tempFileList = [];

    for (var i = 0; i < files.length; i++) {
      const file = files[i];

      if (
        !VALID_FILE_EXTENSION_TASK.includes(
          file.name.split(".").pop().toLowerCase()
        )
      ) {
        toast.warning(`Invalid File Type Of '${file.name}'`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else if (file.size > 1024 * 1024 * MAX_FILE_SIZE_TASK) {
        toast.warning(
          `'${file.name}' file need to be less then ${MAX_FILE_SIZE_TASK}MB`,
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      } else {
        const dto = {
          fileName: file.name,
          fileDataLink: await convertFileToBase64_Split(files[i]),
        };
        tempFileList = dto;
      }
    }
    return tempFileList;
  };

  const handleNewTests = async () => {
    setTestItemMap({});
    const tempData = data?.investigation?.filter((item) => {
      return item.goFortest === true;
    });
    let temp = {};
    tempData?.forEach(async (element) => {
      if (!testItemMap[element.testId]) {
        let reqDto = {
          testId: element?.testId,
          gender: element?.gender,
          age: extraData?.queuePatientAge,
        };
        let parameterData = await zhPatientService.getTestParameters(reqDto);
        if (parameterData.data) {
          temp[element.testId] = parameterData.data;
          setTestItemMap((prev) => {
            let testValue = { ...prev };
            testValue[element.testId] = parameterData.data;
            return testValue;
          });
        }
      }
    });

    if (data?.investigation) {
      const setB = new Set(data?.investigation?.map((item) => item.testId));
      let filteredTestList = tempFilteredTest?.filter((item) =>
        setB.has(item.testId)
      );
      const idsInA = new Set(filteredTestList?.map((item) => item.testId));

      data?.investigation?.forEach((item) => {
        if (!idsInA.has(item.testId)) {
          filteredTestList?.push(item);
        }
      });

      // let tempFiltered = filteredTestList?.map(async (item, index) => {
      //   let temp = { ...item };
      //   let reqDto = {
      //     testId: item?.testId,
      //     gender: item?.gender,
      //     age: extraData?.queuePatientAge,
      //   };
      //   if (!filteredTestList?.parameterList) {
      //     let parameterData = await zhPatientService.getTestParameters(reqDto);

      //     temp.parameterList = parameterData.data;
      //   }
      //   return temp;
      // });

      setTempFilteredTest(filteredTestList);
      // setTempFilteredTest(tempFiltered);
    }
  };

  useEffect(() => {
    setTestList(data?.investigation);
    getUnits(loginUserDetails.orgId).then((response) => {
      if (response.data) {
        const temp = response.data?.find(
          (item) => item.unitId == loginUserDetails.signleUnitId
        );
        setTimezone(temp?.timezone || "");
      }
    });
  }, []);

  useEffect(() => {
    handleNewTests();
  }, [data]);

  useEffect(() => {
    console.log("filtered Test =", tempFilteredTest);
    tempFilteredTest?.forEach(async (test, index) => {
      console.log("filtered TEst=", test);
      if (!test.parameterList) {
        let reqDto = {
          testId: test?.testId,
          gender: test?.gender,
          age: extraData?.queuePatientAge,
        };
        let parameterData = await zhPatientService.getTestParameters(reqDto);
        if (parameterData.data) {
          setTempFilteredTest((prev) => {
            let temp = [...prev];
            temp[index].parameterList = parameterData.data;
            return temp;
          });
        }
      }
    });
  }, [tempFilteredTest]);

  const invList = [
    { invName: "Lipid Profile" },
    { invName: "MRI - Pituitary" },
    { invName: "Serum Immunoglobulins" },
    { invName: "BNP" },
    { invName: "Estrogen" },
    { invName: "Blood For R/E" },
    { invName: "Digoxin Serum Concentration" },
    { invName: "MRI - Both Shoulders" },
    { invName: "Epstein-Barr Virus Antibodies - Serum" },
    { invName: "Skin Scraping Of Lesion" },
  ];

  return (
    <>
      {tempFilteredTest?.length > 0 && (
        <Accordion
          className="docInputElmntAcod"
          expanded={accordionExpanded}
          onChange={() => {
            setAccordionExpanded((prev) => !prev);
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreRounded />}
            aria-controls="panel1-content"
            id="panel1-header"
            className="acodHeader"
          >
            <div className="tskElemHeding">
              {t("test_result")}:
              <span>{/*symptoms gose here when acordion not expend*/}</span>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="testResult">
              <table className="testResulttable testResultInput">
                <thead>
                  <tr>
                    <th className="w50p">{t("test_name")}</th>
                    <th className="w15p textCenter">{t("value")}</th>
                    <th className="w15p textCenter">{t("unit")}</th>
                    <th className="w20p">{t("bio_ref_interval")}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {tempFilteredTest?.map((test, index) => {
                    return (
                      <>
                        <tr>
                          <td colSpan={5} className="singlRow">
                            <div className="testNMTstloc">
                              <div className="testNameH">{test.testName}</div>

                              <div className="formElement">
                                <FormControl className="formControl">
                                  <FormGroup
                                    className="formRadioGroup"
                                    labelId="setTasktype"
                                    name="setTasktype"
                                  >
                                    <FormControlLabel
                                      className="formRadioField"
                                      control={
                                        <Checkbox
                                          checked={test.outSource || false}
                                          onChange={(e) => {
                                            let temp = [...tempFilteredTest];
                                            temp[index].outSource =
                                              e.target.checked;
                                            setTestList(temp);
                                          }}
                                        />
                                      }
                                      label="Outsource Report"
                                    />
                                  </FormGroup>
                                </FormControl>
                              </div>
                            </div>
                            <div className="testInformation mt5">
                              <div className="formElementGroup">
                                <div className="formElement">
                                  <FormControl className="formControl">
                                    <InputLabel
                                      id="setCompletionDate"
                                      className="setCompletionDate"
                                    >
                                      Collection Date Time*
                                    </InputLabel>
                                    <ReactDatePicker
                                      showYearDropdown
                                      yearDropdownItemNumber={100}
                                      scrollableYearDropdown
                                      showMonthDropdown
                                      showIcon
                                      toggleCalendarOnIconClick
                                      labelId="setCompletionDate"
                                      className="formDatePicker"
                                      selected={test.sampleCollectionTime}
                                      maxDate={new Date()}
                                      showTimeSelect
                                      // filterTime={filterPassedTime}
                                      dateFormat="dd-MMM-yyyy h:mm aa"
                                      onChange={(date) => {
                                        let temp = [...tempFilteredTest];
                                        temp[index].sampleCollectionTime = date;
                                        setTestList(temp);
                                        // setCompletion(date);
                                      }}
                                    />
                                  </FormControl>
                                </div>
                                <div className="formElement">
                                  <FormControl className="formControl">
                                    <InputLabel id="sampleType-label">
                                      Sample Type
                                    </InputLabel>
                                    <Select
                                      labelId="sampleType-label"
                                      id="sampleType-select"
                                      value={test.sampleType}
                                      // value={testList[index].sampleType}
                                      label="Sample Type"
                                      onChange={(e) => {
                                        let temp = [...tempFilteredTest];
                                        temp[index].sampleType = e.target.value;
                                        setTestList(temp);
                                      }}
                                      className="formInputField"
                                      variant="outlined"
                                    >
                                      <MenuItem value="Blood">Blood</MenuItem>
                                      <MenuItem value="Urine">Urine</MenuItem>
                                      <MenuItem value="Sputum">Sputum</MenuItem>
                                    </Select>
                                  </FormControl>
                                </div>
                                <div className="formElement">
                                  <FormControl className="formControl">
                                    <TextField
                                      // value={testList[index].reagent}
                                      value={test.reagent}
                                      label="Reagent"
                                      autoComplete="off"
                                      placeholder="Enter Reagent"
                                      variant="outlined"
                                      className="formTextFieldArea"
                                      onChange={(e) => {
                                        let temp = [...tempFilteredTest];
                                        temp[index].reagent = e.target.value;
                                        setTestList(temp);
                                      }}
                                    />
                                  </FormControl>
                                </div>
                              </div>
                              {/* <div className="formElementGroup">
                        <div className="formElement">
                          <FormControl className="formControl">
                            <TextField
                              value={testReagent}
                              label="Reagent"
                              autoComplete="off"
                              placeholder="Enter Reagent"
                              variant="outlined"
                              className="formTextFieldArea"
                              onChange={(e) => {
                                setTestReagent(e.target.value);
                              }}
                            />
                          </FormControl>
                        </div>
                        <div className="formElement">
                          <FormControl className="formControl">
                            <TextField
                              value={testMethod}
                              label="Method"
                              autoComplete="off"
                              placeholder="Enter Method"
                              variant="outlined"
                              className="formTextFieldArea"
                              onChange={(e) => {
                                setTestMethod(e.target.value);
                              }}
                            />
                          </FormControl>
                        </div>
                        <div className="formElement">
                          <Button
                            className="dfultPrimaryBtn"
                            startIcon={<Add />}
                          >
                            Parameters
                          </Button>
                        </div>
                      </div> */}
                            </div>
                          </td>
                        </tr>
                        {test?.parameterList?.map((parameterItem, parIndex) => {
                          return (
                            <tr>
                              <td>
                                <div className="testElmGrp">
                                  <div className="testElmNM">
                                    {parameterItem.parameter}
                                  </div>
                                  {parameterItem.method && (
                                    <div className="testElmDtl">
                                      {` Method: ${parameterItem.method}`}
                                    </div>
                                  )}
                                </div>
                              </td>
                              <td>
                                <div className="testValue textCenter opc1">
                                  <div className="formElement minWidth100px">
                                    <FormControl className="formControl">
                                      <TextField
                                        label="Value"
                                        placeholder="eg:120"
                                        autoComplete="off"
                                        variant="outlined"
                                        className="formTextFieldArea"
                                        value={
                                          // testList?.[index]?.testResult?.[
                                          //   parIndex
                                          // ]?.parameterValue || ""
                                          tempFilteredTest?.[index]
                                            ?.parameterList?.[parIndex]
                                            ?.value || ""
                                        }
                                        onChange={(e) => {
                                          const value = e.target.value;
                                          const temp = [...tempFilteredTest];
                                          // let tempPar = {
                                          //   parameterName:
                                          //     parameterItem.parameter,
                                          //   parameterValue: value,
                                          //   parameterUnit: parameterItem.unit,
                                          //   parameterRefRange:
                                          //     parameterItem.refRange[0],
                                          //   parameterId: 0,
                                          // };
                                          // if (!temp[index].testResult) {
                                          //   temp[index].testResult = [];
                                          // }
                                          // temp[index].testResult[parIndex] =
                                          //   tempPar;
                                          temp[index].parameterList[
                                            parIndex
                                          ].value = value;
                                          setTestList(temp);
                                        }}
                                      />
                                    </FormControl>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="testUnit textCenter">
                                  {parameterItem.unit}
                                </div>
                              </td>
                              <td>
                                <div className="testNrmlRange">
                                  <div className="tstnRangeElm">
                                    Desirable : &lt; 150
                                  </div>
                                  <div className="tstnRangeElm">
                                    Borderline High: 150-199
                                  </div>
                                  <div className="tstnRangeElm">
                                    {" "}
                                    High: 200-499
                                  </div>
                                  <div className="tstnRangeElm">
                                    Very High : &gt; 500
                                  </div>
                                </div>
                              </td>
                              <td>
                                <IconButton
                                  className="deleteRowBtn"
                                  onClick={() => {
                                    const temp = [...tempFilteredTest];
                                    console.log("temp =", temp);
                                    temp[index].parameterList.splice(
                                      parIndex,
                                      1
                                    );
                                    setTempFilteredTest(temp);
                                  }}
                                >
                                  <Trash />
                                </IconButton>
                              </td>
                            </tr>
                          );
                        })}
                        {addParameter && (
                          <>
                            <tr>
                              <td colSpan={5}>
                                <div className="addNewformGrp ">
                                  {/* <div className="formElement">
                                  <FormControl className="formControl">
                                    <Autocomplete
                                      freeSolo
                                      className="formAutocompleteField"
                                      variant="outlined"
                                      options={invList.map(
                                        (option) => option.invName
                                      )}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          className="formAutoComInputField autocompFildPlaceholder"
                                          label={t("parameter_name")}
                                          InputProps={{
                                            ...params.InputProps,
                                          }}
                                        />
                                      )}
                                    />
                                  </FormControl>
                                </div> */}

                                  <div className="formElement">
                                    <FormControl className="formControl">
                                      <TextField
                                        value={testParameterName}
                                        label="Parameter Name"
                                        autoComplete="off"
                                        placeholder="Parameter Name"
                                        variant="outlined"
                                        className="formTextFieldArea"
                                        onChange={(e) => {
                                          setTestParameterName(e.target.value);
                                        }}
                                      />
                                    </FormControl>
                                  </div>

                                  <div className="formElement">
                                    <FormControl className="formControl">
                                      <TextField
                                        value={testMethod}
                                        label="Method"
                                        autoComplete="off"
                                        placeholder="Enter Method"
                                        variant="outlined"
                                        className="formTextFieldArea"
                                        onChange={(e) => {
                                          setTestMethod(e.target.value);
                                        }}
                                      />
                                    </FormControl>
                                  </div>

                                  <div className="formElement minWidth100px">
                                    <FormControl className="formControl">
                                      <TextField
                                        label="Value"
                                        placeholder="eg:120"
                                        autoComplete="off"
                                        variant="outlined"
                                        className="formTextFieldArea"
                                        value={testValue}
                                        onChange={(e) =>
                                          setTestValue(e.target.value)
                                        }
                                      />
                                    </FormControl>
                                  </div>

                                  <div className="formElement minWidth100px">
                                    <FormControl className="formControl">
                                      <TextField
                                        label="Unit"
                                        placeholder="eg:120"
                                        autoComplete="off"
                                        variant="outlined"
                                        className="formTextFieldArea"
                                        value={testUnit}
                                        onChange={(e) =>
                                          setTestUnit(e.target.value)
                                        }
                                      />
                                    </FormControl>
                                  </div>
                                </div>
                                <div className="addNewformGrp ">
                                  <div className="formElement">
                                    <FormControl className="formControl">
                                      <TextField
                                        label="Reference Range"
                                        autoComplete="off"
                                        variant="outlined"
                                        className="formTextFieldArea"
                                        value={testReferenceRange}
                                        onChange={(e) => {
                                          setTestReferenceRange(e.target.value);
                                        }}
                                      />
                                    </FormControl>
                                  </div>

                                  <IconButton
                                    className="updateRowBtn"
                                    onClick={(e) => {
                                      const dto = {
                                        parameter: testParameterName,
                                        method: testMethod,
                                        unit: testUnit,
                                        value: testValue,
                                        refRange: [],
                                      };
                                      const temp = [...tempFilteredTest];
                                      temp?.[index]?.parameterList?.push(dto);
                                      initializeAddParameter();
                                      setTempFilteredTest(temp);
                                    }}
                                  >
                                    <Add />
                                  </IconButton>
                                </div>
                              </td>
                            </tr>
                          </>
                        )}
                        <tr>
                          <td colSpan={5}>
                            <div className="updateNewTest formBtnElement">
                              <input
                                // ref={fileRef}
                                hidden
                                onChange={async (e) => {
                                  const fileData = await handelFileUpload(e);
                                  const temp = [...tempFilteredTest];
                                  temp[index].fileData = fileData;
                                  setTempFilteredTest(temp);
                                }}
                                type="file"
                                id={`upload-button-${test.testId}`}
                              />
                              <Button
                                variant="outlined"
                                startIcon={<AttachFile />}
                                className={
                                  tempFilteredTest?.[index]?.fileData
                                    ? "dfultPrimaryBtn"
                                    : "comBtnOutline"
                                }
                                onClick={() => {
                                  document
                                    .getElementById(
                                      `upload-button-${test.testId}`
                                    )
                                    .click();
                                }}
                              >
                                Upload Report
                              </Button>
                              <Button
                                startIcon={<Add />}
                                className={
                                  addParameter
                                    ? "dfultPrimaryBtn"
                                    : "dfultDarkSecondaryBtn"
                                }
                                onClick={toggleaddParameter}
                              >
                                {t("add_parameter")}
                              </Button>
                              <Button
                                startIcon={<Save />}
                                className="dfultPrimaryBtn"
                                onClick={() => {
                                  let isValid = true;
                                  if (!test.sampleCollectionTime) {
                                    toast.error(
                                      "Collection time cannot be empty"
                                    );
                                    isValid = false;
                                  }

                                  let taskResult = test?.parameterList
                                    ?.filter((item) =>
                                      item.value ? true : false
                                    )
                                    .map((parameterItem) => {
                                      return {
                                        parameterName: parameterItem.parameter,
                                        parameterValue: parameterItem.value,
                                        parameterUnit: parameterItem.unit,
                                        parameterRefRange:
                                          parameterItem.refRange[0],
                                        parameterId: 0,
                                      };
                                    });

                                  debugger;
                                  if (
                                    (!taskResult || taskResult?.length === 0) &&
                                    !test?.fileData
                                  ) {
                                    toast.error("No Test data provided");
                                    isValid = false;
                                  }

                                  if (!isValid) {
                                    return;
                                  }

                                  const reqDto = {
                                    sampleType: test.sampleType || "",
                                    testId: test.testId,
                                    requestionId: 0,
                                    testName: test.testName,
                                    visitId: extraData?.queueVisitId,
                                    patientId: extraData?.queuePatientId,
                                    outSource: test.outSource ? "Y" : "N",
                                    fileName: test?.fileData?.fileName || "",
                                    reagent: test.reagent || "",
                                    sampleCollectionTime: dayjs(
                                      test.sampleCollectionTime
                                    ).format("DD-MM-YYYY HH:mm:ss"),
                                    timezone: timezone,
                                    fileData:
                                      test?.fileData?.fileDataLink || "",
                                    orgId: loginUserDetails.orgId,
                                    testResult: taskResult || [],
                                  };
                                  console.log("reqdto =", reqDto);
                                  return;
                                  zhPatientService.submitTestResultWithoutRequestion(
                                    loginUserDetails.userId,
                                    reqDto
                                  );
                                }}
                              >
                                Save
                              </Button>
                            </div>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="bb"></div>
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
}
