import { Add, ExpandMoreRounded } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import { Trash } from "react-bootstrap-icons";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  getFilteredList,
  getHealthDto,
  MAX_SUGGESTED_RESULT,
} from "./consultationUtil";
import DashboardServices from "../../../../../../../services/DashboardServices";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import DateUtils from "../../../../../../../utils/DateUtils";

const ITEM_HEIGHT = 38;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function OtherOptions({ updateData, data, extraData }) {
  const { t } = useTranslation();
  const [accordionExpanded, setAccordionExpanded] = useState(true);

  const [parameterMonitor, setParameterMonitor] = useState([]);
  const [hospitalization, setHospitalization] = useState("NO");
  const [surgeryDate, setSurgeryDate] = useState(new Date());
  // const [reviewDate, setReviewDate] = useState(new Date(new Date().getDate() + 7));
  const [reviewDate, setReviewDate] = useState(
    new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000)
  );
  const [nextReview, setNextReview] = useState(true);
  const [referPatient, setReferPatient] = useState("");
  const [referSpeciality, setReferSpeciality] = useState(null);

  const [procedureList, setProcedureList] = useState([]);
  const [selectedProcedure, setSelectedProcedure] = useState(null);
  const [userProcedureList, setUserProcedureList] = useState([]);
  const [procedureString, setProcedureString] = useState("");

  //

  // const [specializationString, setSpecializationString] = useState("");
  const [hospitalString, setHospitalString] = useState("");

  const [monitorNames, setMonitorNames] = useState([]);
  const [doctorName, setDoctorName] = useState("");
  const [doctorNote, setDoctorNote] = useState("");

  const [hospitalizationRemark, setHospitalizationRemark] = useState("");
  const [treatmentPlan, setTreatmentPlan] = useState("");
  const [selectedHospital, setSelectedHospital] = useState(null);

  const [specilizationList, setSpecializationList] = useState([]);
  const [hospitalList, setHospitalizationList] = useState();
  const [referralNote, setReferralNote] = useState(null);

  const initializeForm = () => {
    setSelectedProcedure(null);
    setProcedureString("");
    setSurgeryDate(new Date());
  };

  const handleChangeReferPatient = (event) => {
    const selectedValue = event.target.value;
    let tempHospital = selectedHospital;
    let tempSpeciality = referSpeciality;
    let tempReferral = referralNote;
    if (selectedValue) {
      setNextReview(false);
      setReviewDate(null);
    }
    if (
      selectedValue === "higherFacility" ||
      selectedValue === "immediateHospitalization"
    ) {
      if (
        referPatient !== "higherFacility" ||
        referPatient !== "immediateHospitalization"
      ) {
        setSelectedHospital(null);
        tempHospital = null;
      }
      setReferSpeciality(null);
      tempSpeciality = null;
      setReferralNote(null);
      tempReferral = null;
    } else if (selectedValue === "otherSpeciality") {
      setSelectedHospital(null);
      tempHospital = null;
      setReferSpeciality(null);
      tempSpeciality = null;
      setReferralNote(null);
      tempReferral = null;
    } else {
      setSelectedHospital(null);
      tempHospital = null;
      setReferSpeciality(null);
      tempSpeciality = null;
      setReferralNote(null);
      tempReferral = null;
    }
    setReferPatient(selectedValue);
    updateData({
      referType: selectedValue,
      reviewDate: null,
      hospital: tempHospital,
      referDoctorSpeciality: tempSpeciality,
      referralNote: tempReferral,
    });
  };

  const handleChangeReferSpeciality = (event) => {
    setReferSpeciality(event.target.value);
    updateData({
      referDoctorSpeciality: event.target.value,
    });
  };

  const suggestedProcedure = useMemo(() => {
    const filteredList = getFilteredList(
      procedureList,
      procedureString,
      "procedureName"
    );
    return filteredList.slice(0, MAX_SUGGESTED_RESULT);
  }, [procedureList, procedureString]);

  const getProcedures = () => {
    const lifeStyleString = localStorage.getItem("procedureList");
    if (lifeStyleString) {
      setProcedureList(JSON.parse(lifeStyleString));
      return;
    }
    const reqDto = getHealthDto();
    DashboardServices.getProcedures(reqDto).then((response) => {
      if (response.data) {
        setProcedureList(response.data);
        localStorage.setItem("procedureList", JSON.stringify(response.data));
      }
    });
  };

  const getSpecializations = () => {
    const lifeStyleString = localStorage.getItem("specializationList");
    if (lifeStyleString) {
      setSpecializationList(JSON.parse(lifeStyleString));
      return;
    }
    const reqDto = getHealthDto();
    DashboardServices.getSpecializations(reqDto).then((response) => {
      if (response.data) {
        setSpecializationList(response.data || []);
        localStorage.setItem(
          "specializationList",
          JSON.stringify(response.data)
        );
      }
    });
  };

  const getHospitalsOfOrg = () => {
    const lifeStyleString = localStorage.getItem("hospitalList");
    if (lifeStyleString) {
      setHospitalizationList(JSON.parse(lifeStyleString));
      return;
    }
    const reqDto = getHealthDto();
    DashboardServices.getHospitalsOfOrg(reqDto).then((response) => {
      if (response.data) {
        setHospitalizationList(response.data);
        localStorage.setItem("hospitalList", JSON.stringify(response.data));
      }
    });
  };

  const computeMonitors = () => {
    let investigationList = [];
    let vitalsList = [];
    if (data.investigation) {
      investigationList = data.investigation.map((item) => item.testName);
    }
    const vitalNameMaps = {
      TEMPERATURE: "Temperature",
      BP: "Blood Pressure",
      HEART_RATE: "Heart Rate",
      RESPIRATION_RATE: "Respiration Rate",
      SPO2: "SPO2",
    };
    const vitalNames = [
      "TEMPERATURE",
      "BP",
      "HEART_RATE",
      "RESPIRATION_RATE",
      "SPO2",
    ];
    if (extraData?.reqValue?.vitals) {
    }
    vitalsList = vitalNames.map((item) => vitalNameMaps[item]);

    setMonitorNames([...vitalsList, ...investigationList]);
  };

  useEffect(() => {
    computeMonitors();
  }, [data.investigation, extraData]);

  useEffect(() => {
    computeMonitors();

    // if (!data || !data.procedure) {
    //   if (
    //     extraData &&
    //     extraData.reqValue.procedures &&
    //     extraData.reqValue.procedures.length > 0
    //   ) {
    //     const tempArray = extraData.reqValue.procedures.map((item) => {
    //       let tempProcedure = {
    //         id: uuidv4(),
    //         procedureName: item.procedureName,
    //         gender: "",
    //         locallyAdded: true,
    //       };

    //       const temp = {
    //         procedure: tempProcedure,
    //         date: new Date(Date.parse(item.procedureDate)),
    //       };
    //       return temp;
    //     });

    //     setUserProcedureList((prev) => {
    //       const temp = [...prev, ...tempArray];
    //       updateData({ procedure: temp });
    //       return temp;
    //     });
    //   }
    // }

    getProcedures();
    getSpecializations();
    getHospitalsOfOrg();
  }, []);

  useEffect(() => {
    if (data && data.itemToMonitor) {
      setParameterMonitor(data.itemToMonitor);
    }

    //////////////////////////////////////

    if (data && data.procedure) {
      setUserProcedureList(data.procedure);
    }
    if (data && data.referType) {
      setReferPatient(data.referType);
      setNextReview(false);
    }

    if (data && data.referDoctorSpeciality) {
      setReferSpeciality(data.referDoctorSpeciality);
    }
    if (data && data.doctorNote) {
      setDoctorNote(data.doctorNote);
    }

    if (data?.hospital) {
      setSelectedHospital(data?.hospital);
    }

    if (data?.doctorName) {
      setDoctorName(data?.doctorName);
    }

    if (data?.referralNote) {
      setReferralNote(data?.referralNote);
    }

    if (data?.reviewDate) {
      setNextReview(true);
      setReviewDate(DateUtils.convertToDate(data?.reviewDate));
    } else {
      setNextReview(false);
      setReviewDate(null);
    }
  }, [data]);

  const handleChangeparameterMonitor = (event) => {
    const {
      target: { value },
    } = event;
    setParameterMonitor(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    updateData({
      itemToMonitor: typeof value === "string" ? value.split(",") : value,
    });
  };

  return (
    <>
      <Accordion
        className="docInputElmntAcod"
        expanded={accordionExpanded}
        onChange={() => {
          setAccordionExpanded((prev) => !prev);
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreRounded />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="acodHeader"
        >
          <div className="tskElemHeding">
            {t("others_options")}:
            <span>{/*symptoms gose here when acordion not expend*/}</span>
          </div>
        </AccordionSummary>
        <AccordionDetails className="acodDetails">
          <div className="tskElemHeding ">{t("parameter_to_be_monitor")}</div>

          <div className="formElement ">
            <FormControl className="formControl">
              <InputLabel id="ParameterMonitor">
                {t("select_parameters")}
              </InputLabel>
              <Select
                labelId="ParameterMonitor"
                id="Parameter-Monitor"
                multiple
                value={parameterMonitor}
                onChange={handleChangeparameterMonitor}
                input={<OutlinedInput label="Select Parameters" />}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
                className="formInputField"
                variant="outlined"
                disabled={!extraData.isDoctor}
              >
                {monitorNames.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={parameterMonitor.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className="tskElemHeding ">{t("doctor_note")}</div>

          <div className="formElement mt10">
            <FormControl className="formControl">
              <TextField
                label={t("doctor_note")}
                required
                variant="outlined"
                className="descptionTextFild"
                multiline
                rows={4}
                maxRows={6}
                value={doctorNote}
                onChange={(e) => {
                  setDoctorNote(e.target.value);
                  updateData({ doctorNote: e.target.value });
                }}
                disabled={!extraData?.isDoctor}
              ></TextField>
            </FormControl>
          </div>

          <div className="bb"></div>
          <div className="tskElemHeding mt10 ">{t("procedure_surgery")}</div>
          <div className="complaintList  ">
            <ul className=" pastIllness">
              {userProcedureList &&
                userProcedureList.map((item, index) => {
                  return (
                    <li>
                      <div className="valuDateRow complstDtlGrp">
                        <div className="piValu">
                          {item.procedure.procedureName}
                        </div>
                        <div className="d-flex">
                          <div className="piDate">
                            {dayjs(item.date).format("DD-MMM-YYYY")}
                          </div>
                          {extraData?.isDoctor && (
                            <IconButton
                              className="removeItemBtn"
                              onClick={() => {
                                const temp = [...userProcedureList];
                                temp.splice(index, 1);
                                setUserProcedureList(temp);
                              }}
                            >
                              <Trash />
                            </IconButton>
                          )}
                        </div>
                      </div>
                    </li>
                  );
                })}

              {extraData?.isDoctor && (
                <li className="naLstTyp">
                  <div className="addNewformGrp">
                    <div className="formElement">
                      <FormControl className="formControl">
                        <Autocomplete
                          freeSolo
                          className="formAutocompleteField"
                          variant="outlined"
                          value={selectedProcedure}
                          options={suggestedProcedure}
                          inputValue={procedureString}
                          onChange={(e, newValue) => {
                            setSelectedProcedure(newValue);
                          }}
                          onInputChange={(e, value) => {
                            setProcedureString(value);
                          }}
                          getOptionLabel={(option) => option.procedureName}
                          renderOption={(props, item) => {
                            return (
                              <li {...props} key={item.id}>
                                {item.procedureName}
                              </li>
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              className="formAutoComInputField autocompFildPlaceholder"
                              placeholder={t("add_procedure")}
                              InputProps={{
                                ...params.InputProps,
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </div>
                    <div className="formElement mxW-150">
                      <FormControl className="formControl">
                        <InputLabel
                          id="setsurgeryDate"
                          className="setCompletionDate"
                        >
                          {t("select_date")}*
                        </InputLabel>
                        <ReactDatePicker
                          showIcon
                          toggleCalendarOnIconClick
                          showYearDropdown
                          yearDropdownItemNumber={100}
                          scrollableYearDropdown
                          showMonthDropdown
                          labelId="setsurgeryDate"
                          className="formDatePicker"
                          selected={surgeryDate}
                          minDate={new Date()}
                          dateFormat="dd-MMM-yyyy"
                          onChange={(date) => {
                            console.log("new date = ", date);
                            setSurgeryDate(date);
                          }}
                        />
                      </FormControl>
                    </div>

                    <Button
                      className="dfultPrimaryBtn "
                      startIcon={<Add />}
                      onClick={() => {
                        let tempProcedure = selectedProcedure;
                        if (!tempProcedure) {
                          if (!procedureString || procedureString === "") {
                            toast.error(t("please_provide_food_name"));
                            return;
                          }
                          tempProcedure = {
                            id: uuidv4(),
                            procedureName: procedureString,
                            gender: "",
                            locallyAdded: true,
                          };
                        }
                        setUserProcedureList((prev) => {
                          const temp = [
                            ...prev,
                            {
                              procedure: tempProcedure,
                              date: surgeryDate,
                            },
                          ];
                          updateData({ procedure: temp });
                          return temp;
                        });
                        initializeForm();
                      }}
                    >
                      {t("add")}
                    </Button>
                  </div>
                </li>
              )}
            </ul>
          </div>
          <div className="bb"></div>
          <div className="tskElemHeding mt10 ">{t("refer")}</div>

          <div className="formElement ">
            <FormControl className="formControl">
              <InputLabel id="Refer-select-label">{t("refer_type")}</InputLabel>
              <Select
                labelId="Refer-select-label"
                id="Refer-select"
                value={referPatient}
                label="Refer Patient"
                onChange={handleChangeReferPatient}
                className="formInputField"
                variant="outlined"
                disabled={!extraData?.isDoctor}
              >
                <MenuItem value={null}>--{t("select_refer_type")}--</MenuItem>
                <MenuItem value="higherFacility">
                  {t("advise_higher_facility")}
                </MenuItem>
                <MenuItem value="immediateHospitalization">
                  {t("advise_immediate_hospitalization")}
                </MenuItem>

                <MenuItem value="otherSpeciality">
                  {t("refer_to_other_speciality")}
                </MenuItem>
                <MenuItem value="medicoLegal">
                  {t("refer_to_medico_legal")}
                </MenuItem>
              </Select>
            </FormControl>
          </div>

          {(referPatient === "immediateHospitalization" ||
            referPatient === "higherFacility") && (
            <>
              <div className="formElement">
                <FormControl className="formControl pRelative">
                  <Autocomplete
                    freeSolo
                    className="formAutocompleteField"
                    variant="outlined"
                    value={selectedHospital}
                    options={hospitalList}
                    inputValue={hospitalString}
                    onChange={(e, newValue) => {
                      setSelectedHospital(newValue);
                      updateData({ hospital: newValue });
                    }}
                    onInputChange={(e, value) => {
                      setHospitalString(value);
                    }}
                    getOptionLabel={(option) => option.hospitalName}
                    renderOption={(props, item) => {
                      return (
                        <li {...props} key={item.id}>
                          {item.hospitalName}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="formAutoComInputField autocompFildPlaceholder"
                        // placeholder={t("add_previous_procedure")}
                        placeholder={t("select_hospital")}
                        InputProps={{
                          ...params.InputProps,
                        }}
                      />
                    )}
                    disabled={!extraData?.isDoctor}
                  />
                </FormControl>
              </div>
            </>
          )}
          {referPatient === "otherSpeciality" && (
            <>
              <div className="addNewformGrp mt10">
                <div className="formElement ">
                  <FormControl className="formControl">
                    {/* <InputLabel id="speciality-select-label">
                      {t("select_speciality")}
                    </InputLabel> */}

                    <Autocomplete
                      freeSolo
                      className="formAutocompleteField"
                      variant="outlined"
                      value={referSpeciality}
                      options={specilizationList}
                      // inputValue={specializationString}
                      onChange={(e, newValue) => {
                        setReferSpeciality(newValue);
                        updateData({ referDoctorSpeciality: newValue });
                      }}
                      // onInputChange={(e, value) => {
                      //   setSpecializationString(value);
                      // }}
                      getOptionLabel={(option) => option.specializationName}
                      renderOption={(props, item) => {
                        return (
                          <li {...props} key={item.id}>
                            {item.specializationName}
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="formAutoComInputField autocompFildPlaceholder"
                          placeholder={t("select_speciality")}
                          InputProps={{
                            ...params.InputProps,
                          }}
                        />
                      )}
                      disabled={!extraData?.isDoctor}
                    />
                  </FormControl>
                </div>
                <div className="formElement   ">
                  <FormControl className="formControl">
                    <TextField
                      // hiddenLabel
                      label={t("doctor_name_optional")}
                      autoComplete="off"
                      placeholder={t("enter_doctor_name")}
                      variant="outlined"
                      className="formTextFieldArea"
                      value={doctorName}
                      onChange={(e) => {
                        setDoctorName(e.target.value);
                        updateData({ doctorName: e.target.value });
                      }}
                      disabled={!extraData?.isDoctor}
                    />
                  </FormControl>
                </div>
              </div>
            </>
          )}

          {/* {referPatient === "medicoLegal" && (
            <div className="addNewformGrp mt10">
              <div className="formElement   ">
                <FormControl className="formControl">
                  <TextField
                    // hiddenLabel
                    multiline
                    rows={4}
                    maxRows={6}
                    label={t("referral_note")}
                    autoComplete="off"
                    placeholder={t("referral_note")}
                    variant="outlined"
                    className="descptionTextFild"
                    value={referralNote}
                    onChange={(e) => {
                      setReferralNote(e.target.value);
                      updateData({ referralNote: e.target.value });
                    }}
                  />
                </FormControl>
              </div>
            </div>
          )} */}

          <div className="bb"></div>
          {!referPatient && (
            <>
              <div className="tskElemHeding mt10 ">{t("review")}</div>
              <div className="d-flex">
                <div className="formElement wAuto">
                  <FormControl className="formControl">
                    <FormGroup className="formRadioGroup">
                      <FormControlLabel
                        className="formRadioField"
                        control={
                          <Checkbox
                            checked={nextReview}
                            onChange={(e) => {
                              setNextReview(e.target.checked);
                              if (e.target.checked) {
                                // if (reviewDate) {
                                //   reviewDate.setDate(new Date().getDate() + 7);
                                // } else {
                                // }
                                const tempReviewDate = new Date(
                                  new Date().setDate(new Date().getDate() + 7)
                                );
                                setReviewDate(tempReviewDate);
                                updateData({
                                  reviewDate:
                                    dayjs(tempReviewDate).format("DD-MM-YYYY"),
                                });
                              } else {
                                updateData({
                                  reviewDate: null,
                                });
                              }
                            }}
                            disabled={!extraData?.isDoctor ? true : false}
                          />
                        }
                        label={t("next_review")}
                      />
                    </FormGroup>
                  </FormControl>
                </div>
                {nextReview === true && (
                  <>
                    <div className="formElement mxW-160">
                      <FormControl className="formControl">
                        <InputLabel
                          id="setreviewDate"
                          className="setCompletionDate"
                        >
                          {t("review_date")}*
                        </InputLabel>
                        <ReactDatePicker
                          showIcon
                          toggleCalendarOnIconClick
                          showYearDropdown
                          yearDropdownItemNumber={100}
                          scrollableYearDropdown
                          showMonthDropdown
                          labelId="setreviewDate"
                          className="formDatePicker"
                          selected={reviewDate}
                          minDate={new Date()}
                          dateFormat="dd-MMM-yyyy"
                          onChange={(date) => {
                            console.log("new date = ", date);
                            setReviewDate(date);
                            updateData({
                              reviewDate: date
                                ? dayjs(date).format("DD-MM-YYYY")
                                : null,
                            });
                          }}
                          disabled={!extraData.isDoctor}
                        />
                      </FormControl>
                    </div>
                  </>
                )}
              </div>
              <div className="bb"></div>
            </>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
}
