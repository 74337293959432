import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  Add,
  ContentCopyRounded,
  Edit,
  FormatListBulleted,
  Save,
} from "@mui/icons-material";
import {
  FileEarmarkRuled,
  FileText,
  NodePlusFill,
  Trash,
} from "react-bootstrap-icons";
import {
  addDesignation,
  copyPosition,
  createNewDepartment,
  deactivateDepartment,
  deactivatePosition,
  editDesignation,
  getAllDepartment,
  getCtcBand,
  getCurrencyList,
  getCurrencyOfUnit,
  getJdOfPosition,
  getKpiOfPosition,
  getPositionsOfOrg,
  getPositionsOfOrganization,
  getUnits,
  submitPosition,
  updateDepartment,
} from "../../../../services/AdminService";
import { getLoginUserDetails } from "../../../../utils/Utils";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { JDevdModal } from "./JDevModal";
import { KRAevdModal } from "./KRAevdModal";
import { CustomConfirmation } from "../../../../common/CustomConfirmation";
import { JdHtmlView } from "./JdHtmlView";
import { KraHtmlView } from "./KraHtmlView";
import IntegerTextField from "../../../../common/IntegerTextField";
import { useTranslation } from "react-i18next";

const DesignationModal = ({
  open,
  onClose,
  updateDesignationList,
  unitId,
  unitName,
}) => {
  const loginUserDetail = getLoginUserDetails();
  const { t } = useTranslation();
  const [designation, setDesignation] = useState("");

  const createNewDesignation = () => {
    addDesignation(
      loginUserDetail.orgId,
      loginUserDetail.userId,
      designation
    ).then((response) => {
      if (response.data === "0") {
        toast.error(response.data.message);
      } else {
        toast.success(response.data.message);
        updateDesignationList({
          positionId: 0,
          position: null,
          functionId: null,
          positionParId: 0,
          functionName: null,
          designationName: designation,
          salaryCurrency: null,
          minSalary: null,
          maxSalary: null,
          jdSummary: null,
          unitId: unitId,
          unitName: null,
        });
      }
    });
  };

  // const addNewPosition = () => {
  //   const reqDto = {
  //     positionId: 0,
  //     position: null,
  //     functionId: null,
  //     positionParId: 0,
  //     functionName: null,
  //     designationName: "",
  //     salaryCurrency: null,
  //     minSalary: null,
  //     maxSalary: null,
  //     jdSummary: null,
  //     unitId: unitId,
  //     unitName: "Unit 2",
  //     hasKpi: "Y",
  //   };

  // };

  return (
    <div className="bgOfset">
      <div className="bgOfsetOverlay"></div>
      <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
        <div className="addMoreAgendaModel">
          <div className="modelTitle">{t("add_designation")}</div>
          <div className="elementFormContainer">
            <FormControl className="formControl">
              <TextField
                required
                className="modelTextFild"
                label={t("designation_name")}
                variant="outlined"
                multiline
                value={designation}
                onChange={(e) => {
                  setDesignation(e.target.value);
                }}
              />
            </FormControl>
          </div>
          <div className="modActionBtnGrp">
            <Button
              className="dfultPrimaryBtn"
              onClick={(e) => {
                createNewDesignation();
              }}
            >
              {t("add")}
            </Button>
            <Button className="dfultDarkSecondaryBtn" onClick={onClose}>
            {t("cancel")}
            </Button>
          </div>
        </div>
      </Box>
    </div>
  );
};

const EditDesignationModal = ({
  open,
  onClose,
  designationId,
  refreshDesignation,
}) => {
  const loginUserDetail = getLoginUserDetails();
  const { t } = useTranslation();
  const [designationName, setDesignationName] = useState(null);

  const handleEditDesignation = () => {
    editDesignation(
      designationId.designationId,
      loginUserDetail.userId,
      designationName
    ).then((response) => {
      if (response.data === "0") {
        toast.error(t("designation_edit_unsuccessful"));
      } else {
        toast.success(t("designation_edited_successfully"));
        refreshDesignation();
        onClose();
      }
    });
  };

  useState(() => {
    setDesignationName(designationId.designationName);
  }, []);

  return (
    <div className="bgOfset">
      <div className="bgOfsetOverlay"></div>
      <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
        <div className="addMoreAgendaModel">
          <div className="modelTitle">{t("edit_designation")}</div>
          <div className="elementFormContainer">
            <FormControl className="formControl">
              <TextField
                required
                className="modelTextFild"
                label={t("designation_name")}
                variant="outlined"
                multiline
                autoComplete="off"
                value={designationName}
                onChange={(e) => {
                  setDesignationName(e.target.value);
                }}
              />
            </FormControl>
          </div>
          <div className="modActionBtnGrp">
            <Button
              className="dfultPrimaryBtn"
              onClick={(e) => {
                handleEditDesignation();
              }}
            >
              {t("edit")}
            </Button>
            <Button className="dfultDarkSecondaryBtn" onClick={onClose}>
            {t("cancel")}
            </Button>
          </div>
        </div>
      </Box>
    </div>
  );
};

const DepartmentModal = ({ open, onClose, refreshDepartment }) => {
  const [departmentName, setDepartmentName] = useState("");
  const loginUserDetails = getLoginUserDetails();
  const { t } = useTranslation();

  return (
    <div className="bgOfset">
      <div className="bgOfsetOverlay"></div>
      <Box className="ModelBox " sx={{ boxShadow: 24, p: 4 }}>
        <div className="addMoreAgendaModel">
          <div className="modelTitle">{t("add_department")}</div>
          <div className="elementFormContainer">
            <FormControl className="formControl">
              <TextField
                required
                className="modelTextFild"
                label={t("department_name")}
                variant="outlined"
                multiline
                departmentName={departmentName}
                onChange={(e) => {
                  setDepartmentName(e.target.value);
                }}
              />
            </FormControl>
          </div>
          <div className="modActionBtnGrp">
            <Button
              className="dfultPrimaryBtn"
              onClick={() => {
                if (
                  !departmentName ||
                  (departmentName && departmentName.trim() === "")
                ) {
                  toast.error(t("please_enter_department_name"));
                  return;
                }

                const reqDto = {
                  functionId: "",
                  functionName: departmentName,
                  functionDescription: departmentName,
                };

                createNewDepartment(
                  loginUserDetails.orgId,
                  loginUserDetails.userId,
                  reqDto
                ).then((response) => {
                  if (response.data.returnValue === "1") {
                    toast.success(t("department_created_successfully"));
                    onClose();
                    refreshDepartment();
                  } else {
                    toast.error(response.data.message);
                  }
                });
              }}
            >
              {t("add")}
            </Button>
            <Button className="dfultDarkSecondaryBtn" onClick={onClose}>
            {t("cancel")}
            </Button>
          </div>
        </div>
      </Box>
    </div>
  );
};

const CTCBandModal = ({
  open,
  onClose,
  currencies,
  updateCurrencyList,
  unitId,
}) => {
  const { t } = useTranslation();
  const [minSalary, setMinSalary] = useState(0);
  const [maxSalary, setMaxSalary] = useState(0);
  const [salaryCurrency, setSalaryCurrency] = useState(null);
  const minSalRef = useRef();
  const maxSalRef = useRef();
  useEffect(() => {
    if (currencies && currencies.length === 1) {
      setSalaryCurrency(currencies[0]);
    }
    if (currencies && currencies.length > 1) {
      getCurrencyOfUnit(unitId).then((response) => {
        if (response.data) {
          setSalaryCurrency(response.data[0]);
        }
      });
    }
  }, []);
  return (
    <div className="bgOfset">
      <div className="bgOfsetOverlay"></div>
      <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
        <div className="addMoreAgendaModel">
          <div className="modelTitle">{t("add_ctc_band")}</div>
          <div className="elementFormContainer">
            <FormControl className="formControl">
              <IntegerTextField
                required
                className="modelTextFild"
                label={t("min_wage")}
                variant="outlined"
                multiline
                value={minSalary}
                onChange={(data) => setMinSalary(data)}
                ref={minSalRef}
              />
            </FormControl>

            <FormControl className="formControl">
              <IntegerTextField
                required
                className="modelTextFild"
                label={t("max_wage")}
                variant="outlined"
                multiline
                value={maxSalary}
                onChange={(data) => setMaxSalary(data)}
                ref={maxSalRef}
              />
            </FormControl>
            <FormControl className="formControl">
              {/* <InputLabel id="compTime">Currency</InputLabel> */}
              <Select
                required
                className="modelSelectFild"
                // label={"Currency"}
                value={salaryCurrency}
                onChange={(e) => setSalaryCurrency(e.target.value)}
              >
                {currencies.map((currency) => (
                  <MenuItem key={currency} value={currency}>
                    {currency}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* <FormControl className="formControl">
              <InputLabel id="currency-label">Currency</InputLabel>
              <Select
                required
                className="modelSelectFild"
                labelId="currency-label"
                value={salaryCurrency}
                onChange={(e) => setSalaryCurrency(e.target.value)}
              >
                {currencies.map((currency) => (
                  <MenuItem key={currency} value={currency}>
                    {currency}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
          </div>
          <div className="modActionBtnGrp">
            <Button
              className="dfultPrimaryBtn"
              onClick={() => {
                if (minSalary < 0 || maxSalary < 0) {
                  toast.error(t("salary_cannot_be_negative"));
                  return;
                }

                if (minSalary > maxSalary) {
                  toast.error(
                    t("minimum_salary_cannot_be_more_than_maximum_salary")
                  );
                  return;
                }
                if (!salaryCurrency) {
                  toast.error(t("please_select_current_salary"));
                }

                updateCurrencyList({ minSalary, maxSalary, salaryCurrency });
              }}
            >
              {t("add")}
            </Button>
            <Button className="dfultDarkSecondaryBtn" onClick={onClose}>
            {t("cancel")}
            </Button>
          </div>
        </div>
      </Box>
    </div>
  );
};

const ViewEditDepartmentModal = ({
  onClose,
  departments,
  onUpdate,
  refreshDepartment,
}) => {
  const { t } = useTranslation();
  const [editsMade, setEditsMade] = useState(false);
  const loginUserDetails = getLoginUserDetails();
  const [localDepartmentData, setLocalDepartmentData] = useState([]);

  useEffect(() => {
    setLocalDepartmentData(
      departments.map((department) => ({ ...department, isEditing: false }))
    );
  }, [departments]);

  const handleEdit = (index, value) => {
    const temp = [...localDepartmentData];
    temp[index].functionName = value;
    temp[index].functionDescription = value;
    if (departments[index].functionName !== value) {
      temp[index].isEditing = true;
    }
    setLocalDepartmentData(temp);
    // onEdit(index, value);
  };

  const handleDelete = (index) => {
    deactivateDepartment(
      localDepartmentData[index].functionId,
      loginUserDetails.userId
    ).then((response) => {
      if (response.data.returnValue === "1") {
        toast.success(response.data.message);
        // onDelete(index);
        refreshDepartment();
      } else {
        toast.error(response.data.message);
      }
    });
  };

  const handleUpdateDepartment = (index) => {
    const temp = localDepartmentData[index];

    if ((temp && temp.functionName.trim() === "") || !temp) {
      toast.error(t("please_enter_department_name"));
      return;
    }
    updateDepartment(loginUserDetails.userId, temp).then((response) => {
      if (response.data.returnValue === "1") {
        toast.success(response.data.message);
        refreshDepartment();
      } else {
        toast.error(response.data.message);
      }
    });
  };

  const handleUpdate = (index) => {
    onUpdate();
    setEditsMade(false); // Reset editsMade to false after update
  };

  return (
    <div className="bgOfset">
      <div className="bgOfsetOverlay"></div>
      <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
        <div className="addMoreAgendaModel">
          <div className="modelTitle">{t("view_edit_departments")}</div>
          <div className="elementFormContainer">
            {localDepartmentData.length === 0 ? (
              <div>{t("no_departments_available")}</div>
            ) : (
              localDepartmentData.map((department, index) => (
                <div key={department.functionId} className="departmentItem">
                  <FormControl className="formControl">
                    <TextField
                      required
                      className="modelTextFild"
                      variant="outlined"
                      multiline
                      value={department.functionName}
                      onChange={(e) => handleEdit(index, e.target.value)}
                    />
                  </FormControl>
                  <ButtonGroup>
                    {department.isEditing && (
                      <IconButton onClick={() => handleUpdateDepartment(index)}>
                        <Edit />
                      </IconButton>
                    )}
                    <IconButton onClick={() => handleDelete(index)}>
                      <Trash />
                    </IconButton>
                  </ButtonGroup>
                </div>
              ))
            )}
          </div>
          <div className="modActionBtnGrp">
            {editsMade && (
              <Button className="dfultPrimaryBtn" onClick={handleUpdate}>
                {t("update")}
              </Button>
            )}
            <Button className="dfultDarkSecondaryBtn" onClick={onClose}>
            {t("close")}
            </Button>
          </div>
        </div>
      </Box>
    </div>
  );
};

// coustom tooltip start
const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 320,
    maxHeight: 200,
    overflow: "overlay",
    padding: "6px 10px",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));
// coustom tooltip end

export default function DefineSetPosition({ orgDetailsData }) {
  const loginUserDetails = getLoginUserDetails();
  const { t } = useTranslation();
  const [positions, setPosition] = useState([]);
  const [positionList, setPositionList] = useState([]);
  const [rows, setRows] = useState([]);
  const [openJDevdModal, setOpenJDevdModal] = useState(false);
  const [openKRAevdModal, setOpenKRAevdModal] = useState(false);
  const [openDesignationModal, setOpenDesignationModal] = useState(false);
  const [editDesignationModal, setEditDesignationModal] = useState(false);
  const [openDepartmentModal, setOpenDepartmentModal] = useState(false);
  const [openCTCBandModal, setOpenCTCBandModal] = useState(false);
  const [openViewEditDepartmentModal, setOpenViewEditDepartmentModal] =
    useState(false);
  const [newDesignation, setNewDesignation] = useState("");
  const [newDepartment, setNewDepartment] = useState("");
  const [departments, setDepartments] = useState([]);
  const [units, setUnits] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState("");
  const [CTCBands, setCTCBands] = useState([]);
  const [jdData, setJdData] = useState(null);
  const [currencies, setCurrencies] = useState([]);
  const [selectedDesignation, setSelectedDesignation] = useState(null);
  const [kraData, setKraData] = useState(null);
  const [selectedPositionData, setSelectedPositionData] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmObj, setConfirmObj] = useState(null);
  const [selectedFromUnitId, setSelectedFromUnitId] = useState(null);
  const [jdDataStore, setJdDataStore] = useState({});
  const [kraDataStore, setKraDataStore] = useState({});
  const [isNewActive, setIsNewActive] = useState(false);
  const [firstTimeUnitFetch, setFirstTimeUnitFetch] = useState(true);

  const copyPositionFrom = useMemo(() => {
    const temp = [];
    const bookKeeping = {};
    if (
      positions &&
      positions.length > 0 &&
      positionList &&
      positionList.length > 0
    ) {
      positions.forEach((pos) => {
        const unit = pos.unitId;
        if (!bookKeeping[unit]) {
          const posToAdd = positionList.find((item) => item.unitId === unit);
          if (posToAdd) {
            bookKeeping[unit] = true;
          }
          temp.push(pos);
        }
      });
    }
    return temp;
  }, [positionList, positions]);

  const filteredPositions = useMemo(() => {
    const posItem = positions.filter(
      (position) => position.unitId === selectedUnit
    );
    return posItem;
  }, [positions, selectedUnit]);

  useEffect(() => {
    console.log("item =");
    if (positions) {
      positions.forEach((element, index) => {
        if (element.jdSummary) {
          if (!jdDataStore[element.id]) {
            getJdOfPosition(element.positionId).then((response) => {
              if (response.data) {
                // element.jds = response.data;
                setJdDataStore((prev) => {
                  const temp = { ...prev };
                  temp[element.id] = response.data;
                  return temp;
                });
              } else {
                toast.error(t("could_not_fetch_data_something_went_wrong"));
              }
            });
          }
        }

        if (element.hasKpi === "Y") {
          if (!kraDataStore[element.id]) {
            getKpiOfPosition(element.positionId).then((response) => {
              if (response.data) {
                setKraDataStore((prev) => {
                  const temp = { ...prev };
                  temp[element.id] = response.data;
                  return temp;
                });
              }
            });
          }
        }
      });
    }
  }, [positions]);

  useEffect(() => {
    fetchAllDepartments();
    if (orgDetailsData && orgDetailsData.singleUnit === "Y") {
      setSelectedUnit(orgDetailsData.unitId);
    }
    fetchUnitData();
    // fetchPositionsOfOrg();

    fetchCtcBand();
  }, []);

  const fetchAllDepartments = () => {
    getAllDepartment(loginUserDetails.orgId).then((response) => {
      if (response.data) {
        setDepartments(response.data);
      }
    });
  };

  useEffect(() => {
    if (selectedUnit) {
      fetchSetPositionsOfOrg();
      fetchCurrencyOfOrg();
    }
  }, [selectedUnit]);

  const fetchCurrencyOfOrg = () => {
    getCurrencyList().then((response) => {
      if (response.data) {
        setCurrencies(response.data);
      }
    });
    // getCurrencyOfUnit(selectedUnit).then((response) => {
    //   if (response.data) {
    //     setCurrencies(response.data);
    //   }
    // });
  };
  const fetchCtcBand = () => {
    getCtcBand(loginUserDetails.orgId).then((response) => {
      if (response.data) {
        const tempCtcBand = response.data.map((ctcItem) => {
          const ctcparts = ctcItem.split(" ");
          const currency = ctcparts[0];
          const minMaxParts = ctcparts[1]
            .split("-")
            .map((item) => parseInt(item));
          return {
            minSalary: minMaxParts[0],
            maxSalary: minMaxParts[1],
            salaryCurrency: currency,
            salaryId: uuidv4(),
          };
        });

        setCTCBands((prev) => {
          let temp = [...prev];
          tempCtcBand.forEach((tempBandItem) => {
            const isPresent = temp.some(
              (item) =>
                item.minSalary === tempBandItem.minSalary &&
                item.maxSalary === tempBandItem.maxSalary &&
                item.salaryCurrency === tempBandItem.salaryCurrency
            );
            if (isPresent) {
              return;
            } else {
              temp.push(tempBandItem);
            }
          });
          return temp;
        });
      }
    });
  };

  const fetchUnitData = () => {
    getUnits(loginUserDetails.orgId)
      .then((response) => {
        console.log("getUnits", response.data);
        const sortedUnits = response.data.sort((a, b) => a.unitId - b.unitId);
        if (sortedUnits.length > 0) {
          setSelectedUnit(sortedUnits[0].unitId);
        }
        setUnits(sortedUnits);
      })
      .catch((error) => {
        console.error("Error fetching units", error);
      });
  };

  const fetchSetPositionsOfOrg = () => {
    getPositionsOfOrg(
      loginUserDetails.orgId,
      loginUserDetails.userId,
      selectedUnit
    ).then((response) => {
      if (response.data) {
        if (response.data.length > 0) {
          setPositionList(response.data);
          if (firstTimeUnitFetch) {
            setSelectedUnit(response.data[0].unitId);
            setFirstTimeUnitFetch(false);
          }
        }

        const tempCtcBand = [...CTCBands];
        const temp = response.data.map((item) => {
          let bandId = null;
          if (item.minSalary && item.maxSalary && item.salaryCurrency) {
            bandId = uuidv4();
            const tempSalaryBand = {
              minSalary: item.minSalary,
              maxSalary: item.maxSalary,
              salaryCurrency: item.salaryCurrency,
              salaryId: bandId,
            };
            const presentIndex = tempCtcBand.findIndex(
              (item) =>
                item.minSalary === tempSalaryBand.minSalary &&
                item.maxSalary === tempSalaryBand.maxSalary &&
                item.salaryCurrency === tempSalaryBand.salaryCurrency
            );
            if (presentIndex >= 0) {
              // toast.error("Item already present 3");
              bandId = tempCtcBand[presentIndex].salaryId;
              // return tempCtcBand;
            } else {
              tempCtcBand.push(tempSalaryBand);
            }
            // return [...tempCtcBand, tempSalaryBand];
          }

          // const temp = {
          //   ...item,
          //   id: uuidv4(),
          //   salaryId: bandId,
          //   modified: false,
          // };
          return { ...item, id: uuidv4(), salaryId: bandId, modified: false };
        });
        setCTCBands(tempCtcBand);
        setPosition(temp);
      }
    });
  };

  const refreshDepartment = fetchAllDepartments;
  const refreshDesignation = fetchSetPositionsOfOrg;

  const getUnitNameByUnitId = (selectedUnit) => {
    const temp = units.find((item) => item.unitId === selectedUnit);
    return temp.unitName;
  };

  const handleSelectDepartment = (id, value) => {
    const temp = [...positions];
    const index = temp.findIndex((itm) => itm.id === id);
    if (index >= 0) {
      temp[index].functionId = value;
      let fName = "";
      departments.forEach((department) => {
        if (department.functionId === value) {
          fName = department.functionName;
        }
      });
      temp[index].functionName = fName;
      setPosition(temp);
      handleSetModified(id);
    }
  };

  const handleSelectUnit = (event) => {
    setSelectedUnit(event.target.value);
  };

  const handleSelectReportingTo = (id, value) => {
    const temp = [...positions];
    const index = temp.findIndex((itm) => itm.id === id);
    temp[index].positionParId = value;
    setPosition(temp);
    handleSetModified(id);
  };

  const handleCTCBand = (id, value) => {
    const temp = [...positions];
    const index = temp.findIndex((itm) => itm.id === id);

    if (value === null) {
      temp[index].maxSalary = 0;
      temp[index].minSalary = 0;
      temp[index].salaryCurrency = null;
      temp[index].salaryId = null;
      handleSetModified(id);

      return;
    }
    const ctcBnd = CTCBands.find((item) => item.salaryId === value);

    temp[index].maxSalary = ctcBnd.maxSalary;
    temp[index].minSalary = ctcBnd.minSalary;
    temp[index].salaryCurrency = ctcBnd.salaryCurrency;
    temp[index].salaryId = ctcBnd.salaryId;
    handleSetModified(id);
    setPosition(temp);
  };

  const handleAddDesignation = () => {
    setRows((prevRows) => [
      ...prevRows,
      {
        designationText: newDesignation,
        selectDepartment: "",
        selectReportingTo: "",
        selectCTCBand: "",
        isEditing: false,
      },
    ]);
    setNewDesignation("");
    setOpenDesignationModal(false);
  };

  const handleUpdateDepartments = () => {
    const updatedDepartments = [...departments];
    setDepartments(updatedDepartments);
    setOpenViewEditDepartmentModal(false);
  };

  const handleSetModified = (id) => {
    setPosition((prev) => {
      const temp = [...prev];
      const index = temp.findIndex((item) => item.id === id);
      if (index >= 0) {
        temp[index].modified = true;
      }
      return temp;
    });
  };

  return (
    <>
      <div className="fullContainArea">
        <div className="cuHeaderGrp">
          <div className="cuhederTxt">{t("organization_position")}</div>

          {orgDetailsData && orgDetailsData.singleUnit === "N" && (
            <div className="addUnitsBtn organizationPosition w220">
              <FormControl className="formControl">
                <InputLabel id="setUnits">{t("select_unit")}</InputLabel>
                <Select
                  className="formInputField"
                  variant="outlined"
                  labelId="setUnits"
                  id="unit-select"
                  value={selectedUnit}
                  label={t("select_unit")}
                  onChange={handleSelectUnit}
                >
                  {/* <MenuItem value={null} disabled>
                    Select Unit
                  </MenuItem> */}
                  {units.map((unit) => (
                    <MenuItem key={unit.unitId} value={unit.unitId}>
                      {unit.unitName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
        </div>
        <div className="cuContainArea">
          <div className="TableContainer organizationPosition">
            <table className="taskListTable">
              <thead className="taskListtableHeader">
                <tr>
                  <th>
                    <div className="txtWithBtn">
                    {t("designation")}
                      <Tooltip arrow title={t("add_new_designation")}>
                        {/* <IconButton
                          onClick={() => setOpenDesignationModal(true)}
                        >
                          <Add />
                        </IconButton> */}
                      </Tooltip>
                    </div>
                  </th>
                  <th>
                    <div className="txtWithBtn">
                    {t("department")}
                      <Tooltip arrow title="Add New Department">
                        <IconButton
                          onClick={() => setOpenDepartmentModal(true)}
                        >
                          <Add />
                        </IconButton>
                      </Tooltip>
                      <Tooltip arrow title={t("view_edit_department")}>
                        <IconButton
                          onClick={() => setOpenViewEditDepartmentModal(true)}
                        >
                          <FormatListBulleted />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </th>
                  {/* <th>
                    <div className="txtWithBtn">Units</div>
                  </th>
                  <th>
                    <div className="txtWithBtn">Full Title</div>
                  </th> */}

                  <th>
                    <div className="txtWithBtn">{t("reporting_to")}</div>
                  </th>
                  <th>
                    <div className="txtWithBtn">
                    {t("annual_ctc_band")}
                      <Tooltip arrow title={t("add_new_ctc_band")}>
                        <IconButton onClick={() => setOpenCTCBandModal(true)}>
                          <Add />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </th>
                  <th className="mxWidth100">
                    <div className="txtWithBtn">{t("jd")}</div>
                  </th>
                  <th className="mxWidth100">
                    <div className="txtWithBtn">{t("kra")}</div>
                  </th>
                  <th className="mxWidth150">
                    <div className="txtWithBtn">{t("add_remove")}</div>
                  </th>
                </tr>
              </thead>
              <tbody className="scrolableTableBody">
                {filteredPositions.length === 0 ? (
                  <tr>
                    <td colSpan="7">
                      <div className="emptyRowMessage">
                        {orgDetailsData &&
                        orgDetailsData.singleUnit === "N" &&
                        units.length === 0 ? (
                          <>
                            <span className="bigTxt">{t("oops")}</span>
                            <span>
                            {t("no_unit_is_defined_please_add_a_new_unit")}
                            </span>
                          </>
                        ) : (
                          <>
                            <span className="bigTxt">{t("oops")}</span>
                            <span>
                            {t("no_position_defined_please_add_a_new_position")}
                            </span>
                            <Button
                              className="emptyRowBtn"
                              onClick={() => {
                                const reqDto = {
                                  positionId: 0,
                                  position: null,
                                  functionId: null,
                                  positionParId: 0,
                                  functionName: null,
                                  designationName: "",
                                  salaryCurrency: null,
                                  minSalary: null,
                                  maxSalary: null,
                                  jdSummary: null,
                                  unitId: selectedUnit,
                                  unitName: getUnitNameByUnitId(selectedUnit),
                                  hasKpi: "N",
                                  salaryId: null,
                                  id: uuidv4(),
                                  modified: true,
                                };
                                const temp = [...positions];
                                temp.push(reqDto);
                                setPosition(temp);
                                setIsNewActive(true);
                              }}
                              startIcon={<Add />}
                            >
                              {t("position")}
                            </Button>

                            {orgDetailsData &&
                              orgDetailsData.singleUnit === "N" && (
                                <>
                                  <span>{t("or")}</span>
                                  <div className="copyPositionRow">
                                    {filteredPositions.length === 0 && (
                                      <div className="addUnitsBtn organizationPosition width260">
                                        <FormControl className="formControl">
                                          <InputLabel id="setUnits">
                                          {t("select_unit_from_where_to_copy")}
                                          </InputLabel>
                                          <Select
                                            className="formInputField"
                                            variant="outlined"
                                            labelId="setUnits"
                                            id="unit-select"
                                            value={selectedFromUnitId}
                                            label={t("select_unit_from_where_to_copy")}
                                            onChange={(e) => {
                                              setSelectedFromUnitId(
                                                e.target.value
                                              );
                                            }}
                                          >
                                            <MenuItem value={null}>
                                            {t("select_unit")}
                                            </MenuItem>
                                            {copyPositionFrom.map((unit) => (
                                              <MenuItem
                                                key={unit.unitId}
                                                value={unit.unitId}
                                              >
                                                {unit.unitName}
                                              </MenuItem>
                                            ))}
                                          </Select>
                                        </FormControl>
                                      </div>
                                    )}
                                    {orgDetailsData &&
                                      orgDetailsData.singleUnit === "N" &&
                                      filteredPositions.length === 0 && (
                                        <div className="copyPositionbtn">
                                          <Button
                                            startIcon={<ContentCopyRounded />}
                                            onClick={() => {
                                              if (selectedFromUnitId === null) {
                                                toast.error(
                                                  t("please_select_unit_from_where_position_is_To_be_copied")
                                                );
                                                return;
                                              }
                                              copyPosition(
                                                selectedFromUnitId,
                                                selectedUnit,
                                                loginUserDetails.userId
                                              ).then((response) => {
                                                if (response.data === "0") {
                                                  toast.error(
                                                    t("designation_copy_unsuccessful")
                                                  );
                                                } else {
                                                  toast.success(
                                                    t("designation_copied_successFully")
                                                  );
                                                  setSelectedFromUnitId(null);
                                                  fetchSetPositionsOfOrg();
                                                }
                                              });
                                            }}
                                          >
                                            {t("copy_positions")}
                                          </Button>
                                        </div>
                                      )}
                                  </div>
                                </>
                              )}
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                ) : (
                  filteredPositions.map((row, index) => (
                    <tr key={row.id}>
                      <td>
                        <div className="orgPText">
                          {/* {row.modf ? (
                            <TextField
                              className="formInputSelect"
                              value={row.designationName}
                              onChange={(e) =>
                                handleChangedesignationText(
                                  index,
                                  e.target.value
                                )
                              }
                              size="small"
                            />
                          ) : (
                            row.designationName
                          )} */}

                          <TextField
                            className="formInputSelect"
                            value={row.designationName}
                            autoComplete="off"
                            onChange={(e) => {
                              const temp = [...positions];
                              const index = positions.findIndex(
                                (item) => item.id === row.id
                              );
                              if (index < 0) {
                                toast.error(t("somethign_went_wrong"));
                              } else {
                                temp[index].designationName = e.target.value;
                                handleSetModified(row.id);
                                setPosition(temp);
                              }
                              console.log("helo");
                            }}
                            size="small"
                          />

                          {/* <ButtonGroup
                            variant="contained"
                            className="buttonContainerGrp singleBtn"
                          >
                            <IconButton
                              onClick={() => {
                                setEditDesignationModal(true);
                                setSelectedDesignation(row);
                              }}
                            >
                              {row.isEditing ? <Save /> : <Edit />}
                            </IconButton>
                          </ButtonGroup> */}
                        </div>
                      </td>
                      <td>
                        <Select
                          className="formInputSelect"
                          value={row.functionId}
                          hiddenLabel
                          size="small"
                          onChange={(e) =>
                            handleSelectDepartment(row.id, e.target.value)
                          }
                        >
                          <MenuItem value={null}>{t("select_department")}</MenuItem>
                          {departments.map((department) => (
                            <MenuItem
                              key={department.functionId}
                              value={department.functionId}
                            >
                              {department.functionName}
                            </MenuItem>
                          ))}
                        </Select>
                      </td>
                      {/* <td>
                        <Select
                          className="formInputSelect"
                          value={row.selectUnit}
                          hiddenLabel
                          size="small"
                          onChange={(e) =>
                            handleSelectUnit(index, e.target.value)
                          }
                        >
                          <MenuItem value={null} disabled>
                            Select Unit
                          </MenuItem>
                          {units.map((units) => (
                            <MenuItem key={units.value} value={units.value}>
                              {units.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </td> */}
                      {/* <td>
                        <div className="orgPText">
                          {row.designationText},{" "}
                          {
                            departments.find(
                              (department) =>
                                department.value === row.selectDepartment
                            )?.label
                          }
                        </div>
                      </td> */}

                      <td>
                        <Select
                          className="formInputSelect"
                          value={row.positionParId}
                          hiddenLabel
                          size="small"
                          onChange={(e) =>
                            handleSelectReportingTo(row.id, e.target.value)
                          }
                        >
                          <MenuItem key={"repoting_null"} value={null}>
                          {t("select_reporting_head")}
                          </MenuItem>
                          {positionList
                            .filter(
                              (item) =>
                                !(
                                  item.designationName ===
                                    row.designationName &&
                                  item.functionId === row.functionId &&
                                  item.unitId === row.unitId
                                )
                            )
                            .map((position, rowIndex) => (
                              <MenuItem
                                key={position.positionId}
                                value={position.positionId}
                              >
                                {orgDetailsData &&
                                orgDetailsData.singleUnit === "Y"
                                  ? `${position.position}`
                                  : `${position.position} ( ${position.unitName} )`}
                              </MenuItem>
                            ))}
                        </Select>
                      </td>
                      <td>
                        <Select
                          className="formInputSelect"
                          value={row.salaryId}
                          hiddenLabel
                          size="small"
                          onChange={(e) =>
                            handleCTCBand(row.id, e.target.value)
                          }
                        >
                          <MenuItem key={"ctc_null"} value={null}>
                          {t("none")}
                          </MenuItem>
                          {CTCBands &&
                            CTCBands.map((CTCBand) => (
                              <MenuItem
                                key={CTCBand.salaryId}
                                value={CTCBand.salaryId}
                              >
                                {`${CTCBand.minSalary} - ${CTCBand.maxSalary} ,  ${CTCBand.salaryCurrency}`}
                              </MenuItem>
                            ))}
                        </Select>
                      </td>
                      <td>
                        <div className="gdkraBtnGrp">
                          {console.log("position Data =", positions)}
                          {
                            <HtmlTooltip
                              title={
                                <>
                                  <JdHtmlView
                                    jdData={jdDataStore[row.id]}
                                    positionData={row}
                                  />
                                </>
                              }
                            >
                              <Button
                                startIcon={<FileText />}
                                className={
                                  row.jdSummary || jdDataStore[row.id]
                                    ? "activeJd"
                                    : ""
                                }
                                onClick={() => {
                                  if (
                                    row.designationName === "" ||
                                    !row.designationName
                                  ) {
                                    toast.error(t("please_add_designation"));
                                    return;
                                  }
                                  if (
                                    row.functionId === null ||
                                    row.functionId === ""
                                  ) {
                                    toast.error(t("please_select_department"));
                                    return;
                                  }
                                  setJdData(jdDataStore[row.id]);
                                  setSelectedPositionData(row);
                                  setOpenJDevdModal(true);

                                  // getJdOfPosition(row.positionId).then(
                                  //   (response) => {
                                  //     if (response.data) {
                                  //       setJdData(response.data);
                                  //       setSelectedPositionData(row);
                                  //       setOpenJDevdModal(true);
                                  //     } else {
                                  //       toast.error(
                                  //         "Could not fetch Data. Something Wrong happened"
                                  //       );
                                  //     }
                                  //   }
                                  // );
                                }}
                              >
                                {t("jd")}
                              </Button>
                            </HtmlTooltip>
                          }
                        </div>
                      </td>
                      <td>
                        <div className="gdkraBtnGrp">
                          <HtmlTooltip
                            title={
                              <>
                                <KraHtmlView kraData={kraDataStore[row.id]} />
                              </>
                            }
                          >
                            <Button
                              startIcon={<FileEarmarkRuled />}
                              className={
                                row.hasKpi === "Y" || kraDataStore[row.id]
                                  ? "activeKra"
                                  : ""
                              }
                              onClick={() => {
                                if (
                                  row.designationName === "" ||
                                  !row.designationName
                                ) {
                                  toast.error(t("please_add_designation"));
                                  return;
                                }
                                if (
                                  row.functionId === null ||
                                  row.functionId === ""
                                ) {
                                  toast.error(t("please_select_department"));
                                  return;
                                }

                                setKraData(kraDataStore[row.id]);
                                // getKpiOfPosition(row.positionId).then(
                                //   (response) => {
                                //     if (response.data) {
                                //     }
                                //   }
                                // );
                                setSelectedPositionData(row);
                                setOpenKRAevdModal(true);
                              }}
                            >
                              {t("kra")}
                            </Button>
                          </HtmlTooltip>
                        </div>
                      </td>
                      <td>
                        <div className="orgPText">
                          <ButtonGroup
                            variant="contained"
                            className="buttonContainerGrp"
                          >
                            {row.modified && (
                              <IconButton
                                onClick={() => {
                                  let isValid = true;

                                  if (
                                    !row.designationName ||
                                    row.designationName === ""
                                  ) {
                                    toast.error(
                                      t("please_define_designation_name")
                                    );
                                    isValid = false;
                                  }
                                  if (row.functionId === null) {
                                    toast.error(
                                      t("please_select_department_name")
                                    );
                                    isValid = false;
                                  }

                                  // if (
                                  //   row.positionParId === null ||
                                  //   row.positionParId === 0
                                  // ) {
                                  //   toast.error("Please select reporting head");
                                  //   isValid = false;
                                  // }

                                  // if (!row.salaryId) {
                                  //   toast.error(
                                  //     "Please select annual CTC band"
                                  //   );
                                  //   isValid = false;
                                  // }

                                  if (!isValid) {
                                    return;
                                  }

                                  const reqDto = {
                                    positionId: row.positionId,
                                    position: row.position,
                                    functionId: row.functionId,
                                    positionParId: row.positionParId,
                                    functionName: row.functionName,
                                    designationName: row.designationName,
                                    salaryCurrency: row.salaryCurrency,
                                    jdSummary: row.jdSummary,
                                    unitId: row.unitId,
                                    unitName: row.unitName,
                                    hasKpi: row.hasKpi,
                                    minSalary: row.minSalary,
                                    maxSalary: row.maxSalary,
                                    kpis: kraDataStore[row.id] || [],
                                    jds: jdDataStore[row.id] || [],
                                  };

                                  console.log("reqDto = ", reqDto);
                                  // return;
                                  submitPosition(
                                    loginUserDetails.orgId,
                                    loginUserDetails.userId,
                                    reqDto
                                  ).then((response) => {
                                    if (response.data === 0) {
                                      toast.error(t("somethign_went_wrong"));
                                    } else {
                                      toast.success(
                                        t("data_submitted_successfully")
                                      );
                                      setIsNewActive(false);
                                      // const temp = [...positions];
                                      // setPosition(temp);
                                      fetchSetPositionsOfOrg();
                                    }
                                  });
                                }}
                              >
                                <Save />
                              </IconButton>
                            )}

                            {row.positionId !== 0 && (
                              <IconButton
                                onClick={() => {
                                  if (isNewActive) {
                                    toast.error(t("please_save_current_position"));
                                    return;
                                  }
                                  const reqDto = {
                                    positionId: 0,
                                    position: "",
                                    functionId: null,
                                    positionParId: 0,
                                    functionName: null,
                                    designationName: "",
                                    salaryCurrency: null,
                                    minSalary: null,
                                    maxSalary: null,
                                    jdSummary: null,
                                    unitId: row.unitId,
                                    unitName: row.unitName,
                                    hasKpi: "N",
                                    localyAdded: true,
                                    modified: true,
                                    id: uuidv4(),
                                    salaryId: null,
                                  };

                                  const temp = [...positions];
                                  const index = temp.findIndex(
                                    (item) => item.id === row.id
                                  );

                                  if (index >= 0) {
                                    temp.splice(index + 1, 0, reqDto);
                                    setIsNewActive(true);
                                    setPosition(temp);
                                  } else {
                                    toast.error(
                                      t("somethign_went_wrong")
                                    );
                                  }
                                }}
                              >
                                <NodePlusFill />
                              </IconButton>
                            )}

                            <IconButton
                              className="remove"
                              onClick={() => {
                                setShowConfirm(true);
                                setConfirmObj({
                                  title: t("do_you_want_to_delete") ,
                                  positiveButtonName: t("yes") ,
                                  negativeButtonName: t("no") ,
                                  handlePositive: () => {
                                    const temp = [...positions];
                                    const index = temp.findIndex((item) => {
                                      return item.id === row.id;
                                    });
                                    if (index >= 0) {
                                      if (temp[index].positionId === 0) {
                                        temp.splice(index, 1);
                                        setIsNewActive(false);
                                      } else {
                                        deactivatePosition(
                                          temp[index].positionId,
                                          loginUserDetails.userId
                                        ).then((response) => {
                                          if (
                                            response.data.returnValue === "1"
                                          ) {
                                            if (row.positionId === 0) {
                                              setIsNewActive(false);
                                            }
                                            temp.splice(index, 1);

                                            toast.success(
                                              response.data.message
                                            );
                                            // setPosition(temp);
                                            fetchSetPositionsOfOrg();
                                          } else {
                                            toast.error(response.data.message);
                                          }
                                        });
                                      }
                                      setPosition(temp);
                                      setConfirmObj(null);
                                      setShowConfirm(false);
                                    } else {
                                      toast.error(t("somethign_went_wrong"));
                                    }
                                  },
                                  handleNegative: () => {
                                    setShowConfirm(false);
                                    setConfirmObj(null);
                                  },
                                });
                              }}
                            >
                              <Trash />
                            </IconButton>
                          </ButtonGroup>
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {showConfirm && (
        <CustomConfirmation
          openModal={showConfirm}
          handleCloseModal={(e) => {
            setShowConfirm(false);
          }}
          {...confirmObj}
        />
      )}

      {openDesignationModal && (
        <DesignationModal
          open={openDesignationModal}
          onClose={() => setOpenDesignationModal(false)}
          onSave={handleAddDesignation}
          value={newDesignation}
          onChange={(e) => setNewDesignation(e.target.value)}
          unitId={selectedUnit}
          updateDesignationList={(data) => {
            const temp = [...positions];
            temp.push(data);
            setPosition(temp);
          }}
        />
      )}

      {editDesignationModal && (
        <EditDesignationModal
          open={editDesignationModal}
          onClose={() => setEditDesignationModal(false)}
          refreshDesignation={refreshDesignation}
          designationId={selectedDesignation}
        />
      )}

      {openDepartmentModal && (
        <DepartmentModal
          open={openDepartmentModal}
          onClose={() => setOpenDepartmentModal(false)}
          refreshDepartment={refreshDepartment}
        />
      )}
      {openCTCBandModal && (
        <CTCBandModal
          open={openCTCBandModal}
          onClose={() => setOpenCTCBandModal(false)}
          currencies={currencies}
          updateCurrencyList={(data) => {
            const temp = [...CTCBands];

            const isPresent = temp.some(
              (item) =>
                item.minSalary === data.minSalary &&
                item.maxSalary === data.maxSalary &&
                item.salaryCurrency === data.salaryCurrency
            );
            if (isPresent) {
              toast.error(t("item_already_present"));
              return;
            }

            temp.push({ ...data, salaryId: uuidv4() });
            setCTCBands(temp);
            setOpenCTCBandModal(false);
          }}
          unitId={selectedUnit}
        />
      )}
      {openViewEditDepartmentModal && (
        <ViewEditDepartmentModal
          onClose={() => setOpenViewEditDepartmentModal(false)}
          departments={departments}
          onUpdate={handleUpdateDepartments}
          refreshDepartment={refreshDepartment}
        />
      )}

      {openJDevdModal && (
        <JDevdModal
          open={openJDevdModal}
          onClose={() => setOpenJDevdModal(false)}
          jdData={jdData}
          positionData={selectedPositionData}
          updateData={(data) => {
            const { jdSummary, jdDataCopy } = data;

            setPosition((prev) => {
              const temp = [...prev];
              const index = temp.findIndex(
                (item) => item.id === selectedPositionData.id
              );
              temp[index].jdSummary = jdSummary;
              return temp;
            });

            setJdDataStore((prev) => {
              const temp = { ...prev };
              temp[selectedPositionData.id] = jdDataCopy;
              handleSetModified(selectedPositionData.id);

              return temp;
            });
          }}
        />
      )}
      {openKRAevdModal && (
        <KRAevdModal
          open={openKRAevdModal}
          onClose={() => setOpenKRAevdModal(false)}
          positionData={selectedPositionData}
          kraData={kraData}
          updateData={(data) => {
            setKraDataStore((prev) => {
              const temp = { ...prev };
              handleSetModified(selectedPositionData.id);

              temp[selectedPositionData.id] = data;
              return temp;
            });
          }}
        />
      )}
    </>
  );
}
