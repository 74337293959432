import React from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
  BarController,
} from "chart.js";

import { ManageHistoryRounded } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import { Bar, Line, Pie } from "react-chartjs-2";
import { ArrowRepeat, Eye } from "react-bootstrap-icons";

export default function CardReviewReferredPatient({
  handleOpenViewReferredPatient,
  handelOpenViewReviewPatient,
  chartDataOfItem ,
  fetchDashBoardData 
}) {
  ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    LineController,
    BarController
  );
  const data = {
    // labels: ["Review ", "Referred "],
    labels : chartDataOfItem?.todayUnitRefer?.length > 0 ? chartDataOfItem.todayUnitRefer.map((item) => item.label) : [],
    datasets: [
      {
        label: "Patient",
        // data: [50, 30],
        data : chartDataOfItem?.todayUnitRefer?.length > 0 ? chartDataOfItem.todayUnitRefer.map((item) => item.count) : [],
        backgroundColor: ["rgba(8, 121, 167, 1)", "rgba(243, 190, 8, 1)"],
        borderColor: ["rgba(8, 121, 167, .6)", "rgba(243, 190, 8, .6)"],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    cutout: "50%",
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
        position: "bottom",
        labels: {
          boxWidth: 6,
          color: "#d2d2d2",
          font: {
            size: 12,
            weight: "light",
          },
        },
      },
    },
  };

  const barLineData = {
    // labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
    labels : chartDataOfItem?.todayAndFutureUnitReview ? Object.keys(chartDataOfItem.todayAndFutureUnitReview) : [],
    datasets: [
      {
        label: "",
        // data: [12, 19, 3, 5, 2, 3],
        data: chartDataOfItem?.todayAndFutureUnitReview ? Object.values(chartDataOfItem.todayAndFutureUnitReview) : [],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const barLineOoptions = {
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "",
      },
    },
  };
  return (
    <>
      <div className="anDashIndCard">
        <div className="anDashCardArea">
          <div className="andHeader">
            <div className="andCrdNM">Review & Referred
            <IconButton
            className={`refreshIcon`}
            onClick={fetchDashBoardData}
            >
            <ArrowRepeat />
            </IconButton>
            </div>
            <div className="andCrdUnitCount">
              <span title="Attrition">
                {/* <Male /> */}
                {/* This Month: <span className="fbold"> 215421</span> */}
              </span>

              <span title="Tenure">
                {/* <Female /> */}
                {/* This week: <span className="fbold">12154</span> */}
              </span>
            </div>
          </div>

          <div className="andCrdGraf">
            <div className="anDLftGrf">
              <div className="centTxtShow">
                <Pie
                  className="hw100"
                  type="doughnut"
                  data={data}
                  options={options}
                />
                <div className="highlighted-text">
                  {/* <span>Total</span> */}
                  <span className="highDataCount">{chartDataOfItem.todayUnitReferCount}</span>
                </div>
              </div>
            </div>
            <div className="anDRightGrf">
              <Line
                className="hw100"
                type="line"
                data={barLineData}
                options={barLineOoptions}
              />
              {/* <Bar data={barLineData} options={barLineOoptions} /> */}
            </div>
          </div>

          <div className="andCrdFooter">
            <Button
              className="dfultPrimaryBtn"
              startIcon={<Eye />}
              onClick={() => handleOpenViewReferredPatient()}
            >
              Refferred Patient
            </Button>
            <Button
              className="dfultPrimaryBtn"
              startIcon={<Eye />}
              onClick={() => handelOpenViewReviewPatient()}
            >
              Review Patient
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
