import {
  AccessibleRounded,
  ArrowBack,
  ArrowBackIosNewRounded,
  ArrowForwardIosRounded,
  FemaleRounded,
  MaleRounded,
  MonetizationOnOutlined,
  StarRounded,
  TranslateRounded,
} from "@mui/icons-material";
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useMemo } from "react";

import { useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Slider from "react-slick";
import DashboardServices from "../../../../../services/DashboardServices";
import { getLoginUserDetails } from "../../../../../utils/Utils";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import EXP from "../../../../dashboard/image/EXP";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AutorenewIcon from '@mui/icons-material/Autorenew';

const GenderIcon = ({ patientGender }) => {
  let icon;

  if (patientGender === "Male") {
    icon = <MaleRounded />;
  } else if (patientGender === "Female") {
    icon = <FemaleRounded />;
  }

  return icon;
};

export default function ViewBookedAppointmentConsultation({
  handelCloseViewBookedAppointmentConsultation,
  closeAll,
  openAppoinmentBooking,
  refreshBookedAppoinments,
}) {
  const [monthSelect, setMonthSelect] = useState(new Date());
  const [selectDoctor, setSelectDoctor] = useState("");
  const loginUserDetail = getLoginUserDetails();
  const [doctors, setDoctors] = useState([]);
  const [searchDoctorString, setSearchDoctorString] = useState("");

  const filteredDoctor = useMemo(() => {
    let temp = [];
    if (!searchDoctorString || searchDoctorString === "") {
      return doctors;
    } else {
      temp = doctors?.filter((item) => {
        return (
          item.doctorName
            ?.toLowerCase()
            .includes(searchDoctorString?.toLowerCase()) ||
          item.specialization
            ?.toLowerCase()
            .includes(searchDoctorString?.toLowerCase())
        );
      });
    }
    console.log("filtered doctor list =", temp);
    return temp || [];
  }, [searchDoctorString, doctors]);

  const handleChangselectDoctor = (event) => {
    setSelectDoctor(event.target.value);
  };
  const handleMonthSelect = (newDate) => {
    setMonthSelect(newDate);
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 600,
    slidesToShow: 5,
    slidesToScroll: 5,
    initialSlide: 0,
    nextArrow: <ArrowForwardIosRounded />,
    prevArrow: <ArrowBackIosNewRounded />,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const getDoctorAppoinment = (date) => {
    // const toastLoaderId = toast.loading("Fetching appoinment data");
    DashboardServices.getDoctorsAppointmentOnDate(
      loginUserDetail.signleUnitId,
      date
    )
      .then((response) => {
        if (response.data) {
          console.log("Dcotor data =", response.data);
          let tempDoctorData = [];
          response.data.forEach((item) => {
            let languages = item.language.split(",");
            const temp = item?.slots?.filter((slot) => {
              // slot.
              return slot.patientId !== 0;
            });
            if (temp?.length > 0) {
              tempDoctorData.push({
                ...item,
                slots: temp,
                language: languages,
              });
            }
          });

          setDoctors(tempDoctorData);
        }
      })
      .catch((e) => {
        // toast.update(toastLoaderId, {
        //   render: "Could not fetch data",
        //   type: "error",
        //   isLoading: false,
        //   autoClose: true,
        // });
      });
  };

  useEffect(() => {
    getDoctorAppoinment(dayjs(monthSelect).format("DD-MM-YYYY"));
  }, [refreshBookedAppoinments]);

  useEffect(() => {
    getDoctorAppoinment(dayjs(new Date()).format("DD-MM-YYYY"));
  }, []);

  return (
    <>
      <div className="AnalyticsDashboardContainer">
        <div className="AnalyticsDashboardSubHeader">
          <IconButton
            onClick={() => {
              handelCloseViewBookedAppointmentConsultation();
              closeAll();
            }}
          >
            <ArrowBack />
          </IconButton>
          View Appointments
        </div>
        <div className="anDashCardContainArea">
          <div className="fullContainArea">
            <div className="cuHeaderGrp">
              <div className="formSearchGrp">
                <div className="formElement width250">
                  <FormControl className="formControl">
                    {/* <InputLabel id="selectDoctor">Select Doctor</InputLabel> */}
                    <TextField
                      label="Search Doctor or speciality"
                      variant="outlined"
                      className="formTextFieldArea"
                      value={searchDoctorString}
                      // onChange={(e) => setTaskName(e.target.value)}
                      onChange={(e) => {
                        setSearchDoctorString(e.target.value);
                        // handelChangeTaskName(e);
                      }}
                      autoComplete="off"
                    />
                  </FormControl>
                </div>
                <div className="formElement width180">
                  <FormControl className="formControl selectMonth">
                    <InputLabel id="monthSelect" className="setCompletionDate">
                      Select Date
                    </InputLabel>
                    <ReactDatePicker
                      showYearDropdown
                      scrollableYearDropdown
                      showMonthDropdown
                      toggleCalendarOnIconClick
                      yearDropdownItemNumber={100}
                      scrollableMonthYearDropdown
                      showIcon
                      labelId="monthSelect"
                      className="formDatePicker "
                      selected={monthSelect}
                      minDate={
                        new Date(new Date().setMonth(new Date().getMonth() - 4))
                      }
                      maxDate={
                        new Date(new Date().setMonth(new Date().getMonth() + 4))
                      }
                      dateFormat="dd-MMM-yyyy"
                      onChange={(date) => {
                        handleMonthSelect(date);
                        getDoctorAppoinment(dayjs(date).format("DD-MM-YYYY"));
                      }}
                    />
                  </FormControl>
                </div>
              </div>
            </div>

            <div className="cuContainArea">
              {filteredDoctor?.length > 0 && (
                <div className="doctor-carousel BaDocCarousel">
                  <Slider {...settings} className="doclistCusSlider">
                    {filteredDoctor?.map((doctor, index) => (
                      <div key={index} className="doctor-cardBA">
                        <div className="doctor-infoBA">
                          <div className="docImage">
                            <img
                              src={doctor.imageLink}
                              alt={doctor.doctorName}
                            />
                          </div>

                          <div className="docNameOther">
                            <div className="doctorNmSpc">
                              <div className="docName">{doctor.doctorName}</div>
                              <div className="docSpclty">
                                {doctor.specialization}
                              </div>
                            </div>
                            <div className="docExpFeeLan">
                              <div className="docEFL langW100p">
                                <div className="eflicon">
                                  <TranslateRounded />
                                </div>
                                <div className="eflText">
                                  {doctor.language.join(", ")}
                                </div>
                              </div>
                              <div className="docEFL">
                                <div className="eflicon fillnone">
                                  <EXP />
                                </div>
                                <div className="eflText">
                                  {doctor.experience}
                                </div>
                              </div>

                              <div className="docEFL">
                                <div className="eflicon">
                                  <MonetizationOnOutlined />
                                </div>
                                <div className="eflText">
                                  {doctor.fees}
                                  <span>{doctor.feesCurrency}</span>
                                </div>
                              </div>
                              <div className="docEFL">
                                <div className=" docRatvalue">
                                  <StarRounded />
                                </div>
                                <div className="eflText">{doctor.rating}</div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="slots-container">
                          {doctor.slots.map((slot, idx) => (
                            <div
                              onClick={() => {
                                const { slots, ...doctorData } = doctor;
                                openAppoinmentBooking({
                                  ...doctorData,
                                  slot,
                                  date: monthSelect,
                                });
                              }}
                              key={idx}
                              // className={`slot-box ${
                              //   !slot.patientId ? "" : "booked"
                              // }`}
                              className={`slot-box ${!slot.patientId ? "" : "booked"} ${
                                slot.rescheduleDate && slot.rescheduleReqRmks ? "RescheduleRequest" : ""
                              }`}
                            >
                              <div className="slotInfo">
                                <div class="icon">
                                  <AccessibleRounded />
                                </div>
                                <div className="slotTime">{`${slot.fromTime}-${slot.toTime}`}</div>
                              </div>
                              <div className="bookedPatInfo">
                                <div className="patDtl">
                                  <div className="patgender">
                                    <GenderIcon
                                      patientGender={slot.patientGender}
                                    />
                                  </div>

                                  <div className="patName">
                                    {slot.patientName} {slot.rescheduleDate && slot.rescheduleReqRmks && (
              <AutorenewIcon />
            )}
                                  </div>
                                </div>
                                <div className="patAge">{slot.patientAge}</div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              )}
              {!filteredDoctor ||
                (filteredDoctor?.length === 0 && (
                  <div class="noDataCard ">
                    <span>Oops !!</span>
                    <br />
                    <span className="sallMsg">
                      No Booked Doctor Slots Found
                    </span>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
