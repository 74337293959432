import {
    AccessibleRounded,
    Add,
    ArrowBack,
    ArrowBackIosNewRounded,
    ArrowForwardIosRounded,
    FemaleRounded,
    MaleRounded,
    Search,
    Settings,
  } from "@mui/icons-material";
  import {
    Button,
    CircularProgress,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
  } from "@mui/material";
  import React, { useEffect } from "react";
  import { useState } from "react";
  import ReactDatePicker from "react-datepicker";
  import "react-datepicker/dist/react-datepicker.css";
  import DashboardServices from "../../../../../services/DashboardServices";
  import { jwtDecode } from "jwt-decode";
  import DateUtils from "../../../../../utils/DateUtils";
  import { toast } from "react-toastify";
  import AutorenewIcon from '@mui/icons-material/Autorenew';
  
  export default function ViewNewAppointmentBooked({
    handleCloseViewNewAppointMentBooked,
    closeAll,
    handleOpenResheduleDrAppointment,
    refreshReschduleDocAppoint
  }) {
    console.log("selectedDocId");
    const userDetails = jwtDecode(localStorage.getItem("token"));
    const [monthSelect, setMonthSelect] = useState(new Date());
    const [selectDoctor, setSelectDoctor] = useState("");
    const [doctorSlotBookData, setDoctorSlotBookData] = useState([]);
    const [extractedData, setExtractedData] = useState([]);
    const [selectedDay, setSelectedDay] = useState(null);
    const [selectedSlotId, setSelectedSlotId] = useState(null);
    const [submitData, setSubmitData] = useState({});
    const [doctorAppoinMentData , setDoctorAppointMentData] = useState([]);
    const [loading , setloading] = useState(false);
  
    const [listOfDoctorForAppointment, setListOfDoctorForAppointment] = useState(
      []
    );
  
    useEffect(() => {
        console.log('testing  handleCloseViewNewAppointMentBooked')
      DashboardServices.getDoctorsofUnitForAppointment(
        userDetails.signleUnitId
      ).then((response) => {
        console.log("response", response.data);
        setListOfDoctorForAppointment(response.data);
      });
    }, [refreshReschduleDocAppoint]);


    useEffect(() => {
      fetchDoctorSlot();
      fetchDoctorAppointSlot();
    }, [monthSelect , userDetails.userId , refreshReschduleDocAppoint]);
  
    const fetchDoctorSlot = () => {
      if (monthSelect ) {
        const reqDto = {
          fromDate: DateUtils.getDateInDDMMYYYY(monthSelect),
          doctorId: userDetails.userId,
          unitId: userDetails.signleUnitId,
        };
        console.log("reqDto", reqDto);
  
        DashboardServices.getDoctorAllSlots(reqDto).then((response) => {
          console.log("response", response.data);
          setDoctorSlotBookData(response.data);
          // setloading(false)
        });
      }
    };


    const fetchDoctorAppointSlot = () => {
      const fromDate = DateUtils.getDateInDDMMYYYY(monthSelect);
      const unitId = userDetails.signleUnitId;
    
      DashboardServices.getDoctorsAppointmentOnDate(unitId, fromDate)
        .then((response) => {
          console.log("response", response.data);
          setDoctorAppointMentData(response.data);
        })
        .catch((error) => {
          console.error("Error fetching doctor slots:", error);
        });
    };
    


  
    // const handleChangselectDoctor = (event) => {
    //   setSelectDoctor(event.target.value);
    // };
  
    const handleMonthSelect = (newDate) => {
      setMonthSelect(newDate);
    };
  
    useEffect(() => {
      if (doctorSlotBookData.length > 0) {
        const extracted = extractSlotData(doctorSlotBookData);
        setExtractedData(extracted);
        setSelectedDay(extracted[0].slotDate);
        console.log("extractedData", extracted);
      }
    }, [doctorSlotBookData]);
  
    function extractSlotData(data) {
      return data.map((day) => ({
        slotDate: day.slotDate,
        availableCount: day.availableCount,
        bookedCount: day.bookedCount,
        dayName: day.dayName,
        dayMonth: day.dayMonth,
        slots: day.slots.map((slot) => ({
          slotId: slot.slotId,
          fromTime: slot.fromTime,
          toTime: slot.toTime,
          patientId: slot.patientId,
          patientAge: slot.patientAge,
          patientGender: slot.patientGender,
          patientName: slot.patientName,
          patientUnitId: slot.patientUnitId,
          rescheduleDate: slot.rescheduleDate ,
          rescheduleReqRmks: slot.rescheduleReqRmks ,
          status: slot.status
        })),
      }));
    }
  
    const handlePastSevenDaysDate = () => {
      const res = extractedData[0];
      const [day, month, year] = res.slotDate.split('-');
      const parsedDate = new Date(`${year}-${month}-${day}`);
      parsedDate.setDate(parsedDate.getDate() - 7);
      if(new Date() < parsedDate){
        setMonthSelect(parsedDate);
      }else{
      setMonthSelect(new Date());
      }
  };
  
   
  
    const handleFutureSevenDaysDate = () => {
      const res = extractedData[6];
      const [day, month, year] = res.slotDate.split('-');
      const parsedDate = new Date(`${year}-${month}-${day}`);
      parsedDate.setDate(parsedDate.getDate() + 1);
      setMonthSelect(parsedDate);
  };
  
    const handleSubmitData = (slot) => {
  
      console.log('slot' , slot);
      console.log('listOfDoctorForAppointment' , listOfDoctorForAppointment);
      console.log('doctorSlotBookData' , doctorSlotBookData);
      console.log('userDetails.userId' , userDetails.userId);
      console.log('userDetails' , userDetails)
      console.log('doctorAppoinMentData' , doctorAppoinMentData)
  
      const delDoctor = listOfDoctorForAppointment.find(
        (val) => val.doctorId === userDetails.userId
      );

      console.log('delDoctor' , delDoctor);
  
      const delDate = doctorSlotBookData.find((val) =>
    val.slots.some((slotItem) => slotItem.slotId === slot.slotId)
  );
  
      const [day, month, year] = delDate.slotDate.split('-');
    const parsedDate = new Date(`${year}-${month}-${day}`);

    const patientId = doctorAppoinMentData
    .flatMap((doctor) => doctor.slots)
    .find((slotItem) => slotItem.slotId === slot.slotId)?.patientId;
  
  
      const obj = {
        doctorName: delDoctor.doctorName,
        specialization: delDoctor.specializationName,
        experience: delDoctor.experience,
        language: delDoctor.languages,
        fees: delDoctor.fees,
        feesCurrency: delDoctor.feesCurrency,
        rating: null,
        numberOfUserRated: 0,
        imageLink: delDoctor.doctorProfileImageUrl,
        timezone: "",
        doctorId: delDoctor.doctorId,
        slot: slot,
        date: parsedDate,
        patientId:patientId
      };
  
      console.log("obj", obj);
  
      // return
  
    //   handelOpenAppointmentBooking(obj);
    handleOpenResheduleDrAppointment(obj)
    };


    const handleSlotbookingData = (data) =>{
      console.log('data ****' , data);
      handleOpenResheduleDrAppointment(data)
    }
  
    return (
      <>
        <div className="AnalyticsDashboardContainer">
          <div className="AnalyticsDashboardSubHeader">
            <IconButton
              onClick={() => {
                console.log("closing");
                handleCloseViewNewAppointMentBooked();
                closeAll();
              }}
            >
              <ArrowBack />
            </IconButton>
            View Booked appointment
          </div>
          <div className="anDashCardContainArea">
            <div className="fullContainArea">
              <div className="cuHeaderGrp">
                <div className="formSearchGrp">
                  {/* <div className="formElement width250">
                    <FormControl className="formControl">
                      <InputLabel id="selectDoctor">Select Doctor</InputLabel>
                      <Select
                        required
                        labelId="selectDoctor"
                        id="select_Doctor"
                        value={selectDoctor === undefined || selectDoctor === "undefined" ? "" : selectDoctor}
                        label="Select Doctor"
                        onChange={handleChangselectDoctor}
                        className="formInputField"
                        variant="outlined"
                        renderValue={(value) => {
                          const selectedDoctor = listOfDoctorForAppointment.find(
                            (doc) => doc.doctorId === value
                          );
                          return `${selectedDoctor?.doctorName}`;
                        }}
                      >
                        {listOfDoctorForAppointment.map((doctor) => (
                          <MenuItem key={doctor.doctorId} value={doctor.doctorId}>
                            <div
                              style={{ padding: "4px 12px" }}
                              className="sDocOptList"
                            >
                              <div className="sDname">{doctor.doctorName}</div>
                              <div className="sDElm">
                                {doctor.specializationName}
                              </div>
                              <div className="sDElmGrp">
                                <div className="sDElm">
                                  Experience: <span>{doctor.experience}</span>
                                </div>
                                <div className="sDElm">
                                  Fees:{" "}
                                  <span>
                                    {doctor.fees} {doctor.feesCurrency}
                                  </span>
                                </div>
                                <div className="sDElm">
                                  Language: <span>{doctor.languages}</span>
                                </div>
                              </div>
                            </div>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                 */}
                  <div className="formElement width180">
                    <FormControl className="formControl selectMonth">
                      <InputLabel id="monthSelect" className="setCompletionDate">
                        Slots date from
                      </InputLabel>
                      <ReactDatePicker
                        toggleCalendarOnIconClick
                        yearDropdownItemNumber={100}
                        scrollableMonthYearDropdown
                        showIcon
                        labelId="monthSelect"
                        className="formDatePicker "
                        selected={monthSelect}
                        minDate={new Date()}
                        maxDate={
                          new Date(new Date().setMonth(new Date().getMonth() + 4))
                        }
                        dateFormat="dd-MM-yyyy"
                        onChange={(date) => {
                          handleMonthSelect(date);
                        }}
                      />
                    </FormControl>
                  </div>
                </div>
              </div>

              <div className="days-header-actionBtn">
                <IconButton onClick={() => handlePastSevenDaysDate()}>
                  <ArrowBackIosNewRounded />
                </IconButton>
                {/* <div class="days-header"> */}
                  {/* <div class="day-box active">
                    <div className="daysDate">
                      <div className="ddHighTxt">23 August</div>
                      <div className="ddSubTxt">Friday</div>
                    </div>
                    <div className="slotsStatus">
                      <div className="slotsAvailable">
                        <div className="daysIcon">
                          <AccessibleRounded />
                        </div>
                        <div className="slotsDtl">
                          <div className="sdHtxt">12 slots </div>
                          <div className="sdSubTxt">Available</div>
                        </div>
                      </div>
                      <div className="slotsBooked">
                        <div className="daysIcon">
                          <AccessibleRounded />
                        </div>
                        <div className="slotsDtl">
                          <div className="sdHtxt">8 slots </div>
                          <div className="sdSubTxt">Booked</div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* <div class="day-box">
                    <div className="daysDate">
                      <div className="ddHighTxt">24 August</div>
                      <div className="ddSubTxt">Friday</div>
                    </div>
                    <div className="slotsStatus">
                      <div className="slotsAvailable">
                        <div className="daysIcon">
                          <AccessibleRounded />
                        </div>
                        <div className="slotsDtl">
                          <div className="sdHtxt">12 slots </div>
                          <div className="sdSubTxt">Available</div>
                        </div>
                      </div>
                      <div className="slotsBooked">
                        <div className="daysIcon">
                          <AccessibleRounded />
                        </div>
                        <div className="slotsDtl">
                          <div className="sdHtxt">8 slots </div>
                          <div className="sdSubTxt">Booked</div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* <div class="day-box slotUnavailable">
                    <div className="daysDate">
                      <div className="ddHighTxt">25 August</div>
                      <div className="ddSubTxt">Friday</div>
                    </div>
                    <div className="slotsStatus">
                      <div className="slotsAvailable">
                        <div className="daysIcon">
                          <AccessibleRounded />
                        </div>
                        <div className="slotsDtl">
                          <div className="sdHtxt">20 Booked </div>
                          <div className="sdSubTxt">Slots Unavailable</div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* <div class="day-box">
                    <div className="daysDate">
                      <div className="ddHighTxt">26 August</div>
                      <div className="ddSubTxt">Friday</div>
                    </div>
                    <div className="slotsStatus">
                      <div className="slotsAvailable">
                        <div className="daysIcon">
                          <AccessibleRounded />
                        </div>
                        <div className="slotsDtl">
                          <div className="sdHtxt">12 slots </div>
                          <div className="sdSubTxt">Available</div>
                        </div>
                      </div>
                      <div className="slotsBooked">
                        <div className="daysIcon">
                          <AccessibleRounded />
                        </div>
                        <div className="slotsDtl">
                          <div className="sdHtxt">8 slots </div>
                          <div className="sdSubTxt">Booked</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="day-box">
                    <div className="daysDate">
                      <div className="ddHighTxt">27 August</div>
                      <div className="ddSubTxt">Friday</div>
                    </div>
                    <div className="slotsStatus">
                      <div className="slotsAvailable">
                        <div className="daysIcon">
                          <AccessibleRounded />
                        </div>
                        <div className="slotsDtl">
                          <div className="sdHtxt">12 slots </div>
                          <div className="sdSubTxt">Available</div>
                        </div>
                      </div>
                      <div className="slotsBooked">
                        <div className="daysIcon">
                          <AccessibleRounded />
                        </div>
                        <div className="slotsDtl">
                          <div className="sdHtxt">8 slots </div>
                          <div className="sdSubTxt">Booked</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="day-box">
                    <div className="daysDate">
                      <div className="ddHighTxt">28 August</div>
                      <div className="ddSubTxt">Friday</div>
                    </div>
                    <div className="slotsStatus">
                      <div className="slotsAvailable">
                        <div className="daysIcon">
                          <AccessibleRounded />
                        </div>
                        <div className="slotsDtl">
                          <div className="sdHtxt">12 slots </div>
                          <div className="sdSubTxt">Available</div>
                        </div>
                      </div>
                      <div className="slotsBooked">
                        <div className="daysIcon">
                          <AccessibleRounded />
                        </div>
                        <div className="slotsDtl">
                          <div className="sdHtxt">8 slots </div>
                          <div className="sdSubTxt">Booked</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="day-box">
                    <div className="daysDate">
                      <div className="ddHighTxt">29 August</div>
                      <div className="ddSubTxt">Friday</div>
                    </div>
                    <div className="slotsStatus">
                      <div className="slotsAvailable">
                        <div className="daysIcon">
                          <AccessibleRounded />
                        </div>
                        <div className="slotsDtl">
                          <div className="sdHtxt">12 slots </div>
                          <div className="sdSubTxt">Available</div>
                        </div>
                      </div>
                      <div className="slotsBooked">
                        <div className="daysIcon">
                          <AccessibleRounded />
                        </div>
                        <div className="slotsDtl">
                          <div className="sdHtxt">8 slots </div>
                          <div className="sdSubTxt">Booked</div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                {/* </div> */}
  
                {/* <div className="days-header">
                  {extractedData.map((day) => (
                    <div key={day.slotDate} className={`day-box ${day.bookedCount === day.availableCount ? 'slotUnavailable' : ''}`}>
                      <div className="daysDate">
                        <div className="ddHighTxt">{day.slotDate}</div>
                        <div className="ddSubTxt">{day.dayName}</div>
                      </div>
                      <div className="slotsStatus">
                        <div className="slotsAvailable">
                          <div className="daysIcon">
                            <AccessibleRounded />
                          </div>
                          <div className="slotsDtl">
                            <div className="sdHtxt">{day.availableCount} slots </div>
                            <div className="sdSubTxt">Available</div>
                          </div>
                        </div>
                        <div className="slotsBooked">
                          <div className="daysIcon">
                            <AccessibleRounded />
                          </div>
                          <div className="slotsDtl">
                            <div className="sdHtxt">{day.bookedCount} slots </div>
                            <div className="sdSubTxt">Booked</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div> */}
  
                <div className="days-header">
                  {extractedData.map((day) => {
                    // Determine the appropriate class name for each day
                    let dayClass = "day-box";
                    
                    if (day.slotDate === selectedDay) {
                      dayClass += " active";
                    } else if (day.availableCount === 0 || day.availableCount === "0") {
                      dayClass += " slotUnavailable";
                    }
  
                    return (
                      <div
                        key={day.slotDate}
                        className={dayClass}
                        onClick={() => setSelectedDay(day.slotDate)} // Update the selected day
                      >
                        <div className="daysDate">
                          <div className="ddHighTxt">{day.slotDate}  
                          <IconButton onClick={()=>handleSlotbookingData(day)}>
                          <Settings />
                          </IconButton>
                          </div>
                          <div className="ddSubTxt">{day.dayName}</div>
                        </div>
                        <div className="slotsStatus">
                          {day.availableCount > 0 && (
                          <div className="slotsAvailable">
                            <div className="daysIcon">
                              <AccessibleRounded />
                            </div>
                              <div className="slotsDtl">
                                <div className="sdHtxt">
                                  {day.availableCount} slots{" "}
                                </div>
                                <div className="sdSubTxt">Available</div>
                              </div>
                          </div>
                          )}
                          <div className="slotsBooked">
                            <div className="daysIcon">
                              <AccessibleRounded />
                            </div>
                            <div className="slotsDtl">
                            <div className="sdHtxt">
                           {day.availableCount > 0 ? (
                          `${day.bookedCount} slots`
                            ) : (
                          `${day.bookedCount} Booked`
                            )}
                          </div>
                              {day.availableCount > 0 ? (
                                <div className="sdSubTxt">Booked</div>
                              ) : (
                                <div className="sdSubTxt">Slots Unavailable</div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
  
                <IconButton onClick={handleFutureSevenDaysDate}>
                  <ArrowForwardIosRounded />
                </IconButton>
              </div>
  
              <div className="cuContainArea">
                <div className="appoinmentBookingSlot">
                  {/* <div class="slots-container">
                    <div
                      class="slot-box active"
                      onClick={() => {
                        handelOpenAppointmentBooking();
                      }}
                    >
                      <div className="slotInfo">
                        <div class="icon">
                          <AccessibleRounded />
                        </div>
                        <div className="slotTime"> 10:30 - 10:45</div>
                      </div>
                    </div>
  
                    <div class="slot-box booked">
                      <div className="slotInfo">
                        <div class="icon">
                          <AccessibleRounded />
                        </div>
                        <div className="slotTime"> 10:45 - 11:00</div>
                      </div>
                      <div className="bookedPatInfo">
                        <div className="patDtl">
                          <div className="patgender">
                            <MaleRounded />
                          </div>
  
                          <div className="patName">Sanket Santra</div>
                        </div>
                        <div className="patAge">32Y 6M 26D</div>
                      </div>
                    </div>
  
                    <div class="slot-box">
                      <div className="slotInfo">
                        <div class="icon">
                          <AccessibleRounded />
                        </div>
                        <div className="slotTime"> 10:30 - 10:45</div>
                      </div>
                    </div>
  
                    <div class="slot-box">
                      <div className="slotInfo">
                        <div class="icon">
                          <AccessibleRounded />
                        </div>
                        <div className="slotTime"> 10:30 - 10:45</div>
                      </div>
                    </div>
  
                    <div class="slot-box">
                      <div className="slotInfo">
                        <div class="icon">
                          <AccessibleRounded />
                        </div>
                        <div className="slotTime"> 10:30 - 10:45</div>
                      </div>
                    </div>
  
                  </div> */}
  
                  {/*  */}
                  {/* <div className="slots-container">
    {extractedData
      .filter(day => day.slotDate === selectedDay) // Filter slots based on selected day
      .flatMap(day => day.slots.map(slot => (
        <div
          key={slot.slotId}
          className={`slot-box ${slot.patientId ? 'booked' : 'active'}`}
          onClick={() => {
            if (!slot.patientId) {
              handelOpenAppointmentBooking();
            }
          }}
        >
          <div className="slotInfo">
            <div className="icon">
              <AccessibleRounded />
            </div>
            <div className="slotTime"> {slot.fromTime} - {slot.toTime}</div>
          </div>
          {slot.patientId && (
            <div className="bookedPatInfo">
              <div className="patDtl">
                <div className="patgender">
                  {slot.patientGender === 'Male' ? <MaleRounded /> : <FemaleRounded />}
                </div>
                <div className="patName">{slot.patientName}</div>
              </div>
              <div className="patAge">{slot.patientAge}</div>
            </div>
          )}
        </div>
      )))}
  </div> */}
  
                  {/*  */}
  
                  <div className="slots-container">
                    {extractedData
                      .filter((day) => day.slotDate === selectedDay) // Filter slots based on selected day
                      .flatMap((day) =>
                        day.slots.map((slot) => {
                          // Determine the appropriate class name for each slot
                          let slotClass = "slot-box";
                          if (slot?.patientName && slot.patientName.trim() !== "") {
                            slotClass += " booked";
                          } else if (slot.slotId === selectedSlotId) {
                            slotClass += " active";
                          }else if (slot.status === "B" && slot.rescheduleDate && slot.rescheduleReqRmks) {
                            slotClass += " RescheduleRequest";
                          }else if (slot.status === "C") {
                            slotClass += " Unavailable";
                          }else if (slot.slotId === selectedSlotId) {
                            slotClass += "";
                          }
  
                          return (
                            <div
                              key={slot.slotId}
                              className={slotClass}
                              onClick={() => {
                                if (!slot.patientId) {
                                  setSelectedSlotId(slot.slotId);
                                  // handelOpenAppointmentBooking();
                                }
                                handleSubmitData(slot);
                              }}
                            >
                              <div className="slotInfo">
                                <div className="icon">
                                  <AccessibleRounded />
                                </div>
                                <div className="slotTime">
                                  {" "}
                                  {slot.fromTime} - {slot.toTime}
                                </div>
                              </div>
                              {slot?.patientName && slot.patientName.trim() !== "" && (
                                <div className="bookedPatInfo">
                                  <div className="patDtl">
                                    <div className="patgender">
                                      {slot?.patientGender && slot.patientGender.toLowerCase() ===
                                        "male" && <MaleRounded />}
                                      {slot?.patientGender && slot.patientGender.toLowerCase() ===
                                        "female" && <FemaleRounded />}
                                    </div>
                                    <div className="patName">
                                      {slot.patientName}
                                      {slot.status === "B" && slot.rescheduleDate && slot.rescheduleReqRmks && (
                                    <div className="rescheduleIcon">
                                    <AutorenewIcon /> 
                                   </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="patAge">{slot.patientAge}</div>
                                </div>
                              )}
                            </div>
                          );
                        })
                      )}
                  </div>
  
                  {/* <div className="slots-container">
                    {extractedData.flatMap(day => day.slots.map(slot => (
                      <div
                        key={slot.slotId}
                        className={`slot-box ${slot.patientId ? 'booked' : 'active'}`}
                        onClick={() => {
                          if (!slot.patientId) {
                            handelOpenAppointmentBooking();
                          }
                        }}
                      >
                        <div className="slotInfo">
                          <div className="icon">
                            <AccessibleRounded />
                          </div>
                          <div className="slotTime"> {slot.fromTime} - {slot.toTime}</div>
                        </div>
                        {slot.patientId && (
                          <div className="bookedPatInfo">
                            <div className="patDtl">
                              <div className="patgender">
                                <MaleRounded />
                              </div>
                              <div className="patName">Sanket Santra</div>
                            </div>
                            <div className="patAge">32Y 6M 26D</div>
                          </div>
                        )}
                      </div>
                    )))}
                  </div> */}
                </div>
              </div>
  
            </div>
          </div>
        </div>
      </>
    );
  }
  