import {
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
} from "chart.js";
import React from "react";

import { Add } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import { Bar, Pie } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { ArrowRepeat } from "react-bootstrap-icons";

export default function CardRegisterPatient({
  handelOpenViewRegisterPatient,
  handelOpenAddRegisterPatient,
  chartDataOfItem,
  fetchDashBoardData
}) {
  ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    LineController,
    BarController
  );

  const { t } = useTranslation();

  const genderLabels = chartDataOfItem?.patientGenderWiseRegistration ? Object.keys(chartDataOfItem.patientGenderWiseRegistration) : [];
  const genderData = chartDataOfItem?.patientGenderWiseRegistration ? Object.values(chartDataOfItem.patientGenderWiseRegistration) : [];

  const pieChartData = {
    labels: genderLabels.map((label) => (label === "" ? "Unknown" : label)), 
    datasets: [
      {
        label: t("patient_registrations"),
        data: genderData,
        backgroundColor: [
          "rgba(79, 212, 0, 1)",
          "rgba(234, 184, 0, 1)",
          "rgba(241, 61, 60, 1)",
          "rgba(254, 162, 59, 1)",
        ],
        borderColor: "rgba(255, 255, 255, .6)",
        borderWidth: 1,
      },
    ],
  };

  // const data = {
  //   labels: ["Completed", "Queue", "New", "Review"],
  //   datasets: [
  //     {
  //       label: "# of Votes",
  //       data: [12, 19, 3, 5],
  //       backgroundColor: [
  //         "rgba(79, 212, 0, 1)",
  //         "rgba(234, 184, 0, 1)",
  //         "rgba(241, 61, 60, 1)",
  //         "rgba(254, 162, 59, 1)",
  //       ],
  //       borderColor: [
  //         "rgba(255, 255, 255, .6)",
  //         "rgba(255, 255, 255, .6)",
  //         "rgba(255, 255, 255, .6)",
  //         "rgba(255, 255, 255, .6)",
  //       ],
  //       borderWidth: 1,
  //     },
  //   ],
  // };

  const options = {
    cutout: "80%",
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
        position: "bottom",
        labels: {
          boxWidth: 6,
          color: "#d2d2d2",
          font: {
            size: 12,
            weight: "light",
          },
        },
      },
    },
  };

  const ageGroupLabels = chartDataOfItem?.patientAgeGroupWiseRegistration ? Object.keys(chartDataOfItem.patientAgeGroupWiseRegistration) : [];

  const ageGroupData = chartDataOfItem?.patientAgeGroupWiseRegistration ? Object.values(chartDataOfItem.patientAgeGroupWiseRegistration) : [];


  const barLineData = {
    // labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
    labels: ageGroupLabels ,
    datasets: [
      {
        label: t("no_of_registraton"),
        // data: [12, 19, 3, 5, 2, 3],
        data: ageGroupData,
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const barLineOoptions = {
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "",
      },
    },
  };

  //   const barLineData = {
  //     genderlabels,
  //     datasets: [
  //       {
  //         label: "Attrition rate",
  //         backgroundColor: "rgba(255,255,255,.7)",
  //         borderColor: "rgba(255,255,255,1)",
  //         borderWidth: 2,
  //         hoverBackgroundColor: "rgba(255,255,255,1)",
  //         hoverBorderColor: "rgba(237,50,55,1)",
  //         data: genderAttrition,
  //         type: "line",
  //         fill: false,
  //       },
  //       {
  //         label: "Female",
  //         data: genderFemale,
  //         backgroundColor: "#FF6384",
  //         barThickness: 16,
  //       },
  //       {
  //         label: "Male",
  //         data: genderMale,
  //         backgroundColor: "#36A2EB",
  //         barThickness: 16,
  //       },
  //       {
  //         label: "Others",
  //         data: genderOthers,
  //         backgroundColor: "#FFCE56",
  //         barThickness: 16,
  //       },
  //     ],
  //   };
  //   console.log("barLineData", barLineData);

  //   const barLineOoptions = {
  //     cutout: "90%",

  //     plugins: {
  //       datalabels: {
  //         display: false,
  //       },
  //       legend: {
  //         display: false,
  //         position: "bottom",
  //         labels: {
  //           boxWidth: 10,
  //           color: "#d2d2d2",
  //           font: {
  //             size: 12,
  //             weight: "light",
  //           },
  //         },
  //       },
  //     },
  //     scales: {
  //       x: {
  //         stacked: true,
  //       },
  //       y: {
  //         stacked: true,
  //       },
  //     },
  //     borderRadius: 4,
  //   };
  return (
    <>
      <div className="anDashIndCard">
        <div className="anDashCardArea">
          <div className="andHeader">
            <div className="andCrdNM">{t("registered_patients")}
            <IconButton
            className={`refreshIcon`}
            onClick={fetchDashBoardData}
            >
            <ArrowRepeat />
            </IconButton>
            </div>
            <div className="andCrdUnitCount">
              <span title="Attrition">
                {/* <Male /> */}
                {t("this_month")}: <span className="fbold">
                {chartDataOfItem.totalPatientRegistered + chartDataOfItem.totalPatientRegisteredThisMnth}
                </span>
              </span>

              <span title="Tenure">
                {/* <Female /> */}
                {/* This week: <span className="fbold">12154</span> */}
              </span>
            </div>
          </div>

          <div className="andCrdGraf">
            <div className="anDLftGrf">
              <div className="centTxtShow">
                <Pie
                  className="hw100"
                  type="doughnut"
                  data={pieChartData}
                  options={options}
                />
                <div className="highlighted-text">
                  {/* <span>Total</span> */}
                  <span className="highDataCount">{chartDataOfItem.totalPatientRegistered}</span>
                </div>
              </div>
            </div>
            <div className="anDRightGrf">
              <Bar
                className="hw100"
                type="bar"
                data={barLineData}
                options={barLineOoptions}
              />
              {/* <Bar data={barLineData} options={barLineOoptions} /> */}
            </div>
          </div>

          {/* <div className="andCrdData"></div> */}
          <div className="andCrdFooter">
            {/* <Button
              className="dfultPrimaryBtn "
              onClick={() => handelOpenViewRegisterPatient()}
            >
              View
            </Button> */}

            <Button
              startIcon={<Add />}
              className="dfultPrimaryBtn "
              onClick={() => handelOpenAddRegisterPatient()}
            >
              {t("add")}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
