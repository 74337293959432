import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  DeviceHddFill,
  HeartPulse,
  Lungs,
  PersonBoundingBox,
  Plus,
  ThermometerHalf,
  XLg,
} from "react-bootstrap-icons";
import { getLoginUserDetails } from "../../../../../utils/Utils";
import dayjs from "dayjs";
import zhPatientService from "../../../../../services/ZhPatientService";
import {
  ArrowBackIosNew,
  ArrowForwardIos,
  BloodtypeOutlined,
  DescriptionOutlined,
  HeightOutlined,
  MonetizationOnOutlined,
  MonitorWeightOutlined,
  TranslateRounded,
} from "@mui/icons-material";
import PatientComplaints from "../../../../scheduler/view/component/meetings/view/consultation/PatientComplaints";
import DashboardServices from "../../../../../services/DashboardServices";
import MedicalHistory from "../../../../scheduler/view/component/meetings/view/consultation/MedicalHistory";
import PatientFamilyHistory from "../../../../scheduler/view/component/meetings/view/consultation/patient/PatientFamilyHistory";
import PatientDrugAllergy from "../../../../scheduler/view/component/meetings/view/consultation/patient/PatientDrugAllurgy";
import PatientFoodAllergy from "../../../../scheduler/view/component/meetings/view/consultation/patient/PatientFoodAllergy";
import PatientFileUpload from "./PatientFileUpload";
import { CustomModal } from "../../../../task/view/component/Modals/CustomModal";
import { toast } from "react-toastify";
import { AddTabContext } from "../../../../dashboard/view/DashboardPage";
import { v4 } from "uuid";
import { ConfirmProvider } from "material-ui-confirm";
import PatientMeetingsTabContainer from "../../../../scheduler/view/component/meetings/view/PatientMeetingsTabContainer";
import { getHealthDto } from "../../../../scheduler/view/component/meetings/view/consultation/consultationUtil";
import EXP from "../../../../dashboard/image/EXP";
import TestResultInput from "../../../../scheduler/view/component/meetings/view/consultation/TestResultInput";
import LMP from "../../../../dashboard/image/LMP";
import UAC from "../../../../dashboard/image/UAC";
import BP from "../../../../dashboard/image/BP";
import SPO2 from "../../../../dashboard/image/SPO2";
import HeadC from "../../../../dashboard/image/HeadC";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const AddReviewPatientToQueue = ({
  data,
  handleCloseAddReviewPatientToQueue,
  closeReviewPatientToQueue,
}) => {
  const loginUserDetail = getLoginUserDetails();
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [timezone, setTimezone] = useState();
  const [userInvestigationList, setUserInvestigationList] = useState();
  const [prescriptionData, setPrescriptionData] = useState({});
  const [patientInfo, setPatientInfo] = useState(null);

  const [systemicIllnessList, setSystemicIllnessList] = useState([]);
  const [systemicAbuseList, setSystemicAbuseList] = useState([]);

  const [illnessName, setIllnessName] = useState([]);
  const [systemicIllness, setSystemicIllness] = useState("no");

  const [abuseName, setAbuseName] = useState([]);
  const [substanceAbuse, setSubstanceAbuse] = useState("no");

  const [documents, setDocuments] = useState([]);
  const fileElementRef = useRef(null);

  const [previousMedicalRecords, setPreviousMedicalRecords] = useState(null);
  const [fileDivWidth, setFileDivWidth] = useState(0);

  const [filearrowDisable, setFilearrowDisable] = useState(false);
  const [showFileUploadDialog, setShowFileUploadDialog] = useState(false);
  const [referedBy, setReferedBy] = useState("");
  const [selectedDoctor, setSelectedDoctor] = useState(null);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [VitalErrorList, setVitalErrorList] = useState([]);
  const [vitals, setVitals] = useState({});

  const [sos, setSos] = useState(false);

  const [lmp, setLmp] = useState(null);
  const [doctorList, setDoctorList] = useState([]);
  const [unitAllDoctorList, setUnitAllDoctorList] = useState([]);
  const [testGiven, setTestGiven] = useState();
  const [extraData, setExtraData] = useState();
  const [patient, setPatient] = useState(null);

  const [temperatureUnit, setTemperatureUnit] = useState(null);
  const [heightUnit, setHeightUnit] = useState(null);
  const [weightUnit, setWeightUnit] = useState(null);
  const [upperArmUnit, setUpperArmUnit] = useState(null);
  const [headUnit, setHeadUnit] = useState(null);
  const [bloodGroup, setBloodGroup] = useState("");

  const { addTab } = useContext(AddTabContext);

  const updateData = (data) => {
    // toast.success("successfully added data");
    console.log("data =", data);
    setPrescriptionData((prev) => ({ ...prev, ...data }));
  };

  function calculateDateDifference(dateString) {
    // Parse the input date string "DD-MM-YYYY"
    if (!dateString) {
      return "";
    }
    const [day, month, year] = dateString?.split("-").map(Number);
    const givenDate = new Date(year, month - 1, day);
    const today = new Date();

    let years = today.getFullYear() - givenDate.getFullYear();
    let months = today.getMonth() - givenDate.getMonth();
    let days = today.getDate() - givenDate.getDate();

    // Adjust if days are negative
    if (days < 0) {
      months--;
      days += new Date(today.getFullYear(), today.getMonth(), 0).getDate();
    }

    // Adjust if months are negative
    if (months < 0) {
      years--;
      months += 12;
    }

    return `${years}Y ${months}M ${days}D`;
  }

  const getAvailableDoctorsofUnit = () => {
    const diseasetring = localStorage.getItem("unitDoctorList");
    if (diseasetring) {
      setUnitAllDoctorList(JSON.parse(diseasetring));
      return;
    }

    const reqDto = getHealthDto();
    DashboardServices.getAvailableDoctorsofUnit(
      loginUserDetail.signleUnitId
    ).then((response) => {
      if (response.data) {
        setUnitAllDoctorList(response.data);
        localStorage.setItem("unitDoctorList", JSON.stringify(response.data));
      }
    });
  };

  const filehandleHorizantalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
      if (element.scrollLeft === 0) {
        setFilearrowDisable(true);
      } else {
        setFilearrowDisable(false);
      }
    }, speed);
  };

  const handlePatientInfo = (patientInfo) => {
    if (patientInfo) {
      const { systemicIllnesses, substanceAbuse } = patientInfo;
      let tempSelectedIllness = [];
      systemicIllnesses?.forEach((item) => {
        const illness = systemicIllnessList.find(
          (illness) => illness.systemicIllnessName === item
        );
        illness && tempSelectedIllness.push(illness);
      });
      if (tempSelectedIllness && tempSelectedIllness.length > 0) {
        setIllnessName(tempSelectedIllness);
        setSystemicIllness("yes");
      }

      tempSelectedIllness = [];

      substanceAbuse?.forEach((item) => {
        const illness = systemicAbuseList.find(
          (illness) => illness.substanceAbuseName === item
        );
        illness && tempSelectedIllness.push(illness);
      });
      if (tempSelectedIllness && tempSelectedIllness.length > 0) {
        setAbuseName(tempSelectedIllness);
        setSubstanceAbuse("yes");
      }
    }
  };

  const selectSubstanceAbuse = (event) => {
    const value = event.target.value;
    if (value === "no") {
      setAbuseName([]);
    }
    setSubstanceAbuse(value);
  };

  const selectSystemicIllness = (event) => {
    const value = event.target.value;
    if (value === "no") {
      setIllnessName([]);
    }
    setSystemicIllness(event.target.value);
  };

  const handleChangeAbuse = (event) => {
    const {
      target: { value },
    } = event;
    setAbuseName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleChangeIllness = (event) => {
    const {
      target: { value },
    } = event;
    setIllnessName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const getSystemicIllness = () => {
    const brandString = localStorage.getItem("substanceIllnessList");
    if (brandString) {
      setSystemicIllnessList(JSON.parse(brandString));
      return;
    }

    const reqDto = getHealthDto();
    DashboardServices.getSystemicIllness(reqDto).then((response) => {
      if (response.data) {
        setSystemicIllnessList(response.data);
        localStorage.setItem(
          "substanceIllnessList",
          JSON.stringify(response.data)
        );
      }
    });
  };

  const getSubstanceAbuse = () => {
    const brandString = localStorage.getItem("substanceAbuseList");
    if (brandString) {
      setSystemicAbuseList(JSON.parse(brandString));
      return;
    }

    const reqDto = getHealthDto();
    DashboardServices.getSubstanceAbuse(reqDto).then((response) => {
      if (response.data) {
        setSystemicAbuseList(response.data);
        localStorage.setItem(
          "substanceAbuseList",
          JSON.stringify(response.data)
        );
      }
    });
  };

  const submitFormData = async () => {
    if (
      !prescriptionData.patientComplaint ||
      (prescriptionData && prescriptionData.patientComplaint === "")
    ) {
      toast.error("Please Give Patient Complaints");
      return;
    }

    const toastLoaderId = toast.loading("Submitting details, please wait...");

    let tempVisistData = await DashboardServices.generateVisitId(
      loginUserDetail.orgId
    );
    tempVisistData = tempVisistData?.data;
    const itemData = prescriptionData;
    console.log("Prescription Data", itemData);

    let doc = documents?.map((item) => {
      return {
        fileName: item.fileName,
        fileLink: item.fileLink,
        fileData: item.fileData,
        id: item.id,
      };
    });

    const prevDoc =
      previousMedicalRecords
        ?.filter((item) => item.isChecked)
        ?.map((item) => {
          return {
            fileName: item.fileName,
            fileLink: item.fileLink,
            fileData: item.fileData,
            id: item.id,
          };
        }) || [];

    doc = [...doc, ...prevDoc];

    const illnesses = illnessName?.map((item) => item.systemicIllnessName);

    const abuseNameList = abuseName?.map((item) => item.substanceAbuseName);

    const foodAllergies = prescriptionData?.allergicFood?.map(
      (item) => item.foodName
    );

    let hereditaryDiseases =
      prescriptionData?.familyHistory?.map((item) => {
        return {
          diseaseName: item.disease.diseaseName,
          relationship: item.relationShip,
          id: item.disease.id,
        };
      }) || [];

    if (
      prescriptionData?.prevFamilyDisease &&
      prescriptionData?.prevFamilyDisease?.length > 0
    ) {
      hereditaryDiseases = [
        ...hereditaryDiseases,
        ...prescriptionData?.prevFamilyDisease,
      ];
    }

    let medicineAllergies =
      prescriptionData?.allergicDrug?.map((item) => {
        const { brand, genericItem } = item;
        let type = null;
        if (brand) {
          return {
            type: "BRAND",
            genericBrandId: brand.id,
            genericBrandName: brand.brandName,
          };
        } else {
          return {
            type: "GENERIC",
            genericBrandId: genericItem.id,
            genericBrandName: genericItem.generic,
          };
        }
      }) || [];
    if (
      prescriptionData?.previousDrugAllergy &&
      prescriptionData?.previousDrugAllergy?.length > 0
    ) {
      medicineAllergies = [
        ...medicineAllergies,
        ...prescriptionData?.previousDrugAllergy,
      ];
    }

    let diseases =
      prescriptionData?.pastIllness?.map((item) => {
        return {
          diseaseName: item.illness.diseaseName,
          diagnosisDate: dayjs(item.date).format("DD-MM-YYYY"),
          id: 0,
          nature: "CONFIRM",
        };
      }) || [];

    if (prescriptionData?.prevIllness) {
      diseases = [...diseases, ...prescriptionData?.prevIllness];
    }

    let procedures =
      prescriptionData?.prevSurgery?.map((item) => {
        return {
          id: 0,
          procedureName: item.surgery.procedureName,
          procedureDate: dayjs(item.date).format("DD-MM-YYYY"),
        };
      }) || [];

    if (prescriptionData?.prevSurgerry) {
      procedures = [...procedures, ...prescriptionData?.prevSurgerry];
    }

    const currentMedications = prescriptionData?.medication?.map((item) => {
      const { brandName, frequency, dosage, genericName } = item;

      let type = null;
      let genericBrandId = 0;
      let genericBrandName = "";
      if (brandName) {
        type = "BRAND";
        genericBrandId = brandName.id;
        genericBrandName = brandName.brandName;
      } else {
        type = "GENERIC";
        genericBrandId = genericName.id;
        genericBrandName = genericName.generic;
      }

      return {
        type: type,
        genericBrandId: genericBrandId,
        genericBrandName: genericBrandName,
        dosages: dosage.dosage,
        dosagesUnit: dosage.dosageUnit || "",
        since: dayjs(item.since).format("DD-MM-YYYY"),
        frequency: item.frequency.frequency,
      };
    });

    const tmpVitals = Object.values(vitals);

    const submitDto = {
      visitIdDisplay: tempVisistData,
      patientIdDisplay: patient.patientId || "",
      patientId: patient.patientId,
      unitId: loginUserDetail.signleUnitId,
      doctorId: selectedDoctor.doctorId,
      orgId: loginUserDetail.orgId,
      audioNote: prescriptionData.audioData || "",
      dob: patient.patientAge || "",
      complaints: prescriptionData.patientComplaint || "",
      // bloodType: bloodGroup || "",
      patientName: patient.patientName,
      gender: patient.patientGender || "",
      patientProfileUrl: patient.patientImageUrl || "",
      consultationType: "OPD",
      referredBy: referedBy,
      clinicName: loginUserDetail.unitLocationName
        ? loginUserDetail.unitLocationName
        : "Clinic",
      clinicContactNo: loginUserDetail.unitContactNo
        ? loginUserDetail.unitContactNo
        : "9876543210",
      patientType: "Review",

      vitals: tmpVitals || [],
      documents: doc || [],
      systemicIllnesses: illnesses || [],
      substanceAbuse: abuseNameList || [],
      currentMedications: currentMedications || [],
      procedures: procedures || [],
      diseases: diseases || [],
      hereditaryDiseases: hereditaryDiseases || [],
      medicineAllergies: medicineAllergies || [],
      foodAllergies: foodAllergies || [],
      sos: sos ? "Y" : "N",
      doctorAvgConsultTime: selectedDoctor?.avgConsultTime || 0,
      doctorName: selectedDoctor?.doctorName,
      doctorSpecilization: selectedDoctor?.specializationName || "",
      slotId: 0,
    };

    console.log("submit Dto", submitDto);
    // return;

    DashboardServices.submitPatientVisit(submitDto).then((response) => {
      if (response.data.returnValue === "1") {
        toast.update(toastLoaderId, {
          render: response.data.message,
          type: "success",
          isLoading: false,
          autoClose: true,
        });

        if (response.data.page === "Q") {
          // toast.success("Consultation booking complete");
          handleCloseAddReviewPatientToQueue();
          return;
        }

        const key = v4();
        const eventDto = {
          eventTitle: `${response.data.visitId}) Consultation with ${selectedDoctor.doctorName}`,
          eventDescription: `Consultation with ${selectedDoctor.doctorName}`,
          eventStartTime: new Date().toString(),
          eventEndTime: new Date(Date.now() + 20 * 60 * 1000).toString(),
          eventId: response.data.visitId,
          eventType: "Consultation",
          eventLength: 20,
          eventLink: response.data.roomId,
          patientName: patient.patientName,
          patientImageUrl:  patient.patientProfileUrl || "",
        };

        const itemData = {
          queuePatientName: patient.patientName,
          // queueAttempsCount: data.queueAttempsCount,
          queuePatientAge: patient.patientAge,
          // queueAddTime: data.queueAddTime,
          queuePatientGender: patient.patientGender,
          queuePatientProfileUrl: patient.patientProfileUrl || "",
          queueConsultationType: "OPD",
          queueVitals: tmpVitals || [],
          queueComplaints: "",
          queueReferredBy: "",
          queueClinicName: "",
          queueClinicContactNo: "",
          queuePatientType: "New",
          queueVisitId: response.data.visitId,
          queuePatientId: patient.patientId,
          queuePatientUnitId: loginUserDetail.signleUnitId,
          queueRoomId: response.data.roomId,
          reqValue: {},
          isDoctor: false,
          noFeedback: true,
        };

        addTab({
          key: key,
          title: `${eventDto.eventTitle}`,
          content: (
            <ConfirmProvider>
              <PatientMeetingsTabContainer
                eventData={eventDto}
                tabKey={key}
                extraData={itemData}
              />
            </ConfirmProvider>
          ),
          isDraggable: true,
          type: "Meeting",
        });
      } else {
        // toast.error(response.data.message);
        toast.update(toastLoaderId, {
          render: response.data.message,
          type: "error",
          isLoading: false,
          autoClose: true,
        });
      }
    });
  };

  const getAge = (ageString) => {
    // console.log("getAge", dob);
    // const ageString = calculateDateDifference(dob);
    const ageComponent = ageString?.split(" ");
    if (ageComponent?.[0]?.includes("Y")) {
      return parseInt(ageComponent[0].split("Y")[0]) || 0;
    } else {
      return 0;
    }
  };


  useEffect(() => {
    const tempSelectedDoctorList = unitAllDoctorList?.find((doctor) => {
      console.log("Doctor = ", doctor);
      return data.doctorName.includes(doctor.doctorName);
    });

    setSelectedDoctor(tempSelectedDoctorList);
  }, [unitAllDoctorList]);

  useEffect(() => {
    console.log("preview data=", data);
    getSystemicIllness();
    getSubstanceAbuse();
    getAvailableDoctorsofUnit();

    const { patientAge, patientName, patientGender, patientProfileUrl } = data;
    setPatient({
      patientAge,
      patientName,
      patientGender,
      patientProfileUrl,
    });

    setSelectedPatient({
      patientAge,
      patientName,
      patientGender,
      patientProfileUrl,
    })

    const itemData = {
      queuePatientName: data.patientName,
      // queueAttempsCount: data.queueAttempsCount,
      queuePatientAge: data.patientAge,
      // queueAddTime: data.queueAddTime,
      queuePatientGender: data.patientGender,
      queuePatientProfileUrl: data.patientProfileUrl,
      queueConsultationType: data.consultationType,
      queueVitals: data.vitals,
      queueComplaints: data.complaints,
      queueReferredBy: data.referredBy,
      queueClinicName: data.clinicName,
      queueClinicContactNo: data.clinicContactNo,
      queuePatientType: data.patientType,
      queueVisitId: data.visitId,
      queuePatientId: data.patientId,
      queuePatientUnitId: data.unitId,
      queueRoomId: data.roomId,
      doctorId:data.doctorId,
      doctorName:data.doctorName,
      // reqValue: response.data,
      isDoctor: true,
    };

    setExtraData(itemData);

    zhPatientService
      .getTestGivenByVisitId(data.visitId, loginUserDetail.signleUnitId)
      .then((response) => {
        if (response.data) {
          const uniqueTests = Array.from(
            new Set(response.data.map((item) => item.testId))
          ).map((testId) => {
            return response.data.find((item) => item.testId === testId);
          });

          setTestGiven(uniqueTests);
        }
      });

    //   DashboardServices.getPreviousMedicalRecords(
    //       patientDto.rowPatientId
    //     ).then((response) => {
    //       if (response.data) {
    //         let patientInfo = response.data;
    //         setPatientInfo(patientInfo);
    //         handlePatientInfo(patientInfo);
    //       }
    //     });
  }, []);

  const handleChangeSistolic = (index, e) => {
    let value = e.target.value;

    //need bug fixes

    let tempVital = { ...vitals };
    if (!vitals[index]) {
      tempVital[index] = {
        vitalName: "Blood Pressure",
        unit: null,
        vitalValue: "/",
        // recodedTime: "",
        isNormal: "Y",
        vitalCode: "BP",
      };
    }
    let sistolic = tempVital[index].vitalValue.split("/")[0] || "";
    let diastolic = tempVital[index].vitalValue.split("/")[1] || "";

    if (value === "") {
      const tempValue = `${value}/${diastolic}`;
      tempVital[index].vitalValue = tempValue;
      setVitals(tempVital);
      return;
    }
    if (value) {
      value = value.slice(0, 3);
    }

    sistolic = parseInt(value);
    if (isNaN(sistolic)) {
      return;
    }

    const tempValue = `${sistolic}/${diastolic}`;
    tempVital[index].vitalValue = tempValue;

    if (sistolic > 140 || sistolic < 90 || diastolic > 95 || diastolic < 61) {
      tempVital[index].isNormal = "N";
    } else {
      tempVital[index].isNormal = "Y";
    }

    setVitals(tempVital);
  };

  const handleChangeDiastolic = (index, e) => {
    let value = e.target.value;

    //need bug fixes

    let tempVital = { ...vitals };
    if (!vitals[index]) {
      tempVital[index] = {
        vitalName: "Blood Pressure",
        unit: null,
        vitalValue: "/",
        // recodedTime: "",
        isNormal: "Y",
        vitalCode: "BP",
      };
    }
    let sistolic = tempVital[index].vitalValue.split("/")[0] || "";
    let diastolic = tempVital[index].vitalValue.split("/")[1] || "";

    if (value === "") {
      const tempValue = `${sistolic}/${value}`;
      tempVital[index].vitalValue = tempValue;
      setVitals(tempVital);
      return;
    }
    if (value) {
      value = value.slice(0, 3);
    }

    diastolic = parseInt(value);
    if (isNaN(diastolic)) {
      return;
    }

    const tempValue = `${sistolic}/${diastolic}`;
    tempVital[index].vitalValue = tempValue;

    if (sistolic > 140 || sistolic < 90 || diastolic > 95 || diastolic < 61) {
      tempVital[index].isNormal = "N";
    } else {
      tempVital[index].isNormal = "Y";
    }

    setVitals(tempVital);
  };

  const handleChangeHeartRage = (vitalCode, e) => {
    const value = e.target.value;

    let tempVitals = { ...vitals };
    if (!vitals[vitalCode]) {
      tempVitals[vitalCode] = {
        vitalName: "Heart Rate",
        unit: null,
        vitalValue: "",
        // recodedTime: "",
        isNormal: "Y",
        vitalCode: vitalCode,
      };
    }
    if (value === "" || value === null) {
      tempVitals[vitalCode].vitalValue = value;
      tempVitals[vitalCode].isNormal = "Y";
      setVitals(tempVitals);
    }
    const prValue = parseFloat(value.slice(0, 3));

    if (!prValue || isNaN(prValue) || prValue < 0) {
      return;
    }

    tempVitals[vitalCode].vitalValue = prValue;
    if (prValue > 90 || prValue < 50) {
      tempVitals[vitalCode].isNormal = "N";
    } else {
      tempVitals[vitalCode].isNormal = "Y";
    }
    setVitals(tempVitals);
  };

  const handleChangeRR = (value, vitalCode) => {
    let tempVitals = { ...vitals };

    if (!vitals[vitalCode]) {
      tempVitals[vitalCode] = {
        vitalName: "Respiration Rate",
        unit: "bpm",
        vitalValue: "",
        // recodedTime: "",
        isNormal: "Y",
        vitalCode: vitalCode,
      };
    }

    const rrValue = parseFloat(value.slice(0, 3));

    if (value === "" || !value) {
      tempVitals[vitalCode].vitalValue = value;
      tempVitals[vitalCode].isNormal = "Y";
      setVitals(tempVitals);
    }

    if (!rrValue || isNaN(rrValue) || rrValue < 0) {
      return;
    }

    tempVitals[vitalCode].vitalValue = rrValue;
    if (rrValue > 20 || rrValue < 10) {
      tempVitals[vitalCode].isNormal = "N";
    } else {
      tempVitals[vitalCode].isNormal = "Y";
    }
    setVitals(tempVitals);
  };

  const handleChangeSpo2 = (value, vitalCode) => {
    // let spo2arr = value.split(".");
    // let spoBD = "";
    // let spoAD = "";
    let tempVitals = { ...vitals };

    if (!vitals[vitalCode]) {
      tempVitals[vitalCode] = {
        vitalName: "SPO2",
        unit: "%",
        vitalValue: "",
        // recodedTime: "",
        isNormal: "Y",
        vitalCode: vitalCode,
      };
    }

    let [spoBD, spoAD] = value.split(".").map((part) => part || "");

    if (!spoAD) {
      spoAD = "";
    }

    if (spoBD.length > 2) {
      if (parseFloat(spoBD) === 100) {
        spoBD = "100";
        spoAD = "";
      } else {
        spoBD = spoBD.slice(0, 2);
      }
    }

    if (spoAD.length > 2 && parseFloat(spoAD) > 0) {
      if (parseFloat(spoBD) >= 100) {
        spoAD = "";
      } else {
        spoAD = spoAD.slice(0, 2);
      }
    }

    let temp = "";
    if (spoAD.length > 0) {
      temp = `${spoBD}.${spoAD}`;
    } else {
      temp = value.includes(".") ? `${spoBD}.` : spoBD;
    }

    tempVitals[vitalCode].vitalValue = temp;

    let spo = value.split(".");
    if (parseFloat(spo[0]) >= 95) {
      tempVitals[vitalCode].isNormal = "Y";
    } else if (parseFloat(spo[0]) >= 91 || parseFloat(spo[1]) <= 94) {
      tempVitals[vitalCode].isNormal = "M";
    } else {
      tempVitals[vitalCode].isNormal = "N";
    }
    setVitals(tempVitals);
  };

  const handleChangeTemparature = (value, vitalCode) => {
    let tempVitals = { ...vitals };

    if (!vitals[vitalCode]) {
      tempVitals[vitalCode] = {
        vitalName: "Temperature",
        unit: temperatureUnit || "°F",
        vitalValue: "",
        // recodedTime: "",
        isNormal: "Y",
        vitalCode: vitalCode,
      };
    }

    let tmparr = value.split(".");
    let tmpBD = "";
    let tmpAD = "";

    let tmpTemparature = "";

    const unit = tempVitals[vitalCode].unit;

    if (tmparr[0] && tmparr[0].length > 0) {
      tmpBD = tmparr[0];
    }
    if (tmparr[1] && tmparr[1].length > 0) {
      tmpAD = tmparr[1];
    }

    if (unit.includes("F")) {
      if (tmparr[0] && tmparr[0].length > 3) {
        tmpBD = tmparr[0].slice(0, 3);
      }
      if (tmparr[1] && tmparr[1].length > 2) {
        tmpAD = tmparr[1].slice(0, 2);
      }

      if (value.includes(".")) {
        if (tmpAD.length === 0) {
          tmpTemparature = tmpBD + ".";
        } else {
          tmpTemparature = `${tmpBD}.${tmpAD}`;
        }
      } else {
        tmpTemparature = tmpBD;
      }

      tempVitals[vitalCode].vitalValue = tmpTemparature;

      if (
        parseFloat(tmpTemparature) >= 97 &&
        parseFloat(tmpTemparature) <= 99
      ) {
        tempVitals[vitalCode].isNormal = "Y";
      } else if (
        parseFloat(tmpTemparature) > 99 &&
        parseFloat(tmpTemparature) <= 100.3
      ) {
        tempVitals[vitalCode].isNormal = "M";
      } else {
        tempVitals[vitalCode].isNormal = "N";
      }
    } else if (unit.includes("C")) {
      if (tmparr[0] && tmparr[0].length > 3) {
        tmpBD = parseInt(tmparr[0].slice(0, 2));
      }
      if (tmparr[1] && tmparr[1].length > 2) {
        tmpAD = parseInt(tmparr[1].slice(0, 2));
      }

      if (isNaN(tmpBD) || isNaN(tmpAD)) {
        return;
      }

      if (value.includes(".")) {
        if (tmpAD.length === 0) {
          tmpTemparature = tmpBD + ".";
        } else {
          tmpTemparature = `${tmpBD}.${tmpAD}`;
        }
      } else {
        tmpTemparature = tmpBD;
      }

      tempVitals[vitalCode].vitalValue = tmpTemparature;

      if (parseFloat(tmpTemparature) < 36 || parseFloat(tmpTemparature) > 39) {
        tempVitals[vitalCode].isNormal = "N";
      } else {
        tempVitals[vitalCode].isNormal = "Y";
      }
    }
    setVitals(tempVitals);
  };

  const handleChangeHeadCircumference = (value, vitalCode) => {
    let tempVitals = { ...vitals };

    if (!vitals[vitalCode]) {
      tempVitals[vitalCode] = {
        vitalName: "Head Circumference",
        unit: "cm",
        vitalValue: "",
        // recodedTime: "",
        isNormal: "Y",
        vitalCode: vitalCode,
      };
    }

    let tmparr = value.split(".");
    let tmpBD = "";
    let tmpAD = "";

    let tmpTemparature = "";

    const unit = tempVitals[vitalCode].unit;

    if (tmparr[0] && tmparr[0].length > 0) {
      tmpBD = tmparr[0];
    }
    if (tmparr[1] && tmparr[1].length > 0) {
      tmpAD = tmparr[1];
    }

    if (tmparr[0] && tmparr[0].length > 3) {
      tmpBD = parseInt(tmparr[0].slice(0, 2));
    }
    if (tmparr[1] && tmparr[1].length > 2) {
      tmpAD = parseInt(tmparr[1].slice(0, 2));
    }

    if (isNaN(tmpBD) || isNaN(tmpAD)) {
      return;
    }

    if (value.includes(".")) {
      if (tmpAD.length === 0) {
        tmpTemparature = tmpBD + ".";
      } else {
        tmpTemparature = `${tmpBD}.${tmpAD}`;
      }
    } else {
      tmpTemparature = tmpBD;
    }

    tempVitals[vitalCode].vitalValue = tmpTemparature;
    setVitals(tempVitals);
  };

  const handleChangeUpperArm = (value, vitalCode) => {
    let tempVitals = { ...vitals };

    if (!vitals[vitalCode]) {
      tempVitals[vitalCode] = {
        vitalName: "Upper Arm Circumference",
        unit: "cm",
        vitalValue: "",
        // recodedTime: "",
        isNormal: "Y",
        vitalCode: vitalCode,
      };
    }

    let tmparr = value.split(".");
    let tmpBD = "";
    let tmpAD = "";

    let tmpTemparature = "";

    const unit = tempVitals[vitalCode].unit;

    if (tmparr[0] && tmparr[0].length > 0) {
      tmpBD = tmparr[0];
    }
    if (tmparr[1] && tmparr[1].length > 0) {
      tmpAD = tmparr[1];
    }

    if (tmparr[0] && tmparr[0].length > 3) {
      tmpBD = parseInt(tmparr[0].slice(0, 2));
    }
    if (tmparr[1] && tmparr[1].length > 2) {
      tmpAD = parseInt(tmparr[1].slice(0, 2));
    }

    if (isNaN(tmpBD) || isNaN(tmpAD)) {
      return;
    }

    if (value.includes(".")) {
      if (tmpAD.length === 0) {
        tmpTemparature = tmpBD + ".";
      } else {
        tmpTemparature = `${tmpBD}.${tmpAD}`;
      }
    } else {
      tmpTemparature = tmpBD;
    }

    tempVitals[vitalCode].vitalValue = tmpTemparature;
    setVitals(tempVitals);
  };

  const handleChangeWeight = (value, vitalCode) => {
    let tempVitals = { ...vitals };

    if (!vitals[vitalCode]) {
      tempVitals[vitalCode] = {
        vitalName: "Weight",
        unit: weightUnit || "kg",
        vitalValue: "",
        // recodedTime: "",
        isNormal: "Y",
        vitalCode: vitalCode,
      };
    }

    let weight = parseFloat(value);

    let wtarr = value.split(".");
    let wtBD = "";
    let wtAD = "";

    if (wtarr[0] && wtarr[0].length > 0) {
      wtBD = wtarr[0];
    }
    if (wtarr[1] && wtarr[1].length > 0) {
      wtAD = wtarr[1];
    }

    if (wtarr[0] && wtarr[0].length > 3) {
      wtBD = wtarr[0].slice(0, 3);
    }
    if (wtarr[1] && wtarr[1].length > 2) {
      wtAD = wtarr[1].slice(0, 2);
    }

    if (value === "" || !value) {
      tempVitals[vitalCode].vitalValue = value;
      setVitals(tempVitals);
      return;
    }

    if (!weight || isNaN(weight)) {
      return;
    }

    let tmpWeight = "";

    if (value.includes(".")) {
      if (wtAD.length === 0) {
        tmpWeight = wtBD + ".";
      } else {
        tmpWeight = `${wtBD}.${wtAD}`;
      }
    } else {
      tmpWeight = wtBD;
    }

    tempVitals[vitalCode].vitalValue = tmpWeight;

    setVitals(tempVitals);
  };

  const handleChangeHeight = (value, vitalCode) => {
    let tempVitals = { ...vitals };

    if (!vitals[vitalCode]) {
      tempVitals[vitalCode] = {
        vitalName: "Height",
        unit: heightUnit || "cm",
        vitalValue: "",
        // recodedTime: "",
        isNormal: "Y",
        vitalCode: vitalCode,
      };
    }

    const unit = tempVitals[vitalCode].unit;
    let height = parseFloat(value);

    let htarr = value.split(".");
    let htBD = "";
    let htAD = "";

    if (htarr[0] && htarr[0].length > 0) {
      htBD = htarr[0];
    }
    if (htarr[1] && htarr[1].length > 0) {
      htAD = htarr[1];
    }

    if (value === "") {
      tempVitals[vitalCode].vitalValue = value;
      setVitals(tempVitals);
      return;
    }

    if (!height || isNaN(height)) {
      return;
    }

    if (unit === "cm") {
      if (htarr[0] && htarr[0].length > 3) {
        htBD = htarr[0].slice(0, 3);
      }
      if (htarr[1] && htarr[1].length > 2) {
        htAD = htarr[1].slice(0, 2);
      }
      let tempStr = "";
      if (value.includes(".")) {
        if (htAD.length === 0) {
          tempStr = htBD + ".";
        } else {
          tempStr = `${htBD}.${htAD}`;
        }
      } else {
        tempStr = htBD;
      }

      tempVitals[vitalCode].vitalValue = tempStr;
    } else if (unit === "ft~in") {
      tempVitals[vitalCode].vitalValue = value;
    }
    setVitals(tempVitals);
  };

  const handleChangeFoot = (value, vitalCode) => {
    let tempVitals = { ...vitals };
    let foot = 0;
    let inch = 0;

    if (!vitals[vitalCode]) {
      tempVitals[vitalCode] = {
        vitalName: "Height",
        unit: heightUnit || "cm",
        vitalValue: "",
        // recodedTime: "",
        isNormal: "Y",
        vitalCode: vitalCode,
      };
    }

    if (
      tempVitals[vitalCode].vitalValue &&
      tempVitals[vitalCode].vitalValue !== ""
    ) {
      const tmp = parseInt(tempVitals[vitalCode].vitalValue);
      if (tmp) {
        foot = Math.floor(tmp / 12);
        inch = Math.floor(tmp % 12);
      }
    }

    if (!value || value === "") {
      tempVitals[vitalCode].vitalValue = 0 * 12 + inch;
      setVitals(tempVitals);
      return;
    }
    foot = parseInt(value);
    if (isNaN(foot)) {
      return;
    }
    tempVitals[vitalCode].vitalValue = foot * 12 + inch;
    setVitals(tempVitals);
  };

  const handleChangeInch = (value, vitalCode) => {
    let tempVitals = { ...vitals };
    let foot = 0;
    let inch = 0;

    if (!vitals[vitalCode]) {
      tempVitals[vitalCode] = {
        vitalName: "Height",
        unit: heightUnit || "cm",
        vitalValue: "",
        // recodedTime: "",
        isNormal: "Y",
        vitalCode: vitalCode,
      };
    }

    if (
      tempVitals[vitalCode].vitalValue &&
      tempVitals[vitalCode].vitalValue !== ""
    ) {
      const tmp = parseInt(tempVitals[vitalCode].vitalValue);
      if (tmp) {
        foot = Math.floor(tmp / 12);
        inch = Math.floor(tmp % 12);
      }
    }

    if (!value || value === "") {
      tempVitals[vitalCode].vitalValue = foot * 12 + 0;
      setVitals(tempVitals);
      return;
    }
    inch = parseInt(value);
    if (isNaN(inch)) {
      return;
    }
    tempVitals[vitalCode].vitalValue = foot * 12 + inch;
    setVitals(tempVitals);
  };

  const handleLmpChange = (value, vitalCode) => {
    let tempVitals = { ...vitals };

    if (!vitals[vitalCode]) {
      tempVitals[vitalCode] = {
        vitalName: "LMP",
        unit: heightUnit || "cm",
        vitalValue: "",
        // recodedTime: "",
        isNormal: "Y",
        vitalCode: vitalCode,
      };
    }

    if (value) {
      tempVitals[vitalCode].vitalValue = value;
      setVitals(tempVitals);
    }
  };


  const validateVitals = () => {
    const tempVitals = Object.values(vitals);
    let isValid = true;
    const problemList = [];

    const vitalCodeNameMap = {
      BP: "Blood Pressure",
      TEMPERATURE: "Temperature",
      SPO2: "SPO2",
      RESPIRATION_RATE: "Respiratory Rate",
      HEART_RATE: "Heart Rate",
      WEIGHT: "Weight",
      HEIGHT: "Height",
      LMP: "LMP",
      UPPER_ARM_CIRCUMFERENCE: "Upper Arm Circumference",
      HEAD_CIRCUMFERENCE: "Head Circumference",
    };

    const vitalCodeList = [
      "BP",
      "TEMPERATURE",
      "SPO2",
      "RESPIRATION_RATE",
      "HEART_RATE",
      "WEIGHT",
      "HEIGHT",
    ];

    if (
      selectedPatient.patientGender === "Female" &&
      getAge(selectedPatient.patientAge) >= 12
    ) {
      vitalCodeList.push("LMP");
    }

    if (getAge(selectedPatient.patientAge) <= 4) {
      vitalCodeList.push("UPPER_ARM_CIRCUMFERENCE");
      vitalCodeList.push("HEAD_CIRCUMFERENCE");
    }
    const list = [];

    vitalCodeList.forEach((item) => {
      if (!vitals[item]) {
        // setVitalErrorList(prev=>[...prev,`${item} `])
        isValid = false;
        list.push(`${vitalCodeNameMap[item]} is not provided `);
      } else if (
        vitals[item].vitalValue === null ||
        vitals[item].vitalValue === ""
      ) {
        isValid = false;
        list.push(`${vitalCodeNameMap[item]} is not provided`);
      }
    });

    if (!isValid) {
      setVitalErrorList((prev) => [...list]);

      setShowConfirmation(true);
      return false;
    }

    const bp = tempVitals.find((item) => item.vitalCode === "BP");
    const temp = tempVitals.find((item) => item.vitalCode === "TEMPERATURE");
    if (bp) {
      const bpList = bp.vitalValue.split("/");
      const sistolic = parseInt(bpList[0]);
      const diastolic = parseInt(bpList[1]);

      if (sistolic === "" || !sistolic) {
        problemList.push("Sistolic pressure cannot be empty");
        toast.error("Sistolic pressure cannot be empty");
        isValid = false;
      }

      if (diastolic === "" || !diastolic) {
        problemList.push("Diastolic pressure cannot be empty");
        toast.error("Diastolic pressure cannot be empty");
        isValid = false;
      }

      if (sistolic < diastolic) {
        problemList.push("Sistolic pressure must be greater than diastolic");
        toast.error("Sistolic pressure must be greater than diastolic");
        isValid = false;
      }

      if (sistolic - diastolic < 30) {
        problemList.push(
          "Sistolic and diastolic pressure difference must be greater than 30"
        );
        toast.error(
          "Sistolic and diastolic pressure difference must be greater than 30"
        );
        isValid = false;
      }
    }

    if (temp) {
      const unit = temp.unit;
      const value = parseFloat(temp.vitalValue);
      if (unit.includes("C")) {
        if (value > 41.1 || value < 32.22) {
          problemList.push("Temparature must be within 32.22 to 41.10");
          toast.error("Temparature must be within 32.22 to 41.10");
          isValid = false;
        }
      } else if (unit.includes("F")) {
        if (value > 106 || value < 90) {
          problemList.push("Temparature must be within 90 to 106");
          toast.error("Temparature must be within 90 to 106");
          isValid = false;
        }
      }
    }

    setVitalErrorList(problemList);

    if (!isValid) {
      setShowConfirmation(true);
    }

    return isValid;
  };

  return (
    <>
      <div className="rightFlotingPanel">
        <div className="rightFlotingContainer">
          <div className="rfContHeader">
            <div className="rfcHeadText">Review Patient</div>
            <div className="rfcHActionBtnGrp">
              <div className="actionBtn closeBtnD10">
                <IconButton
                  onClick={() => handleCloseAddReviewPatientToQueue()}
                >
                  <XLg />
                </IconButton>
              </div>
            </div>
          </div>

          <div className="rfContContain">
            <div className="rfContContainDetails">
              <div className="elementFormContainer">
                <div className="tskElemHeding ">Doctor Info</div>
                <div className="doctorDetails bookedSlot">
                  <div className="doctorInfoWthImg">
                    <div className="docImage">
                      <img
                        src={selectedDoctor?.doctorProfileImageUrl}
                        alt="zoyel_doctor"
                      />
                    </div>
                    <div className="doctorFullInfo">
                      <div className="doctorNmSpc">
                        <div className="docName">
                          {selectedDoctor?.doctorName}
                        </div>
                        <div className="docSpclty">
                          {` ( ${selectedDoctor?.specializationName || ""} )`}
                        </div>
                      </div>
                      <div className="docExpFeeLan">
                        <div className="docEFL">
                          <div className="eflicon fillnone">
                            <EXP />
                          </div>
                          <div className="eflText">
                            {selectedDoctor?.experience}
                          </div>
                        </div>

                        <div className="docEFL">
                          <div className="eflicon">
                            <MonetizationOnOutlined />
                          </div>
                          <div className="eflText">
                            {selectedDoctor?.fees}
                            <span> {selectedDoctor?.feesCurrency}</span>
                          </div>
                        </div>
                        <div className="docEFL">
                          <div className="eflicon">
                            <TranslateRounded />
                          </div>
                          <div className="eflText">
                            {selectedDoctor?.language?.join(", ")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tskElemHeding ">Patient Info</div>

                <div className="filterPatlistInd appoinBookPatList">
                  <div className="filPatFullinfo">
                    <div className="filPatImg">
                      <img src={patient?.patientProfileUrl} alt="patImage" />
                    </div>
                    <div className="filtPatDtl">
                      <div className="filtPatNm">
                        {patient?.patientName}
                        <span>{`( ${patient?.patientGender}, ${patient?.patientAge} )`}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tskElemHeding">Vitals</div>
                <div className="vitalsGroup">
                  <div className="vitalsInd">
                    <div className="vitIcon">
                      <BP />
                    </div>
                    <div className="vitInfo">
                      <div className="vitNm">Blood Pressure</div>
                      <div
                        className={`vitValue ${
                          vitals?.BP?.isNormal === "Y" || !vitals || !vitals.BP
                            ? "vNormal"
                            : "vRisk"
                        }`}
                      >
                        <input
                          className="editInputVitals"
                          type="text"
                          placeholder="Sys"
                          value={vitals?.BP?.vitalValue?.split("/")?.[0] || ""}
                          onChange={(e) => {
                            handleChangeSistolic("BP", e);
                          }}
                        />
                        <span className="slash"></span>
                        <input
                          className="editInputVitals"
                          type="text"
                          placeholder="Dys"
                          value={vitals?.BP?.vitalValue?.split("/")?.[1] || ""}
                          onChange={(e) => {
                            handleChangeDiastolic("BP", e);
                          }}
                        />
                        <span>mmHg</span>
                      </div>
                    </div>
                  </div>

                  <div className="vitalsInd">
                    <div className="vitIcon">
                      <HeartPulse />
                    </div>
                    <div className="vitInfo">
                      <div className="vitNm">Heart Rate</div>
                      <div
                        className={`vitValue ${
                          vitals?.HEART_RATE?.isNormal === "Y" ||
                          !vitals ||
                          !vitals.HEART_RATE
                            ? "vNormal"
                            : "vRisk"
                        }`}
                      >
                        <input
                          className="editInputVitals"
                          type="text"
                          placeholder="eg:80"
                          value={vitals?.HEART_RATE?.vitalValue || ""}
                          onChange={(e) =>
                            handleChangeHeartRage("HEART_RATE", e)
                          }
                        />
                        <span>bpm</span>
                      </div>
                    </div>
                  </div>

                  <div className="vitalsInd">
                    <div className="vitIcon">
                      <Lungs />
                    </div>
                    <div className="vitInfo">
                      <div className="vitNm">Respiration Rate</div>
                      <div
                        className={`vitValue ${
                          vitals?.RESPIRATION_RATE?.isNormal === "Y" ||
                          !vitals ||
                          !vitals.RESPIRATION_RATE
                            ? "vNormal"
                            : "vRisk"
                        }`}
                      >
                        <input
                          className="editInputVitals"
                          type="text"
                          placeholder="eg:12"
                          value={vitals?.RESPIRATION_RATE?.vitalValue || ""}
                          onChange={(e) => {
                            handleChangeRR(e.target.value, "RESPIRATION_RATE");
                          }}
                        />
                        <span>bpm</span>
                      </div>
                    </div>
                  </div>

                  <div className="vitalsInd">
                    <div className="vitIcon">
                      <SPO2 />
                    </div>
                    <div className="vitInfo">
                      <div className="vitNm">SPO2</div>
                      <div
                        className={`vitValue ${
                          vitals?.SPO2?.isNormal === "Y" ||
                          !vitals ||
                          !vitals.SPO2
                            ? "vNormal"
                            : vitals?.SPO2?.isNormal === "M"
                            ? "vModarate"
                            : "vRisk"
                        }`}
                      >
                        <input
                          className="editInputVitals"
                          type="text"
                          placeholder="eg:98"
                          value={vitals?.SPO2?.vitalValue || ""}
                          onChange={(e) => {
                            handleChangeSpo2(e.target.value, "SPO2");
                          }}
                        />
                        <span>%</span>
                      </div>
                    </div>
                  </div>

                  <div className="vitalsInd">
                    <div className="vitIcon">
                      <ThermometerHalf />
                    </div>
                    <div className="vitInfo">
                      <div className="vitNm">Temperature</div>
                      <div
                        className={`vitValue ${
                          vitals?.TEMPERATURE?.isNormal === "Y" ||
                          !vitals ||
                          !vitals.TEMPERATURE
                            ? "vNormal"
                            : vitals?.TEMPERATURE?.isNormal === "M"
                            ? "vModarate"
                            : "vRisk"
                        }`}
                      >
                        <input
                          className="editInputVitals"
                          type="text"
                          placeholder="eg:96.6"
                          value={vitals?.TEMPERATURE?.vitalValue || ""}
                          onChange={(e) => {
                            handleChangeTemparature(
                              e.target.value,
                              "TEMPERATURE"
                            );
                          }}
                        />
                        <select
                          className="editInputUnits"
                          value={temperatureUnit}
                          onChange={(e) => {
                            setTemperatureUnit(e.target.value);
                            const tempVital = { ...vitals };
                            if (!tempVital.TEMPERATURE) {
                              tempVital.TEMPERATURE = {
                                vitalName: "Temperature",
                                unit: e.target.value,
                                vitalValue: "",
                                // recodedTime: "",
                                isNormal: "Y",
                                vitalCode: "TEMPERATURE",
                              };
                            } else {
                              tempVital.TEMPERATURE.unit = e.target.value;
                            }
                            setVitals(tempVital);
                          }}
                        >
                          <option value="°F">°F </option>
                          <option value="°C">°C </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="vitalsInd">
                    <div className="vitIcon">
                      <MonitorWeightOutlined />
                    </div>
                    <div className="vitInfo">
                      <div className="vitNm">Weight</div>
                      {console.log("vital height =", vitals?.WEIGHT)}
                      <div
                        className={`vitValue ${
                          vitals?.WEIGHT?.isNormal === "Y" ||
                          !vitals ||
                          !vitals.WEIGHT
                            ? "vNormal"
                            : vitals?.WEIGHT?.isNormal === "M"
                            ? "vModarate"
                            : "vRisk"
                        }`}
                      >
                        <input
                          className="editInputVitals"
                          type="text"
                          placeholder="eg:80"
                          value={vitals?.WEIGHT?.vitalValue || ""}
                          onChange={(e) => {
                            handleChangeWeight(e.target.value, "WEIGHT");
                          }}
                        />
                        <select
                          className="editInputUnits"
                          value={weightUnit}
                          onChange={(e) => {
                            setWeightUnit(e.target.value);
                            const tempVital = { ...vitals };
                            if (!tempVital.WEIGHT) {
                              tempVital.WEIGHT = {
                                vitalName: "Weight",
                                unit: e.target.value,
                                vitalValue: "",
                                // recodedTime: "",
                                isNormal: "Y",
                                vitalCode: "WEIGHT",
                              };
                            } else {
                              tempVital.WEIGHT.unit = e.target.value;
                            }
                            setVitals(tempVital);
                          }}
                        >
                          <option value="kg">kg </option>
                          <option value="lb">lb </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="vitalsInd">
                    <div className="vitIcon">
                      <HeightOutlined />
                    </div>
                    <div className="vitInfo">
                      <div className="vitNm">Height</div>
                      <div
                        className={`vitValue ${
                          vitals?.HEIGHT?.isNormal === "Y" ||
                          !vitals ||
                          !vitals.HEIGHT
                            ? "vNormal"
                            : "vRisk"
                        }`}
                      >
                        {heightUnit === "ft~in" && (
                          <>
                            <input
                              className="editInputVitals"
                              type="text"
                              placeholder="eg:182"
                              value={
                                Math.floor(
                                  parseInt(vitals?.HEIGHT?.vitalValue) / 12
                                ) || ""
                              }
                              onChange={(e) => {
                                handleChangeFoot(e.target.value, "HEIGHT");
                              }}
                            />
                            <span>ft</span>
                            <input
                              className="editInputVitals"
                              type="text"
                              placeholder="eg:182"
                              value={
                                Math.floor(
                                  parseInt(vitals?.HEIGHT?.vitalValue) % 12
                                ) || ""
                              }
                              onChange={(e) => {
                                handleChangeInch(e.target.value, "HEIGHT");
                              }}
                            />
                          </>
                        )}

                        {heightUnit !== "ft~in" && (
                          <input
                            className="editInputVitals"
                            type="text"
                            placeholder="eg:182"
                            value={vitals?.HEIGHT?.vitalValue || ""}
                            onChange={(e) => {
                              handleChangeHeight(e.target.value, "HEIGHT");
                            }}
                          />
                        )}
                        <select
                          className="editInputUnits"
                          value={heightUnit}
                          onChange={(e) => {
                            setHeightUnit(e.target.value);
                            const tempVital = { ...vitals };
                            if (!tempVital.HEIGHT) {
                              tempVital.HEIGHT = {
                                vitalName: "Weight",
                                unit: e.target.value,
                                vitalValue: "",
                                // recodedTime: "",
                                isNormal: "Y",
                                vitalCode: "HEIGHT",
                              };
                            } else {
                              tempVital.HEIGHT.unit = e.target.value;
                            }
                            setVitals(tempVital);
                          }}
                        >
                          <option value="cm">cm </option>
                          <option value="ft~in">ft-in </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  {selectedPatient &&
                    getAge(selectedPatient?.patientAge) <= 4 && (
                      <div className="vitalsInd">
                        <div className="vitIcon">
                          <HeadC />
                        </div>
                        <div className="vitInfo">
                          <div className="vitNm">Head Circumference</div>
                          <div className="vitValue">
                            <input
                              className="editInputVitals"
                              type="text"
                              placeholder="eg:34.5"
                              value={
                                vitals?.HEAD_CIRCUMFERENCE?.vitalValue || ""
                              }
                              onChange={(e) => {
                                handleChangeHeadCircumference(
                                  e.target.value,
                                  "HEAD_CIRCUMFERENCE"
                                );
                              }}
                            />
                            <select
                              className="editInputUnits"
                              value={temperatureUnit}
                              onChange={(e) => {
                                setTemperatureUnit(e.target.value);
                                const tempVital = { ...vitals };
                                if (!tempVital.HEAD_CIRCUMFERENCE) {
                                  tempVital.HEAD_CIRCUMFERENCE = {
                                    vitalName: "Head Circumference",
                                    unit: e.target.value,
                                    vitalValue: "",
                                    // recodedTime: "",
                                    isNormal: "Y",
                                    vitalCode: "HEAD_CIRCUMFERENCE",
                                  };
                                } else {
                                  tempVital.HEAD_CIRCUMFERENCE.unit =
                                    e.target.value;
                                }
                                setVitals(tempVital);
                              }}
                            >
                              <option value="cm">cm </option>
                              <option value="in">inch </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    )}

                  {selectedPatient &&
                    getAge(selectedPatient?.patientAge) <= 4 && (
                      <div className="vitalsInd">
                        <div className="vitIcon">
                          <UAC />
                        </div>
                        <div className="vitInfo">
                          <div className="vitNm">Upper Arm Circumference</div>
                          <div className="vitValue">
                            <input
                              className="editInputVitals"
                              type="text"
                              placeholder="eg:12.5"
                              value={
                                vitals?.UPPER_ARM_CIRCUMFERENCE?.vitalValue ||
                                ""
                              }
                              onChange={(e) => {
                                handleChangeUpperArm(
                                  e.target.value,
                                  "UPPER_ARM_CIRCUMFERENCE"
                                );
                              }}
                            />
                            <select
                              className="editInputUnits"
                              value={temperatureUnit}
                              onChange={(e) => {
                                setTemperatureUnit(e.target.value);
                                const tempVital = { ...vitals };
                                if (!tempVital.UPPER_ARM_CIRCUMFERENCE) {
                                  tempVital.UPPER_ARM_CIRCUMFERENCE = {
                                    vitalName: "Temperature",
                                    unit: e.target.value,
                                    vitalValue: "",
                                    // recodedTime: "",
                                    isNormal: "Y",
                                    vitalCode: "UPPER_ARM_CIRCUMFERENCE",
                                  };
                                } else {
                                  tempVital.UPPER_ARM_CIRCUMFERENCE.unit =
                                    e.target.value;
                                }
                                setVitals(tempVital);
                              }}
                            >
                              <option value="cm">cm </option>
                              <option value="in">inch </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    )}

                  {selectedPatient?.patientGender === "Female" &&
                    getAge(selectedPatient?.patientAge) >= 12 && (
                      <div className="vitalsInd">
                        <div className="vitIcon">
                          <LMP />
                        </div>
                        <div className="vitInfo">
                          <div className="vitNm">LMP</div>
                          <div className="vitValue">
                            <input
                              className="editInputDate"
                              type="date"
                              value={
                                vitals?.LMP?.vitalValue
                                  ? dayjs(vitals?.LMP?.vitalValue).format(
                                      "YYYY-MM-DD"
                                    )
                                  : lmp
                              }
                              onChange={(event) => {
                                handleLmpChange(event.target.value, "LMP");
                                setLmp(event.target.value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )}

                  <div className="vitalsInd">
                    <div className="vitIcon">
                      <BloodtypeOutlined />
                    </div>
                    <div className="vitInfo">
                      <div className="vitNm">Blood Group</div>
                      <div className="vitValue">
                        <select
                          className="editInputUnits"
                          onChange={(e) => {
                            const tempVitals = { ...vitals };
                            const value = e.target.value;
                            // if (!vitals["BLOOD_GROUP"]) {
                            //   tempVitals["BLOOD_GROUP"] = {
                            //     vitalName: "Blood Group",
                            //     unit: null,
                            //     vitalValue: value,
                            //     // recodedTime: "",
                            //     isNormal: "Y",
                            //     vitalCode: "BLOOD_GROUP",
                            //   };
                            //   setVitals(tempVitals);
                            // } else {
                            //   tempVitals["BLOOD_GROUP"].vitalValue = value;
                            // }
                            setBloodGroup(value);
                          }}
                          value={bloodGroup || ""}
                        >
                          <option value="" disabled selected>
                            Select
                          </option>
                          <option value="A+">A + </option>
                          <option value="A-">A - </option>
                          <option value="B+">B + </option>
                          <option value="B-">B - </option>
                          <option value="AB+">AB + </option>
                          <option value="AB-">AB - </option>
                          <option value="O+">O + </option>
                          <option value="O-">O - </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <Button
                    startIcon={<DeviceHddFill />}
                    className={"dfultPrimaryBtn"}
                  >
                    Get Vitals From Device
                  </Button>
                </div>

                <div className="bb"></div>

                <PatientComplaints
                  data={prescriptionData}
                  updateData={updateData}
                  extraData={patientInfo}
                />

                {/* files start */}

                <div className="taskElementGrp">
                  <div className="tskElemHeding">Files</div>

                  <div class="elementFileListedGrp">
                    <div className="horaizonScroll">
                      <IconButton
                        className="goPrevousBtn"
                        onClick={() => {
                          filehandleHorizantalScroll(
                            fileElementRef.current,
                            25,
                            200,
                            -10
                          );
                        }}
                        disabled={filearrowDisable}
                      >
                        <ArrowBackIosNew />
                      </IconButton>

                      <div class="elemntFilsgrp" ref={fileElementRef}>
                        {documents?.map((item) => {
                          return (
                            <>
                              <div className="elemntFile">
                                <Tooltip
                                  title={item.fileName}
                                  arrow
                                  className="elmFileTolTip"
                                >
                                  <span className="elemntFilIoc">
                                    <DescriptionOutlined />
                                  </span>
                                  <span className="elemntFilNm">
                                    {item.fileName}
                                  </span>
                                </Tooltip>
                              </div>
                            </>
                          );
                        })}

                        {previousMedicalRecords
                          ?.filter((item) => item.isChecked)
                          ?.map((item) => {
                            return (
                              <>
                                <div className="elemntFile">
                                  <Tooltip
                                    title={item.fileName}
                                    arrow
                                    className="elmFileTolTip"
                                  >
                                    <span className="elemntFilIoc">
                                      <DescriptionOutlined />
                                    </span>
                                    <span className="elemntFilNm">
                                      {item.fileName}
                                    </span>
                                  </Tooltip>
                                </div>
                              </>
                            );
                          })}
                      </div>
                      {fileDivWidth > 370 && (
                        <IconButton
                          className="goNextBtn"
                          onClick={() => {
                            filehandleHorizantalScroll(
                              fileElementRef.current,
                              25,
                              200,
                              10
                            );
                          }}
                        >
                          <ArrowForwardIos />
                        </IconButton>
                      )}
                    </div>
                    <div class="elmntAddMrFiles">
                      <Button
                        className="elemntMrFileBtn"
                        variant="outlined"
                        // onClick={handleAddnewFile}
                        onClick={(e) => {
                          setShowFileUploadDialog(true);
                        }}
                      >
                        <Plus />
                      </Button>
                      <input
                        type="file"
                        // ref={fileInputRef}
                        // onChange={handleChangeFile}
                        style={{ display: "none" }}
                      ></input>
                    </div>
                  </div>
                </div>

                {/* files end */}
                <div className="taskElementGrp mt10">
                  <div className="tskElemHeding">Systemic Illness</div>

                  <div className="formElementGrp flex-wrap">
                    <div className="formElement ">
                      <FormControl className="formControl">
                        <RadioGroup
                          className="formRadioGroup"
                          labelId="setTasktype"
                          name="setTasktype"
                          value={systemicIllness}
                          onChange={selectSystemicIllness}
                        >
                          <FormControlLabel
                            className="formRadioField"
                            value="yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            className="formRadioField"
                            value="no"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                    {systemicIllness === "yes" && (
                      <div className="formElement">
                        <FormControl className="formControl">
                          <InputLabel id="demo-multiple-checkbox-label">
                            Systemic Illness
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            className="formInputField"
                            variant="outlined"
                            value={illnessName}
                            onChange={handleChangeIllness}
                            input={<OutlinedInput label="Systemic Illness" />}
                            renderValue={(selected) =>
                              selected
                                .map((item) => item.systemicIllnessName)
                                .join(", ")
                            }
                            MenuProps={MenuProps}
                          >
                            {systemicIllnessList.map((item) => (
                              <MenuItem key={item.id} value={item}>
                                <Checkbox
                                  checked={
                                    illnessName.findIndex(
                                      (element) => element.id === item.id
                                    ) > -1
                                  }
                                />
                                <ListItemText
                                  primary={`${item.systemicIllnessName}`}
                                />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    )}
                  </div>
                </div>

                <div className="taskElementGrp mt10">
                  <div className="tskElemHeding">Substance Abuse</div>

                  <div className="formElementGrp flex-wrap">
                    <div className="formElement ">
                      <FormControl className="formControl">
                        <RadioGroup
                          className="formRadioGroup"
                          labelId="setTasktype"
                          name="setTasktype"
                          value={substanceAbuse}
                          onChange={selectSubstanceAbuse}
                        >
                          <FormControlLabel
                            className="formRadioField"
                            value="yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            className="formRadioField"
                            value="no"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                    {substanceAbuse === "yes" && (
                      <div className="formElement">
                        <FormControl className="formControl">
                          <InputLabel id="demo-multiple-checkbox-label">
                            Substance Abuse
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            className="formInputField"
                            variant="outlined"
                            value={abuseName}
                            onChange={handleChangeAbuse}
                            input={<OutlinedInput label="Substance Abuse" />}
                            renderValue={(selected) =>
                              selected
                                .map((item) => item.substanceAbuseName)
                                .join(", ")
                            }
                            MenuProps={MenuProps}
                          >
                            {systemicAbuseList.map((item) => (
                              <MenuItem key={item.id} value={item}>
                                <Checkbox
                                  checked={
                                    abuseName.findIndex(
                                      (element) => element.id === item.id
                                    ) > -1
                                  }
                                />
                                <ListItemText
                                  primary={item.substanceAbuseName}
                                />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    )}
                  </div>
                </div>

                <div className="taskElementGrp mt10">
                  <div className="tskElemHeding">Reference Details</div>
                  <div className="formElement">
                    <FormControl className="formControl">
                      <TextField
                        label="Referred By"
                        autoComplete="off"
                        variant="outlined"
                        className="formTextFieldArea"
                        value={referedBy}
                        onChange={(e) => {
                          setReferedBy(e.target.value);
                        }}
                      />
                    </FormControl>
                  </div>
                </div>
                <div className="bb"></div>
                <MedicalHistory
                  data={patientInfo}
                  updateData={updateData}
                  previousInfo={patientInfo}
                />

                <PatientFamilyHistory
                  data={prescriptionData}
                  updateData={updateData}
                  previousInfo={patientInfo}
                />

                <PatientDrugAllergy
                  data={prescriptionData}
                  updateData={updateData}
                  extraData={patientInfo}
                  previousInfo={patientInfo}
                />
                <PatientFoodAllergy
                  data={prescriptionData}
                  updateData={updateData}
                  extraData={patientInfo}
                  previousInfo={patientInfo}
                />

                <TestResultInput
                  data={{ investigation: testGiven }}
                  extraData={extraData}
                />
              </div>
            </div>
          </div>
          <div className="rfContFooter">
            <div className="formBtnElement">
              <Button
                className="dfultPrimaryBtn"
                onClick={async () => {
                  // setShowConfirmation(true)


                  const isValid = validateVitals();
                  
                  if (!isValid) {
                    return;
                  }

                  submitFormData();

                  const promises = userInvestigationList?.map((test) => {
                    let taskResult = test?.parameterList
                      ?.filter((item) => (item.value ? true : false))
                      .map((parameterItem) => {
                        return {
                          parameterName: parameterItem.parameter,
                          parameterValue: parameterItem.value,
                          parameterUnit: parameterItem.unit,
                          parameterRefRange: parameterItem.refRange[0],
                          parameterId: 0,
                        };
                      });
                    const reqDto = {
                      sampleType: test.sampleType || "",
                      testId: test.testId,
                      requestionId: 0,
                      testName: test.testName,
                      // visitId: extraData?.queueVisitId,
                      patientId: patient?.patientId || 0,
                      outSource: test.outSource ? "Y" : "N",
                      fileName: test?.fileData?.fileName || "",
                      reagent: test.reagent || "",
                      sampleCollectionTime: dayjs(
                        test.sampleCollectionTime
                      ).format("DD-MM-YYYY HH:mm:ss"),
                      timezone: timezone,
                      fileData: test?.fileData?.fileDataLink || "",
                      orgId: loginUserDetail.orgId,
                      testResult: taskResult,
                    };
                    console.log("reqdto =", reqDto);
                    // return reqDto;
                    return zhPatientService.submitTestResultWithoutRequestion(
                      loginUserDetail.userId,
                      reqDto
                    );
                  });
                  console.log("********* promises =", promises);

                  try {
                    await Promise.all(promises);

                    console.log("All tests submitted successfully!");
                  } catch (error) {
                    console.error(
                      "Error occurred while submitting tests:",
                      error
                    );
                  }
                }}
              >
                Submit
              </Button>
              <Button
                className="dfultDarkSecondaryBtn"
                onClick={() => handleCloseAddReviewPatientToQueue()}
              >
                Close
              </Button>
            </div>
          </div>
        </div>
      </div>

      {showFileUploadDialog && (
        <div className="RightFlotingContainerArea">
          <PatientFileUpload
            documents={documents}
            setDocuments={setDocuments}
            onClose={(e) => {
              setShowFileUploadDialog(false);
            }}
            previousMedicalRecords={previousMedicalRecords}
            setPreviousMedicalRecords={setPreviousMedicalRecords}
          />
        </div>
      )}

      {showConfirmation && (
        <CustomModal
          modalTitle="Do You Want to continue"
          // style={{ zIndex: 10001 }}
          closeModal={() => {
            setShowConfirmation(false);
          }}
          className={"width90"}
        >
          <div>
            <ul>
              {VitalErrorList &&
                VitalErrorList.map((item) => {
                  return <li>{item}</li>;
                })}
            </ul>
          </div>

          <div className="modActionBtnGrp actionsCreateOrg">
            <Button
              className="dfultDarkSecondaryBtn"
              onClick={() => {
                submitFormData();
                setShowConfirmation(false);
              }}
            >
              Continue
            </Button>
            <Button
              className="dfultPrimaryBtn"
              onClick={(e) => {
                setShowConfirmation(false);
              }}
            >
              Cancel
            </Button>
          </div>
        </CustomModal>
      )}
    </>
  );
};
