import React, { Suspense, lazy } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import LandingPage from "../features/landingPage/view/LandingPage";
import { getLoginUserDetails } from "../utils/Utils";
import { getActiveOtpServices } from "../services/AdminService";
const HomePage = lazy(() => import("../features/home/view/HomePage"));
// import HomePage from "../features/home/view/HomePage";

const RouteGuard = ({ url }) => {
  const { state } = useLocation();
  const jwt = localStorage.getItem("token");

  if (null == jwt) {
    return <Navigate to="/login" />;
  } else {
    const userDtls = getLoginUserDetails();

  if (userDtls.orgId && userDtls.orgId!=="ORG000000000000") {
  const otpServices = JSON.parse(localStorage.getItem("otpServices")) || [];

  if (otpServices.length <= 0) {
    getActiveOtpServices(userDtls.orgId)
      .then((response) => {
        console.log('responseotpservicedata Router', response.data);
        if (Array.isArray(response.data) && response.data.length > 0) {
          localStorage.setItem("otpServices", JSON.stringify(response.data));
        }
      })
      .catch((error) => {
        console.error('Error fetching OTP services:', error);
      });
  }
  }
    if (userDtls?.userType !== "GUEST") {
      // return <Navigate to="/dashboardG" state={state} />;
      return <Navigate to={`/${url}`} />;
    }
  }
};

const AppRoute = () => {
  let host = window.location.host.replace("www.", "");
  const domainArray = host.split(".");

  if (host.includes("ngrok")) {
    return (
      <Suspense fallback="Loading.....">
        {/* <HomePage />; */}
        <LandingPage />
      </Suspense>
    );
  } else if (domainArray.length === 3) {
    if (window.caches) {
      window.caches.keys().then((cacheNames) => {
        cacheNames.forEach((cacheName) => {
          window.caches.delete(cacheName);
        });
      });
    }
    window.location.replace(
      window.location.protocol + "//" + window.location.host
    );
  } else {
    return (
      <Suspense fallback="Loading.....">
        {/* <HomePage />; */}
        <LandingPage />
      </Suspense>
    );
  }
};

export { RouteGuard, AppRoute };
