import { Add, ArrowBack, Search, Visibility } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { FileEarmarkArrowDownFill, Prescription } from "react-bootstrap-icons";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DashboardServices from "../../../../../services/DashboardServices";
import DateUtils from "../../../../../utils/DateUtils";
import { apiEndPoint } from "../../../../../constants/url_provider";
import { toast } from "react-toastify";
// import zhPatientService from "../../../../../services/ZhPatientService";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";

export default function ViewDoctorConsultationList({
  handleCloseViewDoctorConsultList,
}) {
  const userDetails = jwtDecode(localStorage.getItem("token"));
  const { t } = useTranslation();
  const [patientDocumentData, setPatientDocumentData] = useState([]);
  const [filterpatientDocumentData, setFilterPatientDocumentData] = useState(
    []
  );
  const [loading, setLoading] = useState(true);
  const [primaryPosdate, setPrimaryPosdate] = useState(new Date());
  const handleJoiningprimaryPosdate = (newDate) => {
    setPrimaryPosdate(newDate);
  };

  const [primaryToDate, setPrimaryToDate] = useState(new Date());

  const handleToDateChange = (newDate) => {
    setPrimaryToDate(newDate);
  };

  useEffect(() => {
    fetchPatientdocumentData();
  }, []);

  const getMaxDate = (date1) => {
    const date2 = new Date();
    if (!date1) {
      return date2;
    }
    const tempDate = date1 < date2 ? date1 : date2;
    return tempDate;
  };

  const fetchPatientdocumentData = () => {
    console.log("fetch data");

    DashboardServices.getDoctorConsultation(
      userDetails.userId,
      DateUtils.getDateInDDMMYYYY(primaryPosdate),
      DateUtils.getDateInDDMMYYYY(primaryToDate)
    ).then((response) => {
      console.log("response of consul List", response.data);
      setPatientDocumentData(response.data);
      setFilterPatientDocumentData(response.data);
      setLoading(false);
    });
  };

  console.log("patientDocumentData", patientDocumentData);

  return (
    <>
      <div className="AnalyticsDashboardContainer">
        <div className="AnalyticsDashboardSubHeader">
          <IconButton
            onClick={() => {
              handleCloseViewDoctorConsultList();
            }}
          >
            <ArrowBack />
          </IconButton>
          Consultation List
        </div>
        <div className="anDashCardContainArea">
          <div className="fullContainArea">
            <div className="cuHeaderGrp">
              <div className="formSearchGrp">
                <div className="formElement width180">
                  <FormControl className="formControl">
                    <InputLabel id="primPosition" className="setCompletionDate">
                      From Date
                    </InputLabel>
                    <ReactDatePicker
                      toggleCalendarOnIconClick
                      showYearDropdown
                      yearDropdownItemNumber={100}
                      scrollableYearDropdown
                      showMonthDropdown
                      showIcon
                      labelId="primPosition"
                      className="formDatePicker"
                      selected={primaryPosdate}
                      // minDate={new Date()}
                      maxDate={new Date()}
                      dateFormat="dd/MM/yyyy"
                      onChange={(date) => {
                        handleJoiningprimaryPosdate(date);
                      }}
                    />
                  </FormControl>
                </div>
                <div className="formElement width180">
                  <FormControl className="formControl">
                    <InputLabel id="primPosition" className="setCompletionDate">
                      To Date
                    </InputLabel>
                    <ReactDatePicker
                      toggleCalendarOnIconClick
                      showYearDropdown
                      yearDropdownItemNumber={100}
                      scrollableYearDropdown
                      showMonthDropdown
                      showIcon
                      labelId="primPosition"
                      className="formDatePicker"
                      selected={primaryToDate}
                      // minDate={new Date()}
                      // maxDate={new Date()}
                      minDate={primaryPosdate}
                      maxDate={getMaxDate(
                        primaryPosdate
                          ? new Date(
                              primaryPosdate.getFullYear(),
                              primaryPosdate.getMonth() + 3,
                              primaryPosdate.getDate()
                            )
                          : null
                      )}
                      dateFormat="dd/MM/yyyy"
                      onChange={(date) => {
                        handleToDateChange(date);
                      }}
                    />
                  </FormControl>
                </div>
                <Button
                  className="dfultPrimaryBtn"
                  onClick={fetchPatientdocumentData}
                  startIcon={<Search />}
                >
                  Search
                </Button>
              </div>
            </div>

            <div className="cuContainArea">
            {loading ? (
                <div className="center-progress" style={{ height: "65vh" }}>
                  <CircularProgress sx={{ marginTop: "180px" }} />
                </div>
              ) : patientDocumentData.length === 0 ? (
                <div class="noDataCard ">
                  {/* <span>oops</span>No data */}
                  <br />
                  <span className="sallMsg">
                  No Consultation Data is found
                  </span>
                </div>
              ) : (
              <div className="TableContainer">
                <table className="taskListTable">
                  <thead className="taskListtableHeader">
                    <tr>
                      <th>
                        <div className="textWsearch">
                          <span>Patient Name</span>
                        </div>
                      </th>
                      <th>Unit Name</th>
                      <th>Unit Contact Number</th>
                      <th>Consult Start Time</th>
                      <th>consult End Time</th>
                      <th>Prescription Time</th>
                      <th>Waiting Time</th>
                      <th className="width100"></th>
                    </tr>
                  </thead>
                  <tbody className="scrolableTableBody">
                    {patientDocumentData.map((consultList, index) => (
                      <tr key={index}>
                        <td>
                          <div className="tskLstTaskNM fdcolTxt">
                            <span className="headTxt">
                              {consultList.patientName}
                            </span>
                            <span className="subheadTxt">
                              ({consultList.patientGender} ,{" "}
                              {consultList.patientAge})
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className="tskLstTaskNM">
                            {consultList.unitName}
                          </div>
                        </td>
                        <td>
                          <div className="tskLstTaskNM">
                          {consultList.unitContactNo ? consultList.unitContactNo : "No Contact Available"}
                          </div>
                        </td>
                        <td>
                          <div className="tskLstTaskNM">
                            {consultList.consultStartTime}
                          </div>
                        </td>
                        <td>
                          <div className="tskLstTaskNM">
                            {consultList.consultEndTime}
                          </div>
                        </td>
                        <td>
                          <div className="tskLstTaskNM">
                            {consultList.prescriptionTime}
                          </div>
                        </td>
                        <td>
                          <div className="tskLstTaskNM">
                            {consultList.waitingTime}
                          </div>
                        </td>
                        <td>
                            <div className="tblActionBtnGrp">
                            <Tooltip title="View Prescription" arrow>
                              <IconButton>
                                <Visibility />
                              </IconButton>
                              </Tooltip>
                            </div>
                          </td>
                      </tr>
                    ))}
                  </tbody>

                  {/* <tbody className="scrolableTableBody">
                     {patientDocumentData.map((consultList , index)=>{
                     <tr key={index}>
                     <td>
                       <div className="tskLstTaskNM tdFdC">
                         <span className="tdHtext">{consultList.patientName}</span>
                         <span className="tdStext">({consultList.patientGender} , {consultList.patientAge})</span>
                       </div>
                     </td>
                     <td>
                       <div className="tskLstTaskNM">
                       {consultList.unitName}
                       </div>
                     </td>
                     <td>
                       <div className="tskLstTaskNM">
                       {consultList.unitContactNo}
                       </div>
                     </td>
                     <td>
                       <div className="tskLstTaskNM">
                       {consultList.consultStartTime}
                       </div>
                     </td>
                     <td>
                       <div className="tskLstTaskNM">
                       {consultList.consultEndTime}
                       </div>
                     </td>
                     <td>
                       <div className="tskLstTaskNM">
                       {consultList.prescriptionTime}
                       </div>
                     </td>
                     <td>
                       <div className="tskLstTaskNM">
                       {consultList.waitingTime}
                       </div>
                     </td>
                     
                   </tr>
                     })}   
                    </tbody> */}
                  {/* <tbody className="scrolableTableBody">
                    <tr>
                      <td>
                        <div className="tskLstTaskNM tdFdC">
                          <span className="tdHtext">Sk Aftabuddin</span>
                          <span className="tdStext">(Male, 15/Mar/1996)</span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          skaftabuddin@gmail.com
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM tdFdC">
                          <span className="tdHtext">New Town, Kolkata</span>
                          <span className="tdStext">(+91 7384979442)</span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM tdFdC">
                          <span className="tdHtext">Dr. Sanket Santra</span>
                          <span className="tdStext">(Orthopedic Surgeon)</span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          <span className="constStatus statSuccessfull">
                            Completed
                          </span>
                        </div>
                      </td>

                      <td>
                        <div className="tblActionBtnGrp jcc">
                          <IconButton className="dnPresc">
                            <FileEarmarkArrowDownFill />
                          </IconButton>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="tskLstTaskNM tdFdC">
                          <span className="tdHtext">Vikash Kumar</span>
                          <span className="tdStext">(Male, 25/Aug/1993)</span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          vikashkumar@gmail.com
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM tdFdC">
                          <span className="tdHtext">New Town, Kolkata</span>
                          <span className="tdStext">(+91 7384979442)</span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM tdFdC">
                          <span className="tdHtext">Dr. Prashnat Shah</span>
                          <span className="tdStext">(Cardiologists)</span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          <span className="constStatus statCancel">Cancel</span>
                        </div>
                      </td>

                      <td>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="tskLstTaskNM tdFdC">
                          <span className="tdHtext">Sk Aftabuddin</span>
                          <span className="tdStext">(Male, 15/Mar/1996)</span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          skaftabuddin@gmail.com
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM tdFdC">
                          <span className="tdHtext">New Town, Kolkata</span>
                          <span className="tdStext">(+91 7384979442)</span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM tdFdC">
                          <span className="tdHtext">Dr. Sanket Santra</span>
                          <span className="tdStext">(Orthopedic Surgeon)</span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          <span className="constStatus statSuccessfull">
                            Completed
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className="tblActionBtnGrp jcc">
                          <IconButton className="dnPresc">
                            <FileEarmarkArrowDownFill />
                          </IconButton>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <div className="tskLstTaskNM tdFdC">
                          <span className="tdHtext">Rahul Singh</span>
                          <span className="tdStext">(Male, 22/Dec/1988)</span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          rahul.singh88@gmail.com
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM tdFdC">
                          <span className="tdHtext">Sector 5, Noida</span>
                          <span className="tdStext">(+91 9823456789)</span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM tdFdC">
                          <span className="tdHtext">Dr. Anjali Mehra</span>
                          <span className="tdStext">(Cardiologist)</span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          <span className="constStatus statSuccessfull">
                            Completed
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className="tblActionBtnGrp jcc">
                          <IconButton className="dnPresc">
                            <FileEarmarkArrowDownFill />
                          </IconButton>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <div className="tskLstTaskNM tdFdC">
                          <span className="tdHtext">Priya Sharma</span>
                          <span className="tdStext">(Female, 11/Jan/1993)</span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          priya.sharma93@gmail.com
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM tdFdC">
                          <span className="tdHtext">Andheri, Mumbai</span>
                          <span className="tdStext">(+91 9004561230)</span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM tdFdC">
                          <span className="tdHtext">Dr. Suresh Nair</span>
                          <span className="tdStext">(Dermatologist)</span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          <span className="constStatus statSuccessfull">
                            Completed
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className="tblActionBtnGrp jcc">
                          <IconButton className="dnPresc">
                            <FileEarmarkArrowDownFill />
                          </IconButton>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <div className="tskLstTaskNM tdFdC">
                          <span className="tdHtext">Amitabh Chatterjee</span>
                          <span className="tdStext">(Male, 07/Feb/1978)</span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">amitabh78@yahoo.com</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM tdFdC">
                          <span className="tdHtext">Salt Lake, Kolkata</span>
                          <span className="tdStext">(+91 9876543210)</span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM tdFdC">
                          <span className="tdHtext">Dr. Rina Banerjee</span>
                          <span className="tdStext">(Gynecologist)</span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          <span className="constStatus statSuccessfull">
                            Completed
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className="tblActionBtnGrp jcc">
                          <IconButton className="dnPresc">
                            <FileEarmarkArrowDownFill />
                          </IconButton>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <div className="tskLstTaskNM tdFdC">
                          <span className="tdHtext">Rohit Gupta</span>
                          <span className="tdStext">(Male, 10/Jul/1985)</span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          rohit.gupta85@gmail.com
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM tdFdC">
                          <span className="tdHtext">Alwarpet, Chennai</span>
                          <span className="tdStext">(+91 9012345678)</span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM tdFdC">
                          <span className="tdHtext">Dr. Ramesh Kumar</span>
                          <span className="tdStext">(Neurosurgeon)</span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          <span className="constStatus statSuccessfull">
                            Completed
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className="tblActionBtnGrp jcc">
                          <IconButton className="dnPresc">
                            <FileEarmarkArrowDownFill />
                          </IconButton>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <div className="tskLstTaskNM tdFdC">
                          <span className="tdHtext">Sunita Verma</span>
                          <span className="tdStext">(Female, 30/Aug/1990)</span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          sunita.verma90@hotmail.com
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM tdFdC">
                          <span className="tdHtext">Bandra, Mumbai</span>
                          <span className="tdStext">(+91 9123456789)</span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM tdFdC">
                          <span className="tdHtext">Dr. Manish Kapoor</span>
                          <span className="tdStext">(Pulmonologist)</span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          <span className="constStatus statSuccessfull">
                            Completed
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className="tblActionBtnGrp jcc">
                          <IconButton className="dnPresc">
                            <FileEarmarkArrowDownFill />
                          </IconButton>
                        </div>
                      </td>
                    </tr>
                  </tbody> */}
                </table>
              </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
