import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Skeleton,
  Typography,
} from "@mui/material";
import { ThumbsDown, ThumbsUp } from "iconoir-react";
import { React, useEffect, useState } from "react";
import { XLg } from "react-bootstrap-icons";
import RightFlotingAdmin from "../../../../../../admin/view/component/RightFlotingAdmin";
import zhAIService from "../../../../../../../services/ZhAIService";

export default function DrugSafety({ data, closeDrugSafety }) {
  const [drugSafetyData, setDrugSafetyData] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (data.medication && data.medication.length > 0) {
      setLoading(true);
      fetchDrugSafetyData();
    }
  }, [data]);

  const fetchDrugSafetyData = async () => {
    const genericNameIds = data.medication.map((med) => med.genericName.id);
    console.log("genericNameIds", genericNameIds);
    const response = await zhAIService.getDrugSafetyData(genericNameIds);
    console.log("getDrugSafetyData", response.data);
    setDrugSafetyData(response.data);
    setLoading(false);
  };

  // const MedicationInfoCard = ({ info }) => {
  //   return (
  //     <Card
  //       className="drgSafeSkeletonCard"
  //       variant="outlined"
  //       style={{ marginBottom: "16px" }}
  //     >
  //       <CardContent className="drgSafeSkeletonCardContent">
  //         {info.contraindications && (
  //           <>
  //             <Typography variant="h6" gutterBottom>
  //               Contraindications
  //             </Typography>
  //             <Typography variant="body1" paragraph className="op7">
  //               {info.contraindications}
  //             </Typography>
  //             <Divider />
  //           </>
  //         )}
  //         {info.adverse_reactions && (
  //           <>
  //             <Typography variant="h6" gutterBottom>
  //               Adverse Reactions
  //             </Typography>
  //             <Typography variant="body1" paragraph className="op7">
  //               {info.adverse_reactions}
  //             </Typography>
  //             <Divider />
  //           </>
  //         )}
  //         {info.drug_interactions && (
  //           <>
  //             <Typography variant="h6" gutterBottom>
  //               Drug Interactions
  //             </Typography>
  //             <Typography variant="body1" className="op7">
  //               {info.drug_interactions}
  //             </Typography>
  //           </>
  //         )}
  //       </CardContent>
  //     </Card>
  //   );
  // };

  // const MedicationInfoList = ({ data, loading }) => {
  //   const isEmpty = data.every(
  //     (item) =>
  //       !item.contraindications &&
  //       !item.adverse_reactions &&
  //       !item.drug_interactions
  //   );

  //   if (loading) {
  //     return (
  //       <div className="drgSafeSkeleton">
  //         {Array.from(new Array(3)).map((_, index) => (
  //           <Card
  //             className="drgSafeSkeletonCard"
  //             variant="outlined"
  //             style={{ marginBottom: "16px" }}
  //             key={index}
  //           >
  //             <CardContent className="drgSafeSkeletonCardContent">
  //               <Skeleton variant="text" width="60%" height={30} />
  //               <Skeleton variant="text" width="100%" height={15} />
  //               <Skeleton variant="text" width="100%" height={15} />
  //               <Skeleton variant="text" width="80%" height={15} />
  //               <Divider />
  //               <Skeleton
  //                 variant="text"
  //                 width="60%"
  //                 height={30}
  //                 style={{ marginTop: "16px" }}
  //               />
  //               <Skeleton variant="text" width="100%" height={15} />
  //               <Skeleton variant="text" width="80%" height={15} />
  //               <Divider />
  //               <Skeleton
  //                 variant="text"
  //                 width="60%"
  //                 height={30}
  //                 style={{ marginTop: "16px" }}
  //               />
  //               <Skeleton variant="text" width="100%" height={15} />
  //               <Skeleton variant="text" width="90%" height={15} />
  //             </CardContent>
  //           </Card>
  //         ))}
  //       </div>
  //     );
  //   }

  //   if (isEmpty) {
  //     return (
  //       <Box textAlign="center" mt={4}>
  //         <Typography variant="h6" style={{ color: "white" }}>
  //           No data found
  //         </Typography>
  //       </Box>
  //     );
  //   }

  //   return (
  //     <div>
  //       {data.map((item, index) => (
  //         <MedicationInfoCard key={index} info={item} />
  //       ))}
  //     </div>
  //   );
  // };

  const DrugInfo = ({ drugSafetyDataTemp }) => {
    // Filter out null values from the data
    const filteredData = Object.entries(drugSafetyDataTemp).filter(
      ([key, value]) =>
        value !== null &&
        value !== "" &&
        key !== "active_flag" &&
        key !== "generic_name"
    );

    return (
      <Grid container spacing={3}>
        {filteredData.map(([key, value]) => (
          <Grid item xs={12} md={6} key={key}>
            <Card>
              <CardContent>
                <Typography variant="h5" color="textPrimary">
                  {key.replace(/_/g, " ")}
                </Typography>
                <Typography variant="body2">{value}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  };

  const DrugInfoSkeleton = () => {
    // Placeholder skeletons to display while data is loading
    return (
      <Grid container spacing={3}>
        {Array.from(new Array(8)).map((_, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Card>
              <CardContent>
                <Skeleton variant="text" width={150} />
                <Skeleton variant="text" height={40} />
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  };
  return (
    <>
      <RightFlotingAdmin className="ddFlotContainer">
        <div className="flotingAreaContainer">
          <div className="flotingAreaClose">
            <div className="facHeader">Drug Safety</div>
            <IconButton className="CloseBtn" onClick={closeDrugSafety}>
              <XLg />
            </IconButton>
          </div>

          <div className="elementFormContainer">
            {loading ? (
              <DrugInfoSkeleton />
            ) : drugSafetyData ? (
              <DrugInfo drugSafetyDataTemp={drugSafetyData} />
            ) : (
              <Typography>No data found</Typography>
            )}
          </div>

          <div className="elementFooter">
            <div className="formBtnElement">
              <Button
                className="dfultDarkSecondaryBtn"
                onClick={closeDrugSafety}
              >
                Close
              </Button>
            </div>
          </div>
        </div>
      </RightFlotingAdmin>
    </>
  );
}
