import { Add, ExpandMoreRounded } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  IconButton,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Download, Eye, Trash } from "react-bootstrap-icons";
import { downloadFileFromUrl } from "../../../../../../../utils/FileUtils";
import { CustomModal } from "../../../../../../task/view/component/Modals/CustomModal";
import PdfViewer from "../../../../../../../common/PdfViewer";
import { useTranslation } from "react-i18next";
import zhPatientService from "../../../../../../../services/ZhPatientService";

export default function TestResult({ data, extraData }) {
  const { t } = useTranslation();
  const [accordionExpanded, setAccordionExpanded] = useState(true);
  const [showPdf, setShowPdf] = useState(false);
  const pdfLink = useRef();
  const [testResult, setTestResult] = useState(null);

  const getTestResult = () => {
    if (extraData?.queuePatientId && data?.investigation) {
      let reqDto = data?.investigation?.map((item) => {
        return item.testId;
      });
      zhPatientService
        .getTestResultsExceptRequisitionIds(extraData.queuePatientId, reqDto)
        .then((response) => {
          if (response.data) {
            setTestResult(response.data);
          }
        });
    }
  };

  useEffect(() => {
    getTestResult();
  }, []);

  return (
    <>
      {testResult?.length > 0 && (
        <Accordion
          className="docInputElmntAcod"
          expanded={accordionExpanded}
          onChange={() => {
            setAccordionExpanded((prev) => !prev);
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreRounded />}
            aria-controls="panel1-content"
            id="panel1-header"
            className="acodHeader"
          >
            <div className="tskElemHeding">
              {t("test_result")}:
              <span>{/*symptoms gose here when acordion not expend*/}</span>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="testResult">
              <table className="testResulttable">
                <thead>
                  <tr>
                    <th className="w50p">{t("test_name")}</th>
                    <th className="w15p textCenter">{t("value")}</th>
                    <th className="w15p textCenter">{t("unit")}</th>
                    <th className="w20p">{t("bio_ref_interval")}</th>
                  </tr>
                </thead>
                <tbody>
                  {testResult?.map((item) => {
                    return (
                      <>
                        <tr>
                          <td colSpan={4} className="singlRow">
                            <div className="testNameH">{item.testName}</div>
                            {item.fileData && (
                              <>
                                {/* <span
                                onClick={() => {
                                  setShowPdf(true);
                                  pdfLink.current = item.fileData;
                                }}
                              >
                                <Eye />
                              </span> */}
                                <span
                                  onClick={() => {
                                    downloadFileFromUrl(
                                      item.fileData,
                                      `${extraData.queuePatientName}_(${extraData.queuePatientId})_${item.testName}`
                                    );
                                  }}
                                >
                                  <Download />
                                </span>
                              </>
                            )}
                          </td>
                        </tr>
                        {item?.testResult?.map((parItem) => {
                          return (
                            <tr>
                              <td>
                                <div className="testElmGrp">
                                  <div className="testElmNM">
                                    {parItem.parameterName}
                                  </div>
                                  <div className="testElmDtl">
                                    Method: ENZymatic
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="testValue textCenter riskValue">
                                  {parItem.parameterValue}
                                </div>
                              </td>
                              <td>
                                <div className="testUnit textCenter">
                                  {parItem.parameterUnit}
                                </div>
                              </td>
                              <td>
                                {/* <div className="testNrmlRange">
                                  {parItem.parameterRange && (
                                    <div className="tstnRangeElm">
                                      Desirable : &lt;{" "}
                                      {parItem.parameterRange.split("-")[0]}
                                    </div>
                                  )}
                                  <div className="tstnRangeElm">
                                    Borderline: {parItem.parameterRange}
                                  </div>

                                  {parItem.parameterRange && (
                                    <div className="tstnRangeElm">
                                      High : &gt;/=
                                      {parItem.parameterRange.split("-")[1]}
                                    </div>
                                  )}
                                </div> */}
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="bb"></div>
          </AccordionDetails>
        </Accordion>
      )}
      {showPdf && (
        <CustomModal
          modalTitle="Test Result"
          // style={{ zIndex: 10001 }}
          closeModal={() => {
            setShowPdf(false);
          }}
          className={"width90vwModel"}
        >
          <PdfViewer pdfUrl={pdfLink.current} />
        </CustomModal>
      )}
    </>
  );
}
