import { current } from "immer";
import styled, { css, keyframes } from "styled-components";
// import RecordSound from "./assets/media/rec_start.mp3";
import { toast } from "react-toastify";
import jwtDecode from "jwt-decode";
import { convertBlobToBase64, getLoginUserDetails } from "../utils/Utils";
import DashboardServices from "../services/DashboardServices";
import { Microphone, MicrophoneMute, Trash, Xmark } from "iconoir-react";
import { Button, IconButton, Tooltip } from "@mui/material";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import useSound from "use-sound";
import { FiberManualRecord } from "@mui/icons-material";

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`;

const RecordButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => (props.recording ? "#ff1c1c" : "#fff")};
  color: ${(props) => (props.recording ? "#fff" : "#000")};
  border: none;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  margin: 10px;
  font-size: 30px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  animation: ${(props) =>
    props.recording
      ? css`
          ${pulse} 1s infinite
        `
      : "none"};
`;

const RecordAudio = (props) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [recording, setRecording] = useState(false);
  const mediaRecorder = useRef(null);
  const audioChunks = useRef([]);
  const canvasRef = useRef();
  const audioPlayer = useRef();
  const [isPlaying, setIsPlaying] = useState(false);
  // const [playSound, { stop }] = useSound(RecordSound);
  const [recordingStartTime, setRecordingStartTime] = useState(null);
  const [recordingEndTime, setRecordingEndTime] = useState(null);
  const [audioNoteTime, setAudioNoteTime] = useState(0);
  const loginUserDetails = getLoginUserDetails();
  const shouldAddRecording = useRef(true);

  const allowedRecordingTimeLength = 15 * 1000;

  useEffect(() => {
    if (props.recordingLength > 0) {
      setAudioNoteTime(props.recordingLength);
    }
    if (!props.tempAudioChunk.current) {
      console.log("setting up temp AudioChunk");
      props.tempAudioChunk.current = [];
    }
    return () => {
      stopAudio();
      stopRecording();
    };
  }, []);

  function drawWaveform(analyser) {
    const canvas = canvasRef.current;
    const canvasCtx = canvas.getContext("2d");
    canvasCtx.lineWidth = 2;
    // Create a Uint8Array to store the waveform data
    const bufferLength = analyser.fftSize;
    const dataArray = new Uint8Array(bufferLength);

    // Set up the canvas dimensions and colors
    const canvasWidth = canvas.width;
    const canvasHeight = canvas.height;
    canvasCtx.clearRect(0, 0, canvasWidth, canvasHeight);
    canvasCtx.fillStyle = "#fff";
    // canvasCtx.strokeStyle = 'rgb(0, 0, 0)';
    canvasCtx.strokeStyle = "#8683ff";
    canvasCtx.lineWidth = 5;

    // Draw the waveform
    function draw() {
      requestAnimationFrame(draw);

      analyser.getByteTimeDomainData(dataArray);

      canvasCtx.fillRect(0, 0, canvasWidth, canvasHeight);
      canvasCtx.beginPath();
      const sliceWidth = canvasWidth / bufferLength;
      let x = 0;
      for (let i = 0; i < bufferLength; i++) {
        const v = dataArray[i] / 128.0;
        const y = (v * canvasHeight) / 2;
        if (i === 0) {
          canvasCtx.moveTo(x, y);
        } else {
          canvasCtx.lineTo(x, y);
        }
        x += sliceWidth;
      }
      canvasCtx.stroke();
    }

    draw();
  }

  const startRecording = () => {
    if (audioNoteTime >= allowedRecordingTimeLength) {
      toast.error("You cannot record audio for more than 15 sec", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    let context = new AudioContext();
    const analyser = context.createAnalyser();
    // Set the FFT size to 2048 for higher frequency resolution
    analyser.fftSize = 256;
    console.log("Recording Started");
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        const source = context.createMediaStreamSource(stream);
        source.connect(analyser);
        drawWaveform(analyser);
        mediaRecorder.current = new MediaRecorder(stream);
        mediaRecorder.current.start();
        let startTime = new Date();
        // let endTime = new Date();

        // if (!recordingEndTime) {
        //   setRecordingEndTime(endTime);
        // } else {
        //   endTime = recordingEndTime;
        // }

        let endTime = new Date();

        const intervalIdTemp = setInterval(() => {
          // setRecordingEndTime(currentTime);
          endTime = new Date(endTime.getTime() + 1000);
          setRecordingEndTime(endTime);

          const recordingTime = Math.abs(
            props.recordingLength + endTime.getTime() - startTime.getTime()
          );
          setAudioNoteTime(recordingTime);
          props.setRecordingLength(recordingTime);
          if (recordingTime >= allowedRecordingTimeLength) {
            toast.error("You cannot record for more than 15 secs", {
              position: toast.POSITION.TOP_RIGHT,
            });
            stopRecording();
          }
        }, 1000);

        // playSound();
        // setTimeout(() => {
        //   stop();
        // }, 1000);

        //EVENT LISTENER WHEN RECORDING HAS STOPPED AND DATA IS AVAILABLE
        mediaRecorder.current.addEventListener("dataavailable", (event) => {
          if (shouldAddRecording.current === false) {
            if (props.tempAudioChunk.current.length == 0) {
              props.setRecordingLength(0);
            }
            return;
          }
          console.log("Recording addEventListener ", mediaRecorder, event);
          audioChunks.current.push(event.data);
          props.tempAudioChunk.current.push(event.data);
          console.log(audioChunks);
          console.log(props.tempAudioChunk.current);
        });
        //EVENT LISTENER WHEN RECORDING HAS STOPPED AND DATA IS AVAILABLE

        //EVENT LISTENER WHEN RECORDING HAS STOPPED
        mediaRecorder.current.addEventListener("stop", async () => {
          console.log("Recording Stopped", audioChunks, current);
          clearInterval(intervalIdTemp);
          // playSound();
          // setTimeout(() => {
          //   stop();
          // }, 100);
          setIsProcessing(true);
          setRecording(false);
          stream.getTracks().forEach(function (track) {
            track.stop();
          });
          const base64Data = await handleAudio();
          DashboardServices.uploadAudioBlog({
            base64Audio: base64Data,
            orgId: loginUserDetails.orgId,
          }).then((response) => {
            console.log("response=", response);
            props.setRecordingLink(response.data);
            setIsProcessing(false);
          });
        });
        //EVENT LISTENER WHEN RECORDING HAS STOPPED

        setRecording(true);
      })
      .catch((error) => console.error(error));
  };

  const handleAudio = async () => {
    return new Promise((resolve, reject) => {
      let audioFileDataBase64 = undefined;
      if (props.tempAudioChunk.current.length > 0) {
        // const combinedTempBlob = getSingleBlob()
        const audioBlob = new Blob(props.tempAudioChunk.current, {
          type: "audio/wav",
        });
        if (audioBlob) {
          convertBlobToBase64(audioBlob)
            .then((base64Data) => {
              audioFileDataBase64 = base64Data;
              resolve(audioFileDataBase64);
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          resolve(audioFileDataBase64);
        }
      } else {
        resolve(audioFileDataBase64);
      }
    });
  };

  const stopRecording = () => {
    console.log("Recording Stopped", mediaRecorder);
    mediaRecorder.current && mediaRecorder.current.stop();
  };

  const submitVoiceNote = () => {
    console.log("submitVoiceNote", audioChunks.current);
    const audioBlob = new Blob(audioChunks.current, {
      type: "audio/wav",
    });
    const formData = new FormData();
    formData.append(
      "audio",
      audioBlob,
      `recording_${new Date().getTime()}.wav`
    );

    fetch("http://localhost:8080/ahlanapi/appTest/api/messages", {
      method: "POST",
      body: formData,
    })
      .then(() => (audioChunks.current = []))
      .catch((error) => console.error(error));
  };

  const playAudio = () => {
    setIsPlaying(true);
    if (!recording) {
      toast.info("Playing Audio Note", {
        position: toast.TOP_RIGHT,
      });
      // const data = props.tempAudioChunk.current;
      // const blob = new Blob(data);
      // const url = URL.createObjectURL(blob);
      audioPlayer.current = new Audio();
      audioPlayer.current.src = props.recordingLink;
      audioPlayer.current.addEventListener("ended", () => {
        setIsPlaying(false);
      });
      audioPlayer.current.play();
    }
  };

  const stopAudio = () => {
    if (audioPlayer.current) {
      audioPlayer.current.pause();
      audioPlayer.current.currentTime = 0;
      setIsPlaying(false);
    }
  };

  const timeFormatterHHMMSS = (milliseconds) => {
    const totalSecond = milliseconds / 1000;
    console.log(totalSecond);
    const hours = Math.floor(totalSecond / 3600);
    const minutes = Math.floor((totalSecond % 3600) / 60);
    const seconds = Math.floor(totalSecond % 60);

    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = seconds.toString().padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };

  return (
    <>
      <div className="ryvnHeading">Record your voice Notes</div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          width: "100%",
        }}
      >
        {/* {props.tempAudioChunk.current.length === 0 &&
          <> */}
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <RecordButton
            recording={recording}
            onClick={(event) => {
              event.preventDefault();
              !recording && startRecording();
              // recording ? stopRecording() : startRecording();
            }}
            type="button"
          >
            {recording ? <FiberManualRecord /> : <Microphone />}
          </RecordButton>

          {audioNoteTime === 0 ? (
            <div style={{ width: "50px" }}>{`00:00:00`}</div>
          ) : (
            <div style={{ width: "50px" }}>
              {timeFormatterHHMMSS(audioNoteTime)}
            </div>
          )}
        </div>
        <canvas
          ref={canvasRef}
          style={{ flex: "1", height: "40px", color: "orange" }}
        ></canvas>
        {recording && (
          <RecordButton
            recording={recording}
            onClick={(event) => {
              event.preventDefault();
              recording ? stopRecording() : startRecording();
            }}
            type="button"
          >
            {" "}
            <MicrophoneMute />
          </RecordButton>
        )}
        {/* </>
        } */}
      </div>

      <div className="fullWidth">
        {isProcessing && (
          <span className="recodedAudioNots">Processing your recording</span>
        )}
        {props.tempAudioChunk.current.length > 0 && !isProcessing && (
          <>
            <div
              className="fullWidth"
              style={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div
                className="fullWidth"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <span className="recodedAudioNots">Recorded Audio Note</span>
              </div>
              {!recording && (
                <>
                  {/* {isPlaying ? (
                    <StopIcon
                      onClick={() => {
                        stopAudio();
                      }}
                      style={{ fontSize: 36, color: "red", cursor: "pointer" }}
                    />
                  ) : (
                      <PlayArrowRounded
                          onClick={() => {
                            // playAudio();
                          }}
                          style={{ fontSize: 36, cursor: "pointer" }}
                        />
                  )} */}
                  <div className="playRemoveAudioNotes fullWidth">
                    <audio controls className="fullWidth">
                      <source src={`${props.recordingLink}`} />
                    </audio>

                    <div>
                      <Tooltip title={"Delete Audio Note"} placement="right">
                        {/* <IconButton
                      edge="end"
                      aria-label="Visited"
                      disabled={recording}
                      onClick={() => {
                        stopAudio();
                        props.setRecordingLength(0);
                        setAudioNoteTime(0);
                        props.tempAudioChunk.current = [];
                      }}
                    >
                      <Trash style={{ color: "red" }} />
                    </IconButton> */}
                        <IconButton
                          className="removeBtn"
                          disabled={recording}
                          onClick={() => {
                            stopAudio();
                            props.setRecordingLength(0);
                            setAudioNoteTime(0);
                            props.tempAudioChunk.current = [];
                          }}
                        >
                          <Trash />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
      <div className="elementFooter" style={{ width: "calc(100% - 20px)" }}>
        <div className="formBtnElement">
          <Button
            className="dfultPrimaryBtn"
            onClick={() => {
              shouldAddRecording.current = false;
              // stopRecording();
              props.closeDialog();
            }}
          >
            {/* {`${props.tempAudioChunk.current.length > 0 ? "Close" : "Cancel "}`} */}
            {"Close"}
          </Button>
          {/* <Button
            className="dfultDarkSecondaryBtn"
            onClick={() => props.closeDialog()}
          >
            Cancel
          </Button> */}
        </div>
      </div>
    </>
  );
};

export default RecordAudio;
