import {
  ArrowBackIosNew,
  ArrowForwardIos,
  Cancel,
  DescriptionOutlined,
  Download,
  Edit,
  Refresh,
  Save,
} from "@mui/icons-material";
import React, { useEffect, useRef, useState } from "react";
import { Play, Plus } from "react-bootstrap-icons";
import Comorbidity from "./Comorbidity";
import DrugAllergy from "./DrugAllergy";
import FamilyHistory from "./FamilyHistory";
import FoodAllergy from "./FoodAllergy";
import HistoryRiskFactor from "./HistoryRiskFactor";
import Investigations from "./Investigations";
import Medication from "./Medication";
import PossibleDiagnosis from "./PossibleDiagnosis";
import Signs from "./Signs";
import Symptoms from "./Symptoms";
import TestResult from "./TestResult";
import Diagnosis from "./Diagnosis";
import Recommendations from "./Recommendations";
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import OtherOptions from "./OtherOptions";
import { downloadFileFromUrl } from "../../../../../../../utils/FileUtils";
import {
  getHealthDto,
  getVitalSvgAccordingToVitalType,
} from "./consultationUtil";
import DashboardServices from "../../../../../../../services/DashboardServices";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { CustomModal } from "../../../../../../task/view/component/Modals/CustomModal";
import RightFlotingAdmin from "../../../../../../admin/view/component/RightFlotingAdmin";
import DifferentialDiagnosis from "./DifferentialDiagnosis";
import { useTranslation } from "react-i18next";
import PhysicalExamination from "./PhysicalExamination";
import axios from "axios";
import zhAIService from "../../../../../../../services/ZhAIService";
import DrugSafety from "./DrugSafety";
import TestResultInput from "./TestResultInput";
import PatientFileUpload from "../../../../../../analyticsDashboard/view/component/healthcare/PatientFileUpload";
import zhPatientService from "../../../../../../../services/ZhPatientService";
import { getLoginUserDetails } from "../../../../../../../utils/Utils";
import { centerSocket, doctorSocket } from "../../../../../../../socket";

export default function PrescriptionEdit({ updateData, data, extraData }) {
  const { t } = useTranslation();
  const loginUserDetails = getLoginUserDetails();
  const [vitals, setVitals] = useState([]);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const audioUrl = useRef();
  const [height, setHeight] = useState(null);
  const [weight, setWeight] = useState(null);
  const [bmi, setBmi] = useState("");
  const [heightOutput, setHeightOutput] = useState("");
  const [weightOutput, setWeightOutput] = useState("");
  const [bmiClass, setBmiClass] = useState("");
  const [selectedGroup, setSelectedGroup] = useState(1);
  const [pescriptionData, setPescriptionData] = useState({});
  const [patientType, setPatientType] = useState("statNormal");
  const [vitalUnitList, setVitalUnitList] = useState([]);

  const [vitalUnitMap, setVitalUnitMap] = useState(null);
  const [showAudioModal, setShowAudioModal] = useState(false);

  const [lmp, setLmp] = useState(null);

  const [VitalErrorList, setVitalErrorList] = useState([]);
  const [showFileUploadDialog, setShowFileUploadDialog] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [previousMedicalRecords, setPreviousMedicalRecords] = useState(null);
  const [prevDocuments, setPreviousDocuments] = useState([]);

  const calculateBmi = () => {
    const heightInMeters = height / 100;
    const bmiValue = parseFloat(weight / heightInMeters ** 2).toFixed(2);
    const bmiGroupIndex = [
      [0, 18.49], //under weight
      [18.5, 24.99], //normal
      [25, 29.99], //overweight
      [30, 34.99], //
      [35, 39.99],
      [40, 600],
    ].findIndex((e) => e[0] <= bmiValue && bmiValue < e[1]);

    const bmiGroupNames = [
      "Underweight",
      "Normal",
      "Pre-obesity",
      "Obese I",
      "Obese II",
      "Obese III",
    ];

    const heightInInches = (height * 0.393700787).toFixed(0);
    const feet = Math.floor(heightInInches / 12);
    const inches = heightInInches % 12;

    setHeightOutput(`${height} cm / ${feet}' ${inches}"`);
    setWeightOutput(`${weight} kg / ${(2.2046 * weight).toFixed(2)} lb`);
    setBmi(bmiValue);
    setSelectedGroup(bmiGroupIndex);

    updateData({ bmiValue: bmiValue });
    updateData({ bmiType: bmiGroupNames[bmiGroupIndex] });

    const bmiClasses = [
      "bmi-underweight",
      "bmi-normal",
      "bmi-preobesity",
      "bmi-obese1",
      "bmi-obese2",
      "bmi-obese3",
    ];
    setBmiClass(bmiClasses[bmiGroupIndex]);
  };

  const convertHeightInCm = (value, unit) => {
    if (unit === "m") {
      return value * 100;
    } else if (unit === "cm") {
      return value;
    }
  };

  const convertWeightInKg = (value, unit) => {
    if (unit === "kgs") {
      return value;
    } else if (unit === "lbs") {
      return value * 0.45359237;
    }
  };

  const getAge = () => {
    const ageComponent = extraData.queuePatientAge.split(" ");
    if (ageComponent[0].includes("Y")) {
      return parseInt(ageComponent[0].split("Y")[0]) || 0;
    } else {
      return 0;
    }
  };

  const handleChangeSistolic = (index, e) => {
    let value = e.target.value;
    let tempVital = [...vitals];
    let sistolic = tempVital[index].updatedData.vitalValue.split("/")[0];
    let diastolic = tempVital[index].updatedData.vitalValue.split("/")[1];

    if (value === "") {
      const tempValue = `${value}/${diastolic}`;
      tempVital[index].updatedData.vitalValue = tempValue;
      setVitals(tempVital);
      return;
    }
    if (value) {
      value = value.slice(0, 3);
    }

    sistolic = parseInt(value);
    if (isNaN(sistolic)) {
      return;
    }

    const tempValue = `${sistolic}/${diastolic}`;
    tempVital[index].updatedData.vitalValue = tempValue;

    if (sistolic > 140 || sistolic < 90 || diastolic > 95 || diastolic < 61) {
      tempVital[index].isNormal = "N";
    } else {
      tempVital[index].isNormal = "Y";
    }

    setVitals(tempVital);
  };

  const handleChangeDiastolic = (index, e) => {
    let value = e.target.value;
    let tempVital = [...vitals];
    let sistolic = tempVital[index].updatedData.vitalValue.split("/")[0];
    let diastolic = tempVital[index].updatedData.vitalValue.split("/")[1];

    if (value === "") {
      const tempValue = `${sistolic}/${value}`;
      tempVital[index].updatedData.vitalValue = tempValue;
      setVitals(tempVital);
      return;
    }

    if (value) {
      value = value.slice(0, 3);
    }

    diastolic = parseInt(value);
    if (isNaN(diastolic)) {
      return;
    }

    const tempValue = `${sistolic}/${diastolic}`;
    tempVital[index].updatedData.vitalValue = tempValue;

    if (sistolic > 140 || sistolic < 90 || diastolic > 95 || diastolic < 61) {
      tempVital[index].isNormal = "N";
    } else {
      tempVital[index].isNormal = "Y";
    }

    tempVital[index].updatedData.vitalValue = tempValue;
    setVitals(tempVital);
  };

  const validateInput = (value, type, index) => {
    if (type === "SPO2") {
      validateSpo2(value, index);
    } else if (type === "RESPIRATION_RATE") {
      validateRR(value, index);
    } else if (type === "HEART_RATE") {
      validatePulse(value, index);
    } else if (type === "BP") {
      validatePressure(value, index);
    } else if (type === "HEIGHT") {
      validateHeight(value, index);
    } else if (type === "TEMPERATURE") {
      validateTemparature(value, index);
    } else if (type === "WEIGHT") {
      validateWeight(value, index);
    } else {
      const newValue = [...vitals];
      newValue[index].updatedData.vitalValue = value;
      setVitals(newValue);
    }
  };

  const validateTemparature = (value, index) => {
    let tempVitals = [...vitals];
    let spotIndex = index;

    let tmparr = value.split(".");
    let tmpBD = "";
    let tmpAD = "";

    let tmpTemparature = "";

    const unit = tempVitals[index].updatedData.unit;

    if (tmparr[0] && tmparr[0].length > 0) {
      tmpBD = tmparr[0];
    }
    if (tmparr[1] && tmparr[1].length > 0) {
      tmpAD = tmparr[1];
    }

    if (unit.includes("F")) {
      if (tmparr[0] && tmparr[0].length > 3) {
        tmpBD = tmparr[0].slice(0, 3);
      }
      if (tmparr[1] && tmparr[1].length > 2) {
        tmpAD = tmparr[1].slice(0, 2);
      }

      if (value.includes(".")) {
        if (tmpAD.length === 0) {
          tmpTemparature = tmpBD + ".";
        } else {
          tmpTemparature = `${tmpBD}.${tmpAD}`;
        }
      } else {
        tmpTemparature = tmpBD;
      }

      tempVitals[index].updatedData.vitalValue = tmpTemparature;

      if (parseFloat(tmpTemparature) < 95 || parseFloat(tmpTemparature) > 99) {
        tempVitals[index].isNormal = "N";
      } else {
        tempVitals[index].isNormal = "Y";
      }
    } else if (unit.includes("C")) {
      if (tmparr[0] && tmparr[0].length > 3) {
        tmpBD = tmparr[0].slice(0, 2);
      }
      if (tmparr[1] && tmparr[1].length > 2) {
        tmpAD = tmparr[1].slice(0, 2);
      }

      if (value.includes(".")) {
        if (tmpAD.length === 0) {
          tmpTemparature = tmpBD + ".";
        } else {
          tmpTemparature = `${tmpBD}.${tmpAD}`;
        }
      } else {
        tmpTemparature = tmpBD;
      }

      tempVitals[index].updatedData.vitalValue = tmpTemparature;

      if (parseFloat(tmpTemparature) < 36 || parseFloat(tmpTemparature) > 39) {
        tempVitals[index].isNormal = "N";
      } else {
        tempVitals[index].isNormal = "Y";
      }
    }
    setVitals(tempVitals);
  };

  const validateWeight = (value, index) => {
    let tempVitals = [...vitals];
    let spotIndex = index;

    let weight = parseFloat(value);

    let wtarr = value.split(".");
    let wtBD = "";
    let wtAD = "";

    if (wtarr[0] && wtarr[0].length > 0) {
      wtBD = wtarr[0];
    }
    if (wtarr[1] && wtarr[1].length > 0) {
      wtAD = wtarr[1];
    }

    if (wtarr[0] && wtarr[0].length > 3) {
      wtBD = wtarr[0].slice(0, 3);
    }
    if (wtarr[1] && wtarr[1].length > 2) {
      wtAD = wtarr[1].slice(0, 2);
    }

    if (value === "") {
      tempVitals[index].updatedData.vitalValue = value;
      setVitals(tempVitals);
      return;
    }

    if (!weight || isNaN(weight)) {
      return;
    }

    let tmpWeight = "";

    if (value.includes(".")) {
      if (wtAD.length === 0) {
        tmpWeight = wtBD + ".";
      } else {
        tmpWeight = `${wtBD}.${wtAD}`;
      }
    } else {
      tmpWeight = wtBD;
    }

    tempVitals[spotIndex].updatedData.vitalValue = tmpWeight;

    setVitals(tempVitals);
  };

  const validateHeight = (value, index) => {
    let tempVitals = [...vitals];
    let spotIndex = index;
    const unit = tempVitals[index].updatedData.unit;
    let height = parseFloat(value);

    let htarr = value.split(".");
    let htBD = "";
    let htAD = "";

    if (htarr[0] && htarr[0].length > 0) {
      htBD = htarr[0];
    }
    if (htarr[1] && htarr[1].length > 0) {
      htAD = htarr[1];
    }

    if (value === "") {
      tempVitals[index].updatedData.vitalValue = value;
      setVitals(tempVitals);
      return;
    }

    if (!height || isNaN(height)) {
      return;
    }

    if (unit === "cm") {
      if (htarr[0] && htarr[0].length > 3) {
        htBD = htarr[0].slice(0, 3);
      }
      if (htarr[1] && htarr[1].length > 2) {
        htAD = htarr[1].slice(0, 2);
      }
      let tempStr = "";
      if (value.includes(".")) {
        if (htAD.length === 0) {
          tempStr = htBD + ".";
        } else {
          tempStr = `${htBD}.${htAD}`;
        }
      } else {
        tempStr = htBD;
      }

      tempVitals[index].updatedData.vitalValue = tempStr;
    } else if (unit === "ft~in") {
      tempVitals[index].updatedData.vitalValue = value;
    }
    setVitals(tempVitals);
  };

  const validateSpo2 = (value, index) => {
    // let spo2arr = value.split(".");
    // let spoBD = "";
    // let spoAD = "";
    let tempVitals = [...vitals];
    let spotIndex = index;

    let [spoBD, spoAD] = value.split(".").map((part) => part || "");

    if (!spoAD) {
      spoAD = "";
    }

    if (spoBD.length > 2) {
      if (parseFloat(spoBD) === 100) {
        spoBD = "100";
        spoAD = "";
      } else {
        spoBD = spoBD.slice(0, 2);
      }
    }

    if (spoAD.length > 2 && parseFloat(spoAD) > 0) {
      if (parseFloat(spoBD) >= 100) {
        spoAD = "";
      } else {
        spoAD = spoAD.slice(0, 2);
      }
    }

    let temp = "";
    if (spoAD.length > 0) {
      temp = `${spoBD}.${spoAD}`;
    } else {
      temp = value.includes(".") ? `${spoBD}.` : spoBD;
    }

    tempVitals[spotIndex].updatedData.vitalValue = temp;

    let spo = value.split(".");
    if (parseFloat(spo[0]) >= 95 && parseFloat(spo[0]) <= 100) {
      tempVitals[spotIndex].isNormal = "Y";
    } else if (parseFloat(spo[0]) >= 91 && parseFloat(spo[1]) <= 94) {
      tempVitals[spotIndex].isNormal = "M";
    } else {
      tempVitals[spotIndex].isNormal = "Y";
    }
    setVitals(tempVitals);
  };

  const validateRR = (value, index) => {
    let tempVitals = [...vitals];
    let spotIndex = index;
    const rrValue = parseFloat(value.slice(0, 3));

    if (value === "") {
      tempVitals[spotIndex].updatedData.vitalValue = value;
      setVitals(tempVitals);
    }

    if (!rrValue || isNaN(rrValue) || rrValue < 0) {
      return;
    }

    tempVitals[spotIndex].updatedData.vitalValue = rrValue;
    if (rrValue > 20 || rrValue < 10) {
      tempVitals[spotIndex].isNormal = "N";
    } else {
      tempVitals[spotIndex].isNormal = "Y";
    }
    setVitals(tempVitals);
  };

  const validatePulse = (value, index) => {
    let tempVitals = [...vitals];
    let spotIndex = index;
    const prValue = parseFloat(value.slice(0, 3));

    if (value === "" || value === null) {
      tempVitals[spotIndex].updatedData.vitalValue = value;
      tempVitals[spotIndex].isNormal = "Y";
      setVitals(tempVitals);
    }

    if (!prValue || isNaN(prValue) || prValue < 0) {
      return;
    }
    tempVitals[spotIndex].updatedData.vitalValue = prValue;

    const age = getAge();

    if (age >= 6 && age <= 12) {
      if (prValue >= 70 && prValue <= 120) {
        tempVitals[spotIndex].isNormal = "Y";
      } else if (prValue >= 121 && prValue <= 140) {
        tempVitals[spotIndex].isNormal = "M";
      } else {
        tempVitals[spotIndex].isNormal = "N";
      }
    } else {
      if (prValue >= 60 && prValue <= 100) {
        tempVitals[spotIndex].isNormal = "Y";
      } else if (prValue >= 101 && prValue <= 120) {
        tempVitals[spotIndex].isNormal = "M";
      } else {
        tempVitals[spotIndex].isNormal = "N";
      }
    }

    setVitals(tempVitals);
  };

  const validatePressure = (value, index) => {
    let tempVitals = [...vitals];
    let spotIndex = index;
    const sistolicDiastolicList = value.split("/");
    const sistolic = parseInt(sistolicDiastolicList[0]);
    const diastolic = parseInt(sistolicDiastolicList[1]);

    if (!sistolic || isNaN(sistolic) || !diastolic || isNaN(diastolic)) {
      toast.error(t("both_sistolic_and_diastolic_value_must_be_present"));
      return;
    }

    tempVitals[spotIndex].updatedData.vitalValue = value;

    if (sistolic > 140 || sistolic < 90 || diastolic > 95 || diastolic < 61) {
      tempVitals[spotIndex].isNormal = "N";
    } else {
      tempVitals[spotIndex].isNormal = "Y";
    }
    setVitals(tempVitals);
  };

  const getUserPreviousDocuments = () => {
    if (extraData?.queuePatientId) {
      DashboardServices.getPatientPastUploadedDocuments(
        extraData.queuePatientId
      ).then((response) => {
        if (response.data) {
          setPreviousDocuments(response.data);
        }
      });
    }
  };

  useEffect(() => {
    if (data && data.vitals) {
      setVitals(data.vitals);
    }
  }, [data]);

  useEffect(() => {
    console.log("************ extra data", extraData);
    getUserPreviousDocuments();

    if (extraData) {
      audioUrl.current = extraData?.reqValue?.audioNoteFileUrl;
    }
    getVitalUnits();
    const getDummyVital = (vitalCode) => {
      let vitalNameMap = {
        TEMPERATURE: "Temperature",
        BP: "Blood Pressure",
        HEART_RATE: "Heart Rate",
        RESPIRATION_RATE: "Respiration Rate",
        SPO2: "SPO2",
        WEIGHT: "Weight",
        HEIGHT: "Height",
        HEAD_CIRCUMFERENCE: "Head Circumference",
        UPPER_ARM_CIRCUMFERENCE: "Upper Arm Circumference",
        LMP: "LMP",
      };

      let vitalUnitMap = {
        TEMPERATURE: "°F",
        BP: "mmHg",
        HEART_RATE: "bps",
        RESPIRATION_RATE: "bpm",
        SPO2: "%",
        WEIGHT: "kg",
        HEIGHT: "cm",
        HEAD_CIRCUMFERENCE: "cm",
        UPPER_ARM_CIRCUMFERENCE: "cm",
        LMP: "",
      };

      let tempVital = {
        vitalName: vitalNameMap[vitalCode],
        unit: vitalUnitMap[vitalCode],
        vitalValue: vitalCode === "BP" ? "/" : "",
        // recodedTime: "",
        isNormal: "Y",
        vitalCode,
        updatedData: {
          vitalValue: vitalCode === "BP" ? "/" : "",
          unit: vitalUnitMap[vitalCode],
          foot: "",
          inch: "",
        },
      };
      return tempVital;
    };

    // ;
    let vitalList = [
      "TEMPERATURE",
      "BP",
      "HEART_RATE",
      "RESPIRATION_RATE",
      "SPO2",
      "WEIGHT",
      "HEIGHT",
    ];

    if (getAge(extraData.queuePatientAge) <= 4) {
      vitalList.push("HEAD_CIRCUMFERENCE");
      vitalList.push("UPPER_ARM_CIRCUMFERENCE");
    }

    if (
      getAge(extraData.queuePatientAge) >= 12 &&
      extraData.queuePatientGender === "Female"
    ) {
      vitalList.push("LMP");
    }

    let tempVitalList = [];

    extraData?.reqValue?.vitals?.forEach((vital) => {
      const { vitalCode } = vital;
      vitalList = vitalList.filter((item) => item !== vitalCode);
      tempVitalList.push({
        ...vital,
        updatedData: {
          vitalValue: vital.vitalValue,
          unit: vital.unit || null,
          foot: "",
          inch: "",
        },
      });
    });
    if (vitalList?.length > 0) {
      setEditMode(true);
    }
    vitalList?.forEach((item) => {
      tempVitalList.push(getDummyVital(item));
    });
    // console.log("************  Vital temp =", tempVitalList);
    setVitals(tempVitalList);

    if (!data || !data.vitals) {
      if (extraData && extraData.reqValue && extraData.reqValue.vitals) {
        const temp = extraData?.reqValue?.vitals?.filter((item) => {
          return (
            (item.vitalCode !== "LMP" &&
              item.vitalCode !== "HEAD_CIRCUMFERENCE" &&
              item.vitalCode !== "UPPER_ARM_CIRCUMFERENCE") ||
            ((item.vitalCode === "HEAD_CIRCUMFERENCE" ||
              item.vitalCode === "UPPER_ARM_CIRCUMFERENCE") &&
              getAge(extraData.queuePatientAge) <= 4) ||
            (item.vitalCode === "LMP" &&
              extraData.queuePatientGender === "Female")
          );
        });
        // setVitals(temp);
        const tempHeight = extraData?.reqValue?.vitals?.find(
          (item) => item.vitalCode === "HEIGHT"
        );
        const tempWeight = extraData?.reqValue?.vitals?.find(
          (item) => item.vitalCode === "WEIGHT"
        );

        const convertedHeight = convertHeightInCm(
          tempHeight?.vitalValue,
          tempHeight?.unit
        );
        const convertWeight = convertWeightInKg(
          tempWeight?.vitalValue,
          tempWeight?.unit
        );

        setHeight(convertedHeight);
        setWeight(convertWeight);
        updateData({ vitals: temp });
      }
    }

    if (extraData?.reqValue?.docs?.length > 0) {
      setPreviousDocuments(extraData?.reqValue?.docs);
    }

    if (
      extraData &&
      extraData.queueConsultationType &&
      extraData.queueConsultationType.toLowerCase().includes("opd")
    ) {
      setPatientType("statNormal");
    } else if (
      extraData &&
      extraData.queueConsultationType &&
      extraData.queueConsultationType.toLowerCase().includes("urgent")
    ) {
      setPatientType("statImportant");
    } else if (
      extraData &&
      extraData.queueConsultationType &&
      extraData.queueConsultationType.toLowerCase().includes("review")
    ) {
      setPatientType("statReview");
    } else if (
      extraData &&
      extraData.queueConsultationType &&
      extraData.queueConsultationType.toLowerCase().includes("emergency")
    ) {
      setPatientType("statUrgent");
    }

    // calculateBmi();
  }, []);

  const handleFileData = (payload) => {
    const { data } = payload;
    if (data) {
      getUserPreviousDocuments();
    }
    // setPreviousDocuments((prev) => {
    //   return [...prev, ...data];
    // });
  };

  useEffect(() => {
    doctorSocket.on("uploadFileFromCenter", handleFileData);
    return () => {
      doctorSocket.off("uploadFileFromCenter", handleFileData);
    };
  }, [doctorSocket]);

  const getVitalUnits = () => {
    const diseasetring = localStorage.getItem("vitalUnitList");
    if (diseasetring) {
      setVitalUnitList(JSON.parse(diseasetring));
      return;
    }

    const reqDto = getHealthDto();
    DashboardServices.getVitalUnits(reqDto).then((response) => {
      if (response.data) {
        setVitalUnitList(response.data);
        localStorage.setItem("vitalUnitList", JSON.stringify(response.data));
      }
    });
  };

  useEffect(() => {
    const height = vitals.find((item) => item.vitalCode === "HEIGHT");
    const weight = vitals.find((item) => item.vitalCode === "WEIGHT");
    if (height) {
      let htValue = parseFloat(height?.vitalValue);
      let htUnit = height.unit;
      if (htUnit === "ft~in") {
        htValue = htValue * 2.54;
      }
      setHeight(htValue);
    }

    if (weight) {
      let wtValue = parseFloat(weight?.vitalValue);
      let wtUnit = weight.unit;
      if (wtUnit === "lb") {
        wtValue = Math.floor(wtValue * 0.453592);
      }
      setWeight(wtValue);
    }
  }, [vitals]);

  useEffect(() => {
    if (height && weight) {
      calculateBmi();
      console.log("height" + height);
      console.log("weight" + weight);
    }
  }, [height, weight]);

  // document start
  const elementRef = useRef(null);
  const fileElementRef = useRef(null);
  const [filearrowDisable, setFilearrowDisable] = useState(true);
  const filehandleHorizantalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
      if (element.scrollLeft === 0) {
        setFilearrowDisable(true);
      } else {
        setFilearrowDisable(false);
      }
    }, speed);
  };

  const [fileDivWidth, setFileDivWidth] = useState(0);

  useEffect(() => {
    if (fileElementRef.current) {
      setFileDivWidth(fileElementRef.current.offsetWidth);
    }
  }, [fileElementRef]);

  const [tempDivWidth, setTempDivWidth] = useState(0);

  useEffect(() => {
    if (elementRef.current) {
      setTempDivWidth(elementRef.current.offsetWidth);
    }
  }, [elementRef]);

  // const getVitalSvgAccordingToVitalType = (vitalType) => {
  //   if (vitalType === "BP") {
  //     return <BP />;
  //   } else if (vitalType === "Pulse") {
  //     return <HeartPulse />;
  //   } else if (vitalType === "SPO2") {
  //     return <SPO2 />;
  //   } else if (vitalType === "Temperature") {
  //     return <ThermometerHalf />;
  //   } else if (vitalType === "RR") {
  //     return <Lungs />;
  //   } else if (vitalType === "Weight") {
  //     return <MonitorWeightOutlined />;
  //   } else if (vitalType === "Height") {
  //     return <HeightOutlined />;
  //   }
  // };
  // document end

  // edit vitals
  const [editMode, setEditMode] = useState(false);
  const [editedValues, setEditedValues] = useState({});

  const handleSaveVitals = () => {
    if (editMode) {
      if (!validateVitals()) {
        return;
      }

      const tempVitals = vitals.map((item) => ({
        ...item,
        vitalValue: item?.updatedData?.vitalValue,
        unit: item.updatedData.unit,
      }));
      setVitals(tempVitals);
      updateData({ vitals: tempVitals });
      setEditMode(!editMode);
    } else {
      const tempVitals = vitals.map((item) => {
        let foot = 0;
        let inch = 0;
        if (item.unit === "ft~in") {
          foot = Math.floor(parseInt(item?.vitalValue) / 12);
          inch = parseInt(item?.vitalValue) % 12;
        }
        if (isNaN(foot)) {
          foot = 0;
        }
        if (isNaN(inch)) {
          inch = 0;
        }
        return {
          ...item,
          updatedData: {
            vitalValue: item?.vitalValue,
            unit: item.unit,
            foot,
            inch,
          },
        };
      });
      setVitals(tempVitals);
      setEditMode(!editMode);
    }
  };

  const validateVitals = () => {
    const tempVitals = [...vitals];
    let isValid = true;
    const problemList = [];

    const bp = vitals.find((item) => item.vitalCode === "BP");
    const temp = vitals.find((item) => item.vitalCode === "TEMPERATURE");
    if (bp) {
      const bpList = bp?.updatedData?.vitalValue.split("/");
      const sistolic = parseInt(bpList[0]);
      const diastolic = parseInt(bpList[1]);

      if (sistolic === "" || !sistolic) {
        problemList.push("Sistolic pressure cannot be empty");
        isValid = false;
      }

      if (diastolic === "" || !diastolic) {
        problemList.push("Diastolic pressure cannot be empty");
        isValid = false;
      }

      if (sistolic < diastolic) {
        problemList.push("Sistolic pressure must be greater than diastolic");
        toast.error(t("sistolic_pressure_must_be_greater_than_diastolic"));
        isValid = false;
      }

      if (sistolic - diastolic < 30) {
        problemList.push(
          "Sistolic and diastolic pressure difference must be greater than 30"
        );
        toast.error(
          t(
            "sistolic_and_diastolic_pressure_difference_must_be_greater_than_thirty"
          )
        );
        isValid = false;
      }
    }

    if (temp) {
      const unit = temp.updatedData.unit;
      const value = parseFloat(temp?.updatedData?.vitalValue);
      if (unit.includes("C")) {
        if (value > 41.1 || value < 32.22) {
          problemList.push("Temparature must be within 32.22 to 41.10");
          toast.error(t("temparature_must_be_within_thirty_two_and_forty_one"));
          isValid = false;
        }
      } else if (unit.includes("F")) {
        if (value > 106 || value < 90) {
          problemList.push("Temparature must be within 90 to 106");
          toast.error(
            t("temparature_must_be_within_ninety_to_one_hundred_six")
          );
          isValid = false;
        }
      }
    }

    setVitalErrorList(problemList);

    if (!isValid) {
      setShowConfirmation(true);
    }

    return isValid;
  };

  const mapUnitsToVitals = () => {
    const tempVitalUnitMap = {};
    vitalUnitList.forEach((item) => {
      if (!tempVitalUnitMap[item.vitalCode]) {
        tempVitalUnitMap[item.vitalCode] = [item.vitalUnit];
      } else {
        tempVitalUnitMap[item.vitalCode].push(item.vitalUnit);
      }
    });
    setVitalUnitMap(tempVitalUnitMap);
  };

  useEffect(() => {
    if (vitalUnitList && vitalUnitList.length > 0) {
      mapUnitsToVitals();
    }
  }, [vitalUnitList]);

  const handleInputChange = (index, event) => {
    // const newValues = { ...editedValues, [index]: event.target.value };
    const vitalCode = vitals[index].vitalCode;
    validateInput(event.target.value, vitalCode, index);
    // const newValue = [...vitals];
    // newValue[index].updatedData.vitalValue = event.target.value;
    // setVitals(newValue);
  };

  //DIFFENTIAL DIAGNOSTICS
  const [showDifferentialDiagonesis, setShowDifferentialDiagonesis] =
    useState(false);
  const closeDifferentialDiagonesis = () => {
    setShowDifferentialDiagonesis(false);
  };

  const [differentialDiagnosisData, setDifferentialDiagnosisData] =
    useState(null);

  //Drug Safety
  const [showDrugSafety, setShowDrugSafety] = useState(false);
  const closeDrugSafety = () => {
    setShowDrugSafety(false);
  };

  const [drugSafetyData, setDrugSafetyData] = useState(null);
  return (
    <>
      <div className="MeetingTranscriptionContainer">
        <div className="Meeting_Transcription">
          <div className="elementFormContainer">
            <div className="tskPriotGrp">
              <div class={`tskPriot ${patientType}`}>
                <div class="priStat">{extraData.queueConsultationType}</div>
              </div>
              {/* <div class="tskPriot statImportant">
                <div class="priStat">Urgent</div>
              </div>
              <div class="tskPriot statReview">
                <div class="priStat">Review</div>
              </div>
              <div class="tskPriot statUrgent">
                <div class="priStat">Emergency</div>
              </div> */}
            </div>
            <div className="pDemography">
              <div className="pDemogInd">
                {/* <span>Name:</span> */}
                <span className="pDVlue">{extraData.queuePatientName}</span>
              </div>
              <div className="pDemogInd">
                {/* <span>Gender:</span> */}
                <span className="pDVlue">{extraData.queuePatientGender}</span>
              </div>
              <div className="pDemogInd">
                {/* <span>Age:</span> */}
                <span className="pDVlue">{extraData.queuePatientAge}</span>
              </div>
              {extraData?.reqValue?.insurance && (
                <div className="pDemogInd">
                  <span>{t("insurance")}:</span>
                  <span className="pDVlue">{t("yes")}</span>
                </div>
              )}
              {/* <div className="pDemogInd">
                <span>Card:</span>
                <span className="pDVlue">DADS2012541LP</span>
              </div> */}
            </div>
            {extraData?.queueReferredBy && (
              <div className="taskElementsGrpWithTwo mt10">
                <div className="taskElementGrp">
                  <div className="tskElemHeding">{t("refer_by")}</div>
                  <div className="tskElemntInfo">
                    {extraData.queueReferredBy}
                  </div>
                </div>
              </div>
            )}
            {console.log("************* Vitals are =", vitals)}
            {vitals?.length > 0 && (
              <>
                <div className="tskElemHeding mt10">{t("vitals")}</div>
                <div className="vitalsGroup">
                  {vitals?.map((vital, index) => {
                    return (
                      <div key={index} className="vitalsInd">
                        <div className="vitIcon">
                          {getVitalSvgAccordingToVitalType(vital?.vitalCode)}
                        </div>
                        <div className="vitInfo">
                          <div className="vitNm">{vital?.vitalName}</div>
                          <div
                            className={`vitValue ${
                              vital.isNormal === "Y"
                                ? "vNormal"
                                : vital.isNormal === "M"
                                ? "vModarate"
                                : "vRisk"
                            }`}
                          >
                            {console.log(
                              "********** vitals Edit Mode =",
                              editMode
                            )}
                            {editMode ? (
                              <>
                                {vital.vitalCode !== "LMP" &&
                                  vital.vitalCode !== "BP" &&
                                  vital.updatedData &&
                                  vital.updatedData.unit !== "ft~in" && (
                                    <input
                                      className="editInputVitals"
                                      type="text"
                                      value={vital?.updatedData?.vitalValue}
                                      onChange={(event) => {
                                        handleInputChange(index, event);
                                      }}
                                    />
                                  )}
                                {vital.vitalCode === "LMP" && (
                                  <input
                                    className="editInputVitals"
                                    type="date"
                                    value={
                                      lmp ||
                                      dayjs(
                                        Date.parse(
                                          vital?.updatedData?.vitalValue
                                        )
                                      ).format("YYYY-MM-DD")
                                    }
                                    onChange={(event) => {
                                      handleInputChange(index, event);
                                      setLmp(event.target.value);
                                    }}
                                  />
                                )}
                                {vital?.vitalCode === "BP" && (
                                  <>
                                    <input
                                      className="editInputVitals"
                                      type="text"
                                      value={
                                        vital?.updatedData?.vitalValue &&
                                        vital?.updatedData?.vitalValue.split(
                                          "/"
                                        )[0]
                                      }
                                      onChange={(event) => {
                                        handleChangeSistolic(index, event);
                                      }}
                                    />
                                    <span className="slash"></span>
                                    <input
                                      className="editInputVitals"
                                      type="text"
                                      value={
                                        vital?.updatedData?.vitalValue &&
                                        vital?.updatedData?.vitalValue.split(
                                          "/"
                                        )[1]
                                      }
                                      onChange={(event) => {
                                        handleChangeDiastolic(index, event);
                                      }}
                                    />
                                  </>
                                )}
                                {vital.unit &&
                                  vital.updatedData &&
                                  vital.updatedData.unit === "ft~in" && (
                                    <>
                                      <input
                                        className="editInputVitals"
                                        type="text"
                                        value={vital.updatedData.foot}
                                        onChange={(event) => {
                                          const tempVital = [...vitals];
                                          let foot = parseInt(
                                            event.target.value
                                          );
                                          if (isNaN(foot)) {
                                            foot = 0;
                                          }
                                          tempVital[
                                            index
                                          ].updatedData.vitalValue =
                                            foot * 12 +
                                            parseInt(
                                              tempVital[index].updatedData.inch
                                            );

                                          tempVital[index].updatedData.foot =
                                            foot;
                                          setVitals(tempVital);
                                          // handleInputChange(index, event);
                                        }}
                                      />

                                      {vital.unit &&
                                        vital.updatedData &&
                                        (vitalUnitMap[vital.vitalCode].length >
                                        1 ? (
                                          <select
                                            className="editInputUnits"
                                            value={vital.updatedData.unit}
                                            onChange={(event) => {
                                              const newValue = [...vitals];
                                              const userUnit =
                                                newValue[index].unit;

                                              newValue[index].updatedData.unit =
                                                event.target.value;
                                              setVitals(newValue);
                                            }}
                                          >
                                            {vitalUnitMap[vital.vitalCode].map(
                                              (item) => {
                                                return (
                                                  <option
                                                    key={item}
                                                    value={item}
                                                  >
                                                    {item === "ft~in"
                                                      ? "ft"
                                                      : item}
                                                  </option>
                                                );
                                              }
                                            )}
                                          </select>
                                        ) : vitalUnitMap[vital.vitalCode]
                                            .length === 1 ? (
                                          <>
                                            <span>
                                              {vitalUnitMap[vital.vitalCode][0]}
                                            </span>
                                          </>
                                        ) : (
                                          <></>
                                        ))}

                                      <input
                                        className="editInputVitals"
                                        type="text"
                                        value={vital.updatedData.inch}
                                        onChange={(event) => {
                                          const tempVital = [...vitals];
                                          let inch = parseInt(
                                            event.target.value
                                          );

                                          if (isNaN(inch) || inch < 0) {
                                            inch = 0;
                                          }
                                          if (inch > 11) {
                                            return;
                                          }
                                          tempVital[
                                            index
                                          ].updatedData.vitalValue =
                                            tempVital[index].updatedData.foot *
                                              12 +
                                            inch;

                                          tempVital[index].updatedData.inch =
                                            inch;
                                          setVitals(tempVital);
                                          // handleInputChange(index, event);
                                        }}
                                      />
                                      <span>in</span>
                                    </>
                                  )}
                                {vital.unit &&
                                  vital.updatedData &&
                                  vital.updatedData.unit !== "ft~in" &&
                                  (vitalUnitMap?.[vital.vitalCode]?.length >
                                  1 ? (
                                    <select
                                      className="editInputUnits"
                                      value={vital.updatedData.unit}
                                      onChange={(event) => {
                                        const newValue = [...vitals];
                                        const userUnit = newValue[index].unit;
                                        let foot = 0;
                                        let inch = 0;
                                        if (event.target.value === "ft~in") {
                                          foot = Math.floor(
                                            newValue[index].updatedData
                                              .vitalValue / 12
                                          );
                                          inch =
                                            newValue[index].updatedData
                                              .vitalValue % 12;
                                          newValue[index].updatedData.foot =
                                            foot;
                                          newValue[index].updatedData.inch =
                                            inch;
                                        }

                                        newValue[index].updatedData.unit =
                                          event.target.value;
                                        setVitals(newValue);
                                      }}
                                    >
                                      {vitalUnitMap[vital.vitalCode].map(
                                        (item) => {
                                          return (
                                            <option key={item} value={item}>
                                              {item}
                                            </option>
                                          );
                                        }
                                      )}
                                    </select>
                                  ) : vitalUnitMap?.[vital.vitalCode]
                                      ?.length === 1 ? (
                                    <>
                                      <span>
                                        {vitalUnitMap[vital.vitalCode][0]}
                                      </span>
                                    </>
                                  ) : (
                                    <></>
                                  ))}
                              </>
                            ) : (
                              <>
                                <>
                                  {vital.vitalValue &&
                                    vital.vitalValue !== "" &&
                                    vital.unit === "ft~in" &&
                                    `${Math.floor(vital.vitalValue / 12)} ft ${
                                      vital.vitalValue % 12
                                    }  in`}
                                </>
                                <>
                                  {vital.vitalValue &&
                                    vital.vitalValue !== "" &&
                                    vital.unit !== "ft~in" &&
                                    `${vital.vitalValue}  ${
                                      vital.unit
                                        ? `${
                                            vital.unit === "per minute"
                                              ? "/min"
                                              : vital.unit
                                          }`
                                        : ""
                                    }`}
                                </>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {extraData?.isDoctor && (
                    <div>
                      <Button
                        className="joinMetBtn fullHeight"
                        startIcon={<>{editMode ? <Save /> : <Edit />}</>}
                        onClick={handleSaveVitals}
                      >
                        {t("vitals")}
                      </Button>
                    </div>
                  )}

                  {/* <div className="vitalsInd">
                <div className="vitIcon">
                  <HeartPulse />
                </div>
                <div className="vitInfo">
                  <div className="vitNm">Pulse Rate</div>
                  <div className="vitValue vRisk">130/min</div>
                </div>
              </div>
              <div className="vitalsInd">
                <div className="vitIcon">
                  <SPO2 />
                </div>
                <div className="vitInfo">
                  <div className="vitNm">SPO2</div>
                  <div className="vitValue vNormal">99%</div>
                </div>
              </div>
              <div className="vitalsInd">
                <div className="vitIcon">
                  <ThermometerHalf />
                </div>
                <div className="vitInfo">
                  <div className="vitNm">Temperature</div>
                  <div className="vitValue vNormal">98°F</div>
                </div>
              </div>
              <div className="vitalsInd">
                <div className="vitIcon">
                  <Lungs />
                </div>
                <div className="vitInfo">
                  <div className="vitNm">Respiration Rate</div>
                  <div className="vitValue vModarate">20/min</div>
                </div>
              </div>
              <div className="vitalsInd">
                <div className="vitIcon">
                  <MonitorWeightOutlined />
                </div>
                <div className="vitInfo">
                  <div className="vitNm">Body Weight</div>
                  <div className="vitValue vNormal">85kg</div>
                </div>
              </div>
              <div className="vitalsInd">
                <div className="vitIcon">
                  <HeightOutlined />
                </div>
                <div className="vitInfo">
                  <div className="vitNm">Body Height</div>
                  <div className="vitValue vNormal">182cm</div>
                </div>
              </div> */}
                </div>
              </>
            )}
            {height && weight && (
              <div className="bmiGroup">
                <div className="c-bmi__result">
                  {t("bmi")}:
                  <span name="r" className={`vitValue ${bmiClass}`}>
                    {bmi}
                  </span>
                </div>
                <div className="c-bmi__groups" readOnly>
                  {[
                    "Underweight",
                    "Normal",
                    "Pre-obesity",
                    "Obese I",
                    "Obese II",
                    "Obese III",
                  ].map((label, index) => (
                    <div key={index}>
                      <input
                        type="radio"
                        className="bmiInputRadio"
                        id={`bmi-g${index}`}
                        name="g"
                        checked={selectedGroup === index}
                        readOnly
                      />
                      <label htmlFor={`bmi-g${index}`}>{label}</label>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className="taskElementGrp mt10">
              <div className="tskElemHeding">{t("patient_complaints")}</div>
              <div className="tskElemntInfo">{extraData?.queueComplaints}</div>
            </div>
            {extraData?.reqValue?.audioNoteFileUrl && (
              <div className="taskElementGrp">
                <div className="tskElemntInfo">
                  <div className="playAudioNotes">
                    <IconButton
                      variant="outlined"
                      onClick={() => {
                        setShowAudioModal(true);
                      }}
                    >
                      <Play />
                    </IconButton>
                    <span>{t("patient_audio_message")}</span>
                  </div>
                </div>
              </div>
            )}

            <div className="taskElementGrp mt10">
              <div
                className=""
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center ",
                }}
              >
                <div className="tskElemHeding">{t("files")}</div>

                <IconButton
                  className="goPrevousBtn"
                  onClick={() => {
                    getUserPreviousDocuments();
                  }}
                >
                  <Refresh />
                </IconButton>
              </div>
              <div class="elementFileListedGrp">
                <div className="horaizonScroll">
                  <IconButton
                    className="goPrevousBtn"
                    onClick={() => {
                      filehandleHorizantalScroll(
                        fileElementRef.current,
                        25,
                        200,
                        -10
                      );
                    }}
                    disabled={filearrowDisable}
                  >
                    <ArrowBackIosNew />
                  </IconButton>

                  {prevDocuments && (
                    <div class="elemntFilsgrp" ref={fileElementRef}>
                      {prevDocuments?.map((item) => {
                        return (
                          <div className="elemntFile">
                            <Tooltip
                              title={item.fileName}
                              arrow
                              className="elmFileTolTip"
                            >
                              <span className="elemntFilIoc">
                                <DescriptionOutlined />
                              </span>
                              <span className="elemntFilNm">
                                {item.fileName}
                              </span>
                              <span
                                className="elemntFilIoc"
                                onClick={() => {
                                  downloadFileFromUrl(
                                    item.fileLink,
                                    item.fileName
                                  );
                                }}
                              >
                                <Download />
                              </span>
                            </Tooltip>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {/* {documents?.map((item, index) => {
                    return (
                      <div className="elemntFile">
                        <Tooltip
                          title={t("past_medication_file_pdf")}
                          arrow
                          className="elmFileTolTip"
                        >
                          <span className="elemntFilIoc">
                            <DescriptionOutlined />
                          </span>
                          <span className="elemntFilNm">{item.fileName}</span>
                          <span
                            className="elemntFilIoc"
                            onClick={() => {
                              const temp = [...documents];
                              temp.splice(index, 1);
                              setDocuments(temp);
                            }}
                          >
                            <Cancel />
                          </span>
                        </Tooltip>
                      </div>
                    );
                  })} */}

                  {fileDivWidth > 370 && (
                    <IconButton
                      className="goNextBtn"
                      onClick={() => {
                        filehandleHorizantalScroll(
                          fileElementRef.current,
                          25,
                          200,
                          10
                        );
                      }}
                    >
                      <ArrowForwardIos />
                    </IconButton>
                  )}
                </div>
                {!extraData?.isDoctor && (
                  <div class="elmntAddMrFiles">
                    <Button
                      className="elemntMrFileBtn"
                      variant="outlined"
                      onClick={(e) => {
                        setShowFileUploadDialog(true);
                      }}
                    >
                      <Plus />
                    </Button>
                    {/* <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleChangeFile}
                    style={{ display: "none" }}
                  ></input> */}
                  </div>
                )}
              </div>
            </div>
            <div className="bb"></div>
            {/* start symptoms */}
            {(extraData?.isDoctor || data?.symptoms?.length > 0) && (
              <Symptoms
                updateData={updateData}
                data={data}
                extraData={extraData}
              />
            )}
            {/* end symptoms */}
            {/* start Signs */}
            {(extraData.isDoctor || data?.singObj?.length > 0) && (
              <Signs
                updateData={updateData}
                data={data}
                extraData={extraData}
              />
            )}
            {/* end Signs */}
            {/* start PhysicalExamination */}
            {(extraData.isDoctor || data?.physicalExamList?.length > 0) && (
              <PhysicalExamination
                updateData={updateData}
                data={data}
                extraData={extraData}
              />
            )}
            {/* end PhysicalExamination */}
            {/* start Comorbidity */}
            {(extraData.isDoctor || data?.physicalExamList?.length > 0) && (
              <Comorbidity
                updateData={updateData}
                data={data}
                extraData={extraData}
              />
            )}
            {/* end Comorbidity */}
            {/* start HistoryRiskFactor */}
            {(extraData.isDoctor ||
              data?.prevSurgery?.length > 0 ||
              data?.pastIllness?.length > 0 ||
              data?.riskFactor?.length > 0) && (
              <HistoryRiskFactor
                updateData={updateData}
                data={data}
                extraData={extraData}
              />
            )}
            {/* end HistoryRiskFactor */}
            {/* start DrugAllergy */}
            {(extraData.isDoctor || data?.allergicDrug?.length > 0) && (
              <DrugAllergy
                updateData={updateData}
                data={data}
                extraData={extraData}
              />
            )}
            {/* end DrugAllergy */}
            {/* start FoodAllergy */}
            {(extraData.isDoctor || data?.allergicFood?.length > 0) && (
              <FoodAllergy
                updateData={updateData}
                data={data}
                extraData={extraData}
              />
            )}
            {/* end FoodAllergy */}
            {/* start FamilyHistory */}
            {(extraData.isDoctor || data?.familyHistory?.length > 0) && (
              <FamilyHistory
                updateData={updateData}
                data={data}
                extraData={extraData}
              />
            )}
            {/* end FamilyHistory */}
            {/* start possible Diagnosis */}
            {(extraData.isDoctor || data?.possibleDiagonesis?.length > 0) && (
              <PossibleDiagnosis
                updateData={updateData}
                data={data}
                extraData={extraData}
                handleDiffDiagData={(diffDiagData) => {
                  setDifferentialDiagnosisData(diffDiagData);
                  setShowDifferentialDiagonesis(true);
                }}
              />
            )}
            {/* end possible Diagnosis */}
            {/* start Investigations */}
            {(extraData.isDoctor || data?.investigation?.length > 0) && (
              <Investigations
                updateData={updateData}
                data={data}
                extraData={extraData}
              />
            )}
            {/* end Investigations */}
            {/* start TestRsult */}
            {/* {extraData?.reqValue?.tests?.length > 0 && (
            )} */}
            {extraData?.isDoctor && (
              <TestResult
                updateData={updateData}
                data={data}
                extraData={extraData}
              />
            )}
            {/* end TestRsult */}
            {/* start test result input */}
            {!extraData?.isDoctor && (
              <TestResultInput data={data} extraData={extraData} />
            )}
            {/* end test result input */}
            {/* start Diagnosis */}
            {(extraData.isDoctor ||
              data?.possibleDiagonesis?.length > 0 ||
              data?.diagonesis?.length > 0) && (
              <Diagnosis
                updateData={updateData}
                data={data}
                extraData={extraData}
              />
            )}
            {/* end Diagnosis */}
            {/* start Medication */}
            {(extraData.isDoctor || data?.medication?.length > 0) && (
              <Medication
                updateData={updateData}
                data={data}
                setShowDrugSafety={setShowDrugSafety}
                extraData={extraData}
              />
            )}
            {/* end Medication */}
            {/* start Recommendations */}
            {(extraData.isDoctor ||
              data?.lifeStyle?.length > 0 ||
              data?.diet?.length > 0) && (
              <Recommendations
                updateData={updateData}
                data={data}
                extraData={extraData}
              />
            )}
            {/* end Recommendations */}
            {/* start OtherOptions */}

            {(extraData.isDoctor ||
              data?.itemToMonitor?.length > 0 ||
              data?.itemToMonitor ||
              data?.reviewDate ||
              data?.hospital ||
              data?.referDoctorSpeciality ||
              data?.referralNote) && (
              <OtherOptions
                updateData={updateData}
                data={data}
                extraData={extraData}
              />
            )}
            {/* end OtherOptions */}
          </div>
        </div>
      </div>
      {showAudioModal && (
        <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
          <div className="addMoreAgendaModel">
            <div className="modelTitle">{t("audio_message")}</div>
            <div className="modActionBtnGrp">
              <audio controls>
                <source src={`${audioUrl.current}`} />
              </audio>
            </div>

            <div className="modActionBtnGrp">
              <Button
                className="dfultPrimaryBtn"
                onClick={() => {
                  setShowAudioModal(false);
                }}
              >
                {t("cancel")}
              </Button>
            </div>
          </div>
        </Box>
      )}

      {showConfirmation && (
        <CustomModal
          modalTitle={t("do_you_want_to_continue")}
          // style={{ zIndex: 10001 }}
          closeModal={() => {
            setShowConfirmation(false);
          }}
          className={"width90"}
        >
          <div>
            <ul>
              {VitalErrorList &&
                VitalErrorList.map((item) => {
                  return <li>{item}</li>;
                })}
            </ul>
          </div>

          <div className="modActionBtnGrp actionsCreateOrg">
            <Button
              className="dfultDarkSecondaryBtn"
              onClick={() => {
                const tempVitals = vitals.map((item) => ({
                  ...item,
                  vitalValue: item.updatedData.vitalValue,
                  unit: item.updatedData.unit,
                }));
                setVitals(tempVitals);
                updateData({ vitals: tempVitals });
                setEditMode(!editMode);
                setShowConfirmation(false);
              }}
            >
              {t("save")}
            </Button>
            <Button
              className="dfultPrimaryBtn"
              onClick={(e) => {
                setShowConfirmation(false);
              }}
            >
              {t("edit")}
            </Button>
          </div>
        </CustomModal>
      )}
      {showDifferentialDiagonesis && differentialDiagnosisData && (
        <DifferentialDiagnosis
          data={data}
          closeDifferentialDiagonesis={closeDifferentialDiagonesis}
          differentialDiagnosisData={differentialDiagnosisData}
          setDiffDiagnosisDisease={(diffDiagdata) => {
            console.log("setDiffDiagnosisDisease-->>", diffDiagdata);
            if (
              "possibleDiagonesis" in data &&
              data.possibleDiagonesis.length > 0
            ) {
              const difference = diffDiagdata.filter(
                (item1) =>
                  !data.possibleDiagonesis.some(
                    (item2) => item1.id === item2.id
                  )
              );
              const temp = [...data.possibleDiagonesis, ...difference];
              updateData({ possibleDiagonesis: temp });
            } else {
              updateData({ possibleDiagonesis: diffDiagdata });
            }

            setShowDifferentialDiagonesis(false);
          }}
        />
      )}
      {showDrugSafety && (
        <DrugSafety data={data} closeDrugSafety={closeDrugSafety} />
      )}

      {showFileUploadDialog && (
        <div className="RightFlotingContainerArea">
          <PatientFileUpload
            documents={documents}
            setDocuments={setDocuments}
            onClose={(e) => {
              setShowFileUploadDialog(false);
            }}
            previousMedicalRecords={previousMedicalRecords}
            setPreviousMedicalRecords={setPreviousMedicalRecords}
            onSave={(data) => {
              const reqDto = data.map((item) => {
                return {
                  fileName: item.fileName,
                  fileLink: item.fileLink,
                  fileData: item.fileData,
                  id: item.fileLink,
                };
              });
              // ;
              // console.log("files =", reqDto);
              // centerSocket.emit("uploadFileToDoctor", {
              //   doctorId: extraData?.doctorId,
              //   data: reqDto,
              // });
              zhPatientService
                .uploadDocumentsInVC(
                  extraData?.queueVisitId,
                  extraData?.queuePatientId,
                  loginUserDetails.orgId,
                  reqDto
                )
                .then((response) => {
                  if (response.data) {
                    toast.success("Document uploaded successfully");
                    setPreviousDocuments((prev) => {
                      return [...prev, ...response.data];
                    });
                    console.log("Response data =", response.data);
                    if (extraData?.doctorId) {
                      centerSocket.emit("uploadFileToDoctor", {
                        doctorId: extraData?.doctorId,
                        data: response.data,
                      });
                    }
                  }
                })
                .catch((e) => {
                  toast.error("Document upload unsuccessful");
                });
            }}
          />
        </div>
      )}
    </>
  );
}
