import { Add, ArrowBack, Search, Visibility } from "@mui/icons-material";
import { Button, CircularProgress, IconButton } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Download, Trash } from "react-bootstrap-icons";
import { getContractList } from "../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";
import {downloadFileFromUrl} from "../../../../utils/FileUtils"
import { useTranslation } from "react-i18next";

export default function ViewContract({
  handelCloseViewContract,
  handelOpenAddContract,
  refreshContract,
  closeAll
}) {
  const userDetails = jwtDecode(localStorage.getItem("token"));
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);

  const [contractData, setContractData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredContractData, setFilteredContractData] = useState([]);

  console.log("userDetails.orgId", userDetails.orgId);

  useEffect(() => {
    fetchContractData();
  }, [refreshContract]);

  const fetchContractData = () => {
    getContractList(userDetails.orgId)
      .then((response) => {
        console.log("getContractList", response.data);
        setContractData(response.data);
        setFilteredContractData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching units", error);
        setLoading(false);
      });
    }

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    const filteredData = contractData.filter(
      (contract) =>
      contract.contractName &&
      contract.contractName.toLowerCase().includes(query.toLowerCase())
    );

    setFilteredContractData(filteredData);
  };

  const handleDownLoadFile = (contract) =>{
    console.log('download')
    if (contract) {
          const fileLink = contract.fileDataLink;
          const fileNames = contract.fileName
          downloadFileFromUrl(fileLink, fileNames);
        } else {
          console.log("No File found.");
        }
  }

  return (
    <>
      <div className="AnalyticsDashboardContainer">
        <div className="AnalyticsDashboardSubHeader">
          <IconButton onClick={() => {handelCloseViewContract(); closeAll();}}>
            <ArrowBack />
          </IconButton>
          {t("contract")}
        </div>
        <div className="anDashCardContainArea">
          <div className="fullContainArea">
            <div className="cuHeaderGrp">
              <div className="searchTasklist">
                <input
                  type="text"
                  class="serchTaskInput"
                  placeholder={t("search_contract")}
                  value={searchQuery}
                  onChange={handleSearch}
                />
                <IconButton className="seacrhIcon">
                  <Search />
                </IconButton>
              </div>

              <div className="addUnitsBtn">
                <Button
                  startIcon={<Add />}
                  className="dfultPrimaryBtn"
                  onClick={() => handelOpenAddContract()}
                >
                  {t("contract")}
                </Button>
              </div>
            </div>

            <div className="cuContainArea">

            {loading ? (
            <div className="center-progress" style={{ height: "65vh" }}>
              <CircularProgress sx={{ marginTop: "180px" }} />
            </div>
          ) : contractData.length === 0 ? (
            <div class="noDataCard ">
              <span>{t("oops")}</span>{t("no_contract_added_yet")}
              <br />
              <span className="sallMsg">
              {t("click_add_contract_button_to_create_new_contract")}
              </span>
            </div>
          ) : (

              <div className="TableContainer">
                <table className="taskListTable">
                  <thead className="taskListtableHeader">
                    <tr>
                      <th className="width100">{t("sl_no")}</th>
                      <th>{t("contract_name")}</th>
                      <th>{t("contract_type")}</th>
                      <th>{t("responsible_person")}</th>
                      <th>{t("contract_owner_name")}</th>
                      <th className="width100">{t("action")}</th>
                    </tr>
                  </thead>
                  {/* <tbody className="scrolableTableBody">
                    <tr>
                      <td>
                        <div className="tskLstTaskNM">1.</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">Working Contract</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">Sales Contract</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">George White</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">Andrew Flintoff</div>
                      </td>
                      <td>
                        <div className="tblActionBtnGrp">
                          <IconButton>
                            <Download />
                          </IconButton>
                          <IconButton className="removeRowBtn">
                        <Trash />
                      </IconButton>
                        </div>
                      </td>
                    </tr>
                  </tbody> */}
                  <tbody className="scrolableTableBody">
                    {filteredContractData.map((contract, index) => (
                      <tr key={contract.id}>
                        <td>
                          <div className="tskLstTaskNM">{index + 1}</div>
                        </td>
                        <td>
                          <div className="tskLstTaskNM">
                            {contract.contractName}
                          </div>
                        </td>
                        <td>
                          <div className="tskLstTaskNM">
                            {contract.contractType}
                          </div>
                        </td>
                        <td>
                          <div className="tskLstTaskNM">
                            {contract.responsiblePerson}
                          </div>
                        </td>
                        <td>
                          <div className="tskLstTaskNM">
                            {contract.contractOwnerUserName}
                          </div>
                        </td>
                        <td>
                          <div className="tblActionBtnGrp">
                            <IconButton onClick={() => handleDownLoadFile(contract)}>
                              <Download />
                            </IconButton>
                            {/* <IconButton className="removeRowBtn">
                              <Trash />
                            </IconButton> */}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
          )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
