import { BiotechRounded } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import {
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
} from "chart.js";
import React from "react";
import { ArrowRepeat } from "react-bootstrap-icons";
import { Line, Pie } from "react-chartjs-2";

export default function CardInvestigationUnits({
  handelOpenAddInvestigationUnit,
  chartDataOfItem ,
  fetchDashBoardData
}) {
  ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    LineController,
    BarController
  );

  const data = {
    labels: ["Female ", "Male "],
    datasets: [
      {
        label: "Patient",
        data: [96, 47],
        backgroundColor: ["rgba(255, 0, 188,1)", "rgba(0, 120, 215, 1)"],
        borderColor: ["rgba(255, 0, 188, .6)", "rgba(0, 120, 215, .6)"],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    cutout: "80%",
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
        position: "bottom",
        labels: {
          boxWidth: 6,
          color: "#d2d2d2",
          font: {
            size: 12,
            weight: "light",
          },
        },
      },
    },
  };

  const barLineData = {
    // labels: [
    //   "Lipid panel",
    //   "Liver function test",
    //   "Vitamin D",
    //   "Thyroid panel",
    //   "Vitamin B12 levels",
    //   "Electroencephalogram",
    //   "Hearing test",
    //   "MRI scan",
    //   "Ultrasound",
    //   "PET scan",
    // ],
    labels:chartDataOfItem?.dayWiseUnitInvestigations?.length > 0 ? chartDataOfItem.dayWiseUnitInvestigations.map((item) => item.xlabel) : [] ,
    datasets: [
      {
        // label: "Report",
        // data: [32, 10, 23, 15, 42, 13, 20, 11, 9, 24],
        label: chartDataOfItem?.dayWiseUnitInvestigations?.length > 0 
        ? chartDataOfItem.dayWiseUnitInvestigations.map((item) => item.label) 
        : [],
        data: chartDataOfItem?.dayWiseUnitInvestigations?.length > 0 
        ? chartDataOfItem.dayWiseUnitInvestigations.map((item) => item.count) 
        : [],
        backgroundColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  // const barLineOoptions = {
  //   responsive: true,
  //   plugins: {
  //     datalabels: {
  //       display: false,
  //     },
  //     legend: {
  //       display: false,
  //     },
  //     title: {
  //       display: true,
  //       text: "",
  //     },
  //   },
  // };

  const barLineOoptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        // position: "top", 
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const dataIndex = context.dataIndex;
            const specificLabel = chartDataOfItem.dayWiseUnitInvestigations[dataIndex].label;
            
            const count = context.raw;
            return `${specificLabel}: ${count}`;
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: '',
        },
      },
      y: {
        title: {
          display: true,
          text: '',
        },
      },
    },
  };
  

  // const barLineOoptions = {
  //   responsive: true,
  //   plugins: {
  //     legend: {
  //       display: true,
  //       position: "top", // Adjust as necessary
  //     },
  //     tooltip: {
  //       callbacks: {
  //         label: function (context) {
  //           // const xlabel = context.label; 
  //           const label = context.dataset.label;
  //           const count = context.raw;    
  //           return `${label} - Count: ${count}`;
  //         },
  //       },
  //     },
  //   },
  //   scales: {
  //     x: {
  //       title: {
  //         display: true,
  //         text: 'Date',
  //       },
  //     },
  //     y: {
  //       title: {
  //         display: true,
  //         text: 'Count',
  //       },
  //     },
  //   },
  // };
  

  
  return (
    <div className="anDashIndCard">
      <div className="anDashCardArea">
        <div className="andHeader">
          <div className="andCrdNM">Investigation - Unit
          <IconButton
            className={`refreshIcon`}
            onClick={fetchDashBoardData}
            >
            <ArrowRepeat />
          </IconButton>
          </div>
          <div className="andCrdUnitCount">
            <span title="Attrition">
              {/* <Male /> */}
              {/* This Month: <span className="fbold"> 215421</span> */}
            </span>

            <span title="Tenure">
              {/* <Female /> */}
              {/* This week: <span className="fbold">12154</span> */}
            </span>
          </div>
        </div>

        <div className="andCrdGraf">
          <div className="anDLftGrf">
            <div className="centTxtShow">
              {/* <Pie
                className="hw100"
                type="doughnut"
                data={data}
                options={options}
              /> */}
              <div className="highlighted-text">
                {/* <span>Total</span> */}
                {/* <span className="highDataCount">143</span> */}
              </div>
            </div>
          </div>
          <div className="anDRightGrf">
            <Line
              className="hw100"
              type="line"
              data={barLineData}
              options={barLineOoptions}
            />
            {/* <Bar data={barLineData} options={barLineOoptions} /> */}
          </div>
        </div>

        <div className="andCrdFooter">
          <Button
            className="dfultPrimaryBtn"
            startIcon={<BiotechRounded />}
            onClick={() => handelOpenAddInvestigationUnit()}
          >
            Conduct
          </Button>
        </div>
      </div>
    </div>
  );
}
