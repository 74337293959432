import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DashboardServices from "../../../../../services/DashboardServices";
import { getLoginUserDetails } from "../../../../../utils/Utils";
import { toast } from "react-toastify";
import { centerSocket, doctorSocket } from "../../../../../socket";

export const SwitchDoctorModal = ({ closeModal, visitData }) => {
  const loginUserDetail = getLoginUserDetails();
  console.log("visitData", visitData);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [doctorList, setDoctorList] = useState([]);
  const [unitAllDoctorList, setUnitAllDoctorList] = useState([]);

  console.log("loginUserDetail", loginUserDetail);

  useEffect(() => {
    DashboardServices.getDoctorsForSwitchPatient(
      loginUserDetail.signleUnitId,
      visitData.doctorId
    ).then((response) => {
      console.log("response", response.data);
      if (response.data) {
        // const filteredData = response.data.filter(
        //   (item) => item.doctorId !== loginUserDetail.orgId && item.doctorSpecilization !== visitData.doctorSpecilization
        // );
        setUnitAllDoctorList(response.data);
      }
    });
  }, []);

  useEffect(() => {
    if (
      centerSocket != null &&
      unitAllDoctorList &&
      unitAllDoctorList.length > 0
    ) {
      centerSocket.emit("requestOnlineDoctor",{orgId:loginUserDetail.orgId});
      centerSocket.on("responseOnlineDoctor", (doctorIdList) => {
        console.log("responseOnlineDoctor", doctorIdList);
        if (!doctorIdList || doctorIdList.length <= 0) {
          toast.error("No Doctor is online");
          setDoctorList([]);
          return;
        }
        let tempResponse = null;
        DashboardServices.getDoctorsWithQueue(doctorIdList).then((response) => {
          const tempOnlineDoctor = [];
          tempResponse = response.data;
          unitAllDoctorList.forEach((item) => {
            const doctorQueLength = tempResponse[item.doctorId];
            let doesExist = doctorIdList.includes(item.doctorId);

            if (doctorQueLength) {
              tempOnlineDoctor.push({ ...item, queueCount: doctorQueLength });
            } else if (!doctorQueLength && doesExist) {
              tempOnlineDoctor.push({ ...item, queueCount: 0 });
            }
          });

          setDoctorList(tempOnlineDoctor);
        });
      });
    }

    return () => {
      centerSocket.off("responseOnlineDoctor");
    };
  }, [centerSocket, unitAllDoctorList]);

  // const handleSubmit = () => {
  //     console.log('submit');

  //     console.log('selectedDoctor' , selectedDoctor);
  //     console.log('visitData' , visitData)

  //     if (!selectedDoctor) {
  //         toast.error("Please select a doctor");
  //         return;
  //     }

  //     const reqDto = {
  //         currentDoctorId : visitData.doctorId,
  //         assignTodoctorId : selectedDoctor.doctorId,
  //         assignToDoctorName: selectedDoctor.doctorName,
  //         assignToSpecializationName: selectedDoctor.specializationName,
  //         assignToAvgConsultTime: selectedDoctor.avgConsultTime,
  //         rmks: visitData.rmks
  //       };

  //       console.log('reqDto' , reqDto)

  //     DashboardServices.reAssignCancelConsultation(visitData.visitId , loginUserDetail.userId ,reqDto)
  //     .then((response)=>{
  //         console.log('reAssignCancelConsultation response' , response.data);
  //         if (response.data === 1) {
  //             toast.success("Doctor has been switch Successfully", {
  //                 position: toast.POSITION.TOP_RIGHT,
  //             });
  //             closeModal();
  //         } else {
  //             toast.error("error in switching Doctor", {
  //                 position: toast.POSITION.TOP_RIGHT,
  //             });
  //         }
  //     })

  // }

  const handleSubmit = () => {
    console.log("submit");
    console.log("selectedDoctor", selectedDoctor);
    console.log("visitData", visitData);

    if (!selectedDoctor) {
      toast.error("Please select a doctor before submitting.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    const reqDto = {
      currentDoctorId: visitData.doctorId,
      assignTodoctorId: selectedDoctor.doctorId,
      assignToDoctorName: selectedDoctor.doctorName,
      assignToSpecializationName: selectedDoctor.specializationName,
      assignToAvgConsultTime: selectedDoctor.avgConsultTime,
      rmks: visitData.rmks,
    };

    console.log("reqDto", reqDto);

    DashboardServices.reAssignCancelConsultation(
      visitData.visitId,
      loginUserDetail.userId,
      reqDto
    )
      .then((response) => {
        console.log("reAssignCancelConsultation response", response.data);
        if (response.data === 1) {
          toast.success("Doctor switched successfully.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          centerSocket.emit("requestRefreshDoctorQueue", {
            doctorId: selectedDoctor.doctorId,
          });
          centerSocket.emit("requestRefreshDoctorQueue", {
            doctorId: visitData.doctorId,
          });
          closeModal();
        } else {
          toast.error("Failed to switch doctor. Please try again.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error("An unexpected error occurred. Please try again later.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  return (
    <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
      <div className="addMoreAgendaModel">
        <div className="modelTitle">Switch Doctor</div>

        <div className="elementFormContainer">
          <div className="formElement">
            <FormControl className="formControl">
              {/* <InputLabel id="adduser">Add User</InputLabel> */}
              <Autocomplete
                freeSolo
                className="modelformAutocompleteField"
                variant="outlined"
                options={doctorList}
                value={selectedDoctor}
                onChange={(e, newValue) => {
                  setSelectedDoctor(newValue);
                }}
                getOptionLabel={(option) => option.doctorName || ""}
                renderOption={(props, option) => (
                  <div
                    {...props}
                    style={{ padding: "4px 12px" }}
                    className="sDocOptList"
                  >
                    <div className="sDname">{option.doctorName}</div>
                    <div className="sDElm">{option.specializationName}</div>
                    <div className="sDElmGrp">
                      <div className="sDElm">
                        Experience: <span>{option.experience}</span>
                      </div>
                      <div className="sDElm">
                        Fees:
                        <span>
                          {option.fees} {option.feesCurrency}
                        </span>
                      </div>
                      <div className="sDElm">
                        Language: <span>{option.languages}</span>
                      </div>
                      <div className="sDElm">
                        Patient in Queue: <span>{option.queueCount}</span>
                      </div>
                    </div>
                  </div>
                )}
                renderInput={(params) => (
                  <TextField
                    required
                    {...params}
                    label="Search doctor"
                    className="formAutoComInputField autocompFildPlaceholder"
                  />
                )}
              />
            </FormControl>
          </div>

          {/* <div className="formElement">
              <FormControl className="formControl">
                <TextField
                  className="modelTextFild"
                  required
                  label="Remark"
                  variant="outlined"
                  multiline
                  rows={4}
                  maxRows={7}
                  value={switchRemark}
                  onChange={(e) => {
                    setSwitchRemark(e.target.value);
                  }}
                />
              </FormControl>
            </div>
   */}
          {/* <div className="formElement">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={addMorePatient}
                    onChange={(e) => {
                      setAddMorePatient(e.target.checked);
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Add more patient?"
              />
            </div> */}

          {/* {addMorePatient && refreshCount >= 0 && (
              <div className="switchPatlist">
                {allPatientList?.map((item, index) => {
                  return (
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={item.shouldSwitch}
                            onChange={(e) => {
                              if (item.queueVisitId === data.queueVisitId) {
                                return;
                              }
                              allPatientList[index].shouldSwitch =
                                e.target.checked;
                              setRefreshCount((r) => r + 1);
                            }}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        // label={item.queuePatientName}
                        label={
                          <>
                            <div className="patinfom">
                              {data.queuePatientName}{" "}
                              <span>{`(${data.queuePatientAge} / ${data.queuePatientGender})`}</span>
                            </div>
                          </>
                        }
                      />
                    </FormGroup>
                  );
                })}
              </div>
            )} */}
        </div>

        {/* <div className="modAtchFileBtn">
          <label class="btn-up">
            <input type="file" hidden />
            Attach File
          </label>
        </div> */}
        <div className="modActionBtnGrp">
          <Button className="dfultPrimaryBtn" onClick={() => handleSubmit()}>
            Submit
          </Button>
          <Button
            className="dfultDarkSecondaryBtn"
            onClick={() => {
              closeModal();
            }}
          >
            Close
          </Button>
        </div>
      </div>
    </Box>
  );
};
