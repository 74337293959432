import { Add, AttachFile, Save, Tune } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useMemo, useState } from "react";
import { PersonBoundingBox, Trash, XLg } from "react-bootstrap-icons";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { setHours, setMinutes } from "date-fns";
import { useTranslation } from "react-i18next";
import { ConfirmProvider } from "material-ui-confirm";
import PatientFacialBiometricsRegister from "./PatientFacialBiometricsRegister";
import { toast } from "react-toastify";
import zhPatientService from "../../../../../services/ZhPatientService";
import { getLoginUserDetails } from "../../../../../utils/Utils";
import DateUtils from "../../../../../utils/DateUtils";
import DashboardServices from "../../../../../services/DashboardServices";
import {
  getFilteredList,
  getHealthDto,
  MAX_SUGGESTED_RESULT,
} from "../../../../scheduler/view/component/meetings/view/consultation/consultationUtil";
import {
  MAX_FILE_SIZE_TASK,
  VALID_FILE_EXTENSION_TASK,
} from "../../../../../constants/const_values";
import { convertFileToBase64_Split } from "../../../../../utils/FileUtils";

export default function AddInvestigationUnits({
  handelCloseAddInvestigationUnit,
}) {
  const loginUserDetails = getLoginUserDetails();
  const { t } = useTranslation();
  const [addParameter, setAddParameter] = useState(false);
  const [sampleCollected, setSampleCollected] = useState();
  const [sampleType, setSampleType] = useState();
  const [testReagent, setTestReagent] = useState();
  const [testMethod, setTestMethod] = useState();
  const [registerFace, setRegisterFace] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [patientList, setPatientList] = useState([
    {
      rowPatientId: 1,
      patientFullName: "John Doe",
      patientDob: "1990-05-15",
      patientGender: "Male",
    },
    {
      rowPatientId: 2,
      patientFullName: "Jane Smith",
      patientDob: "1995-08-20",
      patientGender: "Female",
    },
    {
      rowPatientId: 3,
      patientFullName: "Mike Johnson",
      patientDob: "1988-02-10",
      patientGender: "Male",
    },
  ]);
  const [investigationList, setInvestigationList] = useState([]);
  const [centerDiagonesises, setCenterDiagonesises] = useState([]);
  const [selectedInvestigation, setSelectedInvestigation] = useState(null);
  const [userInvestigationList, setUserInvestigationList] = useState([]);
  const [investigationSearchString, setInvestigtionSearchString] = useState("");

  const [timezone, setTimezone] = useState();
  const [testParameterName, setTestParameterName] = useState();
  const [tempFilteredTest, setTempFilteredTest] = useState([]);
  const [testValue, setTestValue] = useState("");
  const [testUnit, setTestUnit] = useState("");
  const [testReferenceRange, setTestReferenceRange] = useState("");
  const [suggestedTestList, setSuggestedTestList] = useState([]);

  const arrangeAvailableFunction = (data) => {
    let centerAvailableTest = [];
    let centerUnavailableTest = [];
    investigationList?.forEach((item) => {
      const isAvailable = data?.includes(item.testId);
      if (isAvailable) {
        centerAvailableTest.push({ ...item, isAvailableInCenter: true });
      } else {
        centerUnavailableTest.push(item);
      }
    });
    console.log("center available =", centerAvailableTest);
    return [...centerAvailableTest];
  };
  const processedTestList = useMemo(() => {
    return arrangeAvailableFunction(centerDiagonesises);
  }, [centerDiagonesises, investigationList]);

  const getAge = (dob) => {
    // console.log("getAge", dob);
    const ageString = calculateDateDifference(dob);
    const ageComponent = ageString?.split(" ");
    if (ageComponent?.[0]?.includes("Y")) {
      return parseInt(ageComponent?.[0]?.split("Y")[0]) || 0;
    } else {
      return 0;
    }
  };

  function calculateDateDifference(dateString) {
    // Parse the input date string "DD-MM-YYYY"
    if (!dateString) {
      return "";
    }
    const [day, month, year] = dateString?.split("-").map(Number);
    const givenDate = new Date(year, month - 1, day);
    const today = new Date();

    let years = today.getFullYear() - givenDate.getFullYear();
    let months = today.getMonth() - givenDate.getMonth();
    let days = today.getDate() - givenDate.getDate();

    // Adjust if days are negative
    if (days < 0) {
      months--;
      days += new Date(today.getFullYear(), today.getMonth(), 0).getDate();
    }

    // Adjust if months are negative
    if (months < 0) {
      years--;
      months += 12;
    }

    return `${years}Y ${months}M ${days}D`;
  }

  const initializeAddParameter = () => {
    setTestParameterName("");
    setTestUnit("");
    setTestValue("");
    setTestMethod("");
    setTestReferenceRange("");
  };

  const handelFileUpload = async (event) => {
    const files = event.target.files;
    let tempFileList = [];

    for (var i = 0; i < files.length; i++) {
      const file = files[i];

      if (
        !VALID_FILE_EXTENSION_TASK.includes(
          file.name.split(".").pop().toLowerCase()
        )
      ) {
        toast.warning(`Invalid File Type Of '${file.name}'`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else if (file.size > 1024 * 1024 * MAX_FILE_SIZE_TASK) {
        toast.warning(
          `'${file.name}' file need to be less then ${MAX_FILE_SIZE_TASK}MB`,
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      } else {
        const dto = {
          fileName: file.name,
          fileDataLink: await convertFileToBase64_Split(files[i]),
        };
        tempFileList = dto;
      }
    }
    return tempFileList;
  };

  const getTodaysPatientList = async () => {
    let date = DateUtils.getDateInYYYYMMDD(new Date());
    const resGetTodayPatList = await zhPatientService.getPatListInUnitByDate(
      date,
      date,
      loginUserDetails.signleUnitId,
      loginUserDetails.orgId
    );
    console.log("getTodaysPatientList", resGetTodayPatList.data);
    setPatientList(resGetTodayPatList.data);
  };

  const toggleaddParameter = () => {
    setAddParameter(!addParameter);
  };
  const handleUpdateSampleTypes = (event) => {
    setSampleType(event.target.value);
  };
  const handleUpdateSampleCollectedBy = (event) => {
    setSampleCollected(event.target.value);
  };
  const [completion, setCompletion] = useState(
    setHours(setMinutes(new Date(), 0), 9)
  );

  const handelOpenRegisterFace = () => {
    setRegisterFace(true);
  };

  const getSuggestedTest = async (patId) => {
    const test = await zhPatientService.getPatientPrescriptionSuggested(patId);
    setSuggestedTestList(test.data);
  };

  const onFaceLivenessCheckComplete = async (base64Image) => {
    setRegisterFace(false);
    console.log("onFaceLivenessCheckComplete", base64Image);

    let reqDto = {
      base64Image: base64Image,
      orgId: loginUserDetails.orgId,
    };
    const toastLoaderId = toast.loading(
      "Fetching facial biometrics data, please wait..."
    );
    const resGetFaceData = await zhPatientService.getPatDtlsByFaceData(reqDto);

    console.log(resGetFaceData.data);

    if (resGetFaceData?.data.rowPatientId > 0) {
      toast.update(toastLoaderId, {
        render:
          "Facial biometrics data found, patient details fetched successfully",
        type: "success",
        isLoading: false,
        autoClose: true,
      });
      ;
      setSelectedPatient(resGetFaceData.data);
      getSuggestedTest(resGetFaceData.rowPatientId);
      zhPatientService.updatePatientDataLastAccessTime(
        resGetFaceData.data.rowPatientId
      );
      // setPatientList((prevItems) => [...prevItems, resGetFaceData.data]);
      setPatientList((prevItems) =>
        prevItems.some(
          (item) => item.rowPatientId === resGetFaceData.data.rowPatientId
        )
          ? prevItems
          : [...prevItems, resGetFaceData.data]
      );
    } else {
      toast.update(toastLoaderId, {
        render: "Facial biometrics not found, please register patient first",
        type: "info",
        isLoading: false,
        autoClose: true,
      });
    }
  };

  const initializeForm = () => {
    setSelectedInvestigation(null);
    setInvestigtionSearchString("");
  };

  const getMedicalTestList = () => {
    const symptomString = localStorage.getItem("medicalTestList");
    if (symptomString) {
      setInvestigationList(JSON.parse(symptomString));
      return;
    }

    const reqDto = getHealthDto();

    DashboardServices.getMedicalTestList(reqDto).then((response) => {
      if (response.data) {
        setInvestigationList(response.data);
        localStorage.setItem("medicalTestList", JSON.stringify(response.data));
      }
    });
  };
  useEffect(() => {
    getMedicalTestList();
    getTodaysPatientList();

    zhPatientService
      .getUnitTestIds(loginUserDetails.signleUnitId)
      .then((response) => {
        if (response.data) {
          setCenterDiagonesises(response.data);
          // arrangeAvailableFunction(data);
        }
      });
  }, []);

  const suggestedInvestigation = useMemo(() => {
    const userIdInvestigationList = userInvestigationList?.map(
      (item) => item.testId
    );

    let filteredInvestigation =
      processedTestList?.filter((disease) => {
        const alreadyTaken = userIdInvestigationList?.some(
          (item) => item === disease.testId
        );
        return !alreadyTaken;
      }) || [];

    const filteredList = getFilteredList(
      filteredInvestigation,
      investigationSearchString,
      "testName"
    );
    return filteredList?.slice(0, MAX_SUGGESTED_RESULT) || [];
  }, [investigationSearchString, processedTestList, userInvestigationList]);

  const invList = [
    { invName: "Lipid Profile" },
    { invName: "MRI - Pituitary" },
    { invName: "Serum Immunoglobulins" },
    { invName: "BNP" },
    { invName: "Estrogen" },
    { invName: "Blood For R/E" },
    { invName: "Digoxin Serum Concentration" },
    { invName: "MRI - Both Shoulders" },
    { invName: "Epstein-Barr Virus Antibodies - Serum" },
    { invName: "Skin Scraping Of Lesion" },
  ];

  const suggestedInv = [
    { id: 1, invName: "Complete Blood Count (CBC)" },
    { id: 2, invName: "Liver Function Test (LFT)" },
    { id: 3, invName: "Kidney Function Test (KFT)" },
    { id: 4, invName: "Lipid Profile" },
    { id: 5, invName: "Blood Glucose Test" },
    { id: 6, invName: "Urine Routine Examination" },
    { id: 7, invName: "Chest X-Ray" },
    { id: 8, invName: "Electrocardiogram (ECG)" },
    { id: 9, invName: "Thyroid Function Test (TFT)" },
    { id: 10, invName: "Magnetic Resonance Imaging (MRI)" },
    { id: 11, invName: "Ultrasound" },
    { id: 12, invName: "Computed Tomography (CT) Scan" },
    { id: 13, invName: "Blood Pressure Monitoring" },
    { id: 14, invName: "Pulmonary Function Test (PFT)" },
  ];

  const patientTestWithDetails = useMemo(() => {
    return userInvestigationList;
  }, [userInvestigationList]);

  return (
    <>
      <div className="rightFlotingPanel">
        <div className="rightFlotingContainer">
          <div className="rfContHeader">
            <div className="rfcHeadText">Conduct Investigation</div>
            <div className="rfcHActionBtnGrp">
              <div className="actionBtn closeBtnD10">
                <IconButton onClick={() => handelCloseAddInvestigationUnit()}>
                  <XLg />
                </IconButton>
              </div>
            </div>
          </div>

          <div className="rfContContain">
            <div className="rfContContainDetails">
              <div className="elementFormContainer">
                <div className="formElementGrp">
                  <div className="searchWthfilterBtn">
                    <div className="formElement">
                      <FormControl className="formControl">
                        <Autocomplete
                          freeSolo
                          className="formAutocompleteField"
                          variant="outlined"
                          options={patientList}
                          onChange={(e, newValue) => {
                            console.log("all new Value=", newValue);
                            setSelectedPatient(newValue);
                            newValue &&
                              getSuggestedTest(newValue?.rowPatientId);
                          }}
                          getOptionLabel={(option) =>
                            option.patientFullName || ""
                          }
                          renderOption={(props, option) => (
                            <li
                              {...props}
                              style={{ padding: "4px 12px" }}
                              className="sPatOptList"
                              key={option.rowPatientId}
                            >
                              <div className="spname">
                                {option.patientFullName}
                              </div>
                              <div className="spage">
                                {`${calculateDateDifference(
                                  option.patientDob
                                )} (${option.patientGender})`}
                              </div>
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Search patient"
                              className="formAutoComInputField autocompFildPlaceholder"
                            />
                          )}
                        />
                      </FormControl>
                    </div>

                    <IconButton className="filterPatBtn">
                      <Tune />
                    </IconButton>
                  </div>

                  <div className="scanFaceBtn">
                    <IconButton onClick={() => handelOpenRegisterFace()}>
                      <PersonBoundingBox />
                    </IconButton>
                  </div>
                </div>

                {selectedPatient && (
                  <>
                    <div className="pDemographyImgGrp invSlctpatient">
                      <div className="patImage">
                        <img
                          src={selectedPatient?.patientImageUrl}
                          alt="patient-profile"
                        />
                      </div>
                      <div className="pDemography">
                        <div className="pDemogInd pdlargTxt">
                          <span className="pDVlue">
                            {selectedPatient?.patientFullName}
                          </span>
                        </div>
                        <div className="pDemogInd pdSmallTxt">
                          <span className="pDVlue">
                            {selectedPatient?.patientGender}
                          </span>
                          <span className="pDVlue">{`${calculateDateDifference(
                            selectedPatient?.patientDob
                          )}`}</span>
                        </div>
                      </div>
                    </div>
                    <div className="bb"></div>
                  </>
                )}

                {/* <div className="constWithInvGrp">
                  <div className="tskElemHeding">
                    Past consultation with investigations
                  </div>
                  <div className="constWithInv">
                    <div className="consultDrDT">
                      <div className="cnstDrNam">
                        Dr. Sk. Aftabuddin
                        <span className="constDrSpc">(General Physician)</span>
                      </div>
                      <div className="constDateTime">
                        <span>Consultation Date:</span>12-Sep-2024
                      </div>
                    </div>
                    <div className="listOfInv">
                      <div className="indvInv formElement">
                        <FormControl className="formControl">
                          <FormGroup
                            aria-label="position"
                            row
                            className="feformGroup"
                          >
                            <FormControlLabel
                              className="formCheckBox"
                              control={<Checkbox />}
                              label="Urine Routine Examination"
                              labelPlacement="end"
                            />
                          </FormGroup>
                        </FormControl>
                      </div>
                      <div className="indvInv formElement">
                        <FormControl className="formControl">
                          <FormGroup
                            aria-label="position"
                            row
                            className="feformGroup"
                          >
                            <FormControlLabel
                              className="formCheckBox"
                              control={<Checkbox />}
                              label="Electrocardiogram (ECG)"
                              labelPlacement="end"
                            />
                          </FormGroup>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                  <div className="constWithInv">
                    <div className="consultDrDT">
                      <div className="cnstDrNam">
                        Dr. Prashant Shah
                        <span className="constDrSpc">(General Physician)</span>
                      </div>
                      <div className="constDateTime">
                        <span>Consultation Date:</span>10-Aug-2024
                      </div>
                    </div>
                    <div className="listOfInv">
                      <div className="indvInv formElement">
                        <FormControl className="formControl">
                          <FormGroup
                            aria-label="position"
                            row
                            className="feformGroup"
                          >
                            <FormControlLabel
                              className="formCheckBox"
                              control={<Checkbox />}
                              label="MRI"
                              labelPlacement="end"
                            />
                          </FormGroup>
                        </FormControl>
                      </div>
                      <div className="indvInv formElement">
                        <FormControl className="formControl">
                          <FormGroup
                            aria-label="position"
                            row
                            className="feformGroup"
                          >
                            <FormControlLabel
                              className="formCheckBox"
                              control={<Checkbox />}
                              label="Lipid Profile"
                              labelPlacement="end"
                            />
                          </FormGroup>
                        </FormControl>
                      </div>
                      <div className="indvInv formElement">
                        <FormControl className="formControl">
                          <FormGroup
                            aria-label="position"
                            row
                            className="feformGroup"
                          >
                            <FormControlLabel
                              className="formCheckBox"
                              control={<Checkbox />}
                              label="Complete Blood Count (CBC)"
                              labelPlacement="end"
                            />
                          </FormGroup>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                  <div className="constWithInv">
                    <div className="consultDrDT">
                      <div className="cnstDrNam">
                        Dr. Sanket Santra
                        <span className="constDrSpc">(General Physician)</span>
                      </div>
                      <div className="constDateTime">
                        <span>Consultation Date:</span>2-Aug-2024
                      </div>
                    </div>
                    <div className="listOfInv">
                      <div className="indvInv formElement">
                        <FormControl className="formControl">
                          <FormGroup
                            aria-label="position"
                            row
                            className="feformGroup"
                          >
                            <FormControlLabel
                              className="formCheckBox"
                              control={<Checkbox />}
                              label="MRI"
                              labelPlacement="end"
                            />
                          </FormGroup>
                        </FormControl>
                      </div>
                      <div className="indvInv formElement">
                        <FormControl className="formControl">
                          <FormGroup
                            aria-label="position"
                            row
                            className="feformGroup"
                          >
                            <FormControlLabel
                              className="formCheckBox"
                              control={<Checkbox />}
                              label="Lipid Profile"
                              labelPlacement="end"
                            />
                          </FormGroup>
                        </FormControl>
                      </div>
                      <div className="indvInv formElement">
                        <FormControl className="formControl">
                          <FormGroup
                            aria-label="position"
                            row
                            className="feformGroup"
                          >
                            <FormControlLabel
                              className="formCheckBox"
                              control={<Checkbox />}
                              label="Complete Blood Count (CBC)"
                              labelPlacement="end"
                            />
                          </FormGroup>
                        </FormControl>
                      </div>
                      <div className="indvInv formElement">
                        <FormControl className="formControl">
                          <FormGroup
                            aria-label="position"
                            row
                            className="feformGroup"
                          >
                            <FormControlLabel
                              className="formCheckBox"
                              control={<Checkbox />}
                              label="Liver Function Test (LFT)"
                              labelPlacement="end"
                            />
                          </FormGroup>
                        </FormControl>
                      </div>
                      <div className="indvInv formElement">
                        <FormControl className="formControl">
                          <FormGroup
                            aria-label="position"
                            row
                            className="feformGroup"
                          >
                            <FormControlLabel
                              className="formCheckBox"
                              control={<Checkbox />}
                              label="Kidney Function Test (KFT)"
                              labelPlacement="end"
                            />
                          </FormGroup>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                </div> */}

                {selectedPatient && (
                  <>
                    <div className="tskElemHeding">
                      Investigations form prescriptions
                    </div>
                    <div className="listOfInv">
                      {suggestedTestList?.map((test, index) => {
                        return (
                          <Tooltip
                            arrow
                            title={
                              <>
                                <div className="constDRDT">
                                  <div className="cnstDrNam">
                                    {test.doctorName}
                                  </div>
                                  <div className="constDateTime">
                                    {test.consultDate}
                                  </div>
                                </div>
                              </>
                            }
                          >
                            <div className="indvInv formElement">
                              <FormControl className="formControl">
                                <FormGroup
                                  aria-label="position"
                                  row
                                  className="feformGroup"
                                >
                                  <FormControlLabel
                                    className="formCheckBox"
                                    control={
                                      <Checkbox
                                        value={test.isSelected || false}
                                        onChange={async (e) => {
                                          const temp = [...suggestedTestList];
                                          temp[index].isSelected =
                                            e.target.checked;
                                          setSuggestedTestList(temp);
                                          
                                          if (e.target.checked) {
                                            let tempInvestigation =
                                              investigationList?.find(
                                                (item) =>
                                                  item.testId === test.testId
                                              );
                                            if (tempInvestigation) {
                                              let reqDto = {
                                                testId:
                                                  test?.testId,
                                                gender:
                                                  selectedPatient?.patientGender,
                                                age: getAge(
                                                  selectedPatient?.patientDob
                                                ),
                                              };
                                              const parameters =
                                                await zhPatientService.getTestParameters(
                                                  reqDto
                                                );
                                              tempInvestigation = {
                                                ...tempInvestigation,
                                                parameterList: parameters.data,
                                              };
                                              if (parameters.data) {
                                              }
                                              const tUserList = [
                                                ...userInvestigationList,
                                              ];
                                              tUserList.push(tempInvestigation);
                                              setUserInvestigationList(
                                                tUserList
                                              );
                                            } else {
                                              toast.error(
                                                "Test is not availabe in this center"
                                              );
                                              return;
                                            }
                                          } else {
                                            const investigationIndex =
                                              userInvestigationList?.findIndex(
                                                (item) =>
                                                  item.testId === test.testId
                                              );
                                            if (investigationIndex >= 0) {
                                              const tempUList = [
                                                ...userInvestigationList,
                                              ];
                                              tempUList.splice(
                                                investigationIndex,
                                                1
                                              );
                                              setUserInvestigationList(
                                                tempUList
                                              );
                                            }
                                          }
                                        }}
                                      />
                                    }
                                    label={test.testName}
                                    labelPlacement="end"
                                  />
                                </FormGroup>
                              </FormControl>
                            </div>
                          </Tooltip>
                        );
                      })}
                    </div>
                    <div className="bb"></div>
                    {userInvestigationList?.length > 0 && (
                      <>
                        <div className="tskElemHeding">
                          Add New Investigation
                        </div>

                        <div className="formElementGrp">
                          <div className="formElement">
                            <FormControl className="formControl">
                              <Autocomplete
                                freeSolo
                                className="formAutocompleteField"
                                variant="outlined"
                                value={selectedInvestigation}
                                options={suggestedInvestigation}
                                inputValue={investigationSearchString}
                                onChange={(e, newValue) => {
                                  setSelectedInvestigation(newValue);
                                }}
                                onInputChange={(e, value, reason) => {
                                  setInvestigtionSearchString(value);
                                }}
                                getOptionLabel={(option) => option.testName}
                                renderOption={(props, item) => {
                                  return (
                                    <li {...props} key={item.id}>
                                      {item.testName}
                                    </li>
                                  );
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    className="formAutoComInputField autocompFildPlaceholder"
                                    placeholder={t("search_investigations")}
                                    InputProps={{
                                      ...params.InputProps,
                                    }}
                                  />
                                )}
                              />
                            </FormControl>
                          </div>

                          <Button
                            className="dfultPrimaryBtn "
                            startIcon={<Add />}
                            onClick={async () => {
                              let tempInvestigation = selectedInvestigation;
                              if (!tempInvestigation) {
                                toast.error("Please Select Investigation");
                                return;
                              }
                              let reqDto = {
                                testId: selectedInvestigation?.testId,
                                gender: selectedInvestigation?.gender,
                                age: getAge(selectedPatient?.patientDob),
                              };
                              let parameterData =
                                await zhPatientService.getTestParameters(
                                  reqDto
                                );
                              tempInvestigation.parameterList =
                                parameterData.data;
                              setUserInvestigationList((prev) => [
                                ...prev,
                                tempInvestigation,
                              ]);

                              initializeForm();
                            }}
                          >
                            {t("add")}
                          </Button>
                        </div>
                        <div className="bb"></div>

                        <div className="testResult">
                          <table className="testResulttable testResultInput">
                            <thead>
                              <tr>
                                <th className="w50p">{t("test_name")}</th>
                                <th className="w15p textCenter">
                                  {t("value")}
                                </th>
                                <th className="w15p textCenter">{t("unit")}</th>
                                <th className="w20p">
                                  {t("bio_ref_interval")}
                                </th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {userInvestigationList?.map((item, index) => (
                                <>
                                  <tr>
                                    <td colSpan={5} className="singlRow">
                                      <div className="testNMTstloc">
                                        <div className="testNameH">
                                          {item.testName}
                                        </div>

                                        <div className="formElement">
                                          <FormControl className="formControl">
                                            <FormGroup
                                              className="formRadioGroup"
                                              labelId="setTasktype"
                                              name="setTasktype"
                                            >
                                              <FormControlLabel
                                                className="formRadioField"
                                                value="inhouseReport"
                                                control={
                                                  <Checkbox
                                                    checked={
                                                      item.outSource || false
                                                    }
                                                    onChange={(e) => {
                                                      let temp = [
                                                        ...userInvestigationList,
                                                      ];
                                                      temp[index].outSource =
                                                        e.target.checked;
                                                      setUserInvestigationList(
                                                        temp
                                                      );
                                                    }}
                                                  />
                                                }
                                                label="Outsource Report"
                                              />
                                            </FormGroup>
                                          </FormControl>
                                        </div>
                                      </div>
                                      <div className="testInformation mt5">
                                        <div className="formElementGroup">
                                          <div className="formElement">
                                            <FormControl className="formControl">
                                              <InputLabel
                                                id="setCompletionDate"
                                                className="setCompletionDate"
                                              >
                                                Collaction Date Time*
                                              </InputLabel>
                                              <ReactDatePicker
                                                showIcon
                                                toggleCalendarOnIconClick
                                                labelId="setCompletionDate"
                                                className="formDatePicker"
                                                selected={
                                                  item.sampleCollectionTime
                                                }
                                                maxDate={new Date()}
                                                showTimeSelect
                                                // filterTime={filterPassedTime}
                                                dateFormat="dd/MM/yyyy h:mm aa"
                                                onChange={(date) => {
                                                  let temp = [
                                                    ...userInvestigationList,
                                                  ];
                                                  temp[
                                                    index
                                                  ].sampleCollectionTime = date;
                                                  setUserInvestigationList(
                                                    temp
                                                  );
                                                }}
                                              />
                                            </FormControl>
                                          </div>
                                          <div className="formElement">
                                            <FormControl className="formControl">
                                              <InputLabel id="sampleType-label">
                                                Sample Type
                                              </InputLabel>
                                              <Select
                                                labelId="sampleType-label"
                                                id="sampleType-select"
                                                label="Sample Type"
                                                className="formInputField"
                                                variant="outlined"
                                                value={item.sampleType}
                                                onChange={(e) => {
                                                  let temp = [
                                                    ...userInvestigationList,
                                                  ];
                                                  temp[index].sampleType =
                                                    e.target.value;
                                                  setUserInvestigationList(
                                                    temp
                                                  );
                                                }}
                                              >
                                                <MenuItem value="Blood">
                                                  Blood
                                                </MenuItem>
                                                <MenuItem value="Urine">
                                                  Urine
                                                </MenuItem>
                                                <MenuItem value="Sputum">
                                                  Sputum
                                                </MenuItem>
                                              </Select>
                                            </FormControl>
                                          </div>
                                          <div className="formElement">
                                            <FormControl className="formControl">
                                              <TextField
                                                value={item.reagent}
                                                label="Reagent"
                                                autoComplete="off"
                                                placeholder="Enter Reagent"
                                                variant="outlined"
                                                className="formTextFieldArea"
                                                onChange={(e) => {
                                                  let temp = [
                                                    ...userInvestigationList,
                                                  ];
                                                  temp[index].reagent =
                                                    e.target.value;
                                                  setUserInvestigationList(
                                                    temp
                                                  );
                                                }}
                                              />
                                            </FormControl>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                  {item?.parameterList?.map(
                                    (parameterItem, parIndex) => {
                                      return (
                                        <tr>
                                          <td>
                                            <div className="testElmGrp">
                                              <div className="testElmNM">
                                                {parameterItem.parameter}
                                              </div>
                                              {parameterItem.method && (
                                                <div className="testElmDtl">
                                                  {` Method: ${parameterItem.method}`}
                                                </div>
                                              )}
                                            </div>
                                          </td>
                                          <td>
                                            <div className="testValue textCenter opc1">
                                              <div className="formElement minWidth100px">
                                                <FormControl className="formControl">
                                                  <TextField
                                                    label="Value"
                                                    placeholder="eg:120"
                                                    autoComplete="off"
                                                    variant="outlined"
                                                    className="formTextFieldArea"
                                                    value={
                                                      // testList?.[index]?.testResult?.[
                                                      //   parIndex
                                                      // ]?.parameterValue || ""
                                                      userInvestigationList?.[
                                                        index
                                                      ]?.parameterList?.[
                                                        parIndex
                                                      ]?.value || ""
                                                    }
                                                    onChange={(e) => {
                                                      const value =
                                                        e.target.value;
                                                      const temp = [
                                                        ...userInvestigationList,
                                                      ];

                                                      temp[index].parameterList[
                                                        parIndex
                                                      ].value = value;
                                                      setUserInvestigationList(
                                                        temp
                                                      );
                                                    }}
                                                  />
                                                </FormControl>
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="testUnit textCenter">
                                              {parameterItem.unit}
                                            </div>
                                          </td>
                                          <td>
                                            <div className="testNrmlRange">
                                              <div className="tstnRangeElm">
                                                Desirable : &lt; 150
                                              </div>
                                              <div className="tstnRangeElm">
                                                Borderline High: 150-199
                                              </div>
                                              <div className="tstnRangeElm">
                                                {" "}
                                                High: 200-499
                                              </div>
                                              <div className="tstnRangeElm">
                                                Very High : &gt; 500
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <IconButton
                                              className="deleteRowBtn"
                                              onClick={() => {
                                                const temp = [
                                                  ...userInvestigationList,
                                                ];
                                                console.log("temp =", temp);
                                                temp[
                                                  index
                                                ].parameterList.splice(
                                                  parIndex,
                                                  1
                                                );
                                                setUserInvestigationList(temp);
                                              }}
                                            >
                                              <Trash />
                                            </IconButton>
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                                  {addParameter && (
                                    <>
                                      <tr>
                                        <td colSpan={5}>
                                          <div className="addNewformGrp ">
                                            {/* <div className="formElement">
                                  <FormControl className="formControl">
                                    <Autocomplete
                                      freeSolo
                                      className="formAutocompleteField"
                                      variant="outlined"
                                      options={invList.map(
                                        (option) => option.invName
                                      )}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          className="formAutoComInputField autocompFildPlaceholder"
                                          label={t("parameter_name")}
                                          InputProps={{
                                            ...params.InputProps,
                                          }}
                                        />
                                      )}
                                    />
                                  </FormControl>
                                </div> */}

                                            <div className="formElement">
                                              <FormControl className="formControl">
                                                <TextField
                                                  value={testParameterName}
                                                  label="Parameter Name"
                                                  autoComplete="off"
                                                  placeholder="Parameter Name"
                                                  variant="outlined"
                                                  className="formTextFieldArea"
                                                  onChange={(e) => {
                                                    setTestParameterName(
                                                      e.target.value
                                                    );
                                                  }}
                                                />
                                              </FormControl>
                                            </div>

                                            <div className="formElement">
                                              <FormControl className="formControl">
                                                <TextField
                                                  value={testMethod}
                                                  label="Method"
                                                  autoComplete="off"
                                                  placeholder="Enter Method"
                                                  variant="outlined"
                                                  className="formTextFieldArea"
                                                  onChange={(e) => {
                                                    setTestMethod(
                                                      e.target.value
                                                    );
                                                  }}
                                                />
                                              </FormControl>
                                            </div>

                                            <div className="formElement minWidth100px">
                                              <FormControl className="formControl">
                                                <TextField
                                                  label="Value"
                                                  placeholder="eg:120"
                                                  autoComplete="off"
                                                  variant="outlined"
                                                  className="formTextFieldArea"
                                                  value={testValue}
                                                  onChange={(e) =>
                                                    setTestValue(e.target.value)
                                                  }
                                                />
                                              </FormControl>
                                            </div>

                                            <div className="formElement minWidth100px">
                                              <FormControl className="formControl">
                                                <TextField
                                                  label="Unit"
                                                  placeholder="eg:120"
                                                  autoComplete="off"
                                                  variant="outlined"
                                                  className="formTextFieldArea"
                                                  value={testUnit}
                                                  onChange={(e) =>
                                                    setTestUnit(e.target.value)
                                                  }
                                                />
                                              </FormControl>
                                            </div>
                                          </div>
                                          <div className="addNewformGrp ">
                                            <div className="formElement">
                                              <FormControl className="formControl">
                                                <TextField
                                                  label="Reference Range"
                                                  autoComplete="off"
                                                  variant="outlined"
                                                  className="formTextFieldArea"
                                                  value={testReferenceRange}
                                                  onChange={(e) => {
                                                    setTestReferenceRange(
                                                      e.target.value
                                                    );
                                                  }}
                                                />
                                              </FormControl>
                                            </div>

                                            <IconButton
                                              className="updateRowBtn"
                                              onClick={(e) => {
                                                const dto = {
                                                  parameter: testParameterName,
                                                  method: testMethod,
                                                  unit: testUnit,
                                                  value: testValue,
                                                  refRange: [],
                                                };
                                                const temp = [
                                                  ...userInvestigationList,
                                                ];
                                                temp?.[
                                                  index
                                                ]?.parameterList?.push(dto);
                                                initializeAddParameter();
                                                setUserInvestigationList(temp);
                                              }}
                                            >
                                              <Add />
                                            </IconButton>
                                          </div>
                                        </td>
                                      </tr>
                                    </>
                                  )}
                                  <tr>
                                    <td colSpan={5}>
                                      <div className="testControlBtnGrp">
                                        <Button
                                          className="removeTest"
                                          startIcon={<Trash />}
                                          onClick={() => {
                                            const temp = [
                                              ...userInvestigationList,
                                            ];
                                            temp.splice(index, 1);
                                            setUserInvestigationList(temp);
                                          }}
                                        >
                                          Remove
                                        </Button>
                                        <div className="updateNewTest formBtnElement">
                                          <input
                                            // ref={fileRef}
                                            hidden
                                            onChange={async (e) => {
                                              const fileData =
                                                await handelFileUpload(e);
                                              const temp = [
                                                ...tempFilteredTest,
                                              ];
                                              temp[index].fileData = fileData;
                                              setTempFilteredTest(temp);
                                            }}
                                            type="file"
                                            id={`upload-button-${item.testId}`}
                                          />
                                          <Button
                                            variant="outlined"
                                            startIcon={<AttachFile />}
                                            className="comBtnOutline"
                                            onClick={() => {
                                              document
                                                .getElementById(
                                                  `upload-button-${item.testId}`
                                                )
                                                .click();
                                            }}
                                          >
                                            Upload Report
                                          </Button>
                                          <Button
                                            startIcon={<Add />}
                                            className={
                                              addParameter
                                                ? "dfultPrimaryBtn"
                                                : "dfultDarkSecondaryBtn"
                                            }
                                            onClick={toggleaddParameter}
                                          >
                                            {t("add_parameter")}
                                          </Button>
                                          {/* <Button
                                    startIcon={<Save />}
                                    className="dfultPrimaryBtn"
                                  >
                                    Save
                                  </Button> */}
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="rfContFooter">
            <div className="formBtnElement">
              <Button
                className="dfultPrimaryBtn"
                onClick={async () => {
                  const promises = userInvestigationList?.map((test) => {
                    let taskResult = test?.parameterList
                      ?.filter((item) => (item.value ? true : false))
                      .map((parameterItem) => {
                        return {
                          parameterName: parameterItem.parameter,
                          parameterValue: parameterItem.value,
                          parameterUnit: parameterItem.unit,
                          parameterRefRange: parameterItem.refRange[0],
                          parameterId: 0,
                        };
                      });
                    const reqDto = {
                      sampleType: test.sampleType || "",
                      testId: test.testId,
                      requestionId: 0,
                      testName: test.testName,
                      // visitId: extraData?.queueVisitId,
                      patientId: selectedPatient?.rowPatientId,
                      outSource: test.outSource ? "Y" : "N",
                      fileName: test?.fileData?.fileName || "",
                      reagent: test.reagent || "",
                      sampleCollectionTime: dayjs(test.sampleCollectionTime).format(
                        "DD-MM-YYYY HH:mm:ss"
                      ),
                      timezone: timezone || "Asia/Calcutta",
                      fileData: test?.fileData?.fileDataLink || "",
                      orgId: loginUserDetails.orgId,
                      testResult: taskResult,
                      unitId:loginUserDetails.signleUnitId
                    };
                    console.log("reqdto =", reqDto);
                    // return reqDto;
                    return zhPatientService.submitTestResultWithoutRequestion(
                      loginUserDetails.userId,
                      reqDto
                    );
                  });
                  console.log("********* promises =", promises);

                  try {
                    await Promise.all(promises);

                    console.log("All tests submitted successfully!");
                  } catch (error) {
                    console.error(
                      "Error occurred while submitting tests:",
                      error
                    );
                  }
                }}
              >
                Submit
              </Button>
              <Button
                className="dfultDarkSecondaryBtn"
                onClick={() => handelCloseAddInvestigationUnit()}
              >
                Close
              </Button>
            </div>
          </div>
        </div>
      </div>

      {registerFace && (
        <ConfirmProvider>
          <PatientFacialBiometricsRegister
            open={registerFace}
            onClose={() => setRegisterFace(false)}
            setRegisterFace={setRegisterFace}
            onFaceLivenessCheckComplete={onFaceLivenessCheckComplete}
          />
        </ConfirmProvider>
      )}
    </>
  );
}
