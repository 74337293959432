import { ArrowBack } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DateUtils from "../../../../../utils/DateUtils";
import { jwtDecode } from "jwt-decode";
import { Search } from "react-bootstrap-icons";
import DashboardServices from "../../../../../services/DashboardServices";

export default function ViewReferredPatient({
  handleCloseViewReferredPatient,
  closeAll,
}) {
  const userDetails = jwtDecode(localStorage.getItem("token"));
  const [loading, setLoading] = useState(true);
  const [primaryPosdate, setPrimaryPosdate] = useState(new Date());
  const [referredPatientData, setReferredPatientData] = useState([]);
  const handleJoiningprimaryPosdate = (newDate) => {
    setPrimaryPosdate(newDate);
  };

  useEffect(() => {
    fetchReferredPatientData();
  }, []);

  const fetchReferredPatientData = () => {
    DashboardServices.getReferredPatients(
      userDetails.signleUnitId,
      DateUtils.getDateInDDMMYYYY(primaryPosdate)
    ).then((response) => {
      console.log("response of consul List", response.data);
      setReferredPatientData(response.data);
      setLoading(false);
    });
  };

  return (
    <>
      <div className="AnalyticsDashboardContainer">
        <div className="AnalyticsDashboardSubHeader">
          <IconButton
            onClick={() => {
              console.log("closing");
              handleCloseViewReferredPatient();
              closeAll();
            }}
          >
            <ArrowBack />
          </IconButton>
          Referred Patient
        </div>
        <div className="anDashCardContainArea">
          <div className="fullContainArea">
            <div className="cuHeaderGrp">
              <div className="formSearchGrp">
                <div className="formElement width180">
                  <FormControl className="formControl">
                    <InputLabel id="primPosition" className="setCompletionDate">
                      Select Date
                    </InputLabel>
                    <ReactDatePicker
                      toggleCalendarOnIconClick
                      showYearDropdown
                      yearDropdownItemNumber={100}
                      scrollableYearDropdown
                      showMonthDropdown
                      showIcon
                      labelId="primPosition"
                      className="formDatePicker"
                      selected={primaryPosdate}
                      // minDate={new Date()}
                      maxDate={new Date()}
                      dateFormat="dd/MM/yyyy"
                      onChange={(date) => {
                        handleJoiningprimaryPosdate(date);
                      }}
                    />
                  </FormControl>
                </div>
                <Button
                  className="dfultPrimaryBtn"
                  onClick={fetchReferredPatientData}
                  startIcon={<Search />}
                >
                  Search
                </Button>
              </div>
            </div>

            <div className="cuContainArea">
              {loading ? (
                <div className="center-progress" style={{ height: "65vh" }}>
                  <CircularProgress sx={{ marginTop: "180px" }} />
                </div>
              ) : referredPatientData.length === 0 ? (
                <div class="noDataCard ">
                  {/* <span>oops</span>No data */}
                  <br />
                  <span className="sallMsg">
                    No Refer Patient Data is found
                  </span>
                </div>
              ) : (
                <div className="TableContainer">
                  <table className="taskListTable">
                    <thead className="taskListtableHeader">
                      <tr>
                        <th>
                          <div className="textWsearch">
                            <span>Patient Name</span>
                          </div>
                        </th>
                        <th>Doctor Name</th>
                        <th>Doctor Note</th>
                        <th>Referral Note</th>
                      </tr>
                    </thead>

                    <tbody className="scrolableTableBody">
                      {referredPatientData.map((refer, index) => (
                        <tr key={index}>
                          <td>
                            <div className="tskLstTaskNM tdFdC">
                              <span className="tdHtext">
                                {refer.patientName}
                              </span>
                              <span className="tdStext">
                                ({refer.patientGender}, {refer.patientAge})
                              </span>
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM tdFdC">
                              <span className="tdHtext">
                                {refer.doctorName}
                              </span>
                              <span className="tdStext">
                                ({refer.doctorSpecialization})
                              </span>
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {refer.doctorNote
                                ? refer.doctorNote
                                : "No Doctor Notes"}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {refer.refferalNote
                                ? refer.refferalNote
                                : "No Referral Notes"}
                            </div>
                          </td>
                        </tr>
                      ))}
                      {/* <tr>
                      <td>
                        <div className="tskLstTaskNM tdFdC">
                          <span className="tdHtext">Vikash Kumar</span>
                          <span className="tdStext">(Male, 25/Aug/1993)</span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM tdFdC">
                          <span className="tdHtext">Dr. Prashnat Shah</span>
                          <span className="tdStext">(Cardiologists)</span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          <span className="constStatus statCancel">Cancel</span>
                        </div>
                      </td>

                      <td>
                      </td>
                    </tr> */}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
