import {
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    FormControl,
    TextField,
} from "@mui/material";
import React, { useState } from "react";
import DashboardServices from "../../../../../services/DashboardServices";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";

export const PatientCancelConsultation = ({ closeModal, visitId }) => {
    const userDetails = jwtDecode(localStorage.getItem("token"));
    const [cancellationRemark, setCencallationRemark] = useState("");



    const handlePatientLogout = () => {
        if (cancellationRemark.trim() === "") {
            toast.error("Please enter a reason for cancellation.", {
                position: toast.POSITION.TOP_RIGHT,
            });
            return;
        }
    
        DashboardServices.patientLeft(visitId, userDetails.userId, cancellationRemark)
            .then((response) => {
                console.log('response', response.data);
                if (response.data === 1) {
                    toast.success("Consultation canceled successfully.", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    closeModal();
                } else {
                    toast.error("Failed to cancel the consultation. Please try again.", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                toast.error("An unexpected error occurred. Please try again later.", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            });
    };
    

    return (
        <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
            <div className="addMoreAgendaModel">
                <div className="modelTitle">Patient leave center</div>

                <div className="elementFormContainer">


                    <div className="formElement">
                        <FormControl className="formControl">
                            <TextField
                                className="modelTextFild"
                                required
                                label="Reason"
                                variant="outlined"
                                multiline
                                rows={4}
                                maxRows={7}
                                value={cancellationRemark}
                                onChange={(e) => {
                                    setCencallationRemark(e.target.value);
                                }}
                            />
                        </FormControl>
                    </div>
                </div>

                {/* <div className="modAtchFileBtn">
            <label class="btn-up">
              <input type="file" hidden />
              Attach File
            </label>
          </div> */}
                <div className="modActionBtnGrp">
                    <Button className="dfultPrimaryBtn" onClick={() => handlePatientLogout()}>Submit</Button>
                    <Button
                        className="dfultDarkSecondaryBtn"
                        onClick={() => {
                            closeModal();
                        }}
                    >
                        Close
                    </Button>
                </div>
            </div>
        </Box>
    );
};
